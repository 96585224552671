
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MdlModule } from "@angular-mdl/core";
import { VncLibraryModule } from "vnc-library";

import {
  PageNotFoundComponent,
  AppsDialogComponent,
  AppSwitcherComponent,
  AuthenticationComponent,
  RedirectComponent,
  UserAvatarComponent
} from "./components";

import {
  VNCLongPressDirective,
  SuppressEventsDirective
} from "./directives";

import { TranslateModule } from "@ngx-translate/core";
import { TaskNotificationComponent, TaskNotificationsComponent } from "../task/components";

import { MatFormFieldModule } from "@angular/material/form-field";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatSelectModule } from "@angular/material/select";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatDividerModule } from "@angular/material/divider";
import { MatInputModule } from "@angular/material/input";
import { MatExpansionModule } from "@angular/material/expansion";
import { MentionModule } from "./directives/mention";
import { TFASettingsComponent } from "./components/tfa-settings/tfa-settings.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatTabsModule } from "@angular/material/tabs";

@NgModule({
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatExpansionModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatTooltipModule,
    MatDividerModule,
    MatDialogModule,
    MatButtonModule,
    VncLibraryModule,
    MatTabsModule
  ],
  exports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatExpansionModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatTooltipModule,
    MatDividerModule,
    MatDialogModule,
    MatButtonModule,
    VncLibraryModule,
    MatTabsModule
  ]
})
export class MaterialModule {}


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MdlModule,
        ReactiveFormsModule,
        TranslateModule,
        MaterialModule,
        MentionModule
    ],
    exports: [
        FormsModule,
        MdlModule,
        ReactiveFormsModule,
        PageNotFoundComponent,
        AppsDialogComponent,
        AppSwitcherComponent,
        AuthenticationComponent,
        TranslateModule,
        VNCLongPressDirective,
        SuppressEventsDirective,
        TaskNotificationComponent,
        TaskNotificationsComponent,
        RedirectComponent,
        TFASettingsComponent,
        MaterialModule,
        MentionModule,
        UserAvatarComponent
    ],
    declarations: [
        PageNotFoundComponent,
        AppsDialogComponent,
        AppSwitcherComponent,
        AuthenticationComponent,
        VNCLongPressDirective,
        SuppressEventsDirective,
        TaskNotificationComponent,
        TaskNotificationsComponent,
        RedirectComponent,
        TFASettingsComponent,
        UserAvatarComponent
    ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule,
      providers: [
      ]
    };
  }
}
