
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

  <ng-template #startDateIcon>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="Icon/product/date-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path d="M15.5,3 C16.3284271,3 17,3.67157288 17,4.5 L17,5 L18,5 C19.5976809,5 20.9036609,6.24891996 20.9949073,7.82372721 L21,8 L21,18 C21,19.5976809 19.75108,20.9036609 18.1762728,20.9949073 L6,21 C4.40231912,21 3.09633912,19.75108 3.00509269,18.1762728 L3,18 L3,8 C3,6.40231912 4.24891996,5.09633912 5.82372721,5.00509269 L7,5.005 L7,4.5 C7,3.67157288 7.67157288,3 8.5,3 C9.32842712,3 10,3.67157288 10,4.5 L10,5.003 L14,5.002 L14,4.5 C14,3.67157288 14.6715729,3 15.5,3 Z M18,7 L6,7 C5.48716416,7 5.06449284,7.38604019 5.00672773,7.88337887 L5,8 L5,18 C5,18.5128358 5.38604019,18.9355072 5.88337887,18.9932723 L6,19 L18,19 C18.5128358,19 18.9355072,18.6139598 18.9932723,18.1166211 L19,18 L19,8 C19,7.44771525 18.5522847,7 18,7 Z M16.9804916,14 C17.5370911,14 17.9883041,14.451213 17.9883041,15.0078125 C17.9883041,15.564412 17.5370911,16.015625 16.9804916,16.015625 L7.01950841,16.015625 C6.46290893,16.015625 6.01169591,15.564412 6.01169591,15.0078125 C6.01169591,14.451213 6.46290893,14 7.01950841,14 L16.9804916,14 Z M7.5,9 C8.32842712,9 9,9.67157288 9,10.5 C9,11.3284271 8.32842712,12 7.5,12 C6.67157288,12 6,11.3284271 6,10.5 C6,9.67157288 6.67157288,9 7.5,9 Z" id="Combined-Shape" fill="#FFFFFF"></path>
      </g>
    </svg>
  </ng-template>

<!-- Icons End -->

<div class="icons-block" *ngIf="!activate">
  <div class="date-icon start-date-icon" (click)="selectActivateData()">
    <ng-container [ngTemplateOutlet]="startDateIcon"></ng-container>
  </div>
  <span>{{ 'TASK_START_DATE' | translate }}</span>
</div>

<!-- <div id="start_date_option" class="option-icon" *ngIf="onMainView" [class.selected]="value || isVisible" (click)="onOpen.emit(id); activate=true">
  <mat-icon>today</mat-icon>
  <p>{{ 'TASK_START_DATE' | translate }}</p>
</div> -->

<div id="start_date_option_list" class="option-list" *ngIf="activate">
  <mat-list role="list">
    <mat-list-item role="listitem" *ngFor="let item of filteredItems; let i = index;" [class.selected]="i == selectedIndex" (click)="selectedDate(item);activate=false">
      <mat-icon *ngIf="item.date">today</mat-icon>
      <mat-icon *ngIf="!item.date">event_busy</mat-icon>
      <div class="date-items" style="display: flex;width:100%;justify-content:space-between;">
        <span class="dateDesc">{{ item.name }}</span>
        <span class="align-right" *ngIf="item.date"> {{ item.date | vpLocaleDate: 'MMM d'}} </span>
        <span class="align-right" *ngIf="!item.date">{{ 'NONE' | translate }}</span>
      </div>
    </mat-list-item>
    <mat-list-item role="listitem" class="date-list-last-item">
      <button class="date-picker-button mat-button" (click)='openDatePickerLocal(dateType)'>
        <mat-icon>today</mat-icon>
        <span>{{ 'PICK_START_DATE' | translate }}</span>
      </button>
    </mat-list-item>
  </mat-list>
</div>

