
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ActionReducerMap, createSelector } from "@ngrx/store";
import {
_getIsLoggedInUserLoading,
  appReducer,
  AppState,
  _IsDeviceReady,
  _getIsDatabaseReady,
  _getOnlineStatus,
  _getLoginStatus,
  _getFederatedApps,
  _getIsWindowFocused,
  _getUserProfile,
  _getActiveProfile,
  _getIsRightSidebarExpanded
} from "./app";
import { AppActionTypes } from "../actions/app";
import { VNCContactState, vncContactReducer, vncContactAdapter, _getAllContacts } from "./contacts.reducers";

export interface RootState {
  app: AppState;
  vncContact: VNCContactState;
}

export const reducers: ActionReducerMap<RootState> = {
  app: appReducer,
  vncContact: vncContactReducer,
};

export const getVNCContactState = state => state.vncContact;

// VNCd Contacts
export const {
  selectIds: getVNCContactsIds,
  selectEntities: getVNCContactEntities,
  selectAll: getAllVNCContacts,
  selectTotal: getTotalVNCContacts,
} = vncContactAdapter.getSelectors(getVNCContactState);
export const getVNCContacts = getAllVNCContacts;

export const getContactById = (state: RootState, id: string) => {
  return getVNCContactEntities(state)[id];
};

/**
 *  App Related Reducers
 */
export const getAppState =  state => state.app;
export const getIsLoggedInUserLoading = createSelector(getAppState, _getIsLoggedInUserLoading);
export const getIsDeviceReady = createSelector(getAppState, _IsDeviceReady);
export const IsDatabaseReady = createSelector(getAppState, _getIsDatabaseReady);
export const getOnlineStatus = createSelector(getAppState, _getOnlineStatus);
export const getLoginStatus = createSelector(getAppState, _getLoginStatus);
export const getIsWindowFocused = createSelector(getAppState, _getIsWindowFocused);
export const getUserProfile = createSelector(getAppState, _getUserProfile);
export const getActiveProfile = createSelector(getAppState, _getActiveProfile);
export const getIsRightSidebarExpanded = createSelector(getAppState, _getIsRightSidebarExpanded);

export const getAllContacts = createSelector(getVNCContactState, _getAllContacts);

export function reset(reducer) {
  return function (state, action) {

    if (action.type === AppActionTypes.LOGOUT) {
      state = undefined;
    }

    return reducer(state, action);
  };
}
export const getFederatedApps = createSelector(getAppState, _getFederatedApps);