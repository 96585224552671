
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { CommonUtil } from "../../common/utils/common.utils";
import { take } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable()
export class FilesStorageService {
  private storageLocation;

  constructor() {
    if (environment.isCordova) {
      document.addEventListener("deviceready", this.deviceReady.bind(this), false);
    } else {
      this.deviceReady();
    }
  }

  private deviceReady() {
    console.log("[FilesStorageService] deviceReady");

    // for iOS it's Library/NoCloud
    if (environment.isCordova) {
      this.storageLocation = cordova.file.dataDirectory;
    } else if (environment.isElectron) {
      this.storageLocation = "filesystem:file:///persistent/";
      window.resolveLocalFileSystemURL = window.webkitResolveLocalFileSystemURL || window.resolveLocalFileSystemURL;
      window.requestFileSystem  = window.webkitRequestFileSystem || window.requestFileSystem;
      window.requestFileSystem (window.PERSISTENT, 1000 * 1024 * 1024, function (){});
    }
  }

  downloadImageToDevice(serverUrl, fileName): Observable<string> {
    console.log("[FilesStorageService] downloadImageToDevice", fileName, serverUrl);

    const response = new Subject<string>();
    let blob = null;
    let xhr = new XMLHttpRequest();
    xhr.open("GET", serverUrl);
    xhr.responseType = "blob"; // force the HTTP response, response-type header to be blob
    xhr.onreadystatechange =  () => {
      if (xhr.status === 0) {
        response.error(null);
      }

      if (xhr.readyState === 4 && (xhr.status === 200 || xhr.status === 304)) {
        blob = xhr.response; // xhr.response is now a blob object
        let DataBlob = blob;

        this.saveBlobToDisc(DataBlob, fileName).subscribe((localFileUrl)  => {
          response.next(localFileUrl);
        }, err => {
          response.error(err);
        });
      }
      if (xhr.readyState === 4 && (xhr.status !== 200 && xhr.status !== 304)) {
        response.error(null);
      }
    };

    xhr.send();
    return response.asObservable().pipe(take(1));
  }

  saveBlobToDisc(blob: Blob, fileName: string, returnBase64ContentForiOS: boolean = true): Observable<string> {
    const response = new Subject<string>();

    window.resolveLocalFileSystemURL(this.storageLocation, (dir) => {
      dir.getFile(fileName, { create: true, exclusive: false},  (file) => {
        file.createWriter( (fileWriter) => {
          fileWriter.write(blob);
          fileWriter.onwriteend = () => {
            let localFileUrl = this.filePathOnDisc(fileName);
            if (returnBase64ContentForiOS && CommonUtil.isOnIOS()) {
              window.resolveLocalFileSystemURL(localFileUrl,  (fileEntry) => {
                fileEntry.file((file) => {
                  let reader = new FileReader();
                  reader.onloadend = function (e) {
                    localFileUrl = this.result as string;
                    console.log("[FilesStorageService] downloadImageToDevice iOS success", fileName, localFileUrl);
                    response.next(localFileUrl);
                  };
                  reader.readAsDataURL(file);
                });
              });
            } else {
              console.log("[FilesStorageService] downloadImageToDevice Android success", fileName, localFileUrl);
              response.next(localFileUrl);
            }
          };
          fileWriter.onerror = (err) => {
            console.log("[FilesStorageService] saveBlobToDisc error1", err);
            response.error(err);
            file.remove( () => {}, () => {}, () => {});
          };
        }, (err)  => {
          console.log("[FilesStorageService] saveBlobToDisc error2", err);
          response.error(err);
        });
      });
    }, (err) => {
      console.log("[FilesStorageService] saveBlobToDisc error3", err);
      response.error(err);
    });

    return response.asObservable().pipe(take(1));
  }

  filePathOnDisc(fileName: string){
    return this.storageLocation + fileName;
  }

  filePathOnDiscIfExists(fileName: string): Observable<string> {
    const response = new Subject<string>();

    const localFileUrl = this.filePathOnDisc(fileName);

    window.resolveLocalFileSystemURL(localFileUrl,  (fileEntry) => {
      response.next(localFileUrl);
    }, (err) => {
      response.next(null);
    });

    return response.asObservable().pipe(take(1));
  }

  readBlobFromDisc(fileName: string): Observable<Blob> {
    const response = new Subject<Blob>();

    const localFileUrl = this.filePathOnDisc(fileName);

    window.resolveLocalFileSystemURL(localFileUrl,  (fileEntry) => {
      fileEntry.file((file) => {
        let reader = new FileReader();
        reader.onloadend = function (e) {
          let blob = new Blob([new Uint8Array(this.result as ArrayBuffer)]);
          // var blob = new Blob([new Uint8Array(this.result)], { type: "image/png" });

          response.next(blob);
        };
        reader.readAsArrayBuffer(file);
      });
    }, (err) => {
      console.log("[FilesStorageService] readBlobFromDisc error", err);
      response.next(null);
    });

    return response.asObservable().pipe(take(1));
  }

  ///
  /// Android only stuff

  saveBlobToAndroidDownloadFolder(blob: Blob, fileName: string): Observable<string> {
    const response = new Subject<string>();

    const fileUrl = this.filePathInAndroidDownloadFolder(fileName);

    window["requestFileSystem"](window["PERSISTENT"], blob.size, function (fs) {
      fs.root.getFile(fileUrl, { create: true, exclusive: false }, (fileEntry) => {
        fileEntry.createWriter((fileWriter) => {
          fileWriter.onwriteend = () => {
            console.log("[FilesStorageService] saveBlobToAndroidDownloadFolder success", fileUrl);
            response.next(fileUrl);
          };
          fileWriter.onerror = function (e) {
            response.error(e);
          };
          fileWriter.write(blob);
        });
      }, (err) => {
        console.log("[FilesStorageService] saveBlobToAndroidDownloadFolder error1", err);
        response.error(err);
      });
    }, (err) => {
      console.log("[FilesStorageService] saveBlobToAndroidDownloadFolder error2", err);
      response.error(err);
    });

    return response.asObservable().pipe(take(1));
  }

  filePathInAndroidDownloadFolder(fileName: string){
    return `Download/${fileName}`;
  }

  filePathInAndroidDownloadFolderIfExists(fileName: string){
    const response = new Subject<string>();

    const localFileUrl = this.filePathInAndroidDownloadFolder(fileName);

    window["requestFileSystem"](window["PERSISTENT"], 0, function (fs) {
      fs.root.getFile(localFileUrl, { create: true, exclusive: false }, (fileEntry) => {
        response.next(localFileUrl);
      }, (err) => {
        console.log("[FilesStorageService] filePathInAndroidDownloadFolderIfExists error1", err);
        response.next(null);
      });
    }, (err) => {
      console.log("[FilesStorageService] filePathInAndroidDownloadFolderIfExists error2", err);
      response.next(null);
    });

    return response.asObservable().pipe(take(1));
  }
}
