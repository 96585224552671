
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, forwardRef, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter } from "@angular/core";
import { TaskOptionComponent } from "../task-option.component";
import { Store } from "@ngrx/store";
import { TasksRootState, getLocationList } from "../../../../store/index";
import { CommonUtil } from "../../../../../common/utils/common.utils";
import { CreateLocationDialogComponent } from "../../../../components/create-location-dialog/create-location-dialog.component";
import { Location } from "../../../../models/location";
import { takeWhile } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { TaskService } from "src/app/task/task.service";

@Component({
  selector: "vp-new-vnctask-option-link-location",
  providers: [{
    provide: TaskOptionComponent,
    // eslint-disable-next-line
    useExisting: forwardRef(() => NewTaskLinkLocationOptionComponent)
  }],
  templateUrl: "new-option-link-location.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewTaskLinkLocationOptionComponent extends TaskOptionComponent implements OnInit, OnDestroy {
  id: string = "link-to-location";
  triggerKey = "@";
  isAlive = true;

  activate:boolean = false;
  constructor(private store: Store<TasksRootState>,
    public changerDetectorRef: ChangeDetectorRef,
    private service: TaskService,
    public matDialog: MatDialog ) {
    super(changerDetectorRef, matDialog);
    this.service.cancelActivationOfTags.subscribe(res => {
      this.cancelActivateData();
    });
  }

  ngOnInit() {
    this.store.select(getLocationList).pipe(takeWhile(() => this.isAlive)).subscribe( locations => {
      this.setItems(locations);
    });
  }

  getPayload() {
    let value: Location = this.getValue();
    if (!value) return { };
    return { location_id: value.id, location_name: value.name };
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  openCreateLocationDialog() {
    const dlg = this.matDialog.open(CreateLocationDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-create-location-dialog",
      data: { isCreate: true, selectedLocation: null }
    });
  }

  @Output() selectActivate = new EventEmitter();
  @Output() selectedLocation = new EventEmitter();

  selectActivateData() {
    this.activate = true;
    this.selectActivate.emit(this.activate);
    this.changerDetectorRef.markForCheck();
  }

  cancelActivateData() {
    this.activate = false;
    this.selectActivate.emit(this.activate);
  }

  selectedData(item){
    this.selectedLocation.emit(item);
    this.activate = false;
    this.selectActivate.emit(this.activate);
    this.changerDetectorRef.markForCheck();
  }

}
