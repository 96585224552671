
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ICompact } from "./task";

export class AuthUser {
  id: number;
  login: string;
  firstname: string;
  lastname: string;
  created_on: Date;
  last_login_on: Date;
  api_key: string;
  custom_fields: any[];
  email: string;
  userAvatar: any;
  fullname: string;
  team_user: string;
  can_invite_users: string;
  max_invited_user: number;
  language: string;
  global_mute: string;
  mail_notification: string;
  sound: string;
  notification: string;
  no_self_notified: string;
  jid: string;
  vnctask_channel?: string;

  constructor(json) {
    this.id = json.id;
    this.login = json.login;
    this.firstname = json.firstname;
    this.lastname = json.lastname;
    this.fullname = json.firstname + " " + json.lastname;
    this.created_on = new Date(json.created_on);
    this.last_login_on = new Date(json.last_login_on);
    this.api_key = json.api_key;
    this.custom_fields = json.custom_fields;
    this.email = json.mail ? json.mail : json.email;
    this.userAvatar = json.avatar_url;
    this.team_user = json.team_user;
    this.can_invite_users = json.can_invite_users;
    this.max_invited_user = json.max_invited_user;
    this.language = json.language;
    this.global_mute = json.global_mute;
    this.mail_notification = json.mail_notification;
    this.sound = json.sound;
    this.notification = json.notification;
    this.no_self_notified = json.no_self_notified;
    this.jid = json.jid;
    if (!!json.vnctask_channel) {
      this.vnctask_channel = json.vnctask_channel;
    }
  }
}

export class User {
  id: number;
  name: string;
  avatar: any;
  mail: string;
  username: string;
  firstLastNameCharacter: string;

  constructor(json) {
    this.id = json.id;
    this.name = json.name;
    if (json.avatar) {
      this.avatar = json.avatar;
    } else {
      this.avatar = json.avatar_url;
    }
    this.mail = json.mail;
    if (!json.mail && json.jid) {
      this.mail = json.jid;
    }
    this.username = json.username;
    this.firstLastNameCharacter = this.getCharacters(this.name);
  }

  getCharacters(name) {
    if (name) {
      let firstLatstChar: string;
      let words: string[] = name.split(" ");
      if (words[0]) firstLatstChar = words[0].charAt(0);
      if (words.length > 1) firstLatstChar = firstLatstChar + words[1].charAt(0);
      return firstLatstChar;
    } else {
      return null;
    }
  }
}

export class MyUser {
  id: number;
  name: string;
  avatar: string;
  mail: string;

  constructor(json) {
    this.id = json.id;
    this.name = json.name;
    this.avatar = json.avatar;
    this.mail = json.mail;
  }
}

export class TeamUser {
  id: number;
  name: string;
  avatar: string;
  mail: string;
  archived: boolean;
  firstLastNameCharacter: string;
  isSelected: boolean;

  constructor(json) {
    this.id = json.id;
    this.name = json.name;
    this.avatar = json.avatar;
    this.mail = json.mail;
    if (json.archived) {
      this.archived = true;
    } else {
      this.archived = false;
    }
    this.firstLastNameCharacter = this.getCharacters(this.name);
  }

  getCharacters(name) {
    if (name) {
      let firstLatstChar: string;
      let words: string[] = name.split(" ");
      if (words[0]) firstLatstChar = words[0].charAt(0);
      if (words.length > 1) firstLatstChar = firstLatstChar + words[1].charAt(0);
      return firstLatstChar;
    } else {
      return null;
    }
  }
}
