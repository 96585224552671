
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->
  <ng-template #locationIcon>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Icon/product/location-new</title>
      <g id="Icon/product/location-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Icon-24px" transform="translate(4.000000, 1.000000)" fill="#FFFFFF">
              <path d="M8,0 C12.4228571,0 16,3.443 16,7.7 C16,9.65461538 15.0835503,11.9242604 13.8710168,14.0717708 L13.5334511,14.6540989 C13.476122,14.7505394 13.4182977,14.8466454 13.3600364,14.942376 L13.0056914,15.5119246 C12.9459153,15.6059889 12.8858188,15.6995955 12.8254602,15.7927035 L12.4606281,16.3450387 L12.0923004,16.8834349 L11.7232736,17.4059213 L11.3563441,17.9105274 L10.9943086,18.3952825 L10.6399636,18.8582157 L10.2961056,19.2973566 L8.76209811,21.1029683 C8.40451496,21.5238635 7.7734336,21.575188 7.35253844,21.2176049 C7.31128664,21.1825583 7.2729485,21.1442201 7.23790189,21.1029683 L5.7038944,19.2973566 L5.184,18.6296 L4.82546017,18.1555095 L4.46062813,17.6605826 L4.09230041,17.14679 C4.03077651,17.0596952 3.96922349,16.9718965 3.90769959,16.8834349 L3.53937187,16.3450387 L3.17453983,15.7927035 L2.816,15.2284 C2.69784615,15.0385231 2.5812071,14.8469799 2.46654893,14.6540989 L2.12898316,14.0717708 C0.916449704,11.9242604 0,9.65461538 0,7.7 C0,3.443 3.57714286,0 8,0 Z M8,2 C4.66497074,2 2,4.56503434 2,7.7 C2,8.69322797 2.3458444,9.96053154 3.01409327,11.4203421 C3.64939915,12.8081876 4.54667863,14.3111997 5.63770505,15.8676171 C6.30819597,16.8241143 7.01937804,17.7553435 7.73858591,18.6369503 L8,18.952 L8.26141409,18.6369503 C8.80081999,17.9757452 9.33571138,17.2866275 9.85230757,16.5798717 L10.362295,15.8676171 C11.4533214,14.3111997 12.3506009,12.8081876 12.9859067,11.4203421 C13.6541556,9.96053154 14,8.69322797 14,7.7 C14,4.56503434 11.3350293,2 8,2 Z M8,5.5 C9.38,5.5 10.5,6.62 10.5,8 C10.5,9.38 9.38,10.5 8,10.5 C6.62,10.5 5.5,9.38 5.5,8 C5.5,6.62 6.62,5.5 8,5.5 Z" id="Combined-Shape"></path>
          </g>
      </g>
  </svg>

  </ng-template>

  <div class="icons-block" *ngIf="!activate">
    <div class="date-icon attachment-icon"  (click)="selectActivateData()">
      <ng-container [ngTemplateOutlet]="locationIcon"></ng-container>
    </div>
    <span>{{ 'LOCATIONS' | translate }}</span>
  </div>


<!-- <div id="assign_location_option" class="option-icon" *ngIf="onMainView" [class.selected]="value || isVisible" (click)="activateData()">
  <svg width="38px" height="38px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Icon/product/location-new</title>
    <g id="Icon/product/location-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Icon-24px" transform="translate(4.000000, 1.000000)" fill="#8B96A0">
            <path d="M8,0 C12.4228571,0 16,3.443 16,7.7 C16,9.65461538 15.0835503,11.9242604 13.8710168,14.0717708 L13.5334511,14.6540989 C13.476122,14.7505394 13.4182977,14.8466454 13.3600364,14.942376 L13.0056914,15.5119246 C12.9459153,15.6059889 12.8858188,15.6995955 12.8254602,15.7927035 L12.4606281,16.3450387 L12.0923004,16.8834349 L11.7232736,17.4059213 L11.3563441,17.9105274 L10.9943086,18.3952825 L10.6399636,18.8582157 L10.2961056,19.2973566 L8.76209811,21.1029683 C8.40451496,21.5238635 7.7734336,21.575188 7.35253844,21.2176049 C7.31128664,21.1825583 7.2729485,21.1442201 7.23790189,21.1029683 L5.7038944,19.2973566 L5.184,18.6296 L4.82546017,18.1555095 L4.46062813,17.6605826 L4.09230041,17.14679 C4.03077651,17.0596952 3.96922349,16.9718965 3.90769959,16.8834349 L3.53937187,16.3450387 L3.17453983,15.7927035 L2.816,15.2284 C2.69784615,15.0385231 2.5812071,14.8469799 2.46654893,14.6540989 L2.12898316,14.0717708 C0.916449704,11.9242604 0,9.65461538 0,7.7 C0,3.443 3.57714286,0 8,0 Z M8,2 C4.66497074,2 2,4.56503434 2,7.7 C2,8.69322797 2.3458444,9.96053154 3.01409327,11.4203421 C3.64939915,12.8081876 4.54667863,14.3111997 5.63770505,15.8676171 C6.30819597,16.8241143 7.01937804,17.7553435 7.73858591,18.6369503 L8,18.952 L8.26141409,18.6369503 C8.80081999,17.9757452 9.33571138,17.2866275 9.85230757,16.5798717 L10.362295,15.8676171 C11.4533214,14.3111997 12.3506009,12.8081876 12.9859067,11.4203421 C13.6541556,9.96053154 14,8.69322797 14,7.7 C14,4.56503434 11.3350293,2 8,2 Z M8,5.5 C9.38,5.5 10.5,6.62 10.5,8 C10.5,9.38 9.38,10.5 8,10.5 C6.62,10.5 5.5,9.38 5.5,8 C5.5,6.62 6.62,5.5 8,5.5 Z" id="Combined-Shape"></path>
        </g>
    </g>
</svg>
  <p>{{ 'LOCATIONS' | translate }}</p>
</div> -->

<div id="assign_location_option_list" class="option-list add-assignee-list-dialog" *ngIf="activate">
  <div class="add-assignee-list-header">
    <span>{{ 'LOCATIONS' | translate }}</span>
    <mdl-icon class="add-list-icon" (click)="openCreateLocationDialog()">add_location</mdl-icon>
  </div>

  <mat-list role="list" class="folder-list" *ngIf="filteredItems && filteredItems.length > 0">
    <mat-list-item role="listitem" *ngFor="let location of filteredItems; let i = index;"
    [class.selected]="i == selectedIndex"
    (click)="selectedData(location)">
      <mat-icon>place</mat-icon>
      <span>{{ location.name }}</span>
    </mat-list-item>
  </mat-list>
  <div *ngIf="filteredItems && filteredItems.length === 0" class="no-available-lists">
    <span>{{ 'NO_AVAILABLE_LOCATIONS' | translate }}</span>
  </div>
</div>
