
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy, Input } from "@angular/core";
import { StatisticsInfo } from "../../models/statisticsInfo-model";
import { Store } from "@ngrx/store";
import { TasksRootState, getSelectedFilterType, getSelectedFilterOption } from "../../store";
import { getTaskStatisticsInfo, getSearchStatisticsInfo, getTagStatisticsInfo, getListStatisticsInfo, getLocationStatisticsInfo } from "../../store/selectors";
import { TasksConstants } from "../../shared/task-constacts";
import { TaskStatistics } from "../../models";
import { takeWhile } from "rxjs/operators";

@Component({
  selector: "vp-vnctask-statistics",
  templateUrl: "task-statistics.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskStatisticsComponent implements OnInit, OnDestroy {
  statisticInfo: StatisticsInfo = {
    total_count: 0,
    due_tomorrow_count: 0,
    overdue_count: 0,
    completed_count: 0
  };
  isAlive = true;
  selectedFilterType: string;
  selectedFilterOption: string;

  constructor(
    private store: Store<TasksRootState>,
    private changerDetectorRef: ChangeDetectorRef ) {

      this.store
      .select(getSelectedFilterType)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(value => {
        this.selectedFilterType = value;
        this.changerDetectorRef.markForCheck();
      });

      this.store
      .select(getSelectedFilterOption)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(value => {
        if (value) {
          this.selectedFilterOption = value;
            if (this.selectedFilterOption !== TasksConstants.ROUTE_SEARCH){
              if ( this.selectedFilterType === TasksConstants.ROUTE_TYPE_STATUS) {
                this.store.select(getTaskStatisticsInfo).pipe(takeWhile(() => this.isAlive)).subscribe(info => {
                  if (info) {
                    let taskStatistic: TaskStatistics = info.find( statisticInfo =>
                      statisticInfo.type === this.selectedFilterOption );
                      if (taskStatistic) {
                        this.statisticInfo = taskStatistic.info;
                        this.changerDetectorRef.markForCheck();
                      }
                  }
                });
              } else if ( this.selectedFilterType === TasksConstants.ROUTE_TYPE_LIST) {
                this.store.select(getListStatisticsInfo).pipe(takeWhile(() => this.isAlive)).subscribe(info => {
                  if (info) {
                    this.statisticInfo = info;
                    this.changerDetectorRef.markForCheck();
                  }
                });
              } else if ( this.selectedFilterType === TasksConstants.ROUTE_TYPE_TAG) {
                this.store.select(getTagStatisticsInfo).pipe(takeWhile(() => this.isAlive)).subscribe(info => {
                  if (info) {
                    this.statisticInfo = info;
                    this.changerDetectorRef.markForCheck();
                  }
                });
              } else {
                this.store.select(getLocationStatisticsInfo).pipe(takeWhile(() => this.isAlive)).subscribe(info => {
                  if (info) {
                    this.statisticInfo = info;
                    this.changerDetectorRef.markForCheck();
                  }
                });
              }

            } else {
              this.store.select(getSearchStatisticsInfo).pipe(takeWhile(() => this.isAlive)).subscribe(info => {
                if (info) {
                  this.statisticInfo = info;
                  this.changerDetectorRef.markForCheck();
                }
              });
            }
        }
      });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.isAlive = false;
    this.changerDetectorRef.markForCheck();
  }
}
