
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

 <ng-template #repeatIcon>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="Icon/product/repeat" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Icon-24px" transform="translate(3.000000, 2.000000)" fill="#FFFFFF">
          <path
            d="M4,5 L14,5 L14,8 L18,4 L14,0 L14,3 L2,3 L2,9 L4,9 L4,5 L4,5 Z M14,15 L4,15 L4,12 L0,16 L4,20 L4,17 L16,17 L16,11 L14,11 L14,15 L14,15 Z"
            id="Shape"></path>
        </g>
      </g>
    </svg>
  </ng-template>

  <div class="icons-block"  *ngIf="!activate">
    <div class="date-icon repeat-icon" (click)="selectActivateData()">
      <ng-container [ngTemplateOutlet]="repeatIcon"></ng-container>
    </div>
    <span>{{ 'REPEAT' | translate }}</span>
  </div>

  <div id="repeat_option_list" class="option-list" *ngIf="activate">
    <mat-list role="list">
      <mat-list-item role="listitem" *ngFor="let item of filteredItems; let i = index;" [class.selected]="i == selectedIndex" (click)="selectedData(item)">
        <mat-icon *ngIf="item.value!=='never'">autorenew</mat-icon>
        <span>{{item.name}}</span>
      </mat-list-item>
    </mat-list>
  </div>
