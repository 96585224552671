
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="task-detail-info">
  <div class="statistic-header">
    <span class="statistics-header-text">{{ 'STATISTICS' | translate }}</span>
  </div>
  <div class="detail-body">
    <div class="detail-info-table padding-15">
      <div class="table-row">
        <div class="table-cell label-info">{{ 'NUMBER_OF_TASKS' | translate }}</div>
        <div class="table-cell statistic-detail-info">{{ statisticInfo.total_count }}</div>
      </div>
      <div class="table-row">
        <div class="table-cell label-info">{{ 'DUE_TOMORROW' | translate }}</div>
        <div class="table-cell statistic-detail-info">{{ statisticInfo.due_tomorrow_count }}</div>
      </div>
      <div class="table-row">
        <div class="table-cell label-info"><span>{{ 'OVERDUE' | translate }}</span></div>
        <div class="table-cell statistic-detail-info overdue-info">{{ statisticInfo.overdue_count }}</div>
      </div>
      <div class="table-row">
        <div class="table-cell label-info">{{ 'COMPLETED' | translate }}</div>
        <div class="table-cell statistic-detail-info completed-info">{{ statisticInfo.completed_count }}</div>
      </div>
    </div>
  </div>
</div>
