
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable, OnDestroy } from "@angular/core";
import { distinctUntilChanged, filter, Subscription, map, take, debounceTime, Subject, Observable } from "rxjs";
import { User, AuthUser, TeamUser } from "../models/user";
import { Store } from "@ngrx/store";
import { RootState, getOnlineStatus, getUserProfile } from "src/app/reducers";
import { getAuthUser } from "../store/selectors";
import { environment } from "src/environments/environment";
import { ActionCableService, Channel, Cable } from "angular2-actioncable";
import { ConfigService } from "src/app/common/providers/config.service";
import { TaskService } from "../task.service";
import * as ActionCableNs from "actioncable";
const ActionCable = ActionCableNs;

@Injectable()
export class CableService implements OnDestroy {

  subscription: Subscription;
  cable: Cable;
  channel: Channel;
  cableUrl: string;
  private networkOnline: boolean = true;
  private inBackground: boolean = false;
  private cableMessage: Subject<any> = new Subject();
  staleThreshold = 100;
  currentUser: AuthUser;


  constructor(
    private cableService: ActionCableService,
    private taskService: TaskService,
    private configService: ConfigService,
    private store: Store<RootState>
  ) {
    console.log("[cableService] constructor: ");
    this.store.select(getOnlineStatus).subscribe(v => {
      console.log("[cableService] getOnlineStatus: ", v);
      this.networkOnline = v;
    });
    this.store.select(getAuthUser).pipe(distinctUntilChanged(), debounceTime(200)).subscribe(user => {
      console.log("[cableService] authUser: ", user);
      this.currentUser = user;

      if (!!this.currentUser && !!this.currentUser.vnctask_channel) {
        this.maybeInitCable();
      }

    });
    if (environment.isCordova) {
      document.addEventListener("deviceready", this.deviceReady.bind(this), false);
    } else {
      this.deviceReady();
    }
  }

  maybeInitCable() {
    const token = localStorage.getItem("token");
    const cableBaseUrl = localStorage.getItem("CABLE_URL");
    console.log("maybeInitCable: ", token, cableBaseUrl);
    if (!!token && !!cableBaseUrl) {
      this.initCable();
    } else {
      this.configService.getConfig()
        .pipe(map(response => response.json()))
        .subscribe(data => {
          console.log("cableService configData: ", data);
          if (!!data && !!data.cableURL) {
            localStorage.setItem("CABLE_URL", data.cableURL);
          }
        });
      setTimeout(() => {
        this.maybeInitCable();
      }, 500);
    }
  }

  initCable() {
    ActionCable.ConnectionMonitor.staleThreshold = this.staleThreshold;
    this.store.select(getOnlineStatus).pipe(distinctUntilChanged(), filter(info => !!info)).subscribe(information => {
      this.networkOnline = information;
      if (!!this.networkOnline && !window.appInBackground) {
        console.log("[cableService] initCable: ", this.cable, this.subscription);
        if (!this.cable) {
          this.setupCable();
        } else {
          this.taskSubscriptions();
        }
      } else {
        this.disconnect();
      }
    });
  }

  setupCable() {
    const token = localStorage.getItem("token");
    let cableBaseUrl = localStorage.getItem("CABLE_URL");
    if (!cableBaseUrl) {
      cableBaseUrl = localStorage.getItem("serverURL");
    }
    console.log("[cableService] setupCable: ", this.currentUser);

      this.taskService.getCableAuth().subscribe(cableAuth => {
        const cableUrl = (!!cableBaseUrl && cableBaseUrl.endsWith("/")) ? cableBaseUrl.replace("https", "wss") + "cable?jwt=" + cableAuth : cableBaseUrl.replace("https", "wss") + "/cable?jwt=" + cableAuth;
        this.cableUrl = cableUrl;
        if (!!this.currentUser && !!token && !!this.currentUser.vnctask_channel) {
          this.cable = this.getCable(cableUrl);
          // console.log("[cableService] this.cable: ", this.cable);
          this.channel = this.getChannel(this.cable, "VnctaskChannel", { name: this.currentUser.vnctask_channel });
          this.taskSubscriptions();
        }
      });

  }

  getCable(url: string, params?: any): Cable {
    return this.cableService.cable(url, params);
  }

  getChannel(cable: Cable, name: string, params?: any): Channel {
    return cable.channel(name, params);
  }


  taskSubscriptions() {
    this.subscription = this.channel.received().subscribe(message => {
      console.log("[cableService] received message: ", message);
      this.cableMessage.next(message);
    });
  }

  getOnMessage(): Observable<any> {
    return this.cableMessage.asObservable();
  }



  disconnect(): void {
    if (this.cable) {
      this.cable.disconnect();
    }
    if (!!this.cableUrl) {
      this.cableService.disconnect(this.cableUrl);
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


  private deviceReady() {
    console.log("[CableService] deviceReady");

  }


}
