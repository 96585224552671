
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, ViewChild, HostListener, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Http } from "@angular/http";
import { ConfigService } from "../../../../common/providers/config.service";
import { Broadcaster } from "../../../../common/providers/broadcaster.service";
import { environment } from "../../../../../environments/environment";
import { AppConstants } from "../../../../common/utils/app-constants";
import { TaskService } from "../../../task.service";
import { AuthUser } from "../../../models";
import { getAuthUser } from "../../../store/selectors/tasks.selectors";
import { TasksRootState } from "../../../store/reducers";
import { Store } from "@ngrx/store";
import { TaskRepository } from "../../../repository/task.repository";
import { TaskAccountDeactivateFeedbackComponent } from "../../task-deactivate-account/task-account-deactivate-feedback/task-account-deactivate-feedback.component";
import { getOnlineStatus, getUserProfile } from "../../../../reducers";
import { SuccessService } from "../../../../common/providers/success-service";
import { ErrorService } from "../../../../common/providers/error-service";
import { ErrorType, SuccessType } from "../../../shared/task-enum";
import { takeWhile, take } from "rxjs/operators";
import { CommonUtil } from "src/app/common/utils/common.utils";
import { TFASettingsComponent } from "src/app/shared/components/tfa-settings/tfa-settings.component";
import { TFARepository } from "src/app/task/repository/tfa.repository";
import { SetUserProfile } from "src/app/actions/app";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { HighContrastModeDetector } from "@angular/cdk/a11y";
import { VncLibraryService } from "vnc-library";

@Component({
  selector: "vp-task-general-settings-dialog",
  templateUrl: "task-general-settings.component.html"
})
export class TaskGeneralSettingsDialogComponent implements OnDestroy {
  languageNames: any[] = [];
  selectedLanguage: string;
  isAlive = true;
  isOnline: boolean = false;
  serverURL: string = "";
  showServerSettings = false;
  authUser: AuthUser;
  supportedLanguages = [
    {
      value: "en",
      display: "ENGLISH"
    },
    {
      value: "de",
      display: "GERMAN"
    }
  ];
  isCordova = environment.isCordova;
  isUpdated: boolean = false;
  notification: boolean = false;
  mail_notification: boolean = false;
  no_self_notified: boolean = false;
  sound: boolean = false;
  global_mute: boolean = false;
  isLoading: boolean = false;
  use2FA: boolean;
  is2FAEnabled: boolean;
  isSupport2FA: boolean = false;
  disallowedDeleteAccount: boolean = false;

  constructor(private translate: TranslateService,
    private configService: ConfigService,
    private http: Http,
    private broadcaster: Broadcaster,
    private taskService: TaskService,
    private changerDetectorRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<TaskGeneralSettingsDialogComponent>,
    private store: Store<TasksRootState>,
    private tasksRepo: TaskRepository,
    private errorService: ErrorService,
    private successService: SuccessService,
    private vncLibaryService: VncLibraryService,
    private tfaRepo: TFARepository,
    private matDialog: MatDialog) {

      this.handleSaveSettingErrorMessages();
      this.handleSaveSettingSuccessMessages();

      if (this.isCordova) {
        this.showServerSettings = true;
      }
      this.initSelectedLang();
      this.initServerURL();

      this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
        .subscribe(presence => {
          this.close();
        });

      this.broadcaster.on<any>("hideTaskGeneralSettingsDialog").pipe(takeWhile(() => this.isAlive))
        .subscribe(presence => {
          this.close();
        });

      this.store.select(getAuthUser).pipe(takeWhile(() => this.isAlive)).subscribe(user => {
        if (user) {
          this.authUser = user;
          this.notification = (this.authUser.notification === "true" ? true : false);
          this.mail_notification = (this.authUser.mail_notification === "only_my_events" ? true : false);
          this.sound = (this.authUser.sound === "true" ? true : false);
          this.global_mute = (this.authUser.global_mute === "true" ? true : false);
          this.no_self_notified = (this.authUser.no_self_notified === "true" ? true : false);
          this.selectedLanguage = this.authUser.language !== "" ? this.authUser.language : "en";
          this.changerDetectorRef.markForCheck();
        }
      });

      this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
        this.isOnline = isOnline;
        this.changerDetectorRef.markForCheck();
      });

      this.get2FAStatus();
      if (this.configService.get("disallowedDeleteAccount")) {
        this.disallowedDeleteAccount = this.configService.get("disallowedDeleteAccount");
        this.changerDetectorRef.markForCheck();
      }
  }

  public get2FAStatus() {
    this.isSupport2FA = this.configService.two_factor_authentication;
    if (this.isSupport2FA) {
      this.tfaRepo.get2FAStatus().pipe(take(1)).subscribe( value => {
        if (value && value.tfa_enable) {
          this.is2FAEnabled = value.tfa_enable !== "false" ? true : false;
          this.use2FA = this.is2FAEnabled;
          this.changerDetectorRef.markForCheck();
        }
      });
    }
  }

  handleSaveSettingErrorMessages() {
    this.errorService.only(ErrorType.SaveSettingError).pipe(takeWhile(() => this.isAlive)).subscribe(error => {
      this.vncLibaryService.openSnackBar(error.messages, "","", "", 3000, "bottom", "left").subscribe(res => {
      });
      this.isLoading = false;
      this.changerDetectorRef.markForCheck();
    });
  }

  handleSaveSettingSuccessMessages() {
    this.successService.only(SuccessType.SaveSettingSuccess).pipe(takeWhile(() => this.isAlive)).subscribe(success => {
      this.isLoading = false;
      this.changerDetectorRef.markForCheck();
      this.updateSetting();
    });
  }

  initServerURL() {
    this.serverURL = this.configService.API_URL;
    if (localStorage.getItem("serverURL") !== null) {
      this.serverURL = localStorage.getItem("serverURL");
    }
    this.changerDetectorRef.markForCheck();
  }

  initSelectedLang() {
    let browserLang = this.translate.getBrowserLang();
    let localLang = localStorage.getItem(AppConstants.TASK_LANGUAGE);
    if (localLang !== null && localLang !== "undefined") {
      browserLang = localLang;
    }
    this.selectedLanguage = browserLang.match(/en|de/) ? browserLang : "en";
    this.changerDetectorRef.markForCheck();
  }

  @HostListener("document:keydown.esc", ["$event"])
  hide() {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

  updateSetting(): void {
    this.updateLanguage();
    if (this.showServerSettings && this.configService.API_URL !== this.serverURL) {
      if (environment.isCordova && typeof window.FirebasePlugin !== "undefined") {
        window.FirebasePlugin.getToken(token => {
          this.taskService.removeFirebaseToken(token)
            .pipe(take(1))
            .subscribe(res => {
              console.log("[removeFirebaseToken]", token, res);
              window.FirebasePlugin.unregister();
              this.updateServerUrl();
            }, err => {
              console.error("[removeFirebaseToken - error]", err);
              this.updateServerUrl();
            });
        });

        window.FirebasePlugin.clearAllNotifications(() => {
          console.log("[FirebasePlugin clearAllNotifications] success");
        }, error => {
          console.log("[FirebasePlugin clearAllNotifications] error", error);
        });
      } else {
        this.updateServerUrl();
      }
    }
    this.close();
  }

  updateLanguage(): void {
    this.translate.use(this.selectedLanguage);
    this.translate.reloadLang(this.selectedLanguage);
    this.configService.language = this.selectedLanguage;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  updateServerUrl() {
    this.configService.changeServerUrl(this.serverURL).subscribe(data => {
      if (data.redmineURL) {
        this.configService.selectedServer = true;
        this.configService.API_URL = this.serverURL;
        localStorage.setItem("serverURL", this.serverURL.trim());
        this.configService.setConfigDetails(data);
        this.tasksRepo.logout();
      } else {
        alert("The Server URL entered by you is wrong. Please provide correct URL");
      }
    }, err => {
      alert("The Server URL entered by you is wrong. Please provide correct URL");
    });
  }

  redirectToMain() {
    let initialHref = window.location.href.split("/www/")[0];
    window.location.href = initialHref + "/www/index.html";
  }

  feedback() {
    if (!this.isOnline) {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
      return;
    }

    const dlg = this.matDialog.open(TaskAccountDeactivateFeedbackComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-task-deactivate-feedback-dialog",
    });
    dlg.afterClosed().pipe(take(1)).subscribe($event => {
      if ($event) {
        this.close();
        console.log("[task-general-settings.component] logout");
        this.tasksRepo.logout();
      }
    });
  }

  updateOptions() {
    this.isUpdated = true;
    this.changerDetectorRef.markForCheck();
  }

  update2FAOptions() {
    if ( this.is2FAEnabled && !this.use2FA) {
      this.isUpdated = true;
    } else {
      this.isUpdated = false;
    }
    this.changerDetectorRef.markForCheck();
  }

  onGlobalMuteChange() {
    if (this.global_mute) {
      this.sound = false;
      this.notification = false;
    } else {
      this.sound = (this.authUser.sound === "true" ? true : false );
      this.notification = (this.authUser.notification === "true" ? true : false);
    }
    setTimeout( () => {
      this.changerDetectorRef.markForCheck();
    }, 100);
  }

  onSaveSetting() {
    if (!this.isOnline) {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
      return;
    }
    let OldNotification = this.authUser.notification === "true" ? true : false;
    if (OldNotification !== this.notification) {
      if (this.notification) {
        this.tasksRepo.startPullingNotification();
      } else {
        this.tasksRepo.stopPullingNotification();
      }
    }
    this.authUser.notification = this.notification ? "true" : "false";
    this.authUser.mail_notification = this.mail_notification ? "only_my_events" : "none";
    this.authUser.sound = this.sound ? "true" : "false";
    this.authUser.global_mute = this.global_mute ? "true" : "false";
    this.authUser.language = this.selectedLanguage;
    this.authUser.no_self_notified = this.no_self_notified ? "true" : "false";
    this.isLoading = true;
    this.set2FASettings();
    this.tasksRepo.saveSettings(this.authUser);
  }

  private set2FASettings() {
    if (this.isSupport2FA) {
      if (this.is2FAEnabled && !this.use2FA ) {
        this.tfaRepo.disableTFA().subscribe( res => {
          this.is2FAEnabled = false;
          this.changerDetectorRef.markForCheck();
        });
      }
    }
  }

  setAuthenticationApp() {
    let dialogRef = this.matDialog.open(TFASettingsComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "task_tfa_settings_dialog"
    });
    dialogRef.afterClosed().subscribe($event => {
      if ($event) {
        this.is2FAEnabled = true;
        this.changerDetectorRef.markForCheck();
        this.tasksRepo.logout();
      }
    });
  }
}
