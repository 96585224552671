
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ICompact } from "./task";

export class TaskAttachment {
  id: number;
  filename: string;
  filesize: number;
  content_type: string;
  description: string;
  content_url: string;
  thumbnail_url: string;
  created_on: Date;
  author: ICompact;
  deletable: boolean;

  constructor(json) {
    this.id = json.id;
    this.filename = json.filename;
    this.filesize = json.filesize;
    this.created_on = new Date(json.created_on);
    this.content_type = json.content_type;
    this.description = json.description;
    this.content_url = json.content_url;
    this.thumbnail_url = json.thumbnail_url;
    this.author = json.author;
    this.deletable = json.deletable;
  }
}
