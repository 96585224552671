
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { VNCStandardObj } from "./vnc-standard-obj";

export enum FeedbackType {
  NO_MORE = 1,
  NOT_EXPECTED,
  DONT_LIKE,
  LOT_OF_BUGS,
  OTHER
}

export function getAllFeedbackTypes(): FeedbackType[] {
  return [
    FeedbackType.NO_MORE,
    FeedbackType.NOT_EXPECTED,
    FeedbackType.DONT_LIKE,
    FeedbackType.LOT_OF_BUGS,
    FeedbackType.OTHER
  ];
}

export function getFeedbackType(type: FeedbackType): VNCStandardObj {
  let slug = "";
  let title = "";
  let key = "";

  switch (type) {
    case FeedbackType.NO_MORE: {
      slug = "no_more";
      key = "NO_MORE_REASON";
      break;
    }

    case FeedbackType.NOT_EXPECTED: {
      slug = "not_expected";
      key = "NOT_EXPECTED_REASON";
      break;
    }

    case FeedbackType.DONT_LIKE: {
      slug = "dont_like";
      key = "DONT_LIKE_REASON";
      break;
    }

    case FeedbackType.LOT_OF_BUGS: {
      slug = "lot_of_bugs";
      key = "LOT_OF_BUGS_REASON";
      break;
    }

    case FeedbackType.OTHER: {
      slug = "other";
      key = "OTHER_REASON";
      break;
    }
  }

  return {
    slug: slug,
    title: title,
    translation_key: key,
  };
}

export function feedbackTypeFromString(str: string): FeedbackType {
  switch (str) {
    case "no_more":
      return FeedbackType.NO_MORE;
    case "not_expected":
      return FeedbackType.NOT_EXPECTED;
    case "dont_like":
      return FeedbackType.DONT_LIKE;
    case "lot_of_bugs":
      return FeedbackType.LOT_OF_BUGS;
    case "other":
      return FeedbackType.OTHER;
  }
}
