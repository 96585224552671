
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, forwardRef, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter } from "@angular/core";
import { TaskOptionComponent } from "../task-option.component";
import { Project, List } from "../../../../models";
import { Store } from "@ngrx/store";
import { TasksRootState, getProjectsList, getFolderList } from "../../../../store/index";
import { CommonUtil } from "../../../../../common/utils/common.utils";
import { CreateListDialogComponent } from "../../../create-list-dialog/create-list-dialog.component";
import { TaskRepository } from "../../../../repository/task.repository";
import { takeWhile } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { TaskService } from "src/app/task/task.service";

@Component({
  selector: "vp-new-vnctask-option-link-list",
  providers: [{
    provide: TaskOptionComponent,
    // eslint-disable-next-line
    useExisting: forwardRef(() => NewTaskLinkListOptionComponent)
  }],
  templateUrl: "new-option-link-list.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewTaskLinkListOptionComponent extends TaskOptionComponent implements OnInit, OnDestroy {
  id: string = "link-to-list";
  triggerKey = "$";
  isAlive = true;

  activate:boolean = false;
  constructor(private store: Store<TasksRootState>,
    public changerDetectorRef: ChangeDetectorRef,
    private service: TaskService,
    public matDialog: MatDialog ) {
    super(changerDetectorRef, matDialog);
    this.service.cancelActivationOfTags.subscribe(res => {
      this.cancelActivateData();
    });
  }

  ngOnInit() {
    this.store.select(getFolderList).pipe(takeWhile(() => this.isAlive)).subscribe( lists => {
      this.setItems(lists);
    });
  }

  getPayload() {
    let value: List = this.getValue();
    if (!value) return { };
    return { list_id: value.id, list_name: value.name };
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  openCreateListDialog() {
    const dlg = this.matDialog.open(CreateListDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-create-list-dialog",
      data: { isCreate: true, selectedList: null }
    });
  }

  @Output() selectActivate = new EventEmitter();
  @Output() selectedList = new EventEmitter();
  selectActivateData() {
    this.activate = true;
    this.selectActivate.emit(this.activate);
    this.changerDetectorRef.markForCheck();
  }

  cancelActivateData() {
    this.activate = false;
    this.selectActivate.emit(this.activate);
  }

  selectedData(item){
    this.selectedList.emit(item);
    this.activate = false;
    this.selectActivate.emit(this.activate);
    this.changerDetectorRef.markForCheck();
  }

}
