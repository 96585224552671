
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ConfigService } from "../../../common/providers/config.service";
import { Router } from "@angular/router";
import { TasksConstants } from "../../shared/task-constacts";
import { Store } from "@ngrx/store";
import {
  TasksRootState,
  getSelectedFilterOption,
  getTaskStatisticsInfo,
  getTagList,
  SetEditTaskView,
  getFolderList,
  getAuthUser
} from "../../store/index";
import { TaskStatistics, Tag, List, dialogType, AuthUser, Location } from "../../models";
import { TaskComposeComponent } from "../task-compose";
import { TaskService } from "../../task.service";
import { getOnlineStatus } from "../../../reducers";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { CommonUtil } from "../../../common/utils/common.utils";
import { CreateListDialogComponent } from "../create-list-dialog/create-list-dialog.component";
import { TaskConfirmDialogComponent } from "../../components/task-dialogs/confirm-dialog.component";
import { MessageTranslatorService } from "../../services";
import { ErrorService } from "../../../common/providers/error-service";
import { SuccessService } from "../../../common/providers/success-service";
import { ErrorType, SuccessType } from "../../shared/task-enum";
import { TaskRepository } from "../../repository/task.repository";
import { getSelectedFilterType, getLocationList } from "../../store/selectors";
import { CreateLocationDialogComponent } from "../create-location-dialog/create-location-dialog.component";
import { MatMenuTrigger } from "@angular/material/menu";
import { takeWhile, take } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { VncLibraryService } from "vnc-library";

@Component({
  selector: "vp-sidebar",
  templateUrl: "./sidebar.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy {
  showHideFilters: boolean;
  taskConstants = TasksConstants;
  @Input() isExpanded: boolean;
  @Output() onToggleExpand = new EventEmitter<boolean>();

  selectedFilter: string;
  isAlive: boolean = true;
  taskStatistics: TaskStatistics[];
  tagList: Tag[] = [];
  folderList: List[] = [];
  locationList: Location[] = [];
  tagListExpanded: boolean = false;
  folderListExpanded: boolean = false;
  locationListExpanded: boolean = false;
  isOnline: boolean = false;
  private feedbackIcon = "./feedback-icon.svg";
  dialogType = dialogType;
  isLoading: boolean = false;
  authUser: any;
  selectedFilterType: string;
  currDelSelectedList: List;
  currDelSelectedLocation: Location;
  @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;

  constructor(private translate: TranslateService,
    private config: ConfigService,
    private router: Router,
    private store: Store<TasksRootState>,
    private service: TaskService,
    private broadcaster: Broadcaster,
    private vncLibaryService: VncLibraryService,
    private successService: SuccessService,
    private errorService: ErrorService,
    private taskRepo: TaskRepository,
    private messageTranslatorService: MessageTranslatorService,
    private matDialog: MatDialog,
    private changerDetectorRef: ChangeDetectorRef) {
      this.handleDeleteListSuccessMessages();
      this.handleListErrorMessages();
      this.handleDeleteLocationSuccessMessages();
      this.handleLocationErrorMessages();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setupStore();
    });
  }

  ngOnInit(): void {
  }

  setupStore() {
    this.store
      .select(getSelectedFilterOption)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(value => {
        this.selectedFilter = value;
        this.changerDetectorRef.markForCheck();
      });
    this.store
      .select(getTaskStatisticsInfo)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(info => {
        this.taskStatistics = info;
        this.changerDetectorRef.markForCheck();
    });

    this.store
    .select(getTagList)
    .pipe(takeWhile(() => this.isAlive))
    .subscribe(tags => {
      if (tags) {
        this.tagList = tags;
        this.changerDetectorRef.markForCheck();
      }
    });

    this.store
    .select(getFolderList)
    .pipe(takeWhile(() => this.isAlive))
    .subscribe(lists => {
      if (lists) {
        this.folderList = lists;
        this.changerDetectorRef.markForCheck();
      }
    });

    this.store
    .select(getLocationList)
    .pipe(takeWhile(() => this.isAlive))
    .subscribe(locations => {
      if (locations) {
        this.locationList = locations;
        this.changerDetectorRef.markForCheck();
      }
    });

    this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
      this.isOnline = isOnline;
      this.changerDetectorRef.markForCheck();
    });

    this.store.select(getAuthUser).pipe(takeWhile(() => this.isAlive)).subscribe(user => {
      if (user) {
        this.authUser = user;
        this.changerDetectorRef.markForCheck();
      }
    });

    this.store.select(getSelectedFilterType).pipe(takeWhile(() => this.isAlive)).subscribe(value => {
      this.selectedFilterType = value;
      this.changerDetectorRef.markForCheck();
    });
    this.taskRepo.addAllTaskToIndexedDb();
  }

  handleListErrorMessages() {
    this.errorService.only(ErrorType.ListError).pipe(takeWhile(() => this.isAlive)).subscribe(error => {
      this.vncLibaryService.openSnackBar(error.messages, "","", "", 3000, "bottom", "left").subscribe(res => {
      });
      this.isLoading = false;
      this.changerDetectorRef.markForCheck();
    });
  }

  handleDeleteListSuccessMessages() {
    this.successService.only(SuccessType.ListDeleted).pipe(takeWhile(() => this.isAlive)).subscribe(success => {
      this.vncLibaryService.openSnackBar(success.messages, "","", "", 3000, "bottom", "left").subscribe(res => {
      });
      this.isLoading = false;
      this.changerDetectorRef.markForCheck();

      this.taskRepo.getFolderListWithCounters();
      if (this.selectedFilterType === TasksConstants.ROUTE_TYPE_LIST) {
        if (this.isSelectedFilter(this.currDelSelectedList.name)) {
          this.router.navigate(["/task"]);
        }
      }
    });
  }

  handleLocationErrorMessages() {
    this.errorService.only(ErrorType.LocationError).pipe(takeWhile(() => this.isAlive)).subscribe(error => {
      this.vncLibaryService.openSnackBar(error.messages, "","", "", 3000, "bottom", "left").subscribe(res => {
      });
      this.isLoading = false;
      this.changerDetectorRef.markForCheck();
    });
  }

  handleDeleteLocationSuccessMessages() {
    this.successService.only(SuccessType.LocationDeleted).pipe(takeWhile(() => this.isAlive)).subscribe(success => {
      this.vncLibaryService.openSnackBar(success.messages, "","", "", 3000, "bottom", "left").subscribe(res => {
      });
      this.isLoading = false;
      this.changerDetectorRef.markForCheck();

      this.taskRepo.getLocationsWithCounters();
      if (this.selectedFilterType === TasksConstants.ROUTE_TYPE_LOCATION) {
        if (this.isSelectedFilter(this.currDelSelectedLocation.name)) {
          this.router.navigate(["/task"]);
        }
      }
    });
  }

  toggleExpand() {
    this.onToggleExpand.emit(this.isExpanded);
  }

  navigateStatusRoute(title: any) {
    this.router.navigate(["/task", title]);
  }

  navigateTagRoute(title: any) {
    this.router.navigate(["/task/tags", title]);
  }

  navigateListRoute(title: any) {
    this.router.navigate(["/task/list", title]);
  }

  navigateLocationRoute(title: any) {
    this.router.navigate(["/task/location", title]);
  }

  isSelectedFilter(title: string): boolean {
    if (title === this.selectedFilter) {
      return true;
    }
    return false;
  }

  getTotalCount(routeName) {
    if (this.taskStatistics) {
      let taskStatistic: TaskStatistics = this.taskStatistics.find( statisticInfo =>
        statisticInfo.type === routeName );
      return taskStatistic ? taskStatistic.info.total_count : 0;
    }
    return 0;
  }

  openAddTaskDialog(): void {
    if (this.authUser && this.authUser.team_user === "false") {
      this.taskRepo.syncAuthUser();
    }
    this.store.dispatch(new SetEditTaskView(false));
      this.matDialog.open(TaskComposeComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "task-compose-dialog-new",
      data: { assignee: null }
    });
    this.changerDetectorRef.markForCheck();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  deleteList(list) {
    if (!this.isOnline) {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
      return;
    }
    const dlg = this.matDialog.open(TaskConfirmDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-task-confirm-dialog",
      data: { type: this.dialogType.DELETE, message: this.messageTranslatorService.getMessage(TasksConstants.CONFIRM_DELETE_ATTACHMENT) + " \"" + list.name + "\"?", header: null }
    });
    dlg.afterClosed().pipe(take(1)).subscribe(res => {
      if (!!res) {
        this.isLoading = true;
        this.currDelSelectedList = list;
        this.taskRepo.deleteList(list);
        this.changerDetectorRef.markForCheck();
      }
    });
  }


  renameList(list) {
    const dlg = this.matDialog.open(CreateListDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-create-list-dialog",
      data: { isCreate: false, selectedList: list }
    });
  }

  openAddListDialog() {
    if (this.trigger) {
      this.trigger.closeMenu();
    }

    const dlg = this.matDialog.open(CreateListDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-create-list-dialog",
      data: { isCreate: true, selectedList: null }
    });
    dlg.afterClosed().pipe(take(1)).subscribe(res => {
      if (!!res) {
        this.folderListExpanded = true;
        this.changerDetectorRef.markForCheck();
      }
    });
  }

  deleteLocation(location) {
    if (!this.isOnline) {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
      return;
    }
    const dlg = this.matDialog.open(TaskConfirmDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-task-confirm-dialog",
      data: { type: this.dialogType.DELETE, message: this.messageTranslatorService.getMessage(TasksConstants.CONFIRM_DELETE_ATTACHMENT) + " \"" + location.name + "\"?", header: null }
    });
    dlg.afterClosed().pipe(take(1)).subscribe(res => {
      if (!!res) {
        this.isLoading = true;
        this.currDelSelectedLocation = location;
        this.taskRepo.deleteLocation(location);
        this.changerDetectorRef.markForCheck();
      }
    });
  }


  renameLocation(location) {
    const dlg = this.matDialog.open(CreateLocationDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-create-location-dialog",
      data: { isCreate: false, selectedLocation: location }
    });
  }

  openAddLocationDialog() {
    if (this.trigger) {
      this.trigger.closeMenu();
    }

    const dlg = this.matDialog.open(CreateLocationDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-create-location-dialog",
      data: { isCreate: true, selectedLocation: null }
    });
    dlg.afterClosed().pipe(take(1)).subscribe(res => {
      if (!!res) {
        this.locationListExpanded = true;
        this.changerDetectorRef.markForCheck();
      }
    });
  }
}
