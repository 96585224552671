
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, forwardRef, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, Output, EventEmitter } from "@angular/core";
import { TaskOptionComponent } from "../task-option.component";
import { TaskRepository } from "../../../../repository/task.repository";
import { TaskToolbarComponent } from "../../../task-toolbar/task-toolbar.component";
import { MatDialog } from "@angular/material/dialog";
import { TaskService } from "src/app/task/task.service";

@Component({
  selector: "vp-new-vnctask-option-add-description",
  providers: [{
    provide: TaskOptionComponent,
    // eslint-disable-next-line
    useExisting: forwardRef(() => NewTaskAddDescriptionComponent)
  }],
  templateUrl: "new-options-add-description.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewTaskAddDescriptionComponent extends TaskOptionComponent implements OnInit {
  id: string = "add-description";
  triggerKey: string = "%";
  description: any = {
    name: "Description",
    value: ""
  };
  @ViewChild("taskToolbar", { static: false }) taskToolbar: TaskToolbarComponent;
  activate:boolean = false;
  constructor(
    private taskRepo: TaskRepository,
    public changerDetectorRef: ChangeDetectorRef,
    public matDialog: MatDialog,
    private service: TaskService,
  ) {
    super(changerDetectorRef, matDialog);
    this.service.cancelActivationOfTags.subscribe(res => {
      this.cancelActivateData();
    });
  }

  ngOnInit() { }

  getPayload() {
    let value = this.getValue();
    if (!value || (value && !value.value)) return {};
    return { description: value.value };
  }

  setDescription() {
    this.description.value = this.taskToolbar.getContentInTextile();
    // this.onDescriptionClick(this.description);
    this.selectedData(this.description);
  }

  @Output() selectActivate = new EventEmitter();
  @Output() selectedDescription = new EventEmitter();

  selectActivateData() {
    this.activate = true;
    this.selectActivate.emit(this.activate);
    this.changerDetectorRef.markForCheck();
  }
  cancelActivateData() {
    this.activate = false;
    this.selectActivate.emit(this.activate);
  }
  selectedData(item){
    this.selectedDescription.emit(item);
    this.activate = false;
    this.selectActivate.emit(this.activate);
    this.changerDetectorRef.markForCheck();
  }

}
