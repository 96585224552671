
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
    Component,
    EventEmitter,
    OnInit,
    OnDestroy,
    ViewEncapsulation,
    Input,
    Output
} from "@angular/core";
import { TaskNotification, Options, TaskNotificationType } from "./task-notifications.model";
import { TaskNotificationsService } from "./task-notifications.service";
import { Subscription } from "rxjs";
@Component({
    selector: "vp-task-notifications",
    encapsulation: ViewEncapsulation.None,
    template: `
          <div class="vp-task-notification-wrapper" [ngClass]="position">
              <vp-task-notification
                  *ngFor="let a of notifications; let i = index"
                  [item]="a"
                  [timeOut]="timeOut"
                  [clickToClose]="clickToClose"
                  [maxLength]="maxLength"
                  [pauseOnHover]="pauseOnHover"
                  [theClass]="theClass"
                  [rtl]="rtl"
                  [animate]="animate"
                  [position]="i"
                  >
              </vp-task-notification>
          </div>
      `,
    styles: [`
          .vp-task-notification-wrapper {
              position: fixed;
              width: 320px;
              z-index: 900000;
          }
  
          .vp-task-notification-wrapper.left { left: 10px; }
          .vp-task-notification-wrapper.top { top: 70px; }
          .vp-task-notification-wrapper.right { right: 10px; }
          .vp-task-notification-wrapper.bottom { bottom: 10px; }
  
          @media (max-width: 340px) {
              .vp-task-notification-wrapper {
                  width: auto;
                  left: 10px;
                  right: 10px;
              }
          }
      `]
})

export class TaskNotificationsComponent implements OnInit, OnDestroy {

    @Input() set options(opt: Options) {
        this.attachChanges(opt);
    }

    @Output() onCreate = new EventEmitter();
    @Output() onDestroy = new EventEmitter();

    notifications: TaskNotification[] = [];
    position: ["top" | "bottom", "right" | "left"] = ["bottom", "right"];

    lastNotificationCreated: TaskNotification;
    listener: Subscription;
    // Received values
    lastOnBottom = true;
    maxStack = 8;
    preventLastDuplicates: any;
    preventDuplicates = false;

    // Sent values
    timeOut = 0;
    maxLength = 0;
    clickToClose = true;
    pauseOnHover = true;
    theClass = "";
    rtl = false;
    animate: "fromRight" | "fromLeft" | "rotate" | "scale" = "fromRight";
    constructor(private notificationsService: TaskNotificationsService) {
    }

    ngOnInit() {
        this.listener = this.notificationsService.getChangeEmitter()
            .subscribe(item => {
                switch (item.command) {
                    case "cleanAll":
                        this.notifications = [];
                        break;

                    case "clean":
                        this.cleanSingle(item.id!);
                        break;

                    case "set":
                        if (item.add) {
                            this.add(item.notification!);
                        } else {
                            this.defaultBehavior(item);
                        }
                        break;

                    default:
                        this.defaultBehavior(item);
                        break;
                }
            });
    }

    defaultBehavior(value: any) {
        this.notifications.splice(this.notifications.indexOf(value.notification), 1);
        this.onDestroy.emit(this.buildEmit(value.notification, false));
    }


    // Add the new notification to the notification array
    add(item: TaskNotification) {
        item.createdOn = new Date();

        let toBlock: boolean = this.preventLastDuplicates || this.preventDuplicates ? this.block(item) : false;

        // Save this as the last created notification
        this.lastNotificationCreated = item;

        if (!toBlock) {
            // Check if the notification should be added at the start or the end of the array
            if (this.lastOnBottom) {
                if (this.notifications.length >= this.maxStack) {
                    this.notifications.splice(0, 1);
                }
                this.notifications.push(item);
            } else {
                if (this.notifications.length >= this.maxStack) {
                    this.notifications.splice(this.notifications.length - 1, 1);
                }
                this.notifications.splice(0, 0, item);
            }

            this.onCreate.emit(this.buildEmit(item, true));
        }
    }

    // Check if notifications should be prevented
    block(item: TaskNotification): boolean {

        let toCheck = item.html ? this.checkHtml : this.checkStandard;

        if (this.preventDuplicates && this.notifications.length > 0) {
            for (let i = 0; i < this.notifications.length; i++) {
                if (toCheck(this.notifications[i], item)) {
                    return true;
                }
            }
        }

        if (this.preventLastDuplicates) {

            let comp: TaskNotification;

            if (this.preventLastDuplicates === "visible" && this.notifications.length > 0) {
                if (this.lastOnBottom) {
                    comp = this.notifications[this.notifications.length - 1];
                } else {
                    comp = this.notifications[0];
                }
            } else if (this.preventLastDuplicates === "all" && this.lastNotificationCreated) {
                comp = this.lastNotificationCreated;
            } else {
                return false;
            }
            return toCheck(comp, item);
        }

        return false;
    }

    checkStandard(checker: TaskNotification, item: TaskNotification): boolean {
        return checker.type === item.type && checker.title === item.title && checker.content === item.content;
    }

    checkHtml(checker: TaskNotification, item: TaskNotification): boolean {
        return checker.html ? checker.type === item.type && checker.title === item.title
            && checker.content === item.content && checker.html === item.html : false;
    }

    // Attach all the changes received in the options object
    attachChanges(options: any) {
        Object.keys(options).forEach(a => {
            if (this.hasOwnProperty(a)) {
                (<any>this)[a] = options[a];
            }
        });
    }

    buildEmit(notification: TaskNotification, to: boolean) {
        let toEmit: TaskNotification = {
            createdOn: notification.createdOn,
            type: notification.type,
            id: notification.id
        };

        if (notification.html) {
            toEmit.html = notification.html;
        } else {
            toEmit.title = notification.title;
            toEmit.content = notification.content;
        }

        if (!to) {
            toEmit.destroyedOn = new Date();
        }

        return toEmit;
    }

    cleanSingle(id) {
        let indexOfDelete = 0;
        let doDelete = false;

        this.notifications.forEach((notification, idx) => {
            if (notification.id === id) {
                indexOfDelete = idx;
                doDelete = true;
            }
        });

        if (doDelete) {
            this.notifications.splice(indexOfDelete, 1);
        }
    }

    ngOnDestroy() {
        if (this.listener) {
            this.listener.unsubscribe();
        }
    }
}
