
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, HostListener, Component, Output, EventEmitter, OnDestroy } from "@angular/core";
import { TasksConstants } from "../../shared/task-constacts";
import { getSelectedFilterOption, TasksRootState, getTaskStatisticsInfo } from "../../store/index";
import { Store } from "@ngrx/store";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { TaskStatistics } from "../../models";
import { getOnlineStatus } from "../../../reducers";
import { takeWhile, take } from "rxjs/operators";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "vp-task-option-list",
  template: `
  <div class="task__dialog">
    <div class="task__dialog-header">
      <div class="mobile-back-button">
        <button mat-button (click)="hide()">
          <mat-icon class="disable-select">close</mat-icon>
        </button>
      </div>
      <div class="header_lbl disable-select">
        {{'TASKS' | translate}}
      </div>
      <div class="desktop-close-button">
        <button mat-button (click)="hide()">
          <mat-icon class="disable-select">close</mat-icon>
        </button>
      </div>
    </div>
    <div class="task__dialog-body task-option-list_dialog" id="taskListsScreen">

      <mat-list role="list">
        <mat-list-item role="listitem" (click)="navigateRoute(taskConstants.ROUTE_ALL_TASK)" id="allTasksMenu">
          <div>
            <mat-icon>event_available</mat-icon>
            <span class="list-item">{{ taskConstants.ALL_TASK | translate }}</span>
          </div>
          <div class="rounded-rectangle">
            <span>{{ getTotalCount(taskConstants.ROUTE_ALL_TASK) }}</span>
          </div>
        </mat-list-item>
        <mat-list-item role="listitem" (click)="navigateRoute(taskConstants.ROUTE_NEW)" id="newMenu">
          <div>
            <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>Icon/product/panorama-fish-eye</title>
              <g id="Icon/product/panorama-fish-eye" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Icon-24px" transform="translate(2.000000, 2.000000)" fill="#000000">
                      <path d="M10,0 C4.47,0 0,4.47 0,10 C0,15.53 4.47,20 10,20 C15.53,20 20,15.53 20,10 C20,4.47 15.53,0 10,0 L10,0 Z M10,18 C5.59,18 2,14.41 2,10 C2,5.59 5.59,2 10,2 C14.41,2 18,5.59 18,10 C18,14.41 14.41,18 10,18 L10,18 Z" id="Shape"></path>
                  </g>
              </g>
            </svg>
            <span class="list-item">{{ taskConstants.NEW_TASK | translate }}</span>
          </div>
          <div class="rounded-rectangle">
            <span>{{ getTotalCount(taskConstants.ROUTE_NEW) }}</span>
          </div>
        </mat-list-item>
        <mat-list-item role="listitem" (click)="navigateRoute(taskConstants.ROUTE_OPEN)" id="incompleteMenu">
          <div>
            <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>Icon/product/timelapse</title>
              <g id="Icon/product/timelapse" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Icon-24px" transform="translate(2.000000, 2.000000)" fill="#000000">
                      <path d="M14.24,5.76 C13.07,4.59 11.54,4 10,4 L10,10 L5.76,14.24 C8.1,16.58 11.9,16.58 14.25,14.24 C16.59,11.9 16.59,8.1 14.24,5.76 L14.24,5.76 Z M10,0 C4.48,0 0,4.48 0,10 C0,15.52 4.48,20 10,20 C15.52,20 20,15.52 20,10 C20,4.48 15.52,0 10,0 L10,0 Z M10,18 C5.58,18 2,14.42 2,10 C2,5.58 5.58,2 10,2 C14.42,2 18,5.58 18,10 C18,14.42 14.42,18 10,18 L10,18 Z" id="Shape"></path>
                  </g>
              </g>
            </svg>
            <span class="list-item">{{ taskConstants.OPEN | translate }}</span>
          </div>
          <div class="rounded-rectangle">
            <span>{{ getTotalCount(taskConstants.ROUTE_OPEN) }}</span>
          </div>
        </mat-list-item>

        <mat-list-item role="listitem" (click)="navigateRoute(taskConstants.ROUTE_COMPLETED)" id="completedMenu">
          <div>
            <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>Icon/product/check-circle</title>
              <g id="Icon/product/check-circle" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Icon-24px" transform="translate(2.000000, 2.000000)" fill="#000000">
                      <path d="M10,0 C4.48,0 0,4.48 0,10 C0,15.52 4.48,20 10,20 C15.52,20 20,15.52 20,10 C20,4.48 15.52,0 10,0 L10,0 Z M8,15 L3,10 L4.41,8.59 L8,12.17 L15.59,4.58 L17,6 L8,15 L8,15 Z" id="Shape"></path>
                  </g>
              </g>
            </svg>
            <span class="list-item">{{ taskConstants.COMPLETED | translate }}</span>
          </div>
          <div class="rounded-rectangle">
          <span>{{ getTotalCount(taskConstants.ROUTE_COMPLETED) }}</span>
          </div>
        </mat-list-item>

        <mat-list-item (click)="navigateRoute(taskConstants.ROUTE_ASSIGNEDTOME)" id="assignToMeMenu">
          <div>
            <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>Icon/product/assignment-24px</title>
              <g id="Icon/product/assignment-24px" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Icon-24px" transform="translate(2.000000, 1.000000)" fill="#000000">
                      <path d="M16,2 L11.82,2 C11.4,0.84 10.3,0 9,0 C7.7,0 6.6,0.84 6.18,2 L2,2 C0.9,2 0,2.9 0,4 L0,18 C0,19.1 0.9,20 2,20 L16,20 C17.1,20 18,19.1 18,18 L18,4 C18,2.9 17.1,2 16,2 L16,2 Z M9,2 C9.55,2 10,2.45 10,3 C10,3.55 9.55,4 9,4 C8.45,4 8,3.55 8,3 C8,2.45 8.45,2 9,2 L9,2 Z M11,16 L4,16 L4,14 L11,14 L11,16 L11,16 Z M14,12 L4,12 L4,10 L14,10 L14,12 L14,12 Z M14,8 L4,8 L4,6 L14,6 L14,8 L14,8 Z" id="Shape"></path>
                  </g>
              </g>
            </svg>
            <span class="list-item">{{ taskConstants.ASSIGNEDTOME | translate }}</span>
          </div>
          <div class="rounded-rectangle">
            <span>{{ getTotalCount(taskConstants.ROUTE_ASSIGNEDTOME) }}</span>
          </div>
        </mat-list-item>

        <mat-list-item (click)="navigateRoute(taskConstants.ROUTE_CREATEDBYME)" id="createdByMeMenu">
          <div>
            <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>Icon/product/assignment-24px</title>
              <g id="Icon/product/assignment-24px" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Icon-24px" transform="translate(2.000000, 1.000000)" fill="#000000">
                      <path d="M16,2 L11.82,2 C11.4,0.84 10.3,0 9,0 C7.7,0 6.6,0.84 6.18,2 L2,2 C0.9,2 0,2.9 0,4 L0,18 C0,19.1 0.9,20 2,20 L16,20 C17.1,20 18,19.1 18,18 L18,4 C18,2.9 17.1,2 16,2 L16,2 Z M9,2 C9.55,2 10,2.45 10,3 C10,3.55 9.55,4 9,4 C8.45,4 8,3.55 8,3 C8,2.45 8.45,2 9,2 L9,2 Z M11,16 L4,16 L4,14 L11,14 L11,16 L11,16 Z M14,12 L4,12 L4,10 L14,10 L14,12 L14,12 Z M14,8 L4,8 L4,6 L14,6 L14,8 L14,8 Z" id="Shape"></path>
                  </g>
              </g>
            </svg>
            <span class="list-item">{{ taskConstants.CREATEDBYME | translate }}</span>
          </div>
          <div class="rounded-rectangle">
            <span>{{ getTotalCount(taskConstants.ROUTE_CREATEDBYME) }}</span>
          </div>
        </mat-list-item>

        <mat-list-item (click)="navigateRoute(taskConstants.ROUTE_TODAY_DUE)" id="todayMenu">
          <div>
            <mat-icon>access_time</mat-icon>
            <span class="list-item">{{ taskConstants.TODAY_DUE | translate }}</span>
          </div>
          <div class="rounded-rectangle">
            <span>{{ getTotalCount(taskConstants.ROUTE_TODAY_DUE) }}</span>
          </div>
        </mat-list-item>

        <mat-list-item (click)="navigateRoute(taskConstants.ROUTE_TOMORROW_DUE)" id="tomorrowMenu">
          <div>
            <mat-icon>watch_later</mat-icon>
            <span class="list-item">{{ taskConstants.TOMORROW_DUE | translate }}</span>
          </div>
          <div class="rounded-rectangle">
            <span>{{ getTotalCount(taskConstants.ROUTE_TOMORROW_DUE) }}</span>
          </div>
        </mat-list-item>

        <mat-list-item (click)="navigateRoute(taskConstants.ROUTE_THIS_WEEK_DUE)" id="thisWeekMenu">
          <div>
            <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>Icon/product/date-new</title>
                <g id="Icon/product/date-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <path d="M15.5,3 C16.3284271,3 17,3.67157288 17,4.5 L17,5 L18,5 C19.5976809,5 20.9036609,6.24891996 20.9949073,7.82372721 L21,8 L21,18 C21,19.5976809 19.75108,20.9036609 18.1762728,20.9949073 L6,21 C4.40231912,21 3.09633912,19.75108 3.00509269,18.1762728 L3,18 L3,8 C3,6.40231912 4.24891996,5.09633912 5.82372721,5.00509269 L7,5.005 L7,4.5 C7,3.67157288 7.67157288,3 8.5,3 C9.32842712,3 10,3.67157288 10,4.5 L10,5.003 L14,5.002 L14,4.5 C14,3.67157288 14.6715729,3 15.5,3 Z M18,7 L6,7 C5.48716416,7 5.06449284,7.38604019 5.00672773,7.88337887 L5,8 L5,18 C5,18.5128358 5.38604019,18.9355072 5.88337887,18.9932723 L6,19 L18,19 C18.5128358,19 18.9355072,18.6139598 18.9932723,18.1166211 L19,18 L19,8 C19,7.44771525 18.5522847,7 18,7 Z M16.9804916,14 C17.5370911,14 17.9883041,14.451213 17.9883041,15.0078125 C17.9883041,15.564412 17.5370911,16.015625 16.9804916,16.015625 L7.01950841,16.015625 C6.46290893,16.015625 6.01169591,15.564412 6.01169591,15.0078125 C6.01169591,14.451213 6.46290893,14 7.01950841,14 L16.9804916,14 Z M7.5,9 C8.32842712,9 9,9.67157288 9,10.5 C9,11.3284271 8.32842712,12 7.5,12 C6.67157288,12 6,11.3284271 6,10.5 C6,9.67157288 6.67157288,9 7.5,9 Z" id="Combined-Shape" fill="#000000"></path>
                </g>
            </svg>
            <span class="list-item">{{ taskConstants.THIS_WEEK_DUE | translate }}</span>
          </div>
          <div class="rounded-rectangle">
            <span>{{ getTotalCount(taskConstants.ROUTE_THIS_WEEK_DUE) }}</span>
          </div>
        </mat-list-item>


        <mat-list-item (click)="navigateRoute(taskConstants.ROUTE_TASKS_I_WATCH)" id="taskIWatchMenu">
          <div>
              <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <title>Icon/product/visibility</title>
                  <g id="Icon/product/visibility" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="Icon-24px" transform="translate(1.000000, 4.500000)" fill="#000000">
                          <path d="M11,0 C16,0 20.27,3.11 22,7.5 C20.27,11.89 16,15 11,15 C6,15 1.73,11.89 0,7.5 C1.73,3.11 6,0 11,0 Z M11,2 C7.3276702,2 4.03570887,4.0392665 2.34847366,7.18104549 L2.185,7.499 L2.207812,7.54698098 C3.80585357,10.7580378 7.05081834,12.8886127 10.7102582,12.9957618 L11,13 C14.7772535,13 18.1520927,10.8425393 19.792188,7.54698098 L19.814,7.499 L19.792188,7.45301902 C18.1941464,4.24196222 14.9491817,2.11138727 11.2897418,2.00423816 L11,2 Z M11,3.5 C13.2085695,3.5 15,5.2914305 15,7.5 C15,9.7085695 13.2085695,11.5 11,11.5 C8.7914305,11.5 7,9.7085695 7,7.5 C7,5.2914305 8.7914305,3.5 11,3.5 Z M11,5.5 C9.896,5.5 9,6.396 9,7.5 C9,8.604 9.896,9.5 11,9.5 C12.104,9.5 13,8.604 13,7.5 C13,6.396 12.104,5.5 11,5.5 Z" id="Shape"></path>
                      </g>
                  </g>
              </svg>
              <span class="list-item">{{ taskConstants.TASKS_I_WATCH | translate }}</span>
          </div>
          <div class="rounded-rectangle">
            <span>{{ getTotalCount(taskConstants.ROUTE_TASKS_I_WATCH) }}</span>
          </div>
        </mat-list-item>

        <mat-list-item (click)="navigateRoute(taskConstants.ROUTE_MY_OVERDUE_TASKS)" id="myoverduetasks">
          <div>
              <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <title>Icon/product/assignment-late</title>
                  <g id="Icon/product/assignment-late" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="Icon-24px" transform="translate(3.000000, 1.000000)" fill="#000000">
                          <path d="M16,2 L11.82,2 C11.4,0.84 10.3,0 9,0 C7.7,0 6.6,0.84 6.18,2 L2,2 C0.9,2 0,2.9 0,4 L0,18 C0,19.1 0.9,20 2,20 L16,20 C17.1,20 18,19.1 18,18 L18,4 C18,2.9 17.1,2 16,2 L16,2 Z M10,17 L8,17 L8,15 L10,15 L10,17 L10,17 Z M10,13 L8,13 L8,7 L10,7 L10,13 L10,13 Z M9,4 C8.45,4 8,3.55 8,3 C8,2.45 8.45,2 9,2 C9.55,2 10,2.45 10,3 C10,3.55 9.55,4 9,4 L9,4 Z" id="Shape"></path>
                      </g>
                  </g>
              </svg>
              <span class="list-item">{{ taskConstants.MY_OVERDUE_TASKS | translate }}</span>
          </div>
          <div class="rounded-rectangle">
            <span>{{ getTotalCount(taskConstants.ROUTE_MY_OVERDUE_TASKS) }}</span>
          </div>
        </mat-list-item>

      </mat-list>
    </div>
  </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TaskOptionListComponent implements OnDestroy {
  selectedFilter: string;
  taskConstants = TasksConstants;
  total_count: number = 0;
  taskStatistics: TaskStatistics[];
  isAlive = true;
  isOnline: boolean = false;

  constructor(
    private store: Store<TasksRootState>,
    private broadcaster: Broadcaster,
    private changerDetectorRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<TaskOptionListComponent>
  ) {
    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });
    this.broadcaster.on<any>("hideTaskOptionListDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });
    this.setupStore();
  }

  setupStore() {
    this.store
      .select(getSelectedFilterOption)
      .pipe(take(1))
      .subscribe(value => {
        this.selectedFilter = value;
        this.changerDetectorRef.markForCheck();
      });
    this.store
      .select(getTaskStatisticsInfo)
      .pipe(take(1))
      .subscribe(info => {
        this.taskStatistics = info;
        this.changerDetectorRef.markForCheck();
    });

    this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
      this.isOnline = isOnline;
      this.changerDetectorRef.markForCheck();
    });
  }

  navigateRoute(title: any) {
    this.hide(title);
  }

  isSelectedFilter(title: string): boolean {
    if (title === this.selectedFilter) {
      return true;
    }
    return false;
  }

  @HostListener("document:keydown.esc", ["$event"])
  hide(title?) {
    this.dialogRef.close({ type: this.taskConstants.ROUTE_TYPE_STATUS, title: title});
  }

  getTotalCount(routeName) {
    if (this.taskStatistics) {
      let taskStatistic: TaskStatistics = this.taskStatistics.find( statisticInfo =>
        statisticInfo.type === routeName );
      return taskStatistic ? taskStatistic.info.total_count : 0;
    }
    return 0;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.changerDetectorRef.markForCheck();
  }
}
