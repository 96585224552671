
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ICompact } from "./task";

export class TimeEntry {
  id: number;
  project: ICompact;
  issue: ICompact;
  user: ICompact;
  activity: ICompact;
  hours: string;
  comments: string;
  spent_on: string;
  created_on: Date;
  updated_on: Date;
  avatar: string;

  constructor(json) {
    if (!json) {
      return;
    }

    Object.keys(json).forEach(key => this[key] = json[key]);

    if (json.created_on !== null) {
      this.created_on = new Date(json.created_on);
      if (isNaN(this.created_on.getTime())) {
        this.created_on = null;
      }
    }
    if (json.updated_on !== null) {
      this.updated_on = new Date(json.updated_on);
      if (isNaN(this.updated_on.getTime())) {
        this.updated_on = null;
      }
    }
  }
}
