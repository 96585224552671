
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="autocomplete">
  <div class="autocomplete__input">
    <mdl-textfield
      id="autocomplete-textfield"
      type="text"
      (keydown)="onKeyDown($event)"
      [(ngModel)]="query"
      (focus)="focused = true"
      [readonly]="true"
      >
    </mdl-textfield>
  </div>
  <div class="autocomplete__list" *ngIf="focused">
    <div *ngFor="let item of filteredItems; let i = index"
      [class.active]="i === selectedIndex"
      (click)="onClick(i)">
      {{getDisplayText(item)}}
    </div>
    <div id="pick-task-date" [hidden]="!isTaskDate" class="editdateselect" (click)="editDateClick()"> {{ 'PICK_DATE' | translate }} </div>
  </div>
</div>
