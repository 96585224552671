
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, HostListener, ViewChild, Output, Input, EventEmitter, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { MdlDialogComponent } from "@angular-mdl/core";
import { Task } from "../../models";
import { DatePipe } from "@angular/common";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { takeWhile } from "rxjs/operators";

@Component({
  selector: "vp-task-reminder-dialog",
  template: `
    <mdl-dialog #remainderDialog [mdl-dialog-config]="dialogSetting">
      <div id="vnctask_reminder_dialog" class="vnctask_reminer_dialog mdl-shadow--2dp open">
          <div class="task__dialog-header brand-color mdl-shadow--2dp" layout="row center-center">
            <h4>{{ 'TASK_REMINDER' | translate}}</h4>
            <button id="reminderCloseButton" (click)="hideDialog()" mat-button class="task__dialog-desktop-close-button brand-color mat-button">
            <i class="material-icons">clear</i>
          </button>
          </div>
          <div>
            <div class = "task-reminder-subject">
              <span class="header-text disable-select">{{ 'TASKS' | translate }}</span>
              <span class="header-text disable-select">{{ 'OVERDUE' | translate }}</span>
            </div>
            <div class = "task-reminder-task">
              <div *ngFor="let task of tasks">
                <div (click)="onTaskClick.emit(task); close();" *ngIf="task">
                    <div class="task-reminder-item">
                      <span class="task-subject disable-select">{{task.subject}}</span>
                      <span class="task-due disable-select">{{ datePipe.transform(task.due_date,"MMM dd yyyy") }}</span>
                    </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </mdl-dialog>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskReminderComponent implements OnDestroy {
  @ViewChild("remainderDialog", { static: false }) reminderDialog: MdlDialogComponent;
  dialogSetting: any = {
    clickOutsideToClose: true,
    isModal: true,
    styles: {"width": "99%", "max-width": "500px"},
    enterTransitionDuration: 400,
    leaveTransitionDuration: 400
  };
  isAlive = true;

  tasks: Task [] = [];
  @Output() onTaskClick = new EventEmitter<Task>();
  datePipe: DatePipe = new DatePipe("en");

  constructor(
    private broadcaster: Broadcaster,
    private changerDetectorRef: ChangeDetectorRef ) {
    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
      });
    this.broadcaster.on<any>("hideReminderDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
      });
  }

  public close() {
    this.reminderDialog.close();
  }

  show(duedTaskList): void {
    this.tasks = duedTaskList;
    this.reminderDialog.show();
    this.changerDetectorRef.markForCheck();
  }

  @HostListener("keydown.esc")
  public hideDialog(): void {
    this.reminderDialog.close();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.onTaskClick.unsubscribe();
    this.changerDetectorRef.markForCheck();
  }
}
