
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "vp-task-avatar",
  template: `
  <div layout="column" layout-align="center center" class="layout-align-center-center">
    <div *ngIf="!image" class="avatar ng-binding ng-scope" [ngClass]="(isLarge)?{'avatar-xlg':'true'}:{'avatar-md': 'true'}" >{{ (name) ? name: ('NO_NAME' | translate).charAt(0)  }}</div>
    <img img-cache [src]="image" *ngIf="image" class="avatar-img" [ngClass]="{'avatar-xlg': isLarge }"/>
  </div>
  `
})
export class TaskAvatarComponent {
  @Input() image: string;
  @Input() isLarge: boolean;
  @Input() name: string;

  constructor(private translate: TranslateService) {}
}
