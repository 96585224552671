
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="media-preview-dialog-component media-preview-dialog-desktop" *ngIf="!isOnMobile">
  <div class="image-wrapper">
    <div class="image-header">
      <div class="filename">{{ getFileName() }}</div>
      <span></span>
      <button id="closePreviewDialog" mat-icon-button (click)="close()">
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>Icon/product/close-new</title>
          <g id="Icon/product/close-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <path d="M18.9497475,5.05025253 C19.3402718,5.44077682 19.3402718,6.0739418 18.9497475,6.46446609 L13.4142136,12 L18.9497475,17.5355339 C19.3402718,17.9260582 19.3402718,18.5592232 18.9497475,18.9497475 C18.5592232,19.3402718 17.9260582,19.3402718 17.5355339,18.9497475 L12,13.4142136 L6.46446609,18.9497475 C6.0739418,19.3402718 5.44077682,19.3402718 5.05025253,18.9497475 C4.65972824,18.5592232 4.65972824,17.9260582 5.05025253,17.5355339 L10.5857864,12 L5.05025253,6.46446609 C4.65972824,6.0739418 4.65972824,5.44077682 5.05025253,5.05025253 C5.44077682,4.65972824 6.0739418,4.65972824 6.46446609,5.05025253 L12,10.5857864 L17.5355339,5.05025253 C17.9260582,4.65972824 18.5592232,4.65972824 18.9497475,5.05025253 Z" id="Path" fill="#ffffff"></path>
          </g>
      </svg>
      </button>
    </div>
    <div class="media" [ngSwitch]="selectedMediaType">

      <div *ngSwitchCase="MediaType.VOICE_MESSAGE">
          <img class="audio-preview-image" [src]="CommonUtil.getFullUrl('/assets/media-icons/file-document-mic.svg')" alt="mic">
          <audio id="music" preload="true" controls controlsList="nodownload"
                 onended="this.currentTime = 0;this.pause();" [src]="url">
          </audio>
      </div>
      <img *ngSwitchDefault [style.background]="placeholder" [src]="url"/>

      <video *ngSwitchCase="MediaType.VIDEOS"  preload="true" controls [src]="url" controlsList="nodownload">
        Sorry, your browser doesn't support embedded videos.
      </video>
    </div>
    <div class="media-information">
      <div class="title-and-time">
        <span class="media-title">{{ getSenderName() }}</span>
        <span class="timeago">{{ getTimeAgo() }}</span>
      </div>

      <span></span>
      <div class="image-actions">
        <button mat-button (click)="underDevelopment()">
          <mat-icon>share</mat-icon>
        </button>
        <button mat-button (click)="downloadAttachment()">
          <svg width="40px" height="40px" viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <title>Icon/medium/download</title>
            <g id="Icon/medium/download" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <path d="M48,39.1071429 C48.5128358,39.1071429 48.9355072,39.493183 48.9932723,39.9905217 L49,40.1071429 L49,42.7649169 C48.9990517,45.4483956 46.8562889,47.6314096 44.1758017,47.7453285 L43.9573182,47.75 L21.0426818,47.75 C18.3340587,47.75 16.119353,45.6369253 16.0046654,42.9810622 L16,42.7645635 L16,40.1071429 C16,39.5548581 16.4477153,39.1071429 17,39.1071429 C17.5128358,39.1071429 17.9355072,39.493183 17.9932723,39.9905217 L18,40.1071429 L18,42.7645635 C18,44.3513859 19.2637437,45.6539069 20.8635856,45.7449202 L21.0426818,45.75 L43.9569747,45.7500001 C45.5803891,45.7494425 46.90194,44.5037433 46.9947871,42.9396185 L47,42.7645635 L47,40.1071429 C47,39.5548581 47.4477153,39.1071429 48,39.1071429 Z M33,18.892 C33.5128358,18.892 33.9355072,19.2780402 33.9932723,19.7753789 L34,19.892 L34,35.585 L38.2928932,31.2928932 C38.6533772,30.9324093 39.2206082,30.9046797 39.6128994,31.2097046 L39.7071068,31.2928932 C40.0675907,31.6533772 40.0953203,32.2206082 39.7902954,32.6128994 L39.7071068,32.7071068 L33.7071068,38.7071068 C33.3466228,39.0675907 32.7793918,39.0953203 32.3871006,38.7902954 L32.2928932,38.7071068 L26.2928932,32.7071068 C25.9023689,32.3165825 25.9023689,31.6834175 26.2928932,31.2928932 C26.6533772,30.9324093 27.2206082,30.9046797 27.6128994,31.2097046 L27.7071068,31.2928932 L32,35.585 L32,19.892 C32,19.3397153 32.4477153,18.892 33,18.892 Z" id="Shape" fill="#ffffff"></path>
                <path d="M48,39.1071429 C48.5128358,39.1071429 48.9355072,39.493183 48.9932723,39.9905217 L49,40.1071429 L49,42.7649169 C48.9990517,45.4483956 46.8562889,47.6314096 44.1758017,47.7453285 L43.9573182,47.75 L21.0426818,47.75 C18.3340587,47.75 16.119353,45.6369253 16.0046654,42.9810622 L16,42.7645635 L16,40.1071429 C16,39.5548581 16.4477153,39.1071429 17,39.1071429 C17.5128358,39.1071429 17.9355072,39.493183 17.9932723,39.9905217 L18,40.1071429 L18,42.7645635 C18,44.3513859 19.2637437,45.6539069 20.8635856,45.7449202 L21.0426818,45.75 L43.9569747,45.7500001 C45.5803891,45.7494425 46.90194,44.5037433 46.9947871,42.9396185 L47,42.7645635 L47,40.1071429 C47,39.5548581 47.4477153,39.1071429 48,39.1071429 Z M33,18.892 C33.5128358,18.892 33.9355072,19.2780402 33.9932723,19.7753789 L34,19.892 L34,35.585 L38.2928932,31.2928932 C38.6533772,30.9324093 39.2206082,30.9046797 39.6128994,31.2097046 L39.7071068,31.2928932 C40.0675907,31.6533772 40.0953203,32.2206082 39.7902954,32.6128994 L39.7071068,32.7071068 L33.7071068,38.7071068 C33.3466228,39.0675907 32.7793918,39.0953203 32.3871006,38.7902954 L32.2928932,38.7071068 L26.2928932,32.7071068 C25.9023689,32.3165825 25.9023689,31.6834175 26.2928932,31.2928932 C26.6533772,30.9324093 27.2206082,30.9046797 27.6128994,31.2097046 L27.7071068,31.2928932 L32,35.585 L32,19.892 C32,19.3397153 32.4477153,18.892 33,18.892 Z" id="Shape" fill="#ffffff"></path>
            </g>
        </svg>
        </button>
        <button *ngIf="isDeletable()" mat-button (click)="deleteAttachment()">
          <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <title>Icon/product/delete-new</title>
            <g id="Icon/product/delete-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <path d="M18,7 L6,7 L6,18.2857143 C6,19.7746499 7.19131256,21 8.66666667,21 L15.3333333,21 C16.8086874,21 18,19.7746499 18,18.2857143 L18,7 Z M8,9 L16,9 L16,18.2857143 C16,18.6824929 15.6913126,19 15.3333333,19 L8.66666667,19 L8.57834889,18.9935231 C8.25938319,18.9465317 8,18.649428 8,18.2857143 L8,9 Z M13,3 C13.7404864,3 14.3869825,3.40242038 14.7327215,4.00049436 L18,4 C18.5522847,4 19,4.44771525 19,5 C19,5.55228475 18.5522847,6 18,6 L6,6 C5.44771525,6 5,5.55228475 5,5 C5,4.44771525 5.44771525,4 6,4 L9.26760632,3.99992752 C9.61342606,3.40216612 10.2597476,3 11,3 L13,3 Z" id="Combined-Shape" fill="#ffffff"></path>
            </g>
          </svg>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="media-preview-dialog-mobile" *ngIf="isOnMobile" [ngSwitch]="selectedMediaType">
<div class="media-image-preview" *ngSwitchCase="MediaType.IMAGE">
    <div class="media-image-preview__header">
      <button mat-button class="back-to-media" (click)="close()">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <div class="image-file-name">
        <p>{{getFileName()}}</p>
      </div>
      <span></span>
      <div class="more-options" id="media-menu">
          <button mat-button (click)="downloadAttachment()">
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>Icon/product/arrow-downward</title>
              <g id="Icon/product/arrow-downward" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Icon-24px" transform="translate(4.000000, 4.000000)" fill="#ffffff">
                      <polygon id="Shape" points="16 8 14.59 6.59 9 12.17 9 0 7 0 7 12.17 1.42 6.58 0 8 8 16"></polygon>
                  </g>
              </g>
            </svg>
          </button>
      </div>
      <div class="more-options" id="media-menu">
        <button mat-button [matMenuTriggerFor]="previewMenuBtn">
          <mdl-icon>more_vert</mdl-icon>
        </button>
      </div>
    </div>
    <div class="media-image-preview__body">
      <img [src]="url" alt="image">
    </div>
    <div class="media-image-preview__footer">
      <div class="sender-name">
        <p>{{ getSenderName() }}</p>
      </div>
      <div class="sent-time">
        <p>{{ getTimeAgo() }}</p>
      </div>
    </div>
  </div>
  <div class="media-audio-preview" *ngSwitchCase="MediaType.VOICE_MESSAGE">
      <div class="media-audio-preview__header">
        <button mat-button class="back-to-media" (click)="close()">
          <mdl-icon>chevron_left</mdl-icon>
        </button>
        <div class="audio-file-name">
          <p>{{getFileName()}}</p>
        </div>
        <span></span>
        <div class="more-options" id="media-menu">
            <button mat-button (click)="downloadAttachment()">
              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>Icon/product/arrow-downward</title>
                <g id="Icon/product/arrow-downward" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Icon-24px" transform="translate(4.000000, 4.000000)" fill="#ffffff">
                        <polygon id="Shape" points="16 8 14.59 6.59 9 12.17 9 0 7 0 7 12.17 1.42 6.58 0 8 8 16"></polygon>
                    </g>
                </g>
            </svg>
            </button>
        </div>
        <div class="more-options" id="media-menu">
          <button mat-button [matMenuTriggerFor]="previewMenuBtn">
            <mdl-icon>more_vert</mdl-icon>
          </button>
        </div>
      </div>
      <div class="media-audio-preview__body">
        <img [src]="CommonUtil.getMediaUrl(MediaType.VOICE_MESSAGE)" alt="mic">
      </div>
      <div class="media-audio-preview__audio">
        <audio id="music" preload="true" controls controlsList="nodownload"
               onended="this.currentTime = 0;this.pause();" [src]="url">
        </audio>
      </div>
    </div>

    <div class="media-video-preview" *ngSwitchCase="MediaType.VIDEOS">
      <div class="media-video-preview__header">
        <button mat-button class="back-to-media" (click)="close()">
          <mdl-icon>chevron_left</mdl-icon>
        </button>
        <div class="video-file-name">
          <p>{{getFileName()}}</p>
        </div>
        <span></span>
        <div class="more-options" id="media-menu">
          <button mat-button (click)="downloadAttachment()">
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>Icon/product/arrow-downward</title>
              <g id="Icon/product/arrow-downward" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Icon-24px" transform="translate(4.000000, 4.000000)" fill="#ffffff">
                      <polygon id="Shape" points="16 8 14.59 6.59 9 12.17 9 0 7 0 7 12.17 1.42 6.58 0 8 8 16"></polygon>
                  </g>
              </g>
          </svg>
          </button>
        </div>
        <div class="more-options" id="media-menu">
          <button mat-button [matMenuTriggerFor]="previewMenuBtn">
            <mdl-icon>more_vert</mdl-icon>
          </button>
        </div>
      </div>
      <div class="media-video-preview__body">
        <video controls [src]="url"  preload="true" controlsList="nodownload">
          Sorry, your browser doesn't support embedded videos.
        </video>
      </div>
    </div>
    <mat-menu #previewMenuBtn="matMenu" xPosition="before" class="options-menu">
      <button mat-menu-item class="menu-item" (click)="shareAttachment()">
        <mat-icon style="font-size: 20px;margin: 0;">share</mat-icon>
        <p>{{'SHARE' | translate}}</p>
      </button>
      <button mat-menu-item *ngIf="isDeletable()" class="menu-item" (click)="deleteAttachment()">
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>Icon/product/delete-new</title>
          <g id="Icon/product/delete-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <path d="M18,7 L6,7 L6,18.2857143 C6,19.7746499 7.19131256,21 8.66666667,21 L15.3333333,21 C16.8086874,21 18,19.7746499 18,18.2857143 L18,7 Z M8,9 L16,9 L16,18.2857143 C16,18.6824929 15.6913126,19 15.3333333,19 L8.66666667,19 L8.57834889,18.9935231 C8.25938319,18.9465317 8,18.649428 8,18.2857143 L8,9 Z M13,3 C13.7404864,3 14.3869825,3.40242038 14.7327215,4.00049436 L18,4 C18.5522847,4 19,4.44771525 19,5 C19,5.55228475 18.5522847,6 18,6 L6,6 C5.44771525,6 5,5.55228475 5,5 C5,4.44771525 5.44771525,4 6,4 L9.26760632,3.99992752 C9.61342606,3.40216612 10.2597476,3 11,3 L13,3 Z" id="Combined-Shape" fill="#000000"></path>
          </g>
        </svg>
        <p>{{'DELETE' | translate}}</p>
      </button>
    </mat-menu>
  </div>
