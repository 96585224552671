
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, forwardRef, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter, OnDestroy } from "@angular/core";
import { TaskOptionComponent } from "../task-option.component";
import { TaskRepository } from "../../../../repository/task.repository";
import { MatDialog } from "@angular/material/dialog";
import { TaskService } from "src/app/task/task.service";
import { ConfigService } from "src/app/common/providers/config.service";
import { delay, of, Subject, takeUntil } from "rxjs";

@Component({
  selector: "vp-new-vnctask-option-add-estimate",
  providers: [{
    provide: TaskOptionComponent,
    // eslint-disable-next-line
    useExisting: forwardRef(() => NewTaskAddEstimateComponent)
  }],
  templateUrl: "new-option-add-estimate.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewTaskAddEstimateComponent extends TaskOptionComponent implements OnInit, OnDestroy{
  id: string = "add-estimate";
  triggerKey: string = "\"";
  estimatedHours: string = "00";
  estimatedMinutes: string = "00";
  estimate: any = {
    name: "estimate",
    value: ""
  };
  lang: string = "en";
  private isAlive$ = new Subject()
  activate: boolean = false;
  constructor(
    private taskRepo: TaskRepository,
    public changerDetectorRef: ChangeDetectorRef,
    public matDialog: MatDialog,
    private service: TaskService,
    private configService: ConfigService,
  ) {
    super(changerDetectorRef, matDialog);
    this.service.cancelActivationOfTags.subscribe(res => {
      this.cancelActivateData();
    });
  }

  ngOnInit() {
    this.configService.currentLanguage
    .pipe(takeUntil(this.isAlive$))
    .subscribe((lang: string) => {
      of(null)
        .pipe(delay(100))
        .subscribe(() => {
          this.lang = lang;
          this.changerDetectorRef.markForCheck();
        });
    });
  }

  ngOnDestroy(): void {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  getPayload() {
    let value = this.getValue();
    if (!value || (value && !value.value)) return {};
    return { "estimated_hours": value.value };
  }

  setEstimatedTime() {
    let value = this.estimatedHours + ":" + this.estimatedMinutes;
    this.estimatedHours = this.estimatedHours !== "" ? this.estimatedHours : "00";
    this.estimatedMinutes = this.estimatedMinutes !== "" ? this.estimatedMinutes : "00";
    this.estimate.value = this.estimate.name = this.estimatedHours + ":" + this.estimatedMinutes;
    this.onDescriptionClick(this.estimate);
    this.selectedData(this.estimate);
    this.estimatedHours = this.estimatedMinutes = "00";
  }

  close() {
    this.estimatedHours = this.estimatedMinutes = "00";
    this.goBack();
  }

  validateNumber(event, ele) {
    if (ele.toString().length > 2) {
      return false;
    }
    let key = window.event ? event.keyCode : event.which;
    if ((event.keyCode > 47 && event.keyCode < 58) ||
      (event.keyCode > 95 && event.keyCode < 106) ||
      event.keyCode === 46 || event.keyCode === 8 ||
      event.keyCode === 9 || event.keyCode === 37 ||
      event.keyCode === 39 || event.keyCode === 13) {
      return true;
    } else if (key < 48 || key > 57) {
      return false;
    } else {
      return true;
    }
  }

  @Output() selectActivate = new EventEmitter();
  @Output() selectedEstimate = new EventEmitter();
  selectActivateData() {
    this.activate = true;
    this.selectActivate.emit(this.activate);
    this.changerDetectorRef.markForCheck();
  }

  cancelActivateData() {
    this.activate = false;
    this.selectActivate.emit(this.activate);
  }

  selectedData(item){
    this.selectedEstimate.emit(item);
    this.activate = false;
    this.selectActivate.emit(this.activate);
    this.changerDetectorRef.markForCheck();
  }

}
