
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="taskFieldsDialog" class="task__dialog">
    <div class="vp-task-add-fields-dialog task__dialog-header">
      <div class="mobile-back-button">
        <button mat-button (click)="close()">
          <mat-icon class="disable-select">close</mat-icon>
        </button>
      </div>
      <div class="header_lbl disable-select">
        {{'ADD_FIELD' | translate}}
      </div>
      <div class="desktop-close-button">
        <button mat-button (click)="close()">
          <mat-icon class="disable-select">close</mat-icon>
        </button>
      </div>
    </div>
    <div class="task__dialog-body task__dialog-body--pad">

      <mat-list role="list" class="action-list">
        <mat-list-item role="listitem" id="add-field-reminder" (click)="redirectTo(addFieldsType.reminder)">
          <mat-icon>notifications_active</mat-icon>
          <span>{{'REMINDER' | translate}}</span>
        </mat-list-item>
        <mat-list-item role="listitem" id="add-field-attachment" (click)="redirectTo(addFieldsType.attachment)">
          <div class="icon-container-block">
            <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>Icon/product/attachment</title>
              <g id="Icon/product/attachment" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Icon-24px" transform="translate(2.000000, 7.000000)" fill="#000000">
                      <path d="M0,5.5 C0,2.46 2.46,0 5.5,0 L16,0 C18.21,0 20,1.79 20,4 C20,6.21 18.21,8 16,8 L7.5,8 C6.12,8 5,6.88 5,5.5 C5,4.12 6.12,3 7.5,3 L15,3 L15,5 L7.41,5 C6.86,5 6.86,6 7.41,6 L16,6 C17.1,6 18,5.1 18,4 C18,2.9 17.1,2 16,2 L5.5,2 C3.57,2 2,3.57 2,5.5 C2,7.43 3.57,9 5.5,9 L15,9 L15,11 L5.5,11 C2.46,11 0,8.54 0,5.5 L0,5.5 Z" id="Shape"></path>
                  </g>
              </g>
            </svg>
          </div>
          <span>{{'ATTACHMENT' | translate}}</span>
        </mat-list-item>
        <mat-list-item role="listitem" id="add-field-url"  (click)="redirectTo(addFieldsType.url)">
          <mat-icon>insert_link</mat-icon>
          <span>{{'URL' | translate}}</span>
        </mat-list-item>
        <!-- <mat-list-item role="listitem" id="add-field-subtask" (click)="redirectTo(addFieldsType.subtask)">
          <mat-icon>event_note</mat-icon>
          <span>{{'SUBTASK' | translate}}</span>
        </mat-list-item> -->
        <mat-list-item role="listitem" id="add-field-watchers" (click)="redirectTo(addFieldsType.watchers)">
          <div class="icon-container-block">
            <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>Icon/product/visibility</title>
              <g id="Icon/product/visibility" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Icon-24px" transform="translate(1.000000, 4.500000)" fill="#000000">
                      <path d="M11,0 C16,0 20.27,3.11 22,7.5 C20.27,11.89 16,15 11,15 C6,15 1.73,11.89 0,7.5 C1.73,3.11 6,0 11,0 Z M11,2 C7.3276702,2 4.03570887,4.0392665 2.34847366,7.18104549 L2.185,7.499 L2.207812,7.54698098 C3.80585357,10.7580378 7.05081834,12.8886127 10.7102582,12.9957618 L11,13 C14.7772535,13 18.1520927,10.8425393 19.792188,7.54698098 L19.814,7.499 L19.792188,7.45301902 C18.1941464,4.24196222 14.9491817,2.11138727 11.2897418,2.00423816 L11,2 Z M11,3.5 C13.2085695,3.5 15,5.2914305 15,7.5 C15,9.7085695 13.2085695,11.5 11,11.5 C8.7914305,11.5 7,9.7085695 7,7.5 C7,5.2914305 8.7914305,3.5 11,3.5 Z M11,5.5 C9.896,5.5 9,6.396 9,7.5 C9,8.604 9.896,9.5 11,9.5 C12.104,9.5 13,8.604 13,7.5 C13,6.396 12.104,5.5 11,5.5 Z" id="Shape"></path>
                  </g>
              </g>
            </svg>
          </div>
          <span>{{'WATCHERS' | translate}}</span>
        </mat-list-item>
      </mat-list>
    </div>
</div>

