
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Output, EventEmitter, ChangeDetectionStrategy, Inject, ChangeDetectorRef, OnDestroy, HostListener } from "@angular/core";
import { CommonUtil } from "../../../../common/utils/common.utils";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { SuccessService } from "../../../../common/providers/success-service";
import { ErrorService } from "../../../../common/providers/error-service";
import { TaskRepository } from "../../../repository/task.repository";
import { ErrorType, SuccessType } from "../../../shared/task-enum";
import { Broadcaster } from "../../../../common/providers/broadcaster.service";
import { Store } from "@ngrx/store";
import { TasksRootState, getTasksIsLoading } from "../../../store";
import { getOnlineStatus } from "../../../../reducers";
import { TaskConfirmDialogComponent } from "../../task-dialogs/confirm-dialog.component";
import { dialogType } from "../../../models/dialog-type";
import { MessageTranslatorService } from "../../../services/message-translator-service";
import { TasksConstants } from "../../../shared/task-constacts";
import { takeWhile, take } from "rxjs/operators";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { VncLibraryService } from "vnc-library";


@Component({
  selector: "vp-task-account-deactivate-confirmation",
  templateUrl: "./task-account-deactivate-confirmation.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskAccountDeactivateConfirmationComponent implements OnDestroy {
  confirmDeactivateform: UntypedFormGroup;
  password: string = "";
  isAlive: boolean = true;
  isLoading: boolean = false;
  isIOS = CommonUtil.isOnIOS();
  isOnline: boolean = false;
  dialogType = dialogType;
  feedbackReason: string = "";

  constructor(
    private changerDetectorRef: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private tasksRepo: TaskRepository,
    private vncLibaryService: VncLibraryService,
    private dialogRef: MatDialogRef<TaskAccountDeactivateConfirmationComponent>,
    private broadcaster: Broadcaster,
    private store: Store<TasksRootState>,
    private errorService: ErrorService,
    private successService: SuccessService,
    private messageTranslatorService: MessageTranslatorService,
    private matDialog: MatDialog
  ) {
    this.handleAccountDeactivateConfirmationErrorMessages();
    this.handleAccountDeactivateConfirmationSuccessMessages();
    this.confirmDeactivateform = fb.group({
      "password": [null, Validators.compose([Validators.required, Validators.minLength(8)])]
    });

   this.store.select(getTasksIsLoading).pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
      this.isLoading = value;
      this.changerDetectorRef.markForCheck();
   });

   this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
    this.isOnline = isOnline;
    this.changerDetectorRef.markForCheck();
  });

  this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
    .subscribe(presence => {
      this.close();
    });

  this.broadcaster.on<any>("hideTaskAccountDeactivateConfirmation").pipe(takeWhile(() => this.isAlive))
    .subscribe(presence => {
      this.close();
    });
  }

  @HostListener("document:keydown.esc", ["$event"])
  onESC() {
    this.close(false);
  }

  close(value?) {
    this.dialogRef.close(value);
  }

  handleAccountDeactivateConfirmationErrorMessages() {
    this.errorService.only(ErrorType.DeleteAccountError).pipe(takeWhile(() => this.isAlive)).subscribe(error => {
      this.vncLibaryService.openSnackBar(error.messages, "","", "", 3000, "bottom", "left").subscribe(res => {
      });
      this.isLoading = false;
      this.changerDetectorRef.markForCheck();
    });
  }

  handleAccountDeactivateConfirmationSuccessMessages() {
    this.successService.only(SuccessType.DeleteAccountSuccess).pipe(takeWhile(() => this.isAlive)).subscribe(success => {
      this.vncLibaryService.openSnackBar(success.messages, "","", "", 3000, "bottom", "left").subscribe(res => {
      });
      this.isLoading = false;
      this.changerDetectorRef.markForCheck();
      this.close(true);
    });
  }

  onFormSubmit(form) {
    if (this.isOnline) {
      this.feedbackReason = localStorage.getItem("deactivate_feedback");
      this.password = form.password;
      this.confirmDialog();
    } else {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
    }
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.changerDetectorRef.markForCheck();
  }

  confirmDialog() {
    const dlg = this.matDialog.open(TaskConfirmDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-task-confirm-dialog",
      data: { type: this.dialogType.DEACTIVATE, message: this.messageTranslatorService.getMessage(TasksConstants.CONFIRM_DEACTIVATION_NOTE), header: this.messageTranslatorService.getMessage(TasksConstants.CONFIRM_DEACTIVATION_MESSAGE) }
    });
    dlg.afterClosed().pipe(take(1)).subscribe(res => {
      if (!!res) {
        if (this.feedbackReason && this.password) {
          this.isLoading = true;
          this.changerDetectorRef.markForCheck();
          this.tasksRepo.deleteAccount(this.feedbackReason, this.password);
        }
      }
    });
  }
}
