
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->
  <ng-template #dueDateIcon>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Icon/product/date-confirmed-new</title>
      <g id="Icon/product/date-confirmed-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path d="M15.5,3 C16.3284271,3 17,3.67157288 17,4.5 L17,5 L18,5 C19.5976809,5 20.9036609,6.24891996 20.9949073,7.82372721 L21,8 L21,18 C21,19.5976809 19.75108,20.9036609 18.1762728,20.9949073 L6,21 C4.40231912,21 3.09633912,19.75108 3.00509269,18.1762728 L3,18 L3,8 C3,6.40231912 4.24891996,5.09633912 5.82372721,5.00509269 L7,5.005 L7,4.5 C7,3.67157288 7.67157288,3 8.5,3 C9.32842712,3 10,3.67157288 10,4.5 L10,5.003 L14,5.002 L14,4.5 C14,3.67157288 14.6715729,3 15.5,3 Z M18,7 L6,7 C5.48716416,7 5.06449284,7.38604019 5.00672773,7.88337887 L5,8 L5,18 C5,18.5128358 5.38604019,18.9355072 5.88337887,18.9932723 L6,19 L18,19 C18.5128358,19 18.9355072,18.6139598 18.9932723,18.1166211 L19,18 L19,8 C19,7.44771525 18.5522847,7 18,7 Z M16.0710678,9.29289322 C16.4615921,9.68341751 16.4615921,10.3165825 16.0710678,10.7071068 L10.8284271,15.9497475 C10.4379028,16.3402718 9.80473785,16.3402718 9.41421356,15.9497475 L7.29289322,13.8284271 C6.90236893,13.4379028 6.90236893,12.8047379 7.29289322,12.4142136 C7.68341751,12.0236893 8.31658249,12.0236893 8.70710678,12.4142136 L10.1213203,13.8270129 L14.6568542,9.29289322 C15.0473785,8.90236893 15.6805435,8.90236893 16.0710678,9.29289322 Z" id="Combined-Shape" fill="#FFFFFF"></path>
      </g>
  </svg>
  </ng-template>

<!-- <div id="due_date_option" class="option-icon" *ngIf="onMainView" [class.selected]="value || isVisible" (click)="onOpen.emit(id); activate = !activate">
  <mat-icon>event</mat-icon>
  <p>{{ 'TASK_DUE_DATE' | translate }}</p>
</div> -->
<div class="icons-block" *ngIf="!activate">
  <div class="date-icon due-date-icon" (click)="selectActivateData()">
    <ng-container [ngTemplateOutlet]="dueDateIcon"></ng-container>
  </div>
  <span>{{ 'TASK_DUE_DATE' | translate }}</span>
</div>


<div id="due_date_option_list" class="option-list" *ngIf="active || activate">
  <mat-list role="list">
    <mat-list-item role="listitem" *ngFor="let item of filteredItems; let i = index;" [class.selected]="i == selectedIndex" (click)="selectedDate(item);">
      <mat-icon *ngIf="item.date">today</mat-icon>
      <mat-icon *ngIf="!item.date">event_busy</mat-icon>
      <div class="date-items" style="display: flex;width:100%;justify-content:space-between;">
        <span class="dateDesc">{{ item.name }}</span>
        <span class="align-right" *ngIf="item.date"> {{ item.date | vpLocaleDate: 'MMM d'}} </span>
        <span class="align-right" *ngIf="!item.date">{{ 'NONE' | translate }}</span>
      </div>
    </mat-list-item>
    <mat-list-item role="listitem" class="date-list-last-item">
      <button class="date-picker-button mat-button" (click)='openDatePickerLocal(dateType)'>
        <mat-icon>today</mat-icon>
        <span>{{ 'PICK_DUE_DATE' | translate }}</span>
      </button>
    </mat-list-item>
  </mat-list>
</div>
