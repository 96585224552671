
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { User } from "./user";

export class Project {
  id: number;
  name: string;
  identifier: string;
  description: string;
  status: number;
  created_on: Date;
  updated_on: Date;
  members: Array<User>; // come from DB
  can_invite_users: string;

  constructor(json) {
    this.id = json.id;
    this.name = json.name;
    this.identifier = json.identifier;
    this.description = json.description;
    this.status = json.status;
    this.created_on = new Date(json.created_on);
    this.updated_on = new Date(json.updated_on);
    if (json.members) {
      this.members = json.members.map(jsonUser => new User(jsonUser));
    }
    this.can_invite_users = json.can_invite_users;
  }
}

interface Field {
  id: number;
  name: String;
  value: string;
}
