
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Output, EventEmitter, ChangeDetectionStrategy, Inject, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { CommonUtil } from "../../../../common/utils/common.utils";
import { Router } from "@angular/router";
import { SuccessService } from "../../../../common/providers/success-service";
import { ErrorService } from "../../../../common/providers/error-service";
import { TaskRepository } from "../../../repository/task.repository";
import { ErrorType, SuccessType } from "../../../shared/task-enum";
import { Store } from "@ngrx/store";
import { getOnlineStatus } from "../../../../reducers";
import { Broadcaster } from "../../../../common/providers/broadcaster.service";
import { TasksRootState } from "../../../store/reducers";
import { takeWhile } from "rxjs/operators";
import { VncLibraryService } from "vnc-library";

@Component({
  selector: "vp-forgot-password-form",
  templateUrl: "./forgot-password-form.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPasswordFormComponent implements OnDestroy {
  backgroundImage = (CommonUtil.isOnMobileDevice()) ? "url(" + CommonUtil.getFullUrl("/assets/img/profile-background.jpg") + " )" : "none";
  forgotPasswordForm: UntypedFormGroup;
  email: string = "";
  isAlive: boolean = true;
  isLoading: boolean = false;
  isOnline: boolean = false;
  constructor(
    private changerDetectorRef: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private router: Router,
    private tasksRepo: TaskRepository,
    private errorService: ErrorService,
    private successService: SuccessService,
    private vncLibaryService: VncLibraryService,
    private store: Store<TasksRootState>,
    private broadcaster: Broadcaster
  ) {
    this.handleForgotPasswordErrorMessages();
    this.handleForgotPasswordSuccessMessages();
    this.forgotPasswordForm = fb.group({
      "email": [null, Validators.compose([Validators.required])],
    });

    this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
      this.isOnline = isOnline;
      this.changerDetectorRef.markForCheck();
    });

    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.returnToLoginPage();
      });

    this.broadcaster.on<any>("hideForgotPasswordFormPage").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.returnToLoginPage();
      });

    this.tasksRepo.removeLoginIframe();
  }

  handleForgotPasswordErrorMessages() {
    this.errorService.only(ErrorType.ForgotPasswordError).pipe(takeWhile(() => this.isAlive)).subscribe(error => {
      this.vncLibaryService.openSnackBar(error.messages, "","", "", 3000, "bottom", "left").subscribe(res => {
      });
      this.isLoading = false;
      this.changerDetectorRef.markForCheck();
    });
  }

  handleForgotPasswordSuccessMessages() {
    this.successService.only(SuccessType.ForgotPasswordSuccess).pipe(takeWhile(() => this.isAlive)).subscribe(success => {
      this.isLoading = false;
      this.changerDetectorRef.markForCheck();
      localStorage.setItem("registration_email", this.forgotPasswordForm.value.email);
      this.router.navigate(["/forgot-password/info"], { queryParams: {} });
    });
  }

  onFormSubmit(form) {
    if (this.isOnline) {
      this.isLoading = true;
      this.tasksRepo.forgotPassword(form.email);
    } else {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
    }
  }

  returnToLoginPage() {
    this.router.navigate(["/task"], { queryParams: {} });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.changerDetectorRef.markForCheck();
  }
}
