
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, ViewChild, EventEmitter, Output, ElementRef, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { MdlDialogComponent } from "@angular-mdl/core";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { HeaderService } from "../../services/header.service";
import { TasksRootState, getIsSearchMode } from "../../store";
import { Store } from "@ngrx/store";
import { ErrorService } from "../../../common/providers/error-service";
import { SearchRepository } from "../../repository/search.repository";
import { Router } from "@angular/router";
import { TasksConstants } from "../../shared/task-constacts";
import { ErrorType } from "../../shared/task-enum";
import { MessageTranslatorService } from "../../services";
import { AdvanceSearchRequest } from "../../models";
import { Subject } from "rxjs";
import { TaskRepository } from "../../repository/task.repository";
import { getOnlineStatus } from "../../../reducers";
import { CommonUtil } from "../../../common/utils/common.utils";
import { takeWhile, takeUntil } from "rxjs/operators";

@Component({
  selector: "vp-mobile-search-dialog",
  templateUrl: "./mobile-search.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VpMobileSearchComponent implements OnDestroy{
  @ViewChild("input", { static: false }) input: ElementRef;
  query: string = "";
  private isAlive$ = new Subject<boolean>();
  advanceSearch: AdvanceSearchRequest;
  isSearchMode: boolean = false;
  isAlive = true;
  isOnline: boolean = false;

  constructor(
    private broadcaster: Broadcaster,
    private router: Router,
    private headerService: HeaderService,
    private searchRepo: SearchRepository,
    private errorService: ErrorService,
    private store: Store<TasksRootState>,
    private taskRepo: TaskRepository,
    private messageTranslatorService: MessageTranslatorService,
    private changerDetectorRef: ChangeDetectorRef) {

    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
    });

    this.broadcaster.on<any>("hideMobileSearchDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
    });

    this.searchRepo.selectAdvanceSearchRequest().pipe(takeUntil(this.isAlive$)).subscribe((request) => {
      this.advanceSearch = request;
      this.changerDetectorRef.markForCheck();
    });

    this.store.select(getIsSearchMode).pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
      console.log("VpMobileSearchComponent, getIsSearchMode", value);
      this.isSearchMode = value;
      this.changerDetectorRef.markForCheck();
    });

    this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
      this.isOnline = isOnline;
      this.changerDetectorRef.markForCheck();
    });

    this.focus();
    this.changerDetectorRef.markForCheck();
  }

  clearText() {
    this.query = "";
  }

  focus() {
    setTimeout(() => { this.input.nativeElement.focus(); }, 100);
  }

  onSearch() {
    if (this.query) {
      console.log("VpMobileSearchComponent, onSearch", this.query);

      this.searchRepo.saveSearchKeyword(this.query);

      let query = this.searchRepo.getSearchQuery(this.query, this.advanceSearch);
      this.taskRepo.setSearchParams(query);

      // if already in search mode - then just show tasks
      if (this.isSearchMode) {
        this.taskRepo.getTasks();
        this.taskRepo.getTasksStats();
      // if not - navigate to search
      } else {
        this.router.navigate(["/task", TasksConstants.ROUTE_SEARCH], { queryParams: {} });
      }
    } else {
      this.errorService.emit({ id: ErrorType.GenericMessage, messages: this.messageTranslatorService.getMessage(TasksConstants.SEARCH_STRING_ERR_MSG) });
    }
  }

  close() {
    this.clearText();
    this.headerService.showSearchDialog$.next(false);
    if (this.isSearchMode) {
      this.router.navigate(["/task"]);
    }
  }

  showAdvancedTaskFilters() {
    if (this.isOnline) {
      this.headerService.showAdvancedTaskFilters$.next(true);
    } else {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
    }
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.changerDetectorRef.markForCheck();
  }
}
