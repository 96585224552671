
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ICompact } from "./task";

export interface IProject {
    id: number;
    name: string;
    identifier: string;
}

export class VNCtaskConfig {
    default_project: IProject;
    vnctask_tracker: ICompact;
    new_status: ICompact;
    in_progress_status: ICompact;
    completed_status: ICompact;

    constructor(json) {
        this.default_project = json.default_project;
        this.vnctask_tracker = json.vnctask_tracker;
        this.new_status = json.new_status;
        this.in_progress_status = json.in_progress_status;
        this.completed_status = json.completed_status;
    }
}