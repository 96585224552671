import { openDB, deleteDB } from "idb/with-async-ittr";
import { Observable, Subject } from "rxjs";
import { take } from "rxjs/operators";
import { DatabaseInterface } from "./database.interface";

const AVATAR_STORE = "AvatarStore";
const ALL_TASK_STORE = "AllTaskStore";
const NEW_TASK_STORE = "NewTaskStore";
const OPEN_TASK_STORE = "OpenTaskStore";
const COMPLETED_TASK_STORE = "CompletedTaskStore";
const ASSIGNED_TASK_STORE = "AssignedTaskStore";
const WEEK_STORE = "WeekTaskStore";
const TODAY_STORE = "TodayTaskStore";
const TOMORROW_TASK_STORE = "TomorrowTaskStore";
const CREATED_BY_STORE = "CreatedByTaskStore";
const WATCH_STORE = "WatchTaskStore";
const OVERDUE_STORE = "OverdueTaskStore";
const TAGS_STORE = "TagsTaskStore";
const LOCATION_STORE = "LocationTaskStore";
const LIST_STORE = "ListTaskStore";
const CONTACT_STORE = "ContactTaskStore";
const PENDING_OPERATION_TASK = "PendingOperationTaskUpdate";
const STATISTICS_TASK = "StatisticsTaskStore"
const CONTACT_INFO_TASK = "ContactInfoStore"

export class IndexedDBService implements DatabaseInterface {
    dbName = "OfflineDatabase3";

    dbVersion = 14;
    dataBaseReadyCallback?: any;
    pendingTaskPosition = 0;
    constructor() {
        console.log("[IndexedDBService][constructor]");
    }

    private idbContext() {
        return openDB(this.dbName, this.dbVersion, {
            upgrade(db, oldVersion, newVersion, transaction) {
                db.createObjectStore(STATISTICS_TASK, {
                    keyPath: "id",
                    autoIncrement: false
                });
                db.createObjectStore(ALL_TASK_STORE, {
                    keyPath: "id",
                    autoIncrement: false
                });
                db.createObjectStore(NEW_TASK_STORE, {
                    keyPath: "id",
                    autoIncrement: false
                });
                db.createObjectStore(OPEN_TASK_STORE, {
                    keyPath: "id",
                    autoIncrement: false
                });
                db.createObjectStore(COMPLETED_TASK_STORE, {
                    keyPath: "id",
                    autoIncrement: false
                });
                db.createObjectStore(ASSIGNED_TASK_STORE, {
                    keyPath: "id",
                    autoIncrement: false
                });
                db.createObjectStore(WEEK_STORE, {
                    keyPath: "id",
                    autoIncrement: false
                });
                db.createObjectStore(TODAY_STORE, {
                    keyPath: "id",
                    autoIncrement: false
                });
                db.createObjectStore(TOMORROW_TASK_STORE, {
                    keyPath: "id",
                    autoIncrement: false
                });
                db.createObjectStore(CREATED_BY_STORE, {
                    keyPath: "id",
                    autoIncrement: false
                });
                db.createObjectStore(WATCH_STORE, {
                    keyPath: "id",
                    autoIncrement: false
                });
                db.createObjectStore(OVERDUE_STORE, {
                    keyPath: "id",
                    autoIncrement: false
                });
                db.createObjectStore(TAGS_STORE, {
                    keyPath: "id",
                    autoIncrement: false
                });
                db.createObjectStore(LOCATION_STORE, {
                    keyPath: "id",
                    autoIncrement: false
                });
                db.createObjectStore(LIST_STORE, {
                    keyPath: "id",
                    autoIncrement: false
                });
                db.createObjectStore(CONTACT_STORE, {
                    keyPath: "id",
                    autoIncrement: false
                });
                db.createObjectStore(PENDING_OPERATION_TASK, {
                    keyPath: "id",
                    autoIncrement: false
                });
                db.createObjectStore(AVATAR_STORE, {
                    keyPath: "id",
                    autoIncrement: false
                });
                db.createObjectStore(CONTACT_INFO_TASK, {
                    keyPath: "id",
                    autoIncrement: false
                });
                console.log("[IndexedDBService][idbContext][upgrade] done");
            },
            blocked() {
                console.log("[IndexedDBService][idbContext][blocked]");
            },
            blocking() {
                console.log("[IndexedDBService][idbContext][blocking]");
            }
        });
    }

    getContactInfo(email): Observable<any> {
        console.log("[IndexedDBService][getContactInfo]");
        const response = new Subject<any[]>();
        this.idbContext().then(db => {
            const tx = db.transaction(CONTACT_INFO_TASK, "readonly");
            tx.store.get(IDBKeyRange.only(email)).then(event => {
                console.log("[IndexedDBService][getContactInfo] success", event);
                response.next(event?.data);
            }).catch(error => {
                console.error("[IndexedDBService][getContactInfo]", error);
            })
        });
        return response.asObservable().pipe(take(1));
    }

    addContactInfo(info): Observable<any> {
        const response = new Subject<any>();
        this.idbContext().then(db => {
            const tx = db.transaction(CONTACT_INFO_TASK, "readwrite");
            tx.store.put({
                id: info.jid ? info.jid : info.author_jid,
                data: info
            });
            tx.done.then(() => {
                response.next(true);
            }).catch(error => {
                console.error("[IndexedDBService][addContactInfo]", error);
                response.error(error);
            });
        });
        return response.asObservable().pipe(take(1));
    }

    addTaskStats(task): Observable<any> {
        console.log("[IndexedDBService][addTaskStats]", task);

        const response = new Subject<any>();

        this.idbContext().then(db => {
            const tx = db.transaction(STATISTICS_TASK, "readwrite");
            task.forEach(t => {
                tx.store.put({
                    id : t.type,
                    data :t
                });
            });

            tx.done.then(() => {
                console.log("[IndexedDBService][addTaskStats] success");
                response.next(true);
            }).catch(error => {
                console.error("[IndexedDBService][addTaskStats]", error);
                response.error(error);
            });
        });

        return response.asObservable().pipe(take(1));
    }

    getAllStats(): Observable<any> {
        const response = new Subject<any[]>();

        this.idbContext().then(db => {
            db.getAll(STATISTICS_TASK).then(appt => {
                console.log("[IndexedDBService][getAllAppointments] getAllTasks", appt);
                const stats = appt.map(item => item.data)
                response.next(stats);
            });
        });

        return response.asObservable().pipe(take(1));
    }

    addAvatar(avatar): Observable<any> {
        const response = new Subject<any>();
        this.idbContext().then(db => {
            const tx = db.transaction(AVATAR_STORE, "readwrite");
            tx.store.put(avatar);
            tx.done.then(() => {
                response.next(true);
            }).catch(error => {
                console.error("[IndexedDBService][storeAvatar]", error);
                response.error(error);
            });
        });
        return response.asObservable().pipe(take(1));
    }

    getAvatarById(id): Observable<any> {
        const response = new Subject<any[]>();
        this.idbContext().then(db => {
            const tx = db.transaction(AVATAR_STORE, "readonly");
            tx.store.get(IDBKeyRange.only(parseInt(id))).then(event => {
                console.log("[IndexedDBService][getAvatarById] success", event);
                response.next(event);
            }).catch(error => {
                console.error("[IndexedDBService][getAvatarById]", error);

            })
        });
        return response.asObservable().pipe(take(1));
    }

    getAllAvatar(): Observable<any> {
        const response = new Subject<any[]>();

        this.idbContext().then(db => {
            db.getAll(AVATAR_STORE).then(appt => {
                console.log("[IndexedDBService] getAllAvatar", appt);
                response.next(appt);
            });
        });

        return response.asObservable().pipe(take(1));
    }

    getTaskDetailsById(id): Observable<any> {
        const response = new Subject<any[]>();
        this.idbContext().then(db => {
            const tx = db.transaction(ALL_TASK_STORE, "readonly");
            tx.store.get(IDBKeyRange.only(parseInt(id))).then(event => {
                console.log("[IndexedDBService][getTaskDetailsById] success", event);
                response.next(event);
            }).catch(error => {
                console.error("[IndexedDBService][getTaskDetailsById]", error);

            })
        });

        return response.asObservable().pipe(take(1));
    }

    addBulkTasks(task,store?): Observable<any> {
        console.log("[IndexedDBService][addBulkTasks]", task);

        const response = new Subject<any>();

        this.idbContext().then(db => {
            const tx = db.transaction(store, "readwrite");
            task.forEach(t => {
                tx.store.put({
                    ...t
                });
            });

            tx.done.then(() => {
                console.log("[IndexedDBService][addBulkTasks] success");
                response.next(true);
            }).catch(error => {
                console.error("[IndexedDBService][addBulkTasks]", error);
                response.error(error);
            });
        });

        return response.asObservable().pipe(take(1));
    }

    deleteBulkTasks(keys, store?): Observable<any> {
        const response = new Subject<any>();

        this.idbContext().then(db => {
            const tx = db.transaction(store, "readwrite");
            tx.store.delete(keys);

            tx.done.then(() => {
                console.log("[IndexedDBService][deleteBulkTasks] success");
                response.next(true);
            }).catch(error => {
                console.error("[IndexedDBService][deleteBulkTasks]", error);
                response.error(error);
            });
        });

        return response.asObservable().pipe(take(1));
    }

    getTaskById(id,store?): Observable<any> {
        const response = new Subject<any[]>();
        this.idbContext().then(db => {
            const tx = db.transaction(store, "readonly");
            tx.store.get(IDBKeyRange.only(parseInt(id))).then(event => {
                console.log("[IndexedDBService][getTaskById] success", event);
                response.next(event);
            }).catch(error => {
                console.error("[IndexedDBService][getTaskById]", error);
            })
        });

        return response.asObservable().pipe(take(1));
    }

    getAllTasks(): Observable<any> {
        const response = new Subject<any[]>();

        this.idbContext().then(db => {
            db.getAll(ALL_TASK_STORE).then(appt => {
                console.log("[IndexedDBService][getAllAppointments] getAllTasks", appt);
                response.next(appt);
            });
        });

        return response.asObservable().pipe(take(1));
    }

    addAllTasks(task): Observable<any> {
        console.log("[IndexedDBService][addAllTasks]", task);

        const response = new Subject<any>();

        this.idbContext().then(db => {
            const tx = db.transaction(ALL_TASK_STORE, "readwrite");
            task.forEach(t => {
                tx.store.put({
                    ...t
                });
            });

            tx.done.then(() => {
                console.log("[IndexedDBService][addAllTasks] success");
                response.next(true);
            }).catch(error => {
                console.error("[IndexedDBService][addAllTasks]", error);
                response.error(error);
            });
        });

        return response.asObservable().pipe(take(1));
    }

    getNewTasks(): Observable<any> {
        console.log("[IndexedDBService][getNewTasks]");

        const response = new Subject<any[]>();
        this.idbContext().then(db => {
            db.getAll(NEW_TASK_STORE).then(appt => {
                console.log("[IndexedDBService][getAllAppointments] getOpenTasks", appt);
                response.next(appt);
            });
        });

        return response.asObservable().pipe(take(1));
    }

    addNewTasks(task): Observable<any> {
        console.log("[IndexedDBService][addNewTasks]", task);

        const response = new Subject<any>();

        this.idbContext().then(db => {
            const tx = db.transaction(NEW_TASK_STORE, "readwrite");
            task.forEach(t => {
                tx.store.put({
                    ...t
                });
            });

            tx.done.then(() => {
                console.log("[IndexedDBService][addNewTasks] success");
                response.next(true);
            }).catch(error => {
                console.error("[IndexedDBService][addNewTasks]", error);
                response.error(error);
            });
        });

        return response.asObservable().pipe(take(1));
    }

    getOpenTasks(): Observable<any> {
        console.log("[IndexedDBService][getTasks]");

        const response = new Subject<any[]>();
        this.idbContext().then(db => {
            db.getAll(OPEN_TASK_STORE).then(appt => {
                console.log("[IndexedDBService][getAllAppointments] getOpenTasks", appt);
                response.next(appt);
            });
        });

        return response.asObservable().pipe(take(1));
    }

    addOpenTasks(task): Observable<any> {
        console.log("[IndexedDBService][addOpenTasks]", task);

        const response = new Subject<any>();

        this.idbContext().then(db => {
            const tx = db.transaction(OPEN_TASK_STORE, "readwrite");
            task.forEach(t => {
                tx.store.put({
                    ...t
                });
            });

            tx.done.then(() => {
                console.log("[IndexedDBService][addOpenTasks] success");
                response.next(true);
            }).catch(error => {
                console.error("[IndexedDBService][addOpenTasks]", error);
                response.error(error);
            });
        });

        return response.asObservable().pipe(take(1));
    }

    getCompletedTasks(): Observable<any> {
        console.log("[IndexedDBService][getCompletedTasks]");
        const response = new Subject<any[]>();

        this.idbContext().then(db => {
            db.getAll(COMPLETED_TASK_STORE).then(appt => {
                console.log("[IndexedDBService][getAllAppointments] getCompletedTasks", appt);
                response.next(appt);
            });
        });

        return response.asObservable().pipe(take(1));
    }

    addCompletedTasks(task): Observable<any> {
        console.log("[IndexedDBService][addCompletedTasks]", task);

        const response = new Subject<any>();

        this.idbContext().then(db => {
            const tx = db.transaction(COMPLETED_TASK_STORE, "readwrite");
            task.forEach(t => {
                tx.store.put({
                    ...t
                });
            });

            tx.done.then(() => {
                console.log("[IndexedDBService][addCompletedTasks] success");
                response.next(true);
            }).catch(error => {
                console.error("[IndexedDBService][addCompletedTasks]", error);
                response.error(error);
            });
        });

        return response.asObservable().pipe(take(1));
    }

    addAssignedTasks(task): Observable<any> {
        console.log("[IndexedDBService][addAssignedTasks]", task);

        const response = new Subject<any>();

        this.idbContext().then(db => {
            const tx = db.transaction(ASSIGNED_TASK_STORE, "readwrite");
            task.forEach(t => {
                tx.store.put({
                    ...t
                });
            });

            tx.done.then(() => {
                console.log("[IndexedDBService][addAssignedTasks] success");
                response.next(true);
            }).catch(error => {
                console.error("[IndexedDBService][addAssignedTasks]", error);
                response.error(error);
            });
        });

        return response.asObservable().pipe(take(1));
    }

    getAssignedTasks(): Observable<any> {
        console.log("[IndexedDBService][getAssignedTasks]");
        const response = new Subject<any[]>();

        this.idbContext().then(db => {
            db.getAll(ASSIGNED_TASK_STORE).then(appt => {
                console.log("[IndexedDBService] getAssignedTasks", appt);
                response.next(appt);
            });
        });

        return response.asObservable().pipe(take(1));
    }

    addCreatedTasks(task): Observable<any> {
        console.log("[IndexedDBService][addCreatedTasks]", task);

        const response = new Subject<any>();

        this.idbContext().then(db => {
            const tx = db.transaction(CREATED_BY_STORE, "readwrite");
            task.forEach(t => {
                tx.store.put({
                    ...t
                });
            });

            tx.done.then(() => {
                console.log("[IndexedDBService][addCreatedTasks] success");
                response.next(true);
            }).catch(error => {
                console.error("[IndexedDBService][addCreatedTasks]", error);
                response.error(error);
            });
        });

        return response.asObservable().pipe(take(1));
    }

    getCreatedTasks(): Observable<any> {
        console.log("[IndexedDBService][getCreatedTasks]");
        const response = new Subject<any[]>();

        this.idbContext().then(db => {
            db.getAll(CREATED_BY_STORE).then(appt => {
                console.log("[IndexedDBService] getCreatedTasks", appt);
                response.next(appt);
            });
        });

        return response.asObservable().pipe(take(1));
    }

    addTodayTasks(task): Observable<any> {
        console.log("[IndexedDBService][addTodayTasks]", task);

        const response = new Subject<any>();

        this.idbContext().then(db => {
            const tx = db.transaction(TODAY_STORE, "readwrite");
            task.forEach(t => {
                tx.store.put({
                    ...t
                });
            });

            tx.done.then(() => {
                console.log("[IndexedDBService][addTodayTasks] success");
                response.next(true);
            }).catch(error => {
                console.error("[IndexedDBService][addTodayTasks]", error);
                response.error(error);
            });
        });

        return response.asObservable().pipe(take(1));
    }

    getTodayTasks(): Observable<any> {
        console.log("[IndexedDBService][getTodayTasks]");
        const response = new Subject<any[]>();

        this.idbContext().then(db => {
            db.getAll(TODAY_STORE).then(appt => {
                console.log("[IndexedDBService] getTodayTasks", appt);
                response.next(appt);
            });
        });

        return response.asObservable().pipe(take(1));
    }

    addWeekTasks(task): Observable<any> {
        console.log("[IndexedDBService][addWeekTasks]", task);

        const response = new Subject<any>();

        this.idbContext().then(db => {
            const tx = db.transaction(WEEK_STORE, "readwrite");
            task.forEach(t => {
                tx.store.put({
                    ...t
                });
            });

            tx.done.then(() => {
                console.log("[IndexedDBService][addWeekTasks] success");
                response.next(true);
            }).catch(error => {
                console.error("[IndexedDBService][addWeekTasks]", error);
                response.error(error);
            });
        });

        return response.asObservable().pipe(take(1));
    }

    getWeekTasks(): Observable<any> {
        console.log("[IndexedDBService][getWeekTasks]");
        const response = new Subject<any[]>();

        this.idbContext().then(db => {
            db.getAll(WEEK_STORE).then(appt => {
                console.log("[IndexedDBService] getWeekTasks", appt);
                response.next(appt);
            });
        });

        return response.asObservable().pipe(take(1));
    }

    addTomorrowTasks(task): Observable<any> {
        console.log("[IndexedDBService][addTomorrowTasks]", task);

        const response = new Subject<any>();

        this.idbContext().then(db => {
            const tx = db.transaction(TOMORROW_TASK_STORE, "readwrite");
            task.forEach(t => {
                tx.store.put({
                    ...t
                });
            });

            tx.done.then(() => {
                console.log("[IndexedDBService][addTomorrowTasks] success");
                response.next(true);
            }).catch(error => {
                console.error("[IndexedDBService][addTomorrowTasks]", error);
                response.error(error);
            });
        });

        return response.asObservable().pipe(take(1));
    }

    getTomorrowTasks(): Observable<any> {
        console.log("[IndexedDBService][getTomorrowTasks]");
        const response = new Subject<any[]>();

        this.idbContext().then(db => {
            db.getAll(TOMORROW_TASK_STORE).then(appt => {
                console.log("[IndexedDBService] getTomorrowTasks", appt);
                response.next(appt);
            });
        });

        return response.asObservable().pipe(take(1));
    }

    addOverdueTasks(task): Observable<any> {
        console.log("[IndexedDBService][addOverdueTasks]", task);

        const response = new Subject<any>();

        this.idbContext().then(db => {
            const tx = db.transaction(OVERDUE_STORE, "readwrite");
            task.forEach(t => {
                tx.store.put({
                    ...t
                });
            });

            tx.done.then(() => {
                console.log("[IndexedDBService][addOverdueTasks] success");
                response.next(true);
            }).catch(error => {
                console.error("[IndexedDBService][addOverdueTasks]", error);
                response.error(error);
            });
        });

        return response.asObservable().pipe(take(1));
    }

    getOverdueTasks(): Observable<any> {
        console.log("[IndexedDBService][getOverdueTasks]");
        const response = new Subject<any[]>();

        this.idbContext().then(db => {
            db.getAll(OVERDUE_STORE).then(appt => {
                console.log("[IndexedDBService] getOverdueTasks", appt);
                response.next(appt);
            });
        });

        return response.asObservable().pipe(take(1));
    }

    addWatchTasks(task): Observable<any> {
        console.log("[IndexedDBService] addWatchTasks", task);

        const response = new Subject<any>();

        this.idbContext().then(db => {
            const tx = db.transaction(WATCH_STORE, "readwrite");
            task.forEach(t => {
                tx.store.put({
                    ...t
                });
            });

            tx.done.then(() => {
                console.log("[IndexedDBService][addWatchTasks] success");
                response.next(true);
            }).catch(error => {
                console.error("[IndexedDBService][addWatchTasks]", error);
                response.error(error);
            });
        });

        return response.asObservable().pipe(take(1));
    }

    getWatchTasks(): Observable<any> {
        console.log("[IndexedDBService][getWatchTasks]");
        const response = new Subject<any[]>();

        this.idbContext().then(db => {
            db.getAll(WATCH_STORE).then(appt => {
                console.log("[IndexedDBService] getWatchTasks", appt);
                response.next(appt);
            });
        });

        return response.asObservable().pipe(take(1));
    }

    addTagsTasks(tag, task): Observable<any> {
        console.log("[IndexedDBService] addTagsTasks", task);

        const response = new Subject<any>();

        this.idbContext().then(db => {
            const tx = db.transaction(TAGS_STORE, "readwrite");
            task.forEach(t => {
                tx.store.put({ id: tag, data: task });
            });

            tx.done.then(() => {
                console.log("[IndexedDBService][addTagsTasks] success");
                response.next(true);
            }).catch(error => {
                console.error("[IndexedDBService][addTagsTasks]", error);
                response.error(error);
            });
        });

        return response.asObservable().pipe(take(1));
    }

    getTagsTasks(tag): Observable<any> {
        console.log("[IndexedDBService][getTagsTasks]");
        const response = new Subject<any[]>();
        this.idbContext().then(db => {
            const tx = db.transaction(TAGS_STORE, "readonly");
            tx.store.get(IDBKeyRange.only(tag)).then(event => {
                console.log("[IndexedDBService][getTaskDetailsById] success", event);
                response.next(event?.data);
            }).catch(error => {
                console.error("[IndexedDBService][getTaskDetailsById]", error);

            })
        });
        return response.asObservable().pipe(take(1));
    }

    addListTasks(list, task): Observable<any> {
        console.log("[IndexedDBService] addListTasks", task);

        const response = new Subject<any>();

        this.idbContext().then(db => {
            const tx = db.transaction(LIST_STORE, "readwrite");
            task.forEach(t => {
                tx.store.put({ id: list, data: task });
            });

            tx.done.then(() => {
                console.log("[IndexedDBService][addListTasks] success");
                response.next(true);
            }).catch(error => {
                console.error("[IndexedDBService][addListTasks]", error);
                response.error(error);
            });
        });

        return response.asObservable().pipe(take(1));
    }

    getListTasks(list): Observable<any> {
        console.log("[IndexedDBService][getListTasks]");
        const response = new Subject<any[]>();
        this.idbContext().then(db => {
            const tx = db.transaction(LIST_STORE, "readonly");
            tx.store.get(IDBKeyRange.only(list)).then(event => {
                console.log("[IndexedDBService][getListTasks] success", event);
                response.next(event?.data);
            }).catch(error => {
                console.error("[IndexedDBService][getListTasks]", error);

            })
        });
        return response.asObservable().pipe(take(1));
    }

    addLocationTasks(location, task): Observable<any> {
        console.log("[IndexedDBService] addLocationTasks", task);

        const response = new Subject<any>();

        this.idbContext().then(db => {
            const tx = db.transaction(LOCATION_STORE, "readwrite");
            task.forEach(t => {
                tx.store.put({ id: location, data: task });
            });

            tx.done.then(() => {
                console.log("[IndexedDBService][addLocationTasks] success");
                response.next(true);
            }).catch(error => {
                console.error("[IndexedDBService][addLocationTasks]", error);
                response.error(error);
            });
        });

        return response.asObservable().pipe(take(1));
    }

    getLocationTasks(location): Observable<any> {
        console.log("[IndexedDBService][getLocationTasks]");
        const response = new Subject<any[]>();
        this.idbContext().then(db => {
            const tx = db.transaction(LOCATION_STORE, "readonly");
            tx.store.get(IDBKeyRange.only(location)).then(event => {
                console.log("[IndexedDBService][getLocationTasks] success", event);
                response.next(event?.data);
            }).catch(error => {
                console.error("[IndexedDBService][getLocationTasks]", error);

            })
        });
        return response.asObservable().pipe(take(1));
    }

    getContacts(): Observable<any> {
        const response = new Subject<any[]>();

        this.idbContext().then(db => {
            db.getAll(CONTACT_STORE).then(appt => {
                console.log("[IndexedDBService] getContact", appt);
                response.next(appt);
            });
        });

        return response.asObservable().pipe(take(1));
    }

    addContacts(contact): Observable<any> {
        console.log("[IndexedDBService][addContacts]", contact);

        const response = new Subject<any>();

        this.idbContext().then(db => {
            const tx = db.transaction(CONTACT_STORE, "readwrite");
            contact.forEach(t => {
                tx.store.put({
                    ...t
                });
            });

            tx.done.then(() => {
                console.log("[IndexedDBService][addContact] success");
                response.next(true);
            }).catch(error => {
                console.error("[IndexedDBService][addContact]", error);
                response.error(error);
            });
        });

        return response.asObservable().pipe(take(1));
    }

    getPendingOperationTask(): Observable<any> {
        const response = new Subject<any[]>();

        this.idbContext().then(db => {
            db.getAll(PENDING_OPERATION_TASK).then(appt => {
                console.log("[IndexedDBService] getPendingOperationTask", appt);
                response.next(appt);
            });
        });

        return response.asObservable().pipe(take(1));
    }

    addPendingOperationTask(task): Observable<any> {
        console.log("[IndexedDBService][addPendingOperationTask]", task);

        const response = new Subject<any>();
        this.idbContext().then(db => {
            const tx = db.transaction(PENDING_OPERATION_TASK, "readwrite");
            task.forEach(t => {
                tx.store.put({
                    ...t,
                    position: this.pendingTaskPosition
                });
            });

            tx.done.then(() => {
                this.pendingTaskPosition = this.pendingTaskPosition + 1;
                console.log("[IndexedDBService][addPendingOperationTask] success", task[0].body);
                if (task[0].body?.operationType === "nonBulkUpdate") response.next(task[0].body.newTask);
                else response.next(task[0].body.tasks);
            }).catch(error => {
                console.error("[IndexedDBService][addPendingOperationTask]", error);
                response.error(error);
            });
        });

        return response.asObservable().pipe(take(1));
    }

    deletePendingOperations(keys): Observable<any> {
        console.log("[IndexedDBService][deletePendingOperations]", keys);
        const response = new Subject<any>();

        this.idbContext().then(db => {
            const tx = db.transaction(PENDING_OPERATION_TASK, "readwrite");
            tx.store.delete(keys);

            tx.done.then(() => {
                console.log("[IndexedDBService][deletePendingOperations] success");
                response.next(true);
            }).catch(error => {
                console.error("[IndexedDBService][deletePendingOperations]", error);
                response.error(error);
            });
        });

        return response.asObservable().pipe(take(1));
    }
}