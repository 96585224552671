
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id = "mobileSearchPanel" class = "vp-mobile-search task-search-header">
  <button id = "mbSearchCloseBtn" class="search-back-button" mat-button (click)="close()">
    <svg width="20px" height="20px" viewBox="0 0 24 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Icon/product/keyboard-arrow-left-new</title>
      <g id="Icon/product/keyboard-arrow-left-new" stroke="none" stroke-width="1" fill="000000" fill-rule="evenodd">
          <path d="M15.6568542,7.29289322 C16.0473785,7.68341751 16.0473785,8.31658249 15.6568542,8.70710678 L11.8284271,12.5355339 L15.6568542,16.363961 C16.0473785,16.7544853 16.0473785,17.3876503 15.6568542,17.7781746 C15.26633,18.1686989 14.633165,18.1686989 14.2426407,17.7781746 L9,12.5355339 L14.2426407,7.29289322 C14.633165,6.90236893 15.26633,6.90236893 15.6568542,7.29289322 Z" id="Path" fill="#000000"></path>
      </g>
  </svg>
  </button>
  <input id = "mbSearchInput" #input type="text" class= "input-search" placeholder="{{ 'SEARCH' | translate}}" [(ngModel)]="query" (keydown.enter)="onSearch();"
  />
  <button id = "mbSearchClearBtn" class="icon-button" mat-button (click)="clearText();">
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Icon/product/close-new</title>
      <g id="Icon/product/close-new" stroke="none" stroke-width="1" fill="none" fill-rule="000000">
          <path d="M18.9497475,5.05025253 C19.3402718,5.44077682 19.3402718,6.0739418 18.9497475,6.46446609 L13.4142136,12 L18.9497475,17.5355339 C19.3402718,17.9260582 19.3402718,18.5592232 18.9497475,18.9497475 C18.5592232,19.3402718 17.9260582,19.3402718 17.5355339,18.9497475 L12,13.4142136 L6.46446609,18.9497475 C6.0739418,19.3402718 5.44077682,19.3402718 5.05025253,18.9497475 C4.65972824,18.5592232 4.65972824,17.9260582 5.05025253,17.5355339 L10.5857864,12 L5.05025253,6.46446609 C4.65972824,6.0739418 4.65972824,5.44077682 5.05025253,5.05025253 C5.44077682,4.65972824 6.0739418,4.65972824 6.46446609,5.05025253 L12,10.5857864 L17.5355339,5.05025253 C17.9260582,4.65972824 18.5592232,4.65972824 18.9497475,5.05025253 Z" id="Path" fill="#8B96A0"></path>
      </g>
  </svg>
  </button>
  <button id = "mbSearchFilterBtn" class="icon-button" mat-button (click)="showAdvancedTaskFilters()">
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Icon/product/tune</title>
      <g id="Icon/product/tune" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Icon-24px" transform="translate(3.000000, 3.000000)" fill="#000000">
              <path d="M0,14 L0,16 L6,16 L6,14 L0,14 L0,14 Z M0,2 L0,4 L10,4 L10,2 L0,2 L0,2 Z M10,18 L10,16 L18,16 L18,14 L10,14 L10,12 L8,12 L8,18 L10,18 L10,18 Z M4,6 L4,8 L0,8 L0,10 L4,10 L4,12 L6,12 L6,6 L4,6 L4,6 Z M18,10 L18,8 L8,8 L8,10 L18,10 L18,10 Z M12,6 L14,6 L14,4 L18,4 L18,2 L14,2 L14,0 L12,0 L12,6 L12,6 Z" id="Shape"></path>
          </g>
      </g>
  </svg>
  </button>
</div>
