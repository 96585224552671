
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="taskChangePasswordDialog" class="vp-task-change-password-dialog task__dialog">
    <div class="task__dialog-header">
        <div class="mobile-back-button">
            <button mat-button (click)="close()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
        <div class="header_lbl disable-select">
            {{'CHANGE_PASSWORD' | translate}}
        </div>
        <div class="desktop-close-button">
            <button mat-button (click)="close()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
    </div>
    <div class="task__dialog-body task__dialog-body--pad" *ngIf="!isMobileDevice || ( isMobileDevice && !isPasswordChange)">
        <div class="change-password-form">
            <form [formGroup]="changePasswordForm">
                <mat-form-field>
                    <input type="password" autocomplete="oldPassword" formControlName="oldPassword" matInput placeholder="{{ 'CURRENT_PASSWORD' | translate }}"
                        required [type]="oldPasswordHide ? 'password' : 'text'">
                    <mat-icon matSuffix (click)="oldPasswordHide = !oldPasswordHide">{{oldPasswordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
                <mat-form-field hintLabel="{{ 'PASSWORD_HINT' | translate: {'minLength': minPasswordLenth} }}">
                    <input type="password" autocomplete="password" formControlName="password" matInput placeholder="{{ 'NEW_PASSWORD' | translate }}"
                        required [type]="newPasswordHide ? 'password' : 'text'">
                    <mat-icon matSuffix (click)="newPasswordHide = !newPasswordHide">{{newPasswordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
                <mat-form-field>
                    <input type="password" autocomplete="confirmPassword" formControlName="confirmPassword" matInput
                        placeholder="{{ 'RETYPE_NEW_PASSWORD' | translate }}" required [type]="confirmPasswordHide ? 'password' : 'text'">
                    <mat-icon matSuffix (click)="confirmPasswordHide = !confirmPasswordHide">{{confirmPasswordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-error *ngIf="changePasswordForm.get('confirmPassword').errors && changePasswordForm.get('confirmPassword').errors.ConfirmPassword">
                        {{ 'PASSWORD_MISMATCH_ERROR' | translate }}
                    </mat-error>
                </mat-form-field>
            </form>
        </div>
    </div>
    <div class="task__dialog-footer">
        <div class="desktop-footer">
            <button mat-button (click)="close()">
                {{ 'CANCEL' | translate }}
            </button>
            <button mat-button [disabled]="!changePasswordForm.valid" (click)="onFormSubmit(changePasswordForm.value)">
                {{ 'SAVE' | translate }}
            </button>
        </div>
        <div class="mobile-footer" *ngIf="changePasswordForm.valid">
            <div class="title">
                {{ 'CHANGE_PASSWORD' | translate }}
            </div>
            <div class="save_footer_btn">
                <button mat-button (click)="onFormSubmit(changePasswordForm.value)">
                    <mat-icon>check</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="isPasswordChange" class="message-body">
        <div class="success-div">{{ 'CHANGE_PASSWORD_SUCCESS_MESSAGE' | translate }}</div>
        <div>{{ 'REDIRECTING' | translate }}</div>
    </div>
</div>
<div class="vnctask-loading" *ngIf="isLoading">
  <div class="indicator">
    <vnc-spinner [size]="'medium'" *ngIf="true"></vnc-spinner>
  </div>
</div>
