
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Output, EventEmitter, ChangeDetectionStrategy, Inject, ChangeDetectorRef, OnDestroy, HostListener } from "@angular/core";
import { CommonUtil } from "../../../../common/utils/common.utils";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { ConfirmPasswordValidator } from "../../registration/registration-form/confirm-password.validator";
import { Router } from "@angular/router";
import { SuccessService } from "../../../../common/providers/success-service";
import { ErrorService } from "../../../../common/providers/error-service";
import { TaskRepository } from "../../../repository/task.repository";
import { ErrorType, SuccessType } from "../../../shared/task-enum";
import { Broadcaster } from "../../../../common/providers/broadcaster.service";
import { Store } from "@ngrx/store";
import { TasksRootState, getSettings } from "../../../store";
import { getTasksIsLoading } from "../../../store";
import { getOnlineStatus } from "../../../../reducers";
import { takeWhile } from "rxjs/operators";
import { MatDialogRef } from "@angular/material/dialog";
import { VncLibraryService } from "vnc-library";

@Component({
  selector: "vp-change-password-form",
  templateUrl: "./change-password-form.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangePasswordFormComponent implements OnDestroy {
  changePasswordForm: UntypedFormGroup;
  oldPassword: string = "";
  password: string = "";
  confirmPassword: string = "";
  isAlive: boolean = true;
  isLoading: boolean = false;
  isOnline: boolean = false;
  confirmPasswordHide: boolean = true;
  oldPasswordHide: boolean = true;
  newPasswordHide: boolean = true;
  isMobileDevice = CommonUtil.isOnMobileDevice();
  isPasswordChange: boolean = false;
  minPasswordLenth: number = 8;

  constructor(
    private changerDetectorRef: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private router: Router,
    private tasksRepo: TaskRepository,
    private errorService: ErrorService,
    private successService: SuccessService,
    private dialogRef: MatDialogRef<ChangePasswordFormComponent>,
    private broadcaster: Broadcaster,
    private vncLibaryService: VncLibraryService,
    private store: Store<TasksRootState>
  ) {
    this.handleChangePasswordErrorMessages();
    this.handleChangePasswordSuccessMessages();
    this.changePasswordForm = fb.group({
      "oldPassword": [null, Validators.compose([Validators.required])],
      "password": [null, Validators.compose([Validators.required])],
      "confirmPassword": [null, Validators.compose([Validators.required])]
    }, {
      validator: ConfirmPasswordValidator.MatchPassword
    });

    this.store.select(getSettings).pipe(takeWhile(() => this.isAlive)).subscribe((settings) => {
      if (settings && settings.password_min_length) {
        this.minPasswordLenth = settings.password_min_length;
        this.changePasswordForm.get("password").setValidators([Validators.minLength(this.minPasswordLenth)]);
        this.changePasswordForm.get("confirmPassword").setValidators([Validators.minLength(this.minPasswordLenth)]);
        this.changePasswordForm.get("password").updateValueAndValidity();
        this.changePasswordForm.get("confirmPassword").updateValueAndValidity();
      }
      this.changerDetectorRef.markForCheck();
    });

    this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
      this.isOnline = isOnline;
      this.changerDetectorRef.markForCheck();
    });

    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
      });

    this.broadcaster.on<any>("hideTaskChangePasswordDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
      });
  }

  handleChangePasswordErrorMessages() {
    this.errorService.only(ErrorType.ChangePasswordError).pipe(takeWhile(() => this.isAlive)).subscribe(error => {
      this.vncLibaryService.openSnackBar(error.messages, "","", "", 3000, "bottom", "left").subscribe(res => {
      });
      this.isLoading = false;
      this.changerDetectorRef.markForCheck();
    });
  }

  handleChangePasswordSuccessMessages() {
    this.successService.only(SuccessType.ChangePasswordSuccess).pipe(takeWhile(() => this.isAlive)).subscribe(success => {
      this.vncLibaryService.openSnackBar(success.messages, "","", "", 3000, "bottom", "left").subscribe(res => {
      });
      this.isLoading = false;
      this.isPasswordChange = true;
      this.changerDetectorRef.markForCheck();
      setTimeout( () => {
        this.close(true);
      }, 2000);
    });
  }

  @HostListener("document:keydown.esc", ["$event"])
  hide() {
    this.dialogRef.close();
  }

  close(value?) {
    this.dialogRef.close();
  }

  onFormSubmit(form) {
    if (this.isOnline) {
      this.isLoading = true;
      this.tasksRepo.changePassword(form.oldPassword, form.password, form.confirmPassword);
    } else {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
    }
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.changerDetectorRef.markForCheck();
  }
}
