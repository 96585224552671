
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="task-detail-info">
  <div class="multi-select-task-header">
    <div class="header-info-div">
      <div class="align-item-center">
        <svg width="30px" height="30px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>Icon/product/assignment-turned-in</title>
          <g id="Icon/product/assignment-turned-in" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Icon-24px" transform="translate(3.000000, 1.000000)" fill="#ffffff">
                  <path d="M16,2 L11.82,2 C11.4,0.84 10.3,0 9,0 C7.7,0 6.6,0.84 6.18,2 L2,2 C0.9,2 0,2.9 0,4 L0,18 C0,19.1 0.9,20 2,20 L16,20 C17.1,20 18,19.1 18,18 L18,4 C18,2.9 17.1,2 16,2 L16,2 Z M9,2 C9.55,2 10,2.45 10,3 C10,3.55 9.55,4 9,4 C8.45,4 8,3.55 8,3 C8,2.45 8.45,2 9,2 L9,2 Z M7,16 L3,12 L4.41,10.59 L7,13.17 L13.59,6.58 L15,8 L7,16 L7,16 Z" id="Shape"></path>
              </g>
          </g>
        </svg>
      </div>
      <div id="task-detail-info-selected-count" class="align-item-center">
        <span class="sub-header-text">{{ selectedTasks.length }}  {{ 'SELECTED_TASKS' | translate }}</span>
      </div>
    </div>
  </div>
  <div class="multi-select-body">
    <div class="detail-info-table padding-15">
        <div *ngFor="let task of selectedTasks; let i = index" class="table-row">
          <ng-container *ngIf="i <= 7">
            <div class="multiple-select-tasks">{{ task.subject }}</div>
          </ng-container>
          <ng-container *ngIf="i == 8 && !viewAll">
            <div class="multiple-select-tasks">
                <span>...</span>
                <span class="label-view-all" (click)="viewAll = true;">{{ 'VIEW_ALL' | translate }}</span>
            </div>
          </ng-container>
          <ng-container *ngIf="i > 7 && viewAll">
            <div class="multiple-select-tasks">{{ task.subject }}</div>
          </ng-container>
        </div>
    </div>
  </div>
</div>
