
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { AbstractControl } from "@angular/forms";

export class ConfirmPasswordValidator {
    static MatchPassword(control: AbstractControl) {
       let password = control.get("password").value;

       let confirmPassword = control.get("confirmPassword").value;

        if (password !== confirmPassword) {
            control.get("confirmPassword").setErrors( {ConfirmPassword: true} );
        } else {
            control.get("confirmPassword").setErrors(null);
            return null;
        }
    }
}