
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, forwardRef, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { TaskOptionComponent } from "../task-option.component";
import { Priority } from "../../../../models";
import { TaskUtils } from "../../../../shared/task-utils";
import { Store } from "@ngrx/store";
import { TasksRootState, getPriorityList } from "../../../../store/index";
import { takeWhile } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "vp-vnctask-option-priority",
  providers: [{
    provide: TaskOptionComponent,
    // eslint-disable-next-line
    useExisting: forwardRef(() => TaskPriorityOptionComponent)
  }],
  templateUrl: "option-priority.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskPriorityOptionComponent extends TaskOptionComponent implements OnInit, OnDestroy {
  id: string = "add-priority";
  triggerKey = "!";
  isAlive = true;
  priorities: Priority [] = [];
  constructor(
    private store: Store<TasksRootState>,
    public changerDetectorRef: ChangeDetectorRef,
    public matDialog: MatDialog) {
    super(changerDetectorRef, matDialog);
  }

  ngOnInit() {
    this.store.select(getPriorityList).pipe(takeWhile(() => this.isAlive)).subscribe( priorities => {
      this.priorities = priorities;
      this.setItems(priorities);
    });
  }

  getPayload() {
    let value: Priority = this.getValue();
    let priorityParams;
    if (!value) {
      // default priority
      this.filteredItems.forEach((priority) => {
        if (priority.is_default) {
          priorityParams = { priority_id: priority.id, priority_name: priority.name };
        }
      });
    } else {
      priorityParams = { priority_id: value.id, priority_name: value.name };
    }
    return priorityParams;
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  filter(q: string) {
    let items =
      this.priorities.filter(priority => ( priority.displayName || "").toLowerCase().includes(q.trim().toLowerCase()));
    this.setItems(items);
  }

  getPriorityColor(item) {
    return TaskUtils.getPriorityColor(item);
  }
}
