<div class="task__dialog vp-task-watchers-dialog">
    <div class="task__dialog-header">
      <div class="mobile-back-button">
        <button mat-button (click)="hide()">
          <mat-icon class="mdi-20px" fontSet="mdi" fontIcon="mdi-close"></mat-icon>
        </button>
      </div>
      <div class="header_lbl disable-select">
        {{ 'ADD_WATCHERS' | translate }}
      </div>
      <div class="desktop-close-button">
        <button mat-button (click)="hide()">
          <mat-icon class="disable-select">close</mat-icon>
        </button>
      </div>
    </div>
    <div class="task__dialog-body" [class.save-mode]="isUpdated">
    <div class="watchers-body">
      <mdl-textfield class="filter-search-textfield" [(ngModel)]="searchText" (ngModelChange)="searchTextChange()" [label]="'SEARCH_WATCHERS' | translate"  floating-label></mdl-textfield>
      <div class="selected-list-wrapper" *ngIf="selectedMemberList.length > 0">

        <mat-list role="list" class="selectedMemberList">
          <mat-list-item role="listitem" *ngFor="let member of selectedMemberList">
            <div class="selected-watcher-list">
              <span class="member-avatar">
              <vp-avatar [avatarId]="member.id" [user]="{ name: member && member.name ? member.name : '', avatarURL: member.avatar }"> </vp-avatar>
                <span class="selected-member-close-icon" (click)="removeSelectedMember(member)">
                  <mat-icon>close</mat-icon>
                </span>
              </span>
              <div class="selected-member-name" [innerHTML]="member.name"></div>
            </div>
          </mat-list-item>
        </mat-list>
      </div>
      <div class="option-list" [class.save-mode]="isUpdated">

        <mat-list role="list">
          <mat-list-item role="listitem" *ngFor="let member of memberList" (click)="addMemberToList(member)">
            <vp-avatar [avatarId]="member.id" [user]="{ name: member && member.name ? member.name : '', avatarURL: member.avatar }"> </vp-avatar>
            <span class="username-place disable-select">{{ (member) ? member.name : ('NO_NAME' | translate) }}</span>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
    </div>
    <div class="task__dialog-footer">
      <div class="desktop-footer">
          <button mat-button (click)="hide()">
              {{ 'CANCEL' | translate }}
          </button>
          <button mat-button [disabled]="!isUpdated" (click)="addWatchers()">
              {{ 'UPDATE' | translate }}
          </button>
      </div>
      <div class="mobile-footer" *ngIf="isUpdated">
          <div class="title">
              {{ 'ADD_WATCHERS' | translate }}
          </div>
          <div class="save_footer_btn">
              <button mat-button (click)="addWatchers()">
                  <mat-icon>check</mat-icon>
              </button>
          </div>
      </div>
    </div>
  </div>
  <div class="vnctask-loading" *ngIf="isLoading">
    <div class="indicator">
      <vnc-spinner [size]="'medium'" *ngIf="true"></vnc-spinner>
    </div>
  </div>