
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { DatePipe } from "@angular/common";
import { TasksConstants } from "../shared/task-constacts";
import * as moment from "moment";

export class TaskUtils {

  static datePipe: DatePipe = new DatePipe("en");

  static DB_DATE_FORMAT: string = "yyyy-MM-dd";
  static DB_DATE_TIME_FORMAT: string = "yyyy-MM-dd HH:mm:ss";

  static getParamsForStatistics(): any {
      let params = {};
      params = { count: "1", "include[]": ["due_tomorrow_count", "overdue_count", "completed_count"] };
      return params;
  }

  static getParamsForTags( id: number, sortBy?: string ): any {
    let params = {};
    let order = TasksConstants.ASC;
    if ( sortBy === TasksConstants.PRIORITY_SORTING_KEY) {
      order = TasksConstants.DESC;
    }
    params = { "sort": sortBy + ":" + order, "f[]": ["author_assigned_or_watcher", "status_id", "tags"], "op[author_assigned_or_watcher]": "=", "v[author_assigned_or_watcher][]": "me", "op[status_id]": "*", "op[tags]": "=",  "v[tags][]": id};
    return params;
  }

  static getParamsForLists( id: number, sortBy?: string ): any {
    let params = {};
    let order = TasksConstants.ASC;
    if ( sortBy === TasksConstants.PRIORITY_SORTING_KEY) {
      order = TasksConstants.DESC;
    }
    params = { "sort": sortBy + ":" + order, "f[]": ["author_or_assigned", "status_id", "list"], "op[author_or_assigned]": "=", "v[author_or_assigned][]": "me", "op[status_id]": "*", "op[list]": "=",  "v[list][]": id};
    return params;
  }

  static getParamsForLocations( id: number, sortBy?: string ): any {
    let params = {};
    let order = TasksConstants.ASC;
    if ( sortBy === TasksConstants.PRIORITY_SORTING_KEY) {
      order = TasksConstants.DESC;
    }
    params = { "sort": sortBy + ":" + order, "f[]": ["author_or_assigned", "status_id", "location"], "op[author_or_assigned]": "=", "v[author_or_assigned][]": "me", "op[status_id]": "*", "op[location]": "=",  "v[location][]": id};
    return params;
  }

  static getParamsForFilters(title: string , sortBy?: string ): any {
    console.log("[TaskUtils][getParamsForFilters]", title, sortBy);

    let params = {};
    let order = TasksConstants.ASC;
    if ( sortBy === TasksConstants.PRIORITY_SORTING_KEY || sortBy === TasksConstants.CREATED_SORTING_KEY ) {
      order = TasksConstants.DESC;
    }
    switch (title) {
      case TasksConstants.ROUTE_ALL_TASK:
        params = { "sort": sortBy + ":" + order, "f[]": ["author_or_assigned", "status_id"], "op[author_or_assigned]": "=", "v[author_or_assigned][]": "me", "op[status_id]": "*" };
        break;
      case TasksConstants.ROUTE_OPEN:
        params = { "sort": sortBy + ":" + order, "f[]": ["author_or_assigned", "status_id"], "op[author_or_assigned]": "=", "v[author_or_assigned][]": "me", "op[status_id]": "o" };
        break;
      case TasksConstants.ROUTE_NEW:
        params = { "sort": sortBy + ":" + order, "f[]": ["author_or_assigned", "status_id"], "op[author_or_assigned]": "=", "op[status_id]": "=", "v[author_or_assigned][]": "me", "v[status_id][]": "1" };
        break;
      case TasksConstants.ROUTE_COMPLETED:
        params = { "sort": sortBy + ":" + order, "f[]": ["author_or_assigned", "status_id"], "op[author_or_assigned]": "=", "v[author_or_assigned][]": "me", "op[status_id]": "c" };
        break;
      case TasksConstants.ROUTE_ASSIGNEDTOME:
        params = { "sort": sortBy + ":" + order, "f[]": ["project_id", "status_id", "assigned_to_id", "author_id"], "op[project_id]": "=", "v[project_id][]": "mine", "op[assigned_to_id]": "=", "op[author_id]": "!", "v[assigned_to_id][]": "me", "v[author_id][]": "me", "op[status_id]": "*" };
        break;
      case TasksConstants.ROUTE_CREATEDBYME:
        params = { "sort": sortBy + ":" + order, "f[]": ["project_id", "status_id", "author_id"], "op[project_id]": "=", "v[project_id][]": "mine", "op[author_id]": "=", "v[author_id][]": "me", "op[status_id]": "*" };
        break;
      case TasksConstants.ROUTE_TODAY_DUE:
        params = { "sort": sortBy + ":" + order, "f[]": ["author_or_assigned", "status_id", "due_date"], "op[author_or_assigned]": "=", "v[author_or_assigned][]": "me", "op[status_id]": "*", "op[due_date]": "=", "v[due_date][]": this.dateToday() };
        break;
      case TasksConstants.ROUTE_TOMORROW_DUE:
        params = { "sort": sortBy + ":" + order, "f[]": ["author_or_assigned", "status_id", "due_date"], "op[author_or_assigned]": "=", "v[author_or_assigned][]": "me", "op[status_id]": "*", "op[due_date]": "=", "v[due_date][]": this.dateTomorrow() };
        break;
      case TasksConstants.ROUTE_THIS_WEEK_DUE:
        params = { "sort": sortBy + ":" + order, "f[]": ["author_or_assigned", "status_id", "due_date"], "op[author_or_assigned]": "=", "v[author_or_assigned][]": "me", "op[status_id]": "*", "op[due_date]": "w" };
        break;
      case TasksConstants.ROUTE_TASKS_I_WATCH:
        params = { "sort": sortBy + ":" + order, "f[]": ["watcher_id", "status_id" ], "op[watcher_id]": "=", "v[watcher_id][]": "me", "op[status_id]": "*" };
        break;
      case TasksConstants.ROUTE_MY_OVERDUE_TASKS:
        params = { "sort": sortBy + ":" + order, "f[]": ["author_or_assigned", "status_id", "due_date" ], "op[author_or_assigned]": "=", "v[author_or_assigned][]": "me", "op[status_id]": "o", "op[due_date]": "<=", "v[due_date][]": this.dateYesterday() };
        break;
      default:
        params = null;
        break;
    }

    console.log("[TaskUtils][getParamsForFilters] params", params);
    return params;
  }

  static dateToday(): any {
    let today = new Date();
    return this.datePipe.transform(today, TaskUtils.DB_DATE_FORMAT);
  }

  static dateTomorrow(): any {
    let today = new Date();
    return this.datePipe.transform(today.setDate(today.getDate() + 1), TaskUtils.DB_DATE_FORMAT);
  }

  static dateYesterday(): any {
    let today = new Date();
    return this.datePipe.transform(today.setDate(today.getDate() - 1), TaskUtils.DB_DATE_FORMAT);
  }

  static dateThisWeekEnds(): any {
    let today = new Date();
    let currentWeekDay = today.getDay();
    let weekStartsDay = new Date(today.setDate(today.getDate() - currentWeekDay));
    let weekEndsDay = new Date(new Date(weekStartsDay).setDate(weekStartsDay.getDate() + 6));
    return this.datePipe.transform(weekEndsDay, TaskUtils.DB_DATE_FORMAT);
  }

  static dateThisWeekStarts(): any {
    let today = new Date();
    let currentWeekDay = today.getDay();
    let weekStartsDay = new Date(today.setDate(today.getDate() - currentWeekDay));
    return this.datePipe.transform(weekStartsDay, TaskUtils.DB_DATE_FORMAT);
  }

  static dateToSearchableDBDate(date: Date): any {
    return this.datePipe.transform(date, TaskUtils.DB_DATE_FORMAT);
  }

  static dateToSearchableDBDateTime(date: Date): any {
    return this.datePipe.transform(date, TaskUtils.DB_DATE_TIME_FORMAT);
  }

  static dueDateOrStartDateNull(): Date {
    return new Date(2050, 0);
  }

  static getDateFilterFields(): any {
    return {
      "=": ["date"],
      ">=": ["date"],
      "<=": ["date"],
      "><": ["date", "date"],
      "<t+": ["number"],
      ">t+": ["number"],
      "><t+": ["number"],
      "t+": ["number"],
      ">t-": ["number"],
      "<t-": ["number"],
      "><t-": ["number"],
      "t-": ["number"]
    };
  }

  static getInitSearchFilter(): any {
    return {
      subject: {
        title: "Subject",
        type: "~",
        values: [],
        fields: {
          "~": ["text"],
          "!~": ["text"]
        },
        dataType: "string",
        added: true
      },
      comments: {
        title: "Comments",
        type: "~",
        values: [],
        fields: {
          "~": ["text"],
          "!~": ["text"]
        },
        dataType: "string",
        added: true
      },
      start_date: {
        title: "Start date",
        type: "=",
        values: [],
        fields: this.getDateFilterFields(),
        dataType: "date",
        added: false
      },
      due_date: {
        title: "Due date",
        type: "=",
        values: [],
        fields: this.getDateFilterFields(),
        dataType: "date",
        added: false
      },
      priority_id: {
        title: "Priority",
        type: "=",
        values: [],
        fields: {
          "=": [],
          "!": []
        },
        dataType: "number",
        added: false
      },
      status_id: {
        title: "Status",
        type: "o",
        values: [],
        dataType: "number",
        fields: {
          "=": [],
          "!": []
        },
        added: false
      },
      project_id: {
        title: "Project",
        type: "=",
        values: [],
        fields: {
          "=": [],
          "!": []
        },
        dataType: "string",
        added: false
      },
      assigned_to_id: {
        title: "AssignedTo",
        type: "=",
        values: [],
        fields: {
          "=": [],
          "!": []
        },
        dataType: "number",
        added: false
      },
      author_or_assigned: {
        title: "Auther_or_assigned",
        type: "=",
        values: [],
        fields: {
          "=": [],
          "!": []
        },
        dataType: "string",
        added: false
      },
      tags: {
        title: "Tags",
        type: "=",
        values: [],
        fields: {
          "=": [],
          "!": []
        },
        dataType: "number",
        added: false
      },
      description: {
        title: "Description",
        type: "~",
        values: [],
        fields: {
          "~": ["text"],
          "!~": ["text"]
        },
        dataType: "string",
        added: false
      },
      has_recurring_task: {
        title: "has_recurring_task",
        type: "=",
        values: [1],
        fields: {
          "=": [],
          "!": []
        },
        dataType: "number",
        added: false
      }
    };
  }

  static getDateFilterFormatList(): any {
    return [{
      title: "is",
      value: "=",
      key: "IS"
    }, {
      title: ">=",
      value: ">=",
      key: ">="
    }, {
      title: "<=",
      value: "<=",
      key: "<="
    }, {
      title: "between",
      value: "><",
      fields: ["date", "date"],
      key: "BETWEEN"
    }, {
      title: "in less than",
      value: "<t+",
      key: "IN_LESS_THAN"
    }, {
      title: "in more than",
      value: ">t+",
      key: "IN_MORE_THAN"
    }, {
      title: "in the next",
      value: "><t+",
      key: "IN_THE_NEXT"
    }, {
      title: "in",
      value: "t+",
      key: "IN"
    }, {
      title: "today",
      value: "t",
      key: "TODAY_DUE"
    }, {
      title: "yesterday",
      value: "ld",
      key: "YESTERDAY"
    }, {
      title: "this week",
      value: "w",
      key: "THIS_WEEK"
    }, {
      title: "last week",
      value: "lw",
      key: "LAST_WEEK"
    }, {
      title: "last 2 weeks",
      value: "l2w",
      key: "LAST_2_WEEKS"
    }, {
      title: "this month",
      value: "m",
      key: "THIS_MONTH"
    }, {
      title: "last month",
      value: "lm",
      key: "LAST_MONTH"
    }, {
      title: "this year",
      value: "y",
      key: "THIS_YEAR"
    }, {
      title: "less than days ago",
      value: ">t-",
      key: "LESS_THAN_DAYS_AGO"
    }, {
      title: "more than days ago",
      value: "<t-",
      key: "MORE_THAN_DAYS_AGO"
    }, {
      title: "in the past",
      value: "><t-",
      key: "IN_THE_PAST"
    }, {
      title: "days ago",
      value: "t-",
      key: "DAYS_AGO"
    }, {
      title: "none",
      value: "!*",
      key: "NO_DATE"
    }, {
      title: "any",
      value: "*",
      key: "ANY"
    }];
  }

  static getFilterKeys(): any {
    return [ "subject", "start_date", "due_date", "priority_id", "status_id", "project_id", "assigned_to_id", "tags", "description", "has_recurring_task"];
  }

  static getRouteFilterKey(title: string): string {
    let params = "";
    switch (title) {
      case TasksConstants.ROUTE_ALL_TASK:
        params = TasksConstants.ALL_TASK;
        break;
      case TasksConstants.ROUTE_OPEN:
        params = TasksConstants.OPEN;
        break;
      case TasksConstants.ROUTE_NEW:
        params = TasksConstants.NEW_TASK;
        break;
      case TasksConstants.ROUTE_COMPLETED:
        params = TasksConstants.COMPLETED;
        break;
      case TasksConstants.ROUTE_ASSIGNEDTOME:
        params = TasksConstants.ASSIGNEDTOME;
        break;
      case TasksConstants.ROUTE_CREATEDBYME:
        params = TasksConstants.CREATEDBYME;
        break;
      case TasksConstants.ROUTE_TODAY_DUE:
        params = TasksConstants.TODAY_DUE;
        break;
      case TasksConstants.ROUTE_TOMORROW_DUE:
        params = TasksConstants.TOMORROW_DUE;
        break;
      case TasksConstants.ROUTE_THIS_WEEK_DUE:
        params = TasksConstants.THIS_WEEK_DUE;
        break;
      case TasksConstants.ROUTE_TASKS_I_WATCH:
        params = TasksConstants.TASKS_I_WATCH;
        break;
      case TasksConstants.ROUTE_MY_OVERDUE_TASKS:
        params = TasksConstants.MY_OVERDUE_TASKS;
        break;
      default:
        params = title;
        break;
    }
    return params;
  }

  static getPriorityColor(item: any): string {
    if (item?.name === "Niedrig") {
      return "#607d8b";
    }
    let color;
    switch (item.name.toUpperCase()) {
      case TasksConstants.LOW:
        color = "#607d8b";
        break;
      case TasksConstants.NORMAL:
        color = "#0071bb";
        break;
      case TasksConstants.HIGH:
        color = "#00b8d4";
        break;
      case TasksConstants.URGENT:
        color = "#6200ea";
        break;
      case TasksConstants.IMMEDIATE:
        color = "#d50000";
        break;
      default:
        color = "#317bbc";
        break;
    }
    return color;
  }

  static getEditIconColor(item: any): string {
    if (item?.name === "Niedrig") {
      return "#8eacbb";
    }
    let color;
    switch (item.name.toUpperCase()) {
      case TasksConstants.LOW:
        color = "#8eacbb";
        break;
      case TasksConstants.NORMAL:
        color = "#29b6f6";
        break;
      case TasksConstants.HIGH:
        color = "#62ebff";
        break;
      case TasksConstants.URGENT:
        color = "#9d46ff";
        break;
      case TasksConstants.IMMEDIATE:
        color = "#ff5131";
        break;
      default:
        color = "#317bbc";
        break;
    }
    return color;
  }

  static getStatusColor(item: any): string {
    let color;
    switch (item.name) {
      case TasksConstants.NEW:
        color = "#888888";
        break;
      case TasksConstants.IN_PROGRESS:
        color = "#2d7bbe";
        break;
      case TasksConstants.CLOSED:
        color = "#00a651";
        break;
      default:
        color = "#888888";
        break;
    }
    return color;
  }

  static getStatusKey(title: string): string {
    let params;
    switch (title) {
      case TasksConstants.DUE_DATE_SORTING_KEY:
        params = TasksConstants.DUE_DATE;
        break;
      case TasksConstants.PRIORITY_SORTING_KEY:
        params = TasksConstants.PRIORITY;
        break;
      default:
        params = TasksConstants.DUE_DATE;
        break;
    }
    return params;
  }

  static getstartAndEndOfWeek(): Date[] {
    let now = new Date();
    now.setHours(0, 0, 0, 0);
    let sunday = new Date(now);
    sunday.setDate(sunday.getDate() - sunday.getDay());
    let monday = new Date(now);
    monday.setDate(monday.getDate() - monday.getDay() + 6);
    return [sunday, monday];
  }

  static getTomorrowDate(): Date {
    let currDate = new Date();
    currDate.setHours(0, 0, 0, 0);
    currDate.setDate(currDate.getDate() + 1);
    return currDate;
  }

  static getCurrentDate(): Date {
    let currDate = new Date();
    currDate.setHours(0, 0, 0, 0);
    return currDate;
  }

  static getYesterdayDate(): Date {
    let currDate = new Date();
    currDate.setHours(0, 0, 0, 0);
    currDate.setDate(currDate.getDate() - 1);
    return currDate;
  }

  static isDeviceWidthOptimal() {
    if (window.innerWidth < 1024) {
      return true;
    } else {
      return false;
    }
  }

  static getFormattedDate(date: Date) {
    if (!date) return null;
    if (!date.getTime) return date;
    if (isNaN(date.getTime())) return null;
    let year = date.getFullYear();
    let month: string = String(date.getMonth() + 1);
    month = `${parseInt(month) < 10 ? "0" : ""}${month}`;
    let monthDate: string = String(date.getDate());
    monthDate = `${parseInt(monthDate) < 10 ? "0" : ""}${monthDate}`;
    return `${year}-${month}-${monthDate}`;
  }

  static getFormattedDateWithTime(date: Date) {
    if (!date) return null;
    if (!date.getTime) return date;
    if (isNaN(date.getTime())) return null;
    let year = date.getFullYear();
    let month: string = String(date.getMonth() + 1);
    month = `${parseInt(month) < 10 ? "0" : ""}${month}`;
    let monthDate: string = String(date.getDate());
    monthDate = `${parseInt(monthDate) < 10 ? "0" : ""}${monthDate}`;
    let hours: string = String(date.getHours());
    hours = `${parseInt(hours) < 10 ? "0" : ""}${hours}`;
    let minutes: string = String(date.getMinutes());
    minutes = `${parseInt(minutes) < 10 ? "0" : ""}${minutes}`;
    return `${year}-${month}-${monthDate} ${hours}:${minutes}`;
  }

  static getDateAfterOffset(days: number) {
    let today = new Date();
    return new Date(today.setDate(today.getDate() + days));
  }

  static getWeekDay(day: number, isShort?: boolean): string {
    let weekdays: string[] = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"];
    if (!weekdays[day]) {
      return null;
    }
    if (isShort) {
      return weekdays[day].substr(0, 3);
    } else {
      return weekdays[day];
    }
  }

  static KEYCODES = {
    BACKSPACE: 8,
    ARROW_UP: 40,
    ARROW_DOWN: 38,
    ARROW_LEFT: 37,
    ARROW_RIGHT: 39,
    ENTER: 13
  };

  static getPropertyFromObject(obj: any, prop: string) {
    let value = obj;
    let keys = prop.split(".");
    keys.forEach(key => value = (value || {})[key]);
    return value || "";
  }

  static convertDecimalToTimeFormat(hours: string) {
    console.log("[TaskUtils][convertDecimalToTimeFormat] hours", hours);
    if (hours.indexOf(":") !== -1) {
      return hours;
    }
    let intHours = parseFloat(hours);
    let hrs = Math.floor(intHours);
    let min = (intHours - Math.floor(intHours)) * 60;
    let result = this.lz(hrs) + ":" + this.lz(Math.round(min));
    return result;
  }

  static lz(num) {
    return num < 10 ? "0" + num : num;
  }

  static getMinutes(time: string) {
    return this.lz(moment(time, "hh:mm").minutes());
  }

  static getHours(time: string) {
    return this.lz(moment(time, "hh:mm").hours());
  }

  static validateNumberOf2Digit(event, ele) {
    if (ele) {
      if (ele.toString().length > 2) {
        return false;
      }
      let key = window.event ? event.keyCode : event.which;
      if ((event.keyCode > 47 && event.keyCode < 58) ||
        (event.keyCode > 95 && event.keyCode < 106) ||
        event.keyCode === 46 || event.keyCode === 8 ||
        event.keyCode === 9 || event.keyCode === 37 ||
        event.keyCode === 39 || event.keyCode === 13) {
        return true;
      } else if (key < 48 || key > 57) {
        return false;
      } else {
        return true;
      }
    }
  }

  static statusCompletedId() {
    return parseInt(localStorage.getItem("status_completed"));
  }
  static statusCompletedName() {
    return "Completed";
  }
  static statusNewId() {
    return parseInt(localStorage.getItem("status_new"));
  }
  static statusNewName() {
    return "New";
  }
  static statusInProgressId() {
    return parseInt(localStorage.getItem("status_inProgress"));
  }
  static statusInProgressName() {
    return "In Progress";
  }

  static getDoneRatio() {
    return [ 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  }

  static getCompletedRatio(completeRatio): number {
    let doneRatio: number = parseInt(completeRatio);
    let value = 0;
    if (doneRatio) {
      if (doneRatio > 0 && doneRatio <= 10) {
        value = 10;
      } else if (doneRatio > 10 && doneRatio <= 20) {
        value = 20;
      } else if (doneRatio > 20 && doneRatio <= 30) {
        value = 30;
      } else if (doneRatio > 30 && doneRatio <= 40) {
        value = 40;
      } else if (doneRatio > 40 && doneRatio <= 50) {
        value = 50;
      } else if (doneRatio > 50 && doneRatio <= 60) {
        value = 60;
      } else if (doneRatio > 60 && doneRatio <= 70) {
        value = 70;
      } else if (doneRatio > 70 && doneRatio <= 80) {
        value = 80;
      } else if (doneRatio > 80 && doneRatio <= 90) {
        value = 90;
      } else if (doneRatio > 90 && doneRatio <= 100) {
        value = 100;
      } else if (doneRatio > 100) {
        value = 100;
      }
    }
    return value;
  }

  static getTextHightLightPropertyName(item: any): string {
    if (item?.name === "Niedrig") {
      return "highlight-priority-low";
    }
    let property;
    switch (item.name.toUpperCase()) {
      case TasksConstants.LOW:
        property = "highlight-priority-low";
        break;
      case TasksConstants.NORMAL:
        property = "highlight-priority-normal";
        break;
      case TasksConstants.HIGH:
        property = "highlight-priority-high";
        break;
      case TasksConstants.URGENT:
        property = "highlight-priority-urgent";
        break;
      case TasksConstants.IMMEDIATE:
        property = "highlight-priority-immediate";
        break;
      default:
        property = "highlight-priority-normal";
        break;
    }
    return property;
  }

  static isNumber(val) {
    return /^\d+$/.test(val);
  }
}
