
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<!-- <div id="assign_project_option" class="option-icon" *ngIf="!activate"> -->
  <!-- <mat-icon (click)="selectActivateData()">work</mat-icon>
  <p>{{ 'ASSIGN_PROJECT' | translate }}</p> -->

  <!-- <div class="date-icon project-icon">
    <ng-container [ngTemplateOutlet]="projectIcon" (click)="selectActivateData()"></ng-container>
  </div>
  <span>{{ 'ASSIGN_PROJECT' | translate }}</span> -->
<!-- </div> -->
<div class="icons-block" *ngIf="!activate">
  <div class="date-icon project-icon" (click)="selectActivateData()">
    <ng-container [ngTemplateOutlet]="projectIcon"></ng-container>
  </div>
  <span>{{ 'TASK_PROJECT' | translate }}</span>
</div>

<ng-template #projectIcon>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Icon/product/book-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M18.9414862,3.0330051 C19.6933618,2.91470914 20.4630117,3.11745978 21.0505367,3.59206515 C21.5810884,4.02064714 21.9183658,4.635282 21.9909431,5.35555601 L21.9909431,5.35555601 L22,5.55817853 L22,18.1404996 C22.0007958,19.306033 21.1935303,20.311982 20.0124131,20.6213744 L20.0124131,20.6213744 L19.8270163,20.6607539 L12.4045654,21.9448636 L12.302659,21.9580291 L12.2,21.9624284 L11.7816664,21.9607873 L11.728059,21.958758 L11.5953178,21.9448195 L4.17273223,20.6599312 C3.00867729,20.4582401 2.11887542,19.5261959 2.00760453,18.3276055 L2.00760453,18.3276055 L2,18.1411826 L2,5.55817853 C2,4.79582129 2.34989753,4.07639678 2.94946297,3.59206514 C3.47023546,3.17138279 4.13610415,2.96288263 4.85425414,3.00943611 L4.85425414,3.00943611 L5.0585106,3.03300465 L12,4.125 Z M19.3700981,5.00002853 L19.2523285,5.00870171 L12,6.14972139 L4.79366842,5.01484851 L4.68163601,5.0014326 C4.50634363,4.9903327 4.33473086,5.04406895 4.20624279,5.14786219 C4.07276544,5.25568579 4,5.40529886 4,5.55817853 L4,5.55817853 L3.99891518,18.0946159 L4.00215337,18.187692 C4.02463036,18.4274308 4.22714549,18.6395601 4.51402306,18.6892662 L4.51402306,18.6892662 L11.9364548,19.9741382 L12.0636231,19.9741382 L19.4440442,18.6982304 L19.545787,18.6770422 C19.821008,18.6047276 20.0001639,18.381478 20,18.1411826 L20,18.1411826 L20.0013056,5.60926234 L19.9974696,5.50869106 C19.9835795,5.37329267 19.9125215,5.24380079 19.7937569,5.14786218 C19.6483122,5.03037134 19.4487444,4.97779872 19.2523285,5.00870171 Z M12,7 C12.5522847,7 13,7.44771525 13,8 L13,18 C13,18.5522847 12.5522847,19 12,19 C11.4477153,19 11,18.5522847 11,18 L11,8 C11,7.44771525 11.4477153,7 12,7 Z" id="Combined-Shape" fill="#FFFFFF"></path>
    </g>
  </svg>
</ng-template>


<div id="assign_project_option_list" class="option-list" *ngIf="activate">
  <mat-list role="list">
    <mat-list-item role="listitem" *ngFor="let project of filteredItems; let i = index;"
    [class.selected]="i == selectedIndex"
    (click)="selectedData(project)">
      <mat-icon>work</mat-icon>
      <span>{{project.name}}</span>
    </mat-list-item>
  </mat-list>
</div>
