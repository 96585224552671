
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "./index";
import { UserProfile } from "../shared/models/user-profile";

export class AppActionTypes {
  static LOGIN_REQUEST = "[App] Login Request";
  static LOGOUT = "[App] logout";
  static DEVICE_READY = "[App] Device Ready";
  static DATABASE_READY = "[App] Database Ready";
  static SET_ONLINE_STATUS = "[App] Set Online Status";
  static SET_LOGIN_STATUS = "[App] Set login Status";
  static SET_AVAILABLE_APPS = "[App] set available apps";
  static WINDOW_FOCUS_STATE = "[App] Window Focus State";
  static SET_USER_PROFILE = "[App] Set user profile";
  static SET_ACTIVE_PROFILE = "[APP] Set active profile";
  static SET_RIGHT_SIDEBAR_STATUS = "[APP] Set right sidebar status";
}


export class Logout implements Action {
  readonly type = AppActionTypes.LOGOUT;
}

export class LoginRequest implements Action {
  readonly type = AppActionTypes.LOGIN_REQUEST;
}

export class DeviceReady implements Action {
  readonly type = AppActionTypes.DEVICE_READY;
}

export class DatabaseReady implements Action {
  readonly type = AppActionTypes.DATABASE_READY;
  constructor() {}
}

export class SetOnlineStatus implements Action {
  readonly type = AppActionTypes.SET_ONLINE_STATUS;
  constructor(public payload: boolean) {
  }
}

export class SetLoginStatus implements Action {
  readonly type = AppActionTypes.SET_LOGIN_STATUS;
  constructor(public payload: boolean) {
  }
}

export class SetAvailableApps implements Action {
  readonly type = AppActionTypes.SET_AVAILABLE_APPS;

  constructor(public payload: any[]) {
  }
}

export class WindowFocusStatus implements Action {
  readonly type = AppActionTypes.WINDOW_FOCUS_STATE;
  constructor(public payload: boolean) {}
}

export class SetUserProfile implements Action {
  readonly type = AppActionTypes.SET_USER_PROFILE;
  constructor(public payload: UserProfile) {
  }
}

export class SetActiveProfile implements Action {
  readonly type = AppActionTypes.SET_ACTIVE_PROFILE;
  constructor(public payload: string) {
  }
}

export class SetRightSidebarStatus implements Action {
  readonly type = AppActionTypes.SET_RIGHT_SIDEBAR_STATUS;
  constructor(public payload: boolean) {
  }
}