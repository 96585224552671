
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { enableProdMode, ErrorHandler } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

setupTheme();

if (environment.production || environment.isCordova || environment.isElectron) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

function loadExternalStyle(styleUrl: string) {
  return new Promise((resolve, reject) => {
    let styleElement = document.createElement("link");
    styleElement.rel = "stylesheet";
    styleElement.type = "text/css";
    styleElement.href = styleUrl;
    styleElement.onload = function() {
      resolve(null);
    };
    document.head.appendChild(styleElement);
    styleElement = null;
  });
}

function loadExternalScript(scriptUrl: string) {
  return new Promise(resolve => {
    let scriptElement = document.createElement("script");
    scriptElement.src = scriptUrl;
    scriptElement.onload = resolve;
    document.body.appendChild(scriptElement);
    scriptElement = null;
  });
}

function setupTheme() {
  if (environment.isCordova) {
    loadExternalScript("cordova.js");
    // remove non-cordova-div
    // document.getElementById("non-cordova-only").outerHTML = "";
    loadExternalScript("assets/js/SocialSharing.js");
  } else {
    // document.getElementById("cordova-only").outerHTML = "";
  }

  console.log("[main.ts] environment", environment);
  document.title = environment.title;
}
