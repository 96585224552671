
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

/**
 * Throttle a function
 */
export function throttle(func: any, wait: number, options?: any) {
    options = options || {};
    let context: any;
    let args: any;
    let result: any;
    let timeout: any = null;
    let previous = 0;

    function later() {
      previous = options.leading === false ? 0 : +new Date();
      timeout = null;
      result = func.apply(context, args);
    }

    return function(this: any) {
      const now = +new Date();

      if (!previous && options.leading === false) {
        previous = now;
      }

      const remaining = wait - (now - previous);
      context = this;
      args = arguments;

      if (remaining <= 0) {
        clearTimeout(timeout);
        timeout = null;
        previous = now;
        result = func.apply(context, args);
      } else if (!timeout && options.trailing !== false) {
        timeout = setTimeout(later, remaining);
      }

      return result;
    };
  }

  /**
   * Throttle decorator
   *
   *  class MyClass {
   *    throttleable(10)
   *    myFn() { ... }
   *  }
   */
  export function throttleable(duration: number, options?: any) {
    return function innerDecorator(target: any, key: PropertyKey, descriptor: PropertyDescriptor) {
      return {
        configurable: true,
        enumerable: descriptor.enumerable,
        get: function getter() {
          Object.defineProperty(this, key, {
            configurable: true,
            enumerable: descriptor.enumerable,
            value: throttle(descriptor.value, duration, options)
          });

          return this[key];
        }
      };
    };
  }
