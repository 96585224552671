
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { TaskStatus } from "./task-status";
import { TaskComment } from "./task-comment";
import { TaskAttachment } from "./task-attachment";
import { List } from "./list";
import { TaskUtils } from "../shared/task-utils";
import { User } from "./user";

export interface ICompact {
  id: number;
  name: string;
}

export class Task {
  id: number;
  project: ICompact;
  status: TaskStatus;
  priority: ICompact;
  author: ICompact;
  assigned_to: ICompact;
  subject: string;
  description: string;
  start_date: Date;
  done_ratio: number;
  created_on: Date;
  updated_on: Date;
  due_date: Date;
  comments?: TaskComment[];
  attachments?: TaskAttachment[];
  watchers?: User[];
  allowed_statuses?: any[];
  repeat: string = "";
  userAvatar: any;
  isChecked: boolean = false;
  isSelected: boolean = false;
  isMoreTags: boolean = false;
  tags?: ICompact[];
  remind_on: Date;
  spent_hours: string;
  estimated_hours: string;
  list?: ICompact;
  invitee_email: string;
  can_invite_users: string = "false";
  external_url: string = null;
  location?: ICompact;
  recurrence_id?: number;
  lock_version: number;

  constructor(json) {
    if (!json) {
      return;
    }

    Object.keys(json).forEach(key => this[key] = json[key]);

    this.isChecked = false;
    this.isSelected = false;

    if (json.start_date !== null) {
      this.start_date = new Date(json.start_date);
      if (isNaN(this.start_date.getTime())) {
        this.start_date = null;
      }
    }
    if (json.due_date !== null) {
      this.due_date = new Date(json.due_date);
      if (isNaN(this.due_date.getTime())) {
        this.due_date = null;
      }
    }
    if (json.remind_on !== null) {
      this.remind_on = new Date(json.remind_on);
      if (isNaN(this.remind_on.getTime())) {
        this.remind_on = null;
      }
    }
    this.created_on = new Date(this.created_on);
    this.updated_on = new Date(this.updated_on);
    if (json.watchers) {
      this.watchers = json.watchers.map( json => new User(json));
    } else {
      this.watchers = [];
    }
    if (json.comments) {
      // when parse data from DB
      this.comments = json.comments.map(jsonComment => new TaskComment(jsonComment));
    } else {
      if (json.journals) {
        // when partse server response
        this.comments = json.journals.map(jsonComment => new TaskComment(jsonComment));
      } else {
        this.comments = [];
      }
    }
    if (json.attachments) {
      this.attachments = json.attachments.map( json => new TaskAttachment(json));
    } else {
      this.attachments = [];
    }
    this.status = json.status;
    this.allowed_statuses = json.allowed_statuses;
    this.tags = (json.tags || []).slice();
    this.description = json.description;
    this.subject = json.subject;
    if (json.assigned_to && json.assigned_to.avatar_url) {
      this.userAvatar = json.assigned_to.avatar_url;
    }
  }

  toCreatePayload(): any {
    let payload: any = {};

    if (this.assigned_to) {
      Object.assign(payload, { "assigned_to_id": this.assigned_to.id });
      Object.assign(payload, { "invitee_email": "" });
    } else if (this.invitee_email) {
      Object.assign(payload, { "invitee_email": this.invitee_email });
      Object.assign(payload, { "assigned_to_id": "" });
    }

    //
    if (this.description) {
      Object.assign(payload, { "description": this.description });
    }
    //
    if (this.due_date) {
      Object.assign(payload, { "due_date": TaskUtils.getFormattedDate(this.due_date)});
    } else {
       Object.assign(payload, { "due_date": "" });
    }
    //
    if (this.estimated_hours) {
      Object.assign(payload, { "estimated_hours": this.estimated_hours });
    }
    //
    if (this.list) {
      Object.assign(payload, { "list_id": this.list.id });
    }
    //
    if (this.location) {
      Object.assign(payload, { "location_id": this.location.id });
    }
    //
    if (this.priority) {
      Object.assign(payload, { "priority_id": this.priority.id });
    }
    //
    if (this.project) {
      Object.assign(payload, { "project_id": this.project.id });
    }
    if (this.repeat) {
      Object.assign(payload, { "repeats": this.repeat });
    }
    //
    if (this.start_date) {
      Object.assign(payload, { "start_date": TaskUtils.getFormattedDate(this.start_date)});
    } else {
       Object.assign(payload, { "start_date": "" });
    }
    //
    if (this.subject) {
      Object.assign(payload, { "subject": this.subject });
    }
    //
    if (this.tags) {
      Object.assign(payload, { "tags": this.tags });
    }

    if (this.watchers) {
      Object.assign(payload, { "watchers": this.watchers });
    }

    if (this.external_url) {
      Object.assign(payload, { "external_url": this.external_url });
    }

    return payload;
  }

  static taskToCreateOffline(payload): Task {
    let task = new Task(null);

    task.id = Task.tempId();

    // 'payload' format:
    //
    // assigned_to_id: 1013
    // assigned_to_name
    // description: "Holahsjskdkdkdfffff"
    // due_date: "2019-02-22"
    // estimated_hours: "2:00"
    // list_id: 49
    // list_name
    // priority_id: 5
    // priority_name
    // project_id: 478
    // project_name
    // repeats: "m"
    // start_date: "2019-02-20"
    // subject: "Name"
    // tracker_id: "39"
    // invitee_email
    // tags

    if (payload.assigned_to_id) {
      task.assigned_to = {id: payload.assigned_to_id, name: payload.assigned_to_name};
    } else if (payload.invitee_email) {
      task.invitee_email = payload.invitee_email;
    }
    //
    task.description = payload.description;
    //
    if (payload.due_date) {
      task.due_date = new Date(payload.due_date);
      if (isNaN(task.due_date.getTime())) {
        task.due_date = null;
      }
    }
    //
    task.estimated_hours = payload.estimated_hours;
    //
    if (payload.list_id) {
      task.list = {id: payload.list_id, name: payload.list_name};
    }
    //
    if (payload.location_id) {
      task.location = {id: payload.location_id, name: payload.location_name};
    }
    //
    if (payload.priority_id) {
      task.priority = {id: payload.priority_id, name: payload.priority_name};
    }
    //
    if (payload.project_id) {
      task.project = {id: payload.project_id, name: payload.project_name};
    }
    //
    if (payload.repeats) {
      task.repeat = payload.repeats;
    }
    //
    if (payload.start_date) {
      task.start_date = new Date(payload.start_date);
      if (isNaN(task.start_date.getTime())) {
        task.start_date = null;
      }
    }
    //
    task.subject = payload.subject;
    //
    if (payload.tags) {
      task.tags = payload.tags;
    } else {
      task.tags = [];
    }

    if (payload.watchers) {
      task.watchers = payload.watchers;
    } else {
      task.watchers = [];
    }

    if (!payload.created_on) {
      task.created_on = TaskUtils.dateToSearchableDBDateTime(new Date());
    }
    task.status = new TaskStatus({id: TaskUtils.statusNewId(), name: TaskUtils.statusNewName()});
    task.author = {id: payload.author_id, name: payload.author_name};
    task.comments = [];

    if (!payload.done_ratio) {
      task.done_ratio = 0;
    }
    return task;
  }

  static tempId(): number {
    return 1000000000 + Math.floor(Math.random() * 9999999); // temp id
  }
}
