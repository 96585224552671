
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "../../../actions";
import { Task } from "../../models/task";
import { Update } from "@ngrx/entity/src/models";
import { Project } from "../../models/project";
import { Priority } from "../../models/priority";
import { User, AuthUser, TeamUser } from "../../models/user";
import { StatisticsInfo, Tag, TaskStatistics, List, SearchQuery, Location, Settings } from "../../models/index";

// load Tasks
export const LOAD_TASKS = "[Tasks] Load Tasks";
export const LOAD_TASKS_FAIL = "[Tasks] Load Tasks Fail";
export const LOAD_TASKS_SUCCESS = "[Tasks] Load Tasks Success";
// next load Tasks
export const NEXT_LOAD_TASKS = "[Tasks] Next Load Tasks";
export const NEXT_LOAD_TASKS_FAIL = "[Tasks] Next Load Tasks Fail";
export const NEXT_LOAD_TASKS_SUCCESS = "[Tasks] Next Load Tasks Success";
// load more
export const ALLOW_LOAD_MORE = "[Tasks] Allow Load More";
// create task
export const CREATE_TASK = "[Tasks] Create Task";
export const CREATE_TASK_FAIL = "[Tasks] Create Task Fail";
export const CREATE_TASK_SUCCESS = "[Tasks] Create Task Success";
export const CREATE_TASKS_SUCCESS = "[Tasks] Create Tasks Success";
// update task
export const UPDATE_TASK = "[Tasks] Update Task";
export const UPDATE_TASK_FAIL = "[Tasks] Update Task Fail";
export const UPDATE_TASK_SUCCESS = "[Tasks] Update Task Success";
export const UPDATE_TASKS_SUCCESS = "[Tasks] Update Tasks Success";
// remove tasks
export const REMOVE_TASKS = "[Tasks] Remove Task(s)";
export const REMOVE_TASKS_FAIL = "[Tasks] Remove Task(s) Fail";
export const REMOVE_TASKS_SUCCESS = "[Tasks] Remove Task(s) Success";

export const SEARCH_MODE_TOGGLE = "[Tasks] Search Mode Toggle";
export const SET_TASK_FILTER_TYPE = "[Tasks] Set Task Filter Type";
export const SET_TASK_FILTER_OPTION = "[Tasks] Set Task Filter Option";
export const SET_SORT_BY = "[Tasks] Set Sorting Option";

export const SET_SEARCH_TASK_STATISTICS_INFO = "[Tasks] Set Search Task Statistics Info";
export const SET_TASK_STATISTICS_INFO = "[Tasks] Set Task Statistics Info";
export const SET_TAG_STATISTICS_INFO = "[Tasks] Set Tag Statistics Info";
export const SET_LIST_STATISTICS_INFO = "[Tasks] Set List Statistics Info";
export const SET_LOCATION_STATISTICS_INFO = "[Tasks] Set Location Statistics Info";

export const STORE_PROJECT_LIST = "[Tasks] Store Project List";
export const STORE_PRIORITY_LIST = "[Tasks] Store Priority List";
export const STORE_MEMBER_LIST = "[Tasks] Store Member List";
export const STORE_TAGS_LIST = "[Tasks] Store Tag List";
export const STORE_FOLDER_LIST = "[Tasks] Store Folder List";
export const STORE_SAVE_SEARCHES_LIST = "[Tasks] Store Save Searches List";
export const STORE_LOCATION_LIST = "[Tasks] Store Location List";

export const STORE_AUTH_USER = "[Tasks] Store Task Auth user";
export const SET_TASK_ISLOADING = "[Tasks] Set Task IsLoading";

export const ADD_SELECTED_TASKS_ID = "[Tasks] Add Selected Tasks Id";
export const ADD_SELECTED_TASKS_IDS = "[Tasks] Add Selected Tasks Ids";
export const REMOVE_SELECTED_TASKS_ID = "[Tasks] Remove Selected Tasks Id";
export const RESET_SELECTED_TASKS_ID = "[Tasks] Reset Selected Tasks Id";
export const SET_SELECT_ALL = "[Tasks] Set Select All";

export const SET_DETAIL_VIEW = "[Tasks] Set Detail View";
export const SET_MULTI_SELECT_TASK_VIEW = "[Tasks] Set Multi selected task View";
export const SET_EDIT_TASK_VIEW = "[Tasks] Set Edit Task View";
export const SET_OPEN_TASKS_CHECKED = "[Tasks] Set Open Tasks Checked";
export const SET_CLOSE_TASKS_CHECKED = "[Tasks] Set Close Tasks Checked";
export const UPDATE_REMINDER_TASKS = "[Tasks] Update reminder tasks";
export const SET_SHOW_MY_TASK_CHECKED = "[Tasks] Set show my task checked";
export const UPDATE_ASSIGNMENT_TASKS = "[Tasks] Update assignment tasks";

export const STORE_MY_TEAM_USER = "[Tasks] Store My Team User";
export const SET_TASK_DETAIL_HIGHLIGHT = "[Tasks] Set Task Detail Highlight";
export const STORE_SETTINGS = "[Tasks] Store Settings";

export class LoadTasks implements Action {
  readonly type = LOAD_TASKS;
}

export class LoadTasksFail implements Action {
  readonly type = LOAD_TASKS_FAIL;
}

export class LoadTasksSuccess implements Action {
  readonly type = LOAD_TASKS_SUCCESS;
  constructor(public payload: { currOffset: number, isMoreTasks: boolean, task: Task[] }) {}
}

export class NextLoadTasks implements Action {
  readonly type = NEXT_LOAD_TASKS;
}

export class NextLoadTasksFail implements Action {
  readonly type = NEXT_LOAD_TASKS_FAIL;
}

export class NextLoadTasksSuccess implements Action {
  readonly type = NEXT_LOAD_TASKS_SUCCESS;
  constructor(public payload: { currOffset: number, isMoreTasks: boolean, task: Task[] }) {}
}

export class AllowLoadMore implements Action {
  readonly type = ALLOW_LOAD_MORE;
}

export class CreateTask implements Action {
  readonly type = CREATE_TASK;
}

export class CreateTaskFail implements Action {
  readonly type = CREATE_TASK_FAIL;
}

export class CreateTaskSuccess implements Action {
  readonly type = CREATE_TASK_SUCCESS;
  constructor(public payload: { task: Task}) {}
}

export class CreateTasksSuccess implements Action {
  readonly type = CREATE_TASKS_SUCCESS;
  constructor(public payload: { tasks: Task[]}) {}
}

export class UpdateTask implements Action {
  readonly type = UPDATE_TASK;
}

export class UpdateTaskFail implements Action {
  readonly type = UPDATE_TASK_FAIL;
}

export class UpdateTaskSuccess implements Action {
  readonly type = UPDATE_TASK_SUCCESS;
  constructor(public payload: { task: Update<Task> }) {}
}

export class UpdateTasksSuccess implements Action {
  readonly type = UPDATE_TASKS_SUCCESS;
  constructor(public payload: { tasks: Update<Task>[] }) {}
}

export class RemoveTasks implements Action {
  readonly type = REMOVE_TASKS;
}

export class RemoveTasksFail implements Action {
  readonly type = REMOVE_TASKS_FAIL;
}

export class RemoveTasksSuccess implements Action {
  readonly type = REMOVE_TASKS_SUCCESS;
  constructor(public payload: { ids: number[] }) {}
}

export class SearchModeToggle implements Action {
  readonly type = SEARCH_MODE_TOGGLE;
  constructor(public payload: boolean) {}
}

export class SetSortBy implements Action {
  readonly type = SET_SORT_BY;
  constructor(public payload: string) {}
}

export class SetTaskFilterOption implements Action {
  readonly type = SET_TASK_FILTER_OPTION;
  constructor(public payload: string) {}
}

export class SetSearchStatisticsInfo implements Action {
  readonly type = SET_SEARCH_TASK_STATISTICS_INFO;
  constructor(public payload: StatisticsInfo) {}
}

export class SetTagStatisticsInfo implements Action {
  readonly type = SET_TAG_STATISTICS_INFO;
  constructor(public payload: StatisticsInfo) {}
}

export class SetListStatisticsInfo implements Action {
  readonly type = SET_LIST_STATISTICS_INFO;
  constructor(public payload: StatisticsInfo) {}
}

export class SetLocationStatisticsInfo implements Action {
  readonly type = SET_LOCATION_STATISTICS_INFO;
  constructor(public payload: StatisticsInfo) {}
}

export class SetTaskFilterType implements Action {
  readonly type = SET_TASK_FILTER_TYPE;
  constructor(public payload: string) {}
}

export class SetTaskStatisticsInfo implements Action {
  readonly type = SET_TASK_STATISTICS_INFO;
  constructor(public payload: TaskStatistics[]) {}
}

export class StoreProjectList implements Action {
  readonly type = STORE_PROJECT_LIST;
  constructor(public payload: Project[]) {}
}

export class StorePriorityList implements Action {
  readonly type = STORE_PRIORITY_LIST;
  constructor(public payload: Priority[]) {}
}

export class StoreMemberList implements Action {
  readonly type = STORE_MEMBER_LIST;
  constructor(public payload: User[]) {}
}

export class StoreTagList implements Action {
  readonly type = STORE_TAGS_LIST;
  constructor(public payload: Tag[]) {}
}

export class StoreFolderList implements Action {
  readonly type = STORE_FOLDER_LIST;
  constructor(public payload: List[]) {}
}

export class StoreLocationList implements Action {
  readonly type = STORE_LOCATION_LIST;
  constructor(public payload: Location[]) {}
}

export class StoreAuthUser implements Action {
  readonly type = STORE_AUTH_USER;
  constructor(public payload: AuthUser) {}
}

export class SetIsLoading implements Action {
  readonly type = SET_TASK_ISLOADING;
  constructor(public payload: boolean) {}
}

export class SetSelectAll implements Action {
  readonly type = SET_SELECT_ALL;
  constructor(public payload: boolean) {}
}

export class SetDetailView implements Action {
  readonly type = SET_DETAIL_VIEW;
  constructor(public payload: boolean) {}
}

export class SetTaskDetailHighlight implements Action {
  readonly type = SET_TASK_DETAIL_HIGHLIGHT;
  constructor(public payload: boolean) {}
}

export class SetEditTaskView implements Action {
  readonly type = SET_EDIT_TASK_VIEW;
  constructor(public payload: boolean) {}
}

export class SetOpenTasksChecked implements Action {
  readonly type = SET_OPEN_TASKS_CHECKED;
  constructor(public payload: boolean) {}
}

export class SetCloseTasksChecked implements Action {
  readonly type = SET_CLOSE_TASKS_CHECKED;
  constructor(public payload: boolean) {}
}

export class SetShowMyTaskChecked implements Action {
  readonly type = SET_SHOW_MY_TASK_CHECKED;
  constructor(public payload: boolean) {}
}

export class SetMultiSelectTaskView implements Action {
  readonly type = SET_MULTI_SELECT_TASK_VIEW;
  constructor(public payload: boolean) {}
}

export class AddSelectedTaskId implements Action {
  readonly type = ADD_SELECTED_TASKS_ID;
  constructor(public payload: number) {}
}

export class AddSelectedTaskIds implements Action {
  readonly type = ADD_SELECTED_TASKS_IDS;
  constructor(public payload: number[]) {}
}

export class RemoveSelectedTaskId implements Action {
  readonly type = REMOVE_SELECTED_TASKS_ID;
  constructor(public payload: number) {}
}

export class ResetSelectedTaskId implements Action {
  readonly type = RESET_SELECTED_TASKS_ID;
}

export class UpdateReminderTasks implements Action {
  readonly type = UPDATE_REMINDER_TASKS;
  constructor(public payload: any[]) {}
}

export class UpdateAssignmentTasks implements Action {
  readonly type = UPDATE_ASSIGNMENT_TASKS;
  constructor(public payload: any[]) {}
}

export class StoreMyTeamUsers implements Action {
  readonly type = STORE_MY_TEAM_USER;
  constructor(public payload: TeamUser[]) {}
}

export class StoreSaveSearches implements Action {
  readonly type = STORE_SAVE_SEARCHES_LIST;
  constructor(public payload: SearchQuery[]) {}
}

export class StoreSettings implements Action {
  readonly type = STORE_SETTINGS;
  constructor(public payload: Settings) {}
}
// action types
export type TasksAction =
  | LoadTasks
  | LoadTasksFail
  | LoadTasksSuccess
  | NextLoadTasks
  | NextLoadTasksFail
  | NextLoadTasksSuccess
  | AllowLoadMore
  | CreateTask
  | CreateTaskFail
  | CreateTaskSuccess
  | UpdateTask
  | UpdateTaskFail
  | UpdateTaskSuccess
  | UpdateTasksSuccess
  | RemoveTasks
  | RemoveTasksFail
  | RemoveTasksSuccess
  | SearchModeToggle
  | SetTaskFilterOption
  | SetSearchStatisticsInfo
  | SetTaskFilterType
  | SetSearchStatisticsInfo
  | SetTaskFilterType
  | SetTaskStatisticsInfo
  | SetSortBy
  | StoreProjectList
  | StorePriorityList
  | StoreTagList
  | StoreFolderList
  | StoreMemberList
  | StoreAuthUser
  | SetIsLoading
  | CreateTasksSuccess
  | SetSelectAll
  | SetDetailView
  | AddSelectedTaskId
  | AddSelectedTaskIds
  | RemoveSelectedTaskId
  | ResetSelectedTaskId
  | SetTagStatisticsInfo
  | SetListStatisticsInfo
  | SetMultiSelectTaskView
  | SetEditTaskView
  | UpdateReminderTasks
  | UpdateAssignmentTasks
  | SetOpenTasksChecked
  | StoreMyTeamUsers
  | StoreSaveSearches
  | StoreLocationList
  | SetLocationStatisticsInfo
  | SetShowMyTaskChecked
  | SetTaskDetailHighlight
  | SetCloseTasksChecked
  | StoreSettings;
