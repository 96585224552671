
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, HostListener, Component, Output, EventEmitter, OnDestroy } from "@angular/core";
import { TasksConstants } from "../../shared/task-constacts";
import { getSelectedFilterOption, TasksRootState, getTagList, getSelectedFilterType, getFolderList } from "../../store/index";
import { Store } from "@ngrx/store";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { List, dialogType } from "../../models";
import { TaskRepository } from "../../repository/task.repository";
import { getOnlineStatus } from "../../../reducers";
import { CreateListDialogComponent } from "../create-list-dialog/create-list-dialog.component";
import { CommonUtil } from "../../../common/utils/common.utils";
import { FolderOptionListItemComponent } from "./folder-option-list-item.component";
import { TaskConfirmDialogComponent } from "../task-dialogs/confirm-dialog.component";
import { MessageTranslatorService } from "../../services";
import { ErrorService } from "../../../common/providers/error-service";
import { SuccessService } from "../../../common/providers/success-service";
import { ErrorType, SuccessType } from "../../shared/task-enum";
import { Router } from "@angular/router";
import { takeWhile, take } from "rxjs/operators";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { VncLibraryService } from "vnc-library";

@Component({
  selector: "vp-folder-option-list",
  template: `
  <div class="task__dialog" id="listDialog">
  <div class="task__dialog-header">
    <div class="mobile-back-button">
      <button mat-button (click)="hide()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
    <div class="header_lbl disable-select">
      {{'LISTS' | translate}}
    </div>
    <div class="desktop-close-button">
      <button mat-button (click)="hide()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="task__dialog-body folder-option-list_dialog">
  <mdl-textfield id="listSearchField" class="filter-search-textfield" [(ngModel)]="searchText"  [label]="'FIND_THE_LIST' | translate"  floating-label></mdl-textfield>
  <mat-list role="list" id="listItems">
    <mat-list-item role="listitem" class="disable-select" vpLongPress [timeout]="500" (onLongPress)="$event.stopPropagation();openContextMenu(list)" *ngFor="let list of folderList | vpTaskProjectSearch : searchText; let i = index;"  [class.selected]="i == selectedIndex"  (click)="$event.stopPropagation();navigateRoute(list.name)">
      <div>
        <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <title>Icon/product/assignment-24px</title>
            <g id="Icon/product/assignment-24px" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Icon-24px" transform="translate(2.000000, 1.000000)" fill="#000000">
                    <path d="M16,2 L11.82,2 C11.4,0.84 10.3,0 9,0 C7.7,0 6.6,0.84 6.18,2 L2,2 C0.9,2 0,2.9 0,4 L0,18 C0,19.1 0.9,20 2,20 L16,20 C17.1,20 18,19.1 18,18 L18,4 C18,2.9 17.1,2 16,2 L16,2 Z M9,2 C9.55,2 10,2.45 10,3 C10,3.55 9.55,4 9,4 C8.45,4 8,3.55 8,3 C8,2.45 8.45,2 9,2 L9,2 Z M11,16 L4,16 L4,14 L11,14 L11,16 L11,16 Z M14,12 L4,12 L4,10 L14,10 L14,12 L14,12 Z M14,8 L4,8 L4,6 L14,6 L14,8 L14,8 Z" id="Shape"></path>
                </g>
            </g>
        </svg>
        <span class="list-item disable-select">{{ list.name }}</span>
      </div>
      <div class="rounded-rectangle">
        <span class="disable-select">{{ list.tasks_count }}</span>
      </div>
    </mat-list-item>
  </mat-list>
  </div>
  <button id="newListButton" (click)="openAddListDialog()" mat-button class="add_list-button">
    <i class="material-icons disable-select">add</i>
  </button>
  </div>
  <div class="vnctask-loading" *ngIf="isLoading">
    <div class="indicator">
    <vnc-spinner [size]="'medium'" *ngIf="true"></vnc-spinner>
    </div>
  </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FolderOptionListComponent implements OnDestroy {
  selectedFilter: string;
  isAlive: boolean = true;
  taskConstants = TasksConstants;
  folderList: List[] = [];
  isLongPressed: boolean =  false;
  isLoading: boolean = false;
  dialogType = dialogType;
  searchText: string = "";
  isOnline: boolean = false;
  selectedFilterType: string;
  currDelSelectedList: List;

  constructor(
    private router: Router,
    private store: Store<TasksRootState>,
    private broadcaster: Broadcaster,
    private changerDetectorRef: ChangeDetectorRef,
    private taskRepo: TaskRepository,
    private messageTranslatorService: MessageTranslatorService,
    private vncLibaryService: VncLibraryService,
    private successService: SuccessService,
    private errorService: ErrorService,
    private matDialog: MatDialog,
    private dialogRef: MatDialogRef<FolderOptionListComponent>
  ) {
    this.setupStore();
    this.handleDeleteListSuccessMessages();
    this.handleListErrorMessages();

    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });

    this.broadcaster.on<any>("hideFolderOptionListDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });

    this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
      this.isOnline = isOnline;
      this.changerDetectorRef.markForCheck();
    });
  }

  setupStore() {
    this.store
      .select(getSelectedFilterOption)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(value => {
        this.selectedFilter = value;
        this.changerDetectorRef.markForCheck();
      });

    this.store
    .select(getFolderList)
    .pipe(takeWhile(() => this.isAlive))
    .subscribe(lists => {
      if (lists) {
        this.folderList = lists;
        this.changerDetectorRef.markForCheck();
      }
    });

    this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
      this.isOnline = isOnline;
      this.changerDetectorRef.markForCheck();
    });

    this.store.select(getSelectedFilterType).pipe(takeWhile(() => this.isAlive)).subscribe(value => {
      this.selectedFilterType = value;
      this.changerDetectorRef.markForCheck();
    });
  }

  handleListErrorMessages() {
    this.errorService.only(ErrorType.ListError).pipe(takeWhile(() => this.isAlive)).subscribe(error => {
      this.vncLibaryService.openSnackBar(error.messages, "","", "", 3000, "bottom", "left").subscribe(res => {
      });
      this.isLoading = false;
      this.changerDetectorRef.markForCheck();
    });
  }

  handleDeleteListSuccessMessages() {
    this.successService.only(SuccessType.ListDeleted).pipe(takeWhile(() => this.isAlive)).subscribe(success => {
      this.vncLibaryService.openSnackBar(success.messages, "","", "", 3000, "bottom", "left").subscribe(res => {
      });
      this.isLoading = false;
      this.changerDetectorRef.markForCheck();

      this.taskRepo.getFolderListWithCounters();
      if (this.selectedFilterType === TasksConstants.ROUTE_TYPE_LIST) {
        if (this.isSelectedFilter(this.currDelSelectedList.name)) {
          this.router.navigate(["/task"]);
        }
      }
    });
  }

  navigateRoute(title: any) {
    if (CommonUtil.isOnIOS() && this.isLongPressed) {
      this.isLongPressed = false;
      return;
    }
    this.hide(title);
  }

  isSelectedFilter(title: string): boolean {
    if (title === this.selectedFilter) {
      return true;
    }
    return false;
  }

  @HostListener("document:keydown.esc", ["$event"])
  hide(title?) {
    this.dialogRef.close({ type: this.taskConstants.ROUTE_TYPE_LIST, title: title});
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.changerDetectorRef.markForCheck();
  }

  openAddListDialog() {
    const dlg = this.matDialog.open(CreateListDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-create-list-dialog",
      data: { isCreate: true, selectedList: null }
    });
  }

  openContextMenu(list) {
    this.isLongPressed = true;
    const pDialog = this.matDialog.open(FolderOptionListItemComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-folder-option-list-item-dialog",
    });
    pDialog.afterClosed().pipe(take(1)).subscribe( value => {
      if (value) {
        if (value.type === TasksConstants.RENAME) {
          this.renameList(list);
        } else if (value.type === TasksConstants.DELETE) {
          this.deleteList(list);
        }
      }
    });
  }

  renameList(list) {
    const dlg = this.matDialog.open(CreateListDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-create-list-dialog",
      data: { isCreate: false, selectedList: list }
    });
  }

  deleteList(list) {
    if (!this.isOnline) {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
      return;
    }

    const dlg = this.matDialog.open(TaskConfirmDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-task-confirm-dialog",
      data: { type: this.dialogType.DELETE, message: this.messageTranslatorService.getMessage(TasksConstants.CONFIRM_DELETE_ATTACHMENT) + " \"" + list.name + "\"?", header: null }
    });
    dlg.afterClosed().pipe(take(1)).subscribe(res => {
      if (!!res) {
        this.isLoading = true;
        this.currDelSelectedList = list;
        this.taskRepo.deleteList(list);
        this.changerDetectorRef.markForCheck();
      }
    });
  }
}
