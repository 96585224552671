
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { VNCStandardObj } from "./vnc-standard-obj";

export enum AppType {
  VNCTASK
}

export function getAllAppTypes(): AppType[] {
  return [
    AppType.VNCTASK,
  ];
}

export function getAppType(type: AppType): VNCStandardObj {
  let slug = "";
  let title = "";
  let image_key = "";

  switch (type) {
    default : {
      slug = "vnctask";
      title = "VNCtask";
      image_key = "VNCtask";
      break;
    }
  }

  return {
    slug: slug,
    title: title,
    image_key: "assets/images/" + image_key + ".svg"
    // No need for translation as the app names remain same in both languages
  };
}

export function appTypeFromString(str: string): AppType {
  switch (str) {
    default:
      return AppType.VNCTASK;
  }
}
