
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { NgModule, Injectable } from "@angular/core";
import { RouterModule, Routes, CanLoad, Route, PreloadAllModules } from "@angular/router";
import { AvailableRoutes } from "./common/providers/route.guards";
import { PageNotFoundComponent, RedirectComponent } from "./shared/components";
import { ConfigService } from "./common/providers/config.service";
import { AuthService } from "./common/providers/auth.service";
import { Observable } from "rxjs";
import { environment } from "../environments/environment";
import { RegistrationFormComponent } from "./task/components/registration/registration-form/registration-form.component";
import { RegistrationActivationComponent } from "./task/components/registration/registration-activation/registration-activation.component";
import { ForgotPasswordFormComponent } from "./task/components/forgot-password/forgot-password-form/forgot-password-form.component";
import { ForgotPasswordInfoComponent } from "./task/components/forgot-password/forgot-password-info/forgot-password-info.component";
import { ResetPasswordFormComponent } from "./task/components/forgot-password/reset-password-form/reset-password-form.component";
import { RegistrationLinkExpiredComponent } from "./task/components/registration/registration-link-expired/registration-link-expired.component";
import { map } from "rxjs/operators";

@Injectable()
export class AuthGuard implements CanLoad {
  isCordovaOrElectron = environment.isCordova || environment.isElectron;
  constructor(private configService: ConfigService,
              private authService: AuthService) {
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    if (this.isCordovaOrElectron && !this.configService.selectedServer) {
      return true;
    }

    return this.authService.getProfile().
      pipe(map(profile => {
      // TODO: skip getProfile in RootComponent if profile fetching is successful here.
      if (!profile) {
        if (this.isCordovaOrElectron) {
          return true;
        } else {
          if ( window.location && window.location.href.replace(/\/$/, "") !== window.location.origin) {
            let currentDate = new Date();
            currentDate.setSeconds(currentDate.getSeconds() + 300);
            document.cookie = "redirectUrl=" + encodeURIComponent(window.location.href) + ";expires=" + currentDate.toUTCString() + ";path=/";
          }
          window.location.href = this.configService.API_URL + "/api/login";
        }
      }
      if (profile) {
        let allcookies = document.cookie;
        if (allcookies) {
          let cookiearray = allcookies.split(";");
          let redirectUrl;
          for (let i = 0; i < cookiearray.length; i++) {
            let name = cookiearray[i].split("=")[0];
            if ( name && "redirectUrl" === name.trim()) {
              redirectUrl = decodeURIComponent(cookiearray[i].split("=")[1]);
              break;
            }
          }
          if (redirectUrl) {
            let currentDate = new Date();
            currentDate.setSeconds(currentDate.getSeconds() - 300);
            document.cookie = "redirectUrl=;expires=" + currentDate.toUTCString() + ";path=/";
            window.location.href = redirectUrl;
          }
        }
      }
      return !!profile;
      }));
  }
}

const routes: Routes = [
  {
    path: "",
    redirectTo: "task",
    pathMatch: "full"
  },
  {
    path: "task",
    canLoad: [AuthGuard],
    canActivate: [AvailableRoutes],
    data: { sidebar: true, appName: "VNCtask", path: "/task" },
    loadChildren: () => import('./task/task.module').then(m => m.TaskModule)
  },
  {
    path: "redirect",
    component: RedirectComponent
  },
  {
    path: "registration/form",
    component: RegistrationFormComponent
  },
  {
    path: "registration/activation",
    component: RegistrationActivationComponent
  },
  {
    path: "registration/expired",
    component: RegistrationLinkExpiredComponent
  },
  {
    path: "forgot-password/form",
    component: ForgotPasswordFormComponent
  },
  {
    path: "forgot-password/info",
    component: ForgotPasswordInfoComponent
  },
  {
    path: "forgot-password/reset",
    component: ResetPasswordFormComponent
  },
  {
    path: "**",
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,  {
    // Tell the router to use the HashLocationStrategy.
    useHash: false,
    enableTracing: false,

      // This will tell Angular to preload the lazy-loaded routes after the
      // application has been bootstrapped. This will extend to both top-level
      // and nested lazy-loaded modules.
      preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
