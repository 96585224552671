
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ActionReducerMap, createFeatureSelector, createSelector } from "@ngrx/store";
import { RootState } from "../../../reducers/index";
import { TaskState, taskReducer } from "./tasks.reducers";
import { SearchState, searchReducer, _getAdvanceSearchRequest, _getSearchKeyword } from "./search";

export interface TasksRootState extends RootState {
  task: State;
}

export const getTasksRootState = createFeatureSelector<State>("task");

export const taskRootReducer = {
  task: taskReducer,
  search: searchReducer
};

export interface State {
  task: TaskState;
  search: SearchState;
}

export const getSearchState = createSelector(
  getTasksRootState,
  state => state.search
);

export const getAdvanceSearchRequest = createSelector(getSearchState, _getAdvanceSearchRequest);
export const getSearchKeyword = createSelector(getSearchState, _getSearchKeyword);
