
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  EventEmitter,
  Output,
  AfterViewInit
} from "@angular/core";
import { SearchRepository } from "../../../repository/search.repository";
import { MyUser, UserType, VNCStandardObj, getUserType } from "../../../models";
import { TaskRepository } from "../../../repository/task.repository";
import { isNullOrUndefined } from "util";
import { Broadcaster } from "../../../../common/providers/broadcaster.service";
import { take, takeWhile } from "rxjs/operators";

@Component({
  selector: "vp-select-users",
  templateUrl: "./select-users-dialog.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectUsersComponent implements OnInit, AfterViewInit, OnDestroy {
  changed = false;
  isAlive = true;
  allSelected: boolean;
  assignUserList: MyUser[] = [];
  selectedUserList: MyUser[] = [];
  selectedUserType: UserType;
  UserType = UserType;
  mdlFixDone = false;
  @Output() closeDialog = new EventEmitter<boolean>();

  constructor(
    private broadcaster: Broadcaster,
    private searchRepo: SearchRepository,
    private tasksRepo: TaskRepository,
    private changeDetectionRef: ChangeDetectorRef) {

    this.tasksRepo.allUserList$.pipe(take(1)).subscribe(members => {
      if (members) {
        this.assignUserList = members;
        this.changeDetectionRef.markForCheck();
      }
    });

    this.searchRepo.selectSavedAssignees().pipe(take(1)).subscribe((selectedUsers) => {
      this.selectedUserType = selectedUsers.type;
      this.selectedUserList = selectedUsers.list;
      this.changeDetectionRef.markForCheck();
    });

    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
      });

    this.broadcaster.on<any>("hideTaskSelectUsersDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
      });
  }

  ngOnInit() {
  }

  close() {
    this.closeDialog.emit(true);
  }

  ngAfterViewInit() {
    if (!this.mdlFixDone) {
      this.mdlFixDone = true;

      // PIYUSH_COMMENT serious issue with Mdl
      // https://github.com/mseemann/angular2-mdl/issues/145
      // https://github.com/angular/angular/issues/10816
      setTimeout(() => {
        this.changeDetectionRef.markForCheck();
      } , 100);
    }
  }

  checkForUpdate() {
    this.changed = true;
    this.changeDetectionRef.markForCheck();
  }

  enableSubmit() {}

  userSelected(event , user: MyUser) {
    if (event === true) {
      // Removing if already selected. This is sometimes caused by double click.
      this.selectedUserList = this.selectedUserList.filter(c => c.id !== user.id);
      this.selectedUserList.push(user);
    } else {
      this.selectedUserList = this.selectedUserList.filter(c => c.id !== user.id);
    }

    this.changed = true;
    this.allSelected = false;
    if (this.selectedUserList.length === 0) {
      this.changed = false;
    }
    this.changeDetectionRef.markForCheck();
  }

  typeChanged() {
    this.changed = true;
    this.changeDetectionRef.markForCheck();
  }

  typeSelected() {
    this.changed = false;
    this.changeDetectionRef.markForCheck();
  }

  getUserTypeObject(range: UserType): VNCStandardObj {
    return getUserType(range);
  }

  isUserSelected(user: MyUser) {
    return !isNullOrUndefined(this.selectedUserList.find((c) => c.id === user.id));
  }

  saveUsers() {
    this.searchRepo.saveAssigneeList(
      {
        type: this.selectedUserType,
        list: this.selectedUserList
      }
    );
    this.close();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.changeDetectionRef.markForCheck();
  }
}
