
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="taskServiceDeskDialog" class="task__dialog vp-task-service-desk-dialog">
  <div id="taskServiceDeskDialogHeader" class="task__dialog-header">
    <div class="mobile-back-button">
      <button mat-button (click)="close()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
    <div class="header_lbl disable-select">
      {{'TASK_SERVICE_DESK_TITLE' | translate}}
    </div>
    <div class="desktop-close-button">
      <button mat-button (click)="close()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="task__dialog-body task__dialog-body--pad">
    <div class="content">
      <p class="left">
        {{ 'TASK_SERVICE_DESK_CONTENT' | translate }}
      </p>
      <p class="button">
        <a id="task-service-desk-visit-link" target="_blank" class="open-new-window" href="https://redmine.vnc.biz/helpdesk">
          {{ 'TASK_SERVICE_DESK_BTN_TITLE' | translate}}
        </a>
      </p>
    </div>
  </div>
</div>

