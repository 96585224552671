
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vp-task-image-cropper-dialog task__dialog">
    <div class="task__dialog-header">
        <div class="mobile-back-button">
            <button mat-button (click)="hide()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
        <div class="header_lbl disable-select">
        {{'PROFILE_PHOTO' | translate}}
        </div>
        <div class="desktop-close-button">
            <button mat-button (click)="hide()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
    </div>
    <div class="task__dialog-body task__dialog-body--pad">
        <div class="row">
            <div class="user-profile__upload-avatar">
                <div id="cropper-image" class="cropper-image">
                    <img-cropper #cropper [image]="imageData" [settings]="cropperSettings"></img-cropper>
                </div>
                <div class="file-upload">
                    <div class="avatar-option-left-div">
                        <span (click)="removeAvatar()">
                            <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <title>Icon/product/delete-new</title>
                                <g id="Icon/product/delete-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <path d="M18,7 L6,7 L6,18.2857143 C6,19.7746499 7.19131256,21 8.66666667,21 L15.3333333,21 C16.8086874,21 18,19.7746499 18,18.2857143 L18,7 Z M8,9 L16,9 L16,18.2857143 C16,18.6824929 15.6913126,19 15.3333333,19 L8.66666667,19 L8.57834889,18.9935231 C8.25938319,18.9465317 8,18.649428 8,18.2857143 L8,9 Z M13,3 C13.7404864,3 14.3869825,3.40242038 14.7327215,4.00049436 L18,4 C18.5522847,4 19,4.44771525 19,5 C19,5.55228475 18.5522847,6 18,6 L6,6 C5.44771525,6 5,5.55228475 5,5 C5,4.44771525 5.44771525,4 6,4 L9.26760632,3.99992752 C9.61342606,3.40216612 10.2597476,3 11,3 L13,3 Z" id="Combined-Shape" fill="#8B96A0"></path>
                                </g>
                            </svg>
                            <span class="uploadlbl">{{'REMOVE' | translate }}</span>
                        </span>
                    </div>
                    <div class="avatar-option-right-div">
                        <input id="custom-file-input" #cropFileChooser type="file" class="hide" (click)="cropFileChooser.value = null;" (change)="fileChangeListener($event)"
                        accept="image/*">
                        <span (click)="cropFileChooser.click()">
                            <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <title>Icon/product/loop</title>
                                <g id="Icon/product/loop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Icon-24px" transform="translate(4.000000, 1.000000)" fill="#8B96A0">
                                        <path d="M8,3 L8,0 L4,4 L8,8 L8,5 C11.31,5 14,7.69 14,11 C14,12.01 13.75,12.97 13.3,13.8 L14.76,15.26 C15.54,14.03 16,12.57 16,11 C16,6.58 12.42,3 8,3 L8,3 Z M8,17 C4.69,17 2,14.31 2,11 C2,9.99 2.25,9.03 2.7,8.2 L1.24,6.74 C0.46,7.97 0,9.43 0,11 C0,15.42 3.58,19 8,19 L8,22 L12,18 L8,14 L8,17 L8,17 Z" id="Shape"></path>
                                    </g>
                                </g>
                            </svg>
                            <span class="uploadlbl">{{'CHANGE' | translate }}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="task__dialog-footer">
        <div class="desktop-footer">
            <button mat-button (click)="hide()">
                {{ 'CANCEL' | translate }}
            </button>
            <button mat-button [disabled]="!isFileUploded" (click)="saveCropperImge()">
                {{ 'SAVE' | translate }}
            </button>
        </div>
        <div class="mobile-footer" *ngIf="isFileUploded">
            <div class="title">
                {{ 'SAVE_PROFILE_PHOTO' | translate }}
            </div>
            <div class="save_footer_btn">
                <button mat-button (click)="saveCropperImge()">
                    <mat-icon>check</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>
