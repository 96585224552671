
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Input } from "@angular/core";
import { CommonUtil } from "../../../common/utils/common.utils";

@Component({
  selector: "vp-user-profile",
  template: `
    <div id="profileMenuMain" class="profile__cover" [style.backgroundImage]="profileBackground">
      <vp-avatar [avatarId]="user.id" [user]="{ name: user && user.fullname ? user.fullname : '', avatarURL: user.userAvatar }"> </vp-avatar>
      <h4 id="userEmail" class="profile__h4 disable-select">{{user.email}}</h4>
    </div>
  `,
})
export class UserProfileComponent {
  @Input() user: any = {};
  profileBackground = "url(" + CommonUtil.getFullUrl("/assets/img/profile-background.jpg") + ")";
  constructor(
  ) {}
}
