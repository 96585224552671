
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { VNCStandardObj } from "./vnc-standard-obj";

export enum UserType {
  NONE = 1,
  ALL_USERS,
  SELECTED
}

export function getAllUserTypes(): UserType[] {
  return [
    UserType.NONE,
    UserType.ALL_USERS,
    UserType.SELECTED
  ];
}

export function getUserType(type: UserType): VNCStandardObj {
  let slug = "";
  let title = "";
  let key = "";

  switch (type) {
    case UserType.ALL_USERS: {
      slug = "all_users";
      key = "ALL_USERS";
      break;
    }

    case UserType.SELECTED: {
      slug = "selected";
      key = "CONTENT_SELECTED";
      break;
    }

    default: {
      slug = "none";
      key = "NONE";
      break;
    }
  }

  return {
    slug: slug,
    title: title,
    translation_key: key,
  };
}

export function UserTypeFromString(str: string): UserType {
  switch (str) {
    case "all_users":
      return UserType.ALL_USERS;
    case "selected":
      return UserType.SELECTED;
    default:
      return UserType.NONE;
  }
}
