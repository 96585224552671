
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnChanges, Input, Output, EventEmitter, ViewChild, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { MdlPopoverComponent } from "@angular-mdl/popover";
import { BulkUpdateIssueType } from "../../shared/task-enum";
import { TasksRootState, getAuthUser, getProjectsList } from "../../store/index";
import { Store } from "@ngrx/store";
import { ResponsiveService } from "../../../common/providers/responsive.service";
import { Project } from "../../models";
import { takeWhile } from "rxjs/operators";

@Component({
  selector: "vp-vnctask-header-menubar",
  templateUrl: "task-header-menubar.html"
})
export class TaskHeaderMenubarComponent implements OnChanges, OnDestroy {
  @ViewChild("taskContextPopover", { static: false }) contextPopover: MdlPopoverComponent;
  @Input() totalCount;
  @Input() isSearchMode;
  @Input() selectedTasksIds: number[] = [];
  @Input() selectAll: boolean = false;
  @Input() isChangeUserOptionVisible: boolean = true;
  @Output() menuItemClick = new EventEmitter<object>();
  public bulkUpdateIssueType = BulkUpdateIssueType;
  screen: string = this.responsiveService.getScreen();
  isAlive: boolean = true;
  authUser: any = {};
  projectList: Project[] = [];

  constructor(private responsiveService: ResponsiveService,
    private store: Store<TasksRootState>,
    private changerDetectorRef: ChangeDetectorRef
  ) {
    this.store.select(getAuthUser).pipe(takeWhile(() => this.isAlive)).subscribe(user => {
      if (user) {
        this.authUser = user;
        this.changerDetectorRef.markForCheck();
      }
    });
    this.store.select(getProjectsList).pipe(takeWhile(() => this.isAlive)).subscribe( projects => {
      this.projectList = projects;
      this.changerDetectorRef.markForCheck();
    });
   }

  ngOnChanges(changes) { }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.menuItemClick.unsubscribe();
  }

  updateSelectAll () {
    this.selectAll = !this.selectAll;
  }
}
