
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="forgotpassword" [style.backgroundImage]="backgroundImage">
    <div class="forgotpassword-form">
        <div class="title">{{ 'LINK_EXPIRED' | translate }}</div>
        <div style="margin-top:30px;">
            <form [formGroup]="resendLinkForm" (ngSubmit)="onFormSubmit(resendLinkForm.value)">
                <span class="enter-email-msg">
                    {{ 'RESEND_LINK_ENTER_MESSAGE' | translate }}
                </span>
                <mat-form-field>
                    <input type="email" autocomplete="email" formControlName="email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" matInput placeholder="Email" required>
                    <mat-error *ngIf="resendLinkForm.get('email').errors?.pattern">
                        {{ 'EMAIL_INVALID_ERROR' | translate }}
                    </mat-error>
                </mat-form-field>
                <div class="login-button">
                    <button type="submit" [disabled]="!resendLinkForm.valid">{{ 'RESEND' | translate }}</button>
                </div>
            </form>
        </div>
    </div>
    <div class="app_footer-title">
      <span class="disable-select" (click)="returnToLoginPage()">{{ 'RETURN_TO_LOGIN_PAGE' | translate }}</span>
    </div>
</div>
<div class="vnctask-loading" *ngIf="isLoading">
  <div class="indicator">
    <vnc-spinner [size]="'medium'" *ngIf="true"></vnc-spinner>
  </div>
</div>
