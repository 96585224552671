
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, forwardRef, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter } from "@angular/core";
import { TaskOptionComponent } from "../task-option.component";
import { TaskRepository } from "../../../../repository/task.repository";
import { DateFilterType } from "../../../../shared/task-enum";
import { TaskUtils } from "../../../../shared/task-utils";
import { DateType } from "../../../../models";
import { MatDialog } from "@angular/material/dialog";
import { TaskDatePickerComponent } from "../../../task-date-picker/task-date-picker.component";
import { take } from "rxjs";
import { TaskService } from "src/app/task/task.service";

@Component({
  selector: "vp-new-vnctask-option-due-date",
  providers: [{
    provide: TaskOptionComponent,
    // eslint-disable-next-line
    useExisting: forwardRef(() => NewTaskDueDateOptionComponent)
  }],
  templateUrl: "new-option-due-date.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewTaskDueDateOptionComponent extends TaskOptionComponent implements OnInit {
  id: string = "add-due-date";
  triggerKey = "^";
  dateType = DateType;

  activate:boolean = false;
  @Output() selectedDueDate = new EventEmitter();
  @Output() selectActivate = new EventEmitter();
  constructor(
    private taskRepo: TaskRepository,
    public changerDetectorRef: ChangeDetectorRef,
    public matDialog: MatDialog,
    private service: TaskService,

  ) {
    super(changerDetectorRef, matDialog);
    this.service.cancelActivationOfTags.subscribe(res => {
      this.cancelActivateData();
    });
  }

  ngOnInit() {
    let items = this.taskRepo.getDateFilterItems(DateFilterType.DueDate);
    this.setItems(items);
  }

  getPayload() {
    let value = this.getValue();
    if (!value || (value && !value.date)) return { due_date: ""};
    return { due_date: TaskUtils.getFormattedDate(value.date) };
  }


  selectActivateData() {
    this.activate = true;
    this.selectActivate.emit(this.activate);
    this.changerDetectorRef.markForCheck();
  }

  cancelActivateData() {
    this.activate = false;
    this.selectActivate.emit(this.activate);
  }

  selectedDate(item){
      console.log("selected date", item)
      this.selectedDueDate.emit(item);
      this.activate = false;
      this.selectActivate.emit(this.activate);
      this.changerDetectorRef.markForCheck();
  }

  openDatePickerLocal(type) {
    const dlg = this.matDialog.open(TaskDatePickerComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-task-datepicker-dialog",
      data: { header: "", rangeStart: this.rangeStart}
    });
    dlg.afterClosed().pipe(take(1)).subscribe(res => {
      console.log("selected date from datepicker", res);
      if(res) {
        this.selectedDate({date: res, name: 'find'});
      }
    });
  }


}
