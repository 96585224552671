
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { TasksRootState, getAdvanceSearchRequest, getSearchKeyword } from "../store/reducers/index";
import { Observable } from "rxjs";
import { SearchClearAll, SearchAssigneesUpdate, SearchAppUpdate, SearchTimeRangeUpdate, SearchDueDateRangeUpdate, SearchThroughTypeUpdate, SearchAttachmentUpdate, SearchClosedTasksUpdate, SaveSearchesUpdate, SearchProjectsUpdate, SearchTagsUpdate, SearchKeywordUpdate, SearchListsUpdate, SearchLocationsUpdate } from "../store/actions/search";
import { AdvanceSearchRequest, AppType, AdvanceSearchTime, AdvanceSearchDueDateTime, AdvanceSearchThroughType, Project, ICompact, DueDateTimeRangeType, TimeRangeType, AdvanceSearchTag, TagType, AdvanceSearchUser, UserType, AdvanceSearchList, ListType, AdvanceSearchLocation, LocationType } from "../models";
import { MyUser } from "../models/user";
import { SuccessService } from "../../common/providers/success-service";
import { ErrorService } from "../../common/providers/error-service";
import { TaskService } from "../task.service";
import { ErrorType, SuccessType } from "../shared/task-enum";
import { MessageTranslatorService } from "../services/message-translator-service";
import { TasksConstants } from "../shared/task-constacts";
import { map, take } from "rxjs/operators";

@Injectable()
export class SearchRepository {
  private _inSearchMode: boolean;
  constructor(private store: Store<TasksRootState>,
    private taskService: TaskService,
    private errorService: ErrorService,
    private successService: SuccessService,
    private messageTranslatorService: MessageTranslatorService) {
  }

  getSearchKeyword(): Observable<string> {
    return this.store.select(getSearchKeyword);
  }

  saveSearchKeyword(keyword) {
    this.store.dispatch(new SearchKeywordUpdate(keyword));
  }

  clearSearch() {
    this.store.dispatch(new SearchClearAll());
  }

  selectAdvanceSearchRequest(): Observable<AdvanceSearchRequest> {
    return this.store.select(getAdvanceSearchRequest);
  }

  saveAssigneeList(users: AdvanceSearchUser) {
    this.store.dispatch(new SearchAssigneesUpdate(users));
  }

  selectSavedAssignees(): Observable<AdvanceSearchUser> {
    return this.store.select(getAdvanceSearchRequest).pipe(map(req => req.assignees));
  }

  saveProjectList(project: Project[]) {
    this.store.dispatch(new SearchProjectsUpdate(project));
  }

  selectSavedProjects(): Observable<Project[]> {
    return this.store.select(getAdvanceSearchRequest).pipe(map(req => req.projects));
  }

  saveTagList(data: AdvanceSearchTag) {
    this.store.dispatch(new SearchTagsUpdate(data));
  }

  selectSavedTags(): Observable<AdvanceSearchTag> {
    return this.store.select(getAdvanceSearchRequest).pipe(map(req => req.tags));
  }

  saveList(data: AdvanceSearchList) {
    this.store.dispatch(new SearchListsUpdate(data));
  }

  selectSavedLists(): Observable<AdvanceSearchList> {
    return this.store.select(getAdvanceSearchRequest).pipe(map(req => req.lists));
  }

  saveLocation(data: AdvanceSearchLocation) {
    this.store.dispatch(new SearchLocationsUpdate(data));
  }

  selectSavedLocations(): Observable<AdvanceSearchLocation> {
    return this.store.select(getAdvanceSearchRequest).pipe(map(req => req.locations));
  }

  saveAppList(apps: AppType[]) {
    this.store.dispatch(new SearchAppUpdate(apps));
  }

  selectSavedApps(): Observable<AppType[]> {
    return this.store.select(getAdvanceSearchRequest).pipe(map(req => req.app_types));
  }

  saveTimeRange(data: AdvanceSearchTime) {
    this.store.dispatch(new SearchTimeRangeUpdate(data));
  }

  selectTimeRange(): Observable<AdvanceSearchTime> {
    return this.store.select(getAdvanceSearchRequest).pipe(map(req => req.time));
  }

  saveDueDateRange(data: AdvanceSearchDueDateTime) {
    this.store.dispatch(new SearchDueDateRangeUpdate(data));
  }

  selectDueDateRange(): Observable<AdvanceSearchDueDateTime> {
    return this.store.select(getAdvanceSearchRequest).pipe(map(req => req.dueDateTime));
  }

  saveSearchThroughType(data: AdvanceSearchThroughType) {
    this.store.dispatch(new SearchThroughTypeUpdate(data));
  }

  selectSearchThroughType(): Observable<AdvanceSearchThroughType> {
    return this.store.select(getAdvanceSearchRequest).pipe(map(req => req.search_through_type));
  }

  saveAttachment(data: boolean) {
    this.store.dispatch(new SearchAttachmentUpdate(data));
  }

  selectAttachmentSearches(): Observable<boolean> {
    return this.store.select(getAdvanceSearchRequest).pipe(map(req => req.attachments));
  }

  saveClosedTasks(data: boolean) {
    this.store.dispatch(new SearchClosedTasksUpdate(data));
  }

  selectClosedTasks(): Observable<boolean> {
    return this.store.select(getAdvanceSearchRequest).pipe(map(req => req.closed_tasks));
  }


  selectSaveSearches(): Observable<boolean> {
    return this.store.select(getAdvanceSearchRequest).pipe(map(req => req.save_searches));
  }

  saveSearchesUpdate(data: boolean) {
    this.store.dispatch(new SaveSearchesUpdate(data));
  }

  public setSearchMode(value: boolean): void {
    this._inSearchMode = value;
  }

  public isInSearchMode(): boolean {
    return this._inSearchMode;
  }

  public getSearchQuery(keyword: string, advanceSearch: AdvanceSearchRequest) {
    let query: any = {};

    console.log("[SearchRepository][getSearchQuery] keyword & advanceSearch", keyword, advanceSearch);

    if (advanceSearch) {

      // search through
      let key = "fulltext";
      if (!query["f[]"]) query["f[]"] = [];
      query["f[]"].push(key);
      if (!query[`op[${key}][]`]) query[`op[${key}][]`] = [];
      if (advanceSearch.search_through_type.taskname) {
        query[`op[${key}][]`].push("subj");
      }
      if (advanceSearch.search_through_type.description) {
        query[`op[${key}][]`].push("desc");
      }
      if (advanceSearch.search_through_type.comment) {
        query[`op[${key}][]`].push("comm");
      }
      if (advanceSearch.attachments) {
        query[`op[${key}][]`].push("att");
      }
      if (!query[`v[${key}][]`]) query[`v[${key}][]`] = [];
      query[`v[${key}][]`].push(keyword);

      // Assign users
      if (advanceSearch.assignees.type !== UserType.NONE) {
        key = "assigned_to_id";
        if (!query["f[]"]) query["f[]"] = [];
        query["f[]"].push(key);

        if (advanceSearch.assignees.type === UserType.ALL_USERS) {
          query[`op[${key}]`] = "*";
        } else {
          query[`op[${key}]`] = "=";
          if (!query[`v[${key}][]`]) query[`v[${key}][]`] = [];
          advanceSearch.assignees.list.forEach( user => {
            query[`v[${key}][]`].push(user.id);
          });
        }
      }

      // Projects
      key = "project_id";

      if (!query["f[]"]) query["f[]"] = [];
      query["f[]"].push(key);
      query[`op[${key}]`] = "=";
      if (!query[`v[${key}][]`]) query[`v[${key}][]`] = [];

      if (advanceSearch.projects.length === 0) {
        query[`v[${key}][]`].push("mine");
      } else {
        advanceSearch.projects.forEach( project => {
          query[`v[${key}][]`].push(project.id);
        });
      }

      // Tags
      if (advanceSearch.tags.type !== TagType.NONE) {
        key = "tags";
        if (!query["f[]"]) query["f[]"] = [];
        query["f[]"].push(key);

        if (advanceSearch.tags.type === TagType.ALL_TAGS) {
          query[`op[${key}]`] = "*";
        } else {
          query[`op[${key}]`] = "=";
          if (!query[`v[${key}][]`]) query[`v[${key}][]`] = [];
          advanceSearch.tags.list.forEach( tag => {
            query[`v[${key}][]`].push(tag.id);
          });
        }
      }

      // Lists
      if (advanceSearch.lists.type !== ListType.NONE) {
        key = "list";
        if (!query["f[]"]) query["f[]"] = [];
        query["f[]"].push(key);

        if (advanceSearch.lists.type === ListType.ALL_LISTS) {
          query[`op[${key}]`] = "*";
        } else {
          query[`op[${key}]`] = "=";
          if (!query[`v[${key}][]`]) query[`v[${key}][]`] = [];
          advanceSearch.lists.list.forEach( list => {
            query[`v[${key}][]`].push(list.id);
          });
        }
      }

      // Locations
      if (advanceSearch.locations.type !== LocationType.NONE) {
        key = "location";
        if (!query["f[]"]) query["f[]"] = [];
        query["f[]"].push(key);

        if (advanceSearch.locations.type === LocationType.ALL_LOCATIONS) {
          query[`op[${key}]`] = "*";
        } else {
          query[`op[${key}]`] = "=";
          if (!query[`v[${key}][]`]) query[`v[${key}][]`] = [];
          advanceSearch.locations.list.forEach( location => {
            query[`v[${key}][]`].push(location.id);
          });
        }
      }

      // Status
      key = "status_id";
      if (!query["f[]"]) query["f[]"] = [];
      query["f[]"].push(key);
      if (advanceSearch.closed_tasks) {
        query[`op[${key}]`] = "*";
      } else {
        query[`op[${key}]`] = "o";
      }

      // Creation Date
      if (advanceSearch.time.type !== TimeRangeType.NONE) {
        key = "created_on";
        if (!query["f[]"]) query["f[]"] = [];
        query["f[]"].push(key);
        switch (advanceSearch.time.type) {
          case TimeRangeType.LAST_WEEK:
            query[`op[${key}]`] = "lw";
            break;
          case TimeRangeType.LAST_MONTH:
            query[`op[${key}]`] = "lm";
            break;
          case TimeRangeType.CUSTOM:
            query[`op[${key}]`] = "><";
            if (!query[`v[${key}][]`]) query[`v[${key}][]`] = [];
            query[`v[${key}][]`].push(advanceSearch.time.from.date);
            query[`v[${key}][]`].push(advanceSearch.time.to.date);
            break;
        }
      }
      // Due Date
      if (advanceSearch.dueDateTime.type !== DueDateTimeRangeType.NONE) {
        key = "due_date";
        if (!query["f[]"]) query["f[]"] = [];
        query["f[]"].push(key);
        switch (advanceSearch.dueDateTime.type) {
          case DueDateTimeRangeType.LAST_WEEK:
            query[`op[${key}]`] = "lw";
            break;
          case DueDateTimeRangeType.LAST_MONTH:
            query[`op[${key}]`] = "lm";
            break;
          case DueDateTimeRangeType.CUSTOM:
            query[`op[${key}]`] = "><";
            if (!query[`v[${key}][]`]) query[`v[${key}][]`] = [];
            query[`v[${key}][]`].push(advanceSearch.dueDateTime.from.date);
            query[`v[${key}][]`].push(advanceSearch.dueDateTime.to.date);
            break;
        }
      }
    }
    console.log("[SearchRepository][getSearchQuery] query", query);
    return query;
  }

  public generateSaveSearchesQuery(queryName: string, keyword: string, advanceSearch: AdvanceSearchRequest) {
    let query: any = {};
    let subquery = {};
    subquery["name"] = queryName;
    subquery["visibility"] = "0";
    subquery["for_task"] = "1";
    query["query"] = subquery;
    query["default_columns"] = "1";

    if (!query["f"]) query["f"] = [];
    query["f"].push("status_id");
    query["f"].push("author_or_assigned");
    query["f"].push("tracker_id");
    query["f"].push("project_id");
    query["f"].push("fulltext");

    let fulltextOp = [];
    if (advanceSearch.search_through_type.taskname) {
      fulltextOp.push("subj");
    }
    if (advanceSearch.search_through_type.description) {
      fulltextOp.push("desc");
    }
    if (advanceSearch.search_through_type.comment) {
      fulltextOp.push("comm");
    }
    if (advanceSearch.attachments) {
      fulltextOp.push("att");
    }

    if (!query["op"]) query["op"] = {};
    query["op"]["status_id"] = (advanceSearch.closed_tasks) ? "*" : "o";
    query["op"]["author_or_assigned"] = "=";
    query["op"]["fulltext"] = fulltextOp;
    query["op"]["tracker_id"] = "=";
    query["op"]["project_id"] = "=";

    if (!query["v"]) query["v"] = {};
    query["v"]["author_or_assigned"] =  ["me"];
    query["v"]["fulltext"] = [ keyword ];
    query["v"]["tracker_id"] = [ localStorage.getItem("tracker_id") ];


    if ( advanceSearch.assignees.type !== UserType.NONE) {
      query["f"].push("assigned_to_id");
      query["op"]["assigned_to_id"] = (advanceSearch.assignees.type === UserType.ALL_USERS) ? "*" : "=";
      if (advanceSearch.assignees.list.length > 0 ) {
        query["v"]["assigned_to_id"] = [];
        advanceSearch.assignees.list.forEach( user => {
          query["v"]["assigned_to_id"].push(user.id);
        });
      }
    }
    if (advanceSearch.projects.length > 0 ) {
      query["v"]["project_id"] = [];
      advanceSearch.projects.forEach( project => {
        query["v"]["project_id"].push(project.id);
      });
    } else {
      query["v"]["project_id"] = ["mine"];
    }

    if ( advanceSearch.time.type !== TimeRangeType.NONE) {
      query["f"].push("created_on");
      let createOnOp = "lm";
      let createOnValue = [];
      switch (advanceSearch.time.type) {
        case TimeRangeType.LAST_WEEK:
          createOnOp = "lw";
          break;
        case TimeRangeType.LAST_MONTH:
          createOnOp = "lm";
          break;
        case TimeRangeType.CUSTOM:
          createOnOp = "><";
          createOnValue.push(advanceSearch.time.from.date);
          createOnValue.push(advanceSearch.time.to.date);
          break;
      }
      query["op"]["created_on"] = createOnOp;
      if (createOnValue.length > 0) {
        query["v"]["created_on"] = createOnValue;
      }
    }

    if ( advanceSearch.dueDateTime.type !== DueDateTimeRangeType.NONE) {
      query["f"].push("due_date");
      let dueDateOp = "lm";
      let dueDateValue = [];
      switch (advanceSearch.dueDateTime.type) {
        case DueDateTimeRangeType.LAST_WEEK:
          dueDateOp = "lw";
          break;
        case DueDateTimeRangeType.LAST_MONTH:
          dueDateOp = "lm";
          break;
        case DueDateTimeRangeType.CUSTOM:
          dueDateOp = "><";
          dueDateValue.push(advanceSearch.dueDateTime.from.date);
          dueDateValue.push(advanceSearch.dueDateTime.to.date);
          break;
      }
      query["op"]["due_date"] = dueDateOp;
      if (dueDateValue.length > 0) {
        query["v"]["due_date"] = dueDateValue;
      }
    }

    if ( advanceSearch.tags.type !== TagType.NONE) {
      query["f"].push("tags");
      query["op"]["tags"] = (advanceSearch.tags.type === TagType.ALL_TAGS) ? "*" : "=";
      if (advanceSearch.tags.list.length > 0 ) {
        query["v"]["tags"] = [];
        advanceSearch.tags.list.forEach( tag => {
          query["v"]["tags"].push(tag.id);
        });
      }
    }

    if ( advanceSearch.lists.type !== ListType.NONE) {
      query["f"].push("list");
      query["op"]["list"] = (advanceSearch.lists.type === ListType.ALL_LISTS) ? "*" : "=";
      if (advanceSearch.lists.list.length > 0 ) {
        query["v"]["list"] = [];
        advanceSearch.lists.list.forEach( list => {
          query["v"]["list"].push(list.id);
        });
      }
    }

    if ( advanceSearch.locations.type !== LocationType.NONE) {
      query["f"].push("location");
      query["op"]["location"] = (advanceSearch.locations.type === LocationType.ALL_LOCATIONS) ? "*" : "=";
      if (advanceSearch.locations.list.length > 0 ) {
        query["v"]["location"] = [];
        advanceSearch.locations.list.forEach( location => {
          query["v"]["location"].push(location.id);
        });
      }
    }
    return query;
  }

  public saveSearches(queryName: string) {
    let keyword;
    let advanceSearch;
    this.getSearchKeyword().pipe(take(1)).subscribe( value => {
      keyword = value;
    });
    this.selectAdvanceSearchRequest().pipe(take(1)).subscribe( searchRequest => {
      advanceSearch = searchRequest;
    });
    let query = this.generateSaveSearchesQuery(queryName, keyword, advanceSearch);
    this.taskService.saveSearches(query).subscribe( res => {
      this.successService.emit({ id: SuccessType.saveSearchesSuccess, messages: this.messageTranslatorService.getMessage(TasksConstants.SEARCH_SAVED_MSG) });
    }, err => {
      this.errorService.emit({ id: ErrorType.saveSearchesError, messages: err });
    });
  }

  public generateSearchQueryFromFilters(filters: any) {
    let query: any = {};

    console.log("[SearchRepository][generateSearchQueryFromFilters] filters", filters);

    if (filters) {
      if (!query["f[]"]) query["f[]"] = [];

      // search through
      if (filters.fulltext) {
        let key = "fulltext";
        query["f[]"].push(key);
        if (!query[`op[${key}][]`]) query[`op[${key}][]`] = [];
        filters.fulltext.operator.forEach( op => {
          query[`op[${key}][]`].push(op);
        });
        if (!query[`v[${key}][]`]) query[`v[${key}][]`] = [];
        query[`v[${key}][]`].push(filters.fulltext.values[0]);
      }

      // author or assigned
      if ( filters.author_or_assigned) {
        let key = "author_or_assigned";
        if (!query["f[]"]) query["f[]"] = [];
        query["f[]"].push(key);
        query[`op[${key}]`] = filters.author_or_assigned.operator;
        if (!query[`v[${key}][]`]) query[`v[${key}][]`] = [];
        query[`v[${key}][]`].push(filters.author_or_assigned.values[0]);
      }

      // Assign users
      if (filters.assigned_to_id) {
        let key = "assigned_to_id";
        if (!query["f[]"]) query["f[]"] = [];
        query["f[]"].push(key);
        query[`op[${key}]`] = filters.assigned_to_id.operator;
        if (filters.assigned_to_id.operator === "=") {
          if (!query[`v[${key}][]`]) query[`v[${key}][]`] = [];
          filters.assigned_to_id.values.forEach( id => {
            query[`v[${key}][]`].push(id);
          });
        }
      }

      // Projects
      if (filters.project_id) {
        let key = "project_id";
        if (!query["f[]"]) query["f[]"] = [];
        query["f[]"].push(key);
        query[`op[${key}]`] = filters.project_id.operator;
        if (filters.project_id.operator === "=") {
           if (!query[`v[${key}][]`]) query[`v[${key}][]`] = [];
           filters.project_id.values.forEach( id => {
            query[`v[${key}][]`].push(id);
           });
        }
      }

      // Tags
      if (filters.tags) {
        let key = "tags";
        if (!query["f[]"]) query["f[]"] = [];
        query["f[]"].push(key);
        query[`op[${key}]`] = filters.tags.operator;
        if (filters.tags.operator === "=") {
          if (!query[`v[${key}][]`]) query[`v[${key}][]`] = [];
          filters.tags.values.forEach( id => {
            query[`v[${key}][]`].push(id);
          });
        }
      }

      // Lists
      if (filters.list) {
        let key = "list";
        if (!query["f[]"]) query["f[]"] = [];
        query["f[]"].push(key);
        query[`op[${key}]`] = filters.list.operator;
        if (filters.list.operator === "=") {
          if (!query[`v[${key}][]`]) query[`v[${key}][]`] = [];
          filters.list.values.forEach( id => {
            query[`v[${key}][]`].push(id);
          });
        }
      }

      // Status
      if (filters.status_id) {
        let key = "status_id";
        if (!query["f[]"]) query["f[]"] = [];
        query["f[]"].push(key);
        query[`op[${key}]`] = filters.status_id.operator;
      }

      // Creation Date
      if ( filters.created_on) {
        let key = "created_on";
        if (!query["f[]"]) query["f[]"] = [];
        query["f[]"].push(key);
        query[`op[${key}]`] = filters.created_on.operator;
        if (filters.created_on.operator === "><") {
          if (!query[`v[${key}][]`]) query[`v[${key}][]`] = [];
          filters.created_on.values.forEach( value => {
            query[`v[${key}][]`].push(value);
          });
        }
      }

      // Due Date
      if ( filters.due_date) {
        let key = "due_date";
        if (!query["f[]"]) query["f[]"] = [];
        query["f[]"].push(key);
        query[`op[${key}]`] = filters.due_date.operator;
        if (filters.due_date.operator === "><") {
          if (!query[`v[${key}][]`]) query[`v[${key}][]`] = [];
          filters.due_date.values.forEach( value => {
            query[`v[${key}][]`].push(value);
          });
        }
      }
    }
    console.log("[SearchRepository][generateSearchQueryFromFilters] query", query);
    return query;
  }
}
