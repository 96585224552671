
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  AfterViewInit
} from "@angular/core";
import { SearchRepository } from "../../../repository/search.repository";
import { Project, ListType, ICompact, getListType, VNCStandardObj, LocationType, getLocationType } from "../../../models";
import { Store } from "@ngrx/store";
import { TasksRootState, getProjectsList, getFolderList, getLocationList } from "../../../store/index";
import { isNullOrUndefined } from "util";
import { Broadcaster } from "../../../../common/providers/broadcaster.service";
import { take, takeWhile } from "rxjs/operators";

@Component({
  selector: "vp-select-locations",
  templateUrl: "./select-locations-dialog.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectLocationsComponent implements OnInit, AfterViewInit, OnDestroy {
  changed = false;
  isAlive = true;
  allSelected: boolean;
  locationList: ICompact[] = [];
  selectedLocationType: LocationType;
  selectedLocation: ICompact[];
  LocationType = LocationType;
  mdlFixDone = false;
  @Output() closeDialog = new EventEmitter<boolean>();

  constructor(
    private broadcaster: Broadcaster,
    private searchRepo: SearchRepository,
    private store: Store<TasksRootState>,
    private changeDetectionRef: ChangeDetectorRef) {

    this.store.select(getLocationList).pipe(take(1)).subscribe( locations => {
      if (locations) {
        this.locationList = locations;
        this.changeDetectionRef.markForCheck();
      }
    });

    this.searchRepo.selectSavedLocations().pipe(take(1)).subscribe((selectedLocations) => {
      this.selectedLocationType = selectedLocations.type;
      this.selectedLocation = selectedLocations.list;
      this.changeDetectionRef.markForCheck();
    });

    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
      });

    this.broadcaster.on<any>("hideTaskSelectLocationsDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
      });
  }

  ngOnInit() {
  }

  close() {
    this.closeDialog.emit(true);
  }

  ngAfterViewInit() {
    if (!this.mdlFixDone) {
      this.mdlFixDone = true;

      // PIYUSH_COMMENT serious issue with Mdl
      // https://github.com/mseemann/angular2-mdl/issues/145
      // https://github.com/angular/angular/issues/10816
      setTimeout(() => {
        this.changeDetectionRef.markForCheck();
      } , 100);
    }
  }

  checkForUpdate() {
    this.changed = true;
    this.changeDetectionRef.markForCheck();
  }

  enableSubmit() {}

  locationSelected(event , location: ICompact) {
    if (event === true) {
      // Removing if already selected. This is sometimes caused by double click.
      this.selectedLocation = this.selectedLocation.filter(c => c.id !== location.id);
      this.selectedLocation.push(location);
    } else {
      this.selectedLocation = this.selectedLocation.filter(c => c.id !== location.id);
    }

    this.changed = true;
    this.allSelected = false;
    if (this.selectedLocation.length === 0) {
      this.changed = false;
    }
    this.changeDetectionRef.markForCheck();
  }

  typeChanged() {
    this.changed = true;
    this.changeDetectionRef.markForCheck();
  }

  typeSelected() {
    this.changed = false;
    this.changeDetectionRef.markForCheck();
  }

  getLocationTypeObject(range: LocationType): VNCStandardObj {
    return getLocationType(range);
  }

  isLocationSelected(location: ICompact) {
    return !isNullOrUndefined(this.selectedLocation.find((c) => c.id === location.id));
  }

  saveLocation() {
    this.searchRepo.saveLocation(
      {
        type: this.selectedLocationType,
        list: this.selectedLocation
      }
    );
    this.close();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.changeDetectionRef.markForCheck();
  }
}
