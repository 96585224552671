
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Input, Output, EventEmitter, OnDestroy, HostListener, ChangeDetectionStrategy, ChangeDetectorRef, SimpleChanges, OnChanges } from "@angular/core";
import { PagedList, Task } from "../../models";
import { TaskUtils } from "../../shared/task-utils";
import { getSelectedFilterType, TasksRootState, getIsSearchMode } from "../../store/index";
import { Store } from "@ngrx/store";
import { TasksConstants } from "../../shared/task-constacts";
import { CommonUtil } from "../../../common/utils/common.utils";
import { takeWhile } from "rxjs/operators";

@Component({
  selector: "vp-vnctask-list",
  templateUrl: "task-list.html"
})
export class TaskListComponent implements OnDestroy {
  @Input() tasks: Task[] = [];
  @Output() onTaskClick = new EventEmitter<Task>();
  @Output() onSelectTask = new EventEmitter<any>();
  @Output() openAddTaskDialog = new EventEmitter<any>();
  @Output() scrollMore = new EventEmitter<any>();

  isDeleteSelection: boolean = false;
  selectedTasks: number[] = [];
  isDeleteInitialized: boolean = false;
  selectedFilterType: string;
  isAlive = true;
  taskConstants = TasksConstants;
  CommonUtil = CommonUtil;
  isMobileDevice = CommonUtil.isOnMobileDevice();
  isSearchMode: boolean = false;

  constructor(
    private changerDetectorRef: ChangeDetectorRef,
    private store: Store<TasksRootState> ) {
      this.setupStore();
     }

  ngOnDestroy(): void {
    this.onTaskClick.unsubscribe();
    this.onSelectTask.unsubscribe();
    this.openAddTaskDialog.unsubscribe();
    this.isAlive = false;
  }

  scrollMoreContacts(event){
    this.scrollMore.emit(event);
  }

  setupStore() {
    this.store
      .select(getSelectedFilterType)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(value => {
      this.selectedFilterType = value;
      this.changerDetectorRef.markForCheck();
    });

    this.store.select(getIsSearchMode).pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
      this.isSearchMode = value;
      this.changerDetectorRef.markForCheck();
    });
  }

  onTaskClicked($event) {
    this.onTaskClick.emit($event);
  }

  onTaskSelected($event) {
    this.onSelectTask.emit($event);
    $event.task.isChecked = $event.state;
  }

  addTaskClick() {
    this.openAddTaskDialog.emit();
  }

  getPriorityColor(item) {
    return TaskUtils.getPriorityColor(item);
  }
}
