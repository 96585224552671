
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { MessageTranslatorService } from "./../../services/message-translator-service";
import { TimeEntry } from "../../models/time-entry";
import { ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, HostListener, Component, Output, EventEmitter, OnDestroy, Inject } from "@angular/core";
import { TasksConstants } from "../../shared/task-constacts";
import { getSelectedFilterOption, TasksRootState, getTagList, getSelectedFilterType } from "../../store/index";
import { Store } from "@ngrx/store";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { Tag } from "../../models";
import { TaskRepository } from "../../repository/task.repository";
import { TaskService } from "../../task.service";
import { TaskUtils } from "../../shared/task-utils";
import { getOnlineStatus } from "../../../reducers";
import { takeWhile, finalize, take } from "rxjs/operators";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { VncLibraryService } from "vnc-library";

@Component({
  selector: "vp-log-time",
  template: `
  <div class="task__dialog vp-log-time-dialog">
  <div class="task__dialog-header">
    <div class="mobile-back-button">
      <button mat-button (click)="hide()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
    <div class="header_lbl disable-select">
        {{'LOG_TIME' | translate}}
    </div>
    <div class="desktop-close-button">
      <button mat-button (click)="hide()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="task__dialog-body log-time">
    <mdl-list>
      <mdl-list-item>
        <div class="label-div">
          <span class="label-field">{{ 'DATE' | translate }}</span>
          <span class="require-field">*</span>
          <span class="label-field">:</span>
        </div>
        <div class="value-div">
          <input readonly="true" [min]="created_on" [(ngModel)]="logDate" class="spent-date" [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1">
          <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
        </div>
      </mdl-list-item>
      <mdl-list-item>
        <div class="label-div">
          <span class="label-field">{{ 'SPENT_TIME' | translate }}</span>
          <span class="require-field">*</span>
          <span class="label-field">:</span>
        </div>
        <div class="value-div">
          <input class="spent-time-input" [(ngModel)]="spentHours" (keydown)="validateNumber($event, spentHours)" type="text" maxlength="2" inputmode="numeric" pattern="[0-9]*" placeholder="hh">
          <span>:</span>
          <input class="spent-time-input" [(ngModel)]="spentMinutes" (keydown)="validateNumber($event, spentMinutes)" type="text" maxlength="2" inputmode="numeric" pattern="[0-9]*" placeholder="mm">
        </div>
      </mdl-list-item>
      <mdl-list-item>
        <div>
          <span class="label-field">{{ 'DESCRIPTION' | translate }}</span>
          <span class="require-field">*</span>
          <span class="label-field">:</span>
        </div>
      </mdl-list-item>
      <mdl-list-item>
        <mat-form-field hintLabel="{{ 'TIME_LOG_COMMENT_HINT' | translate }}">
          <textarea matInput #input [(ngModel)]="comments"
            matTextareaAutosize
            matAutosizeMinRows="1"
            maxlength="255"
          ></textarea>
          <mat-hint align="end">{{input.value?.length || 0}}/255</mat-hint>
        </mat-form-field>
      </mdl-list-item>
    </mdl-list>
  </div>
  <div class="task__dialog-footer">
      <div class="desktop-footer">
          <button mat-button (click)="hide()">
              {{ 'CANCEL' | translate }}
          </button>
          <button mat-button [disabled]="disableSaveEntries()" (click)="onSaveLogTime()">
              {{ 'SAVE' | translate }}
          </button>
      </div>
      <div class="mobile-footer">
          <div class="title">
              {{ 'LOG_SPENT_TIME' | translate }}
          </div>
          <div class="save_footer_btn">
              <button mat-button [disabled]="disableSaveEntries()" (click)="onSaveLogTime()">
                  <mat-icon>check</mat-icon>
              </button>
          </div>
      </div>
    </div>
  </div>
  <div class="vnctask-loading" *ngIf="isLoading">
    <div class="indicator">
      <vnc-spinner [size]="'medium'" *ngIf="true"></vnc-spinner>
    </div>
  </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class LogTimeComponent implements OnDestroy {
  selectedFilter: string;
  isAlive: boolean = true;
  taskConstants = TasksConstants;
  tagList: Tag[] = [];
  isOnline: boolean = false;
  isLoading: boolean = false;
  logDate: any;
  spentHours: any;
  spentMinutes: any;
  issue_id: number;
  comments: any;
  created_on: Date;
  constructor(
    private store: Store<TasksRootState>,
    private broadcaster: Broadcaster,
    private changerDetectorRef: ChangeDetectorRef,
    private taskRepo: TaskRepository,
    private taskService: TaskService,
    private vncLibaryService: VncLibraryService,
    private messageTranslatorService: MessageTranslatorService,
    private dialogRef: MatDialogRef<LogTimeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.issue_id = this.data.issue_id;
    this.created_on = new Date(this.data.created_on);
    this.created_on.setDate( this.created_on.getDate() - 1 );
    this.logDate = new Date();
    this.spentHours = "00";
    this.spentMinutes = "00";
    this.changerDetectorRef.markForCheck();

    this.setupStore();

    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });

    this.broadcaster.on<any>("hideLogTimeDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });
  }

  setupStore() {
    this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
      this.isOnline = isOnline;
      this.changerDetectorRef.markForCheck();
    });
  }

  @HostListener("document:keydown.esc", ["$event"])
  hide() {
    this.dialogRef.close();
  }

  close(timeEntry?) {
    this.dialogRef.close( { timeEntry: timeEntry });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.changerDetectorRef.markForCheck();
  }

  onSaveLogTime() {
    if (this.isOnline) {
      if (!this.validNumber(this.spentHours) || !this.validNumber(this.spentMinutes)) {
        this.vncLibaryService.openSnackBar(this.messageTranslatorService.getMessage(TasksConstants.SPENT_TIME_INVALID), "","", "", 3000, "bottom", "left").subscribe(res => {
        });
        return false;
      }
      if ( this.spentHours === "00" && this.spentMinutes === "00" ) {
        this.vncLibaryService.openSnackBar(this.messageTranslatorService.getMessage(TasksConstants.SPENT_TIME_SHOULD_BE_GREATER), "","", "", 3000, "bottom", "left").subscribe(res => {
        });
        return false;
      }
      let spentTime = this.spentHours + ":" + this.spentMinutes;
      let body = {
        "time_entry": {
          "hours": spentTime,
          "spent_on": TaskUtils.getFormattedDate(this.logDate),
          "comments": this.comments,
          "issue_id": this.issue_id
        }
      };
      this.isLoading = true;
      this.taskService.createLogTime(body).pipe(take(1), finalize( () => {
        this.isLoading = false;
        this.changerDetectorRef.markForCheck();
      })). subscribe( res => {
        this.vncLibaryService.openSnackBar(this.messageTranslatorService.getMessage(TasksConstants.LOG_TIME_SUCCESS), "","", "", 3000, "bottom", "left").subscribe(res => {
        });
        this.close(res);
      }, err => {
        if ( err && err.includes(TasksConstants.HOURS_IS_INVALID) ) {
          this.vncLibaryService.openSnackBar(this.messageTranslatorService.getMessage(TasksConstants.SPENT_TIME_INVALID), "","", "", 3000, "bottom", "left").subscribe(res => {
          });
        } else {
          this.vncLibaryService.openSnackBar(err, "","", "", 3000, "bottom", "left").subscribe(res => {
          });
        }
      });
    } else {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
    }
  }

  validNumber(ele) {
    return TaskUtils.isNumber(ele);
  }

  validateNumber(event, ele) {
    return TaskUtils.validateNumberOf2Digit(event, ele);
  }

  disableSaveEntries() {
    if ( this.logDate && this.comments && this.spentHours && this.spentMinutes) {
      return false;
    } else {
      return true;
    }
  }
}
