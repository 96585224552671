
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  InjectionToken,
  Inject
} from "@angular/core";
import { MdlDialogReference } from "@angular-mdl/core";
import { Subject } from "rxjs";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { CommonUtil } from "../../../common/utils/common.utils";
import { takeUntil } from "rxjs/operators";

export const APP_DATA = new InjectionToken<any>("appData");

@Component({
  selector: "vp-install-app",
  templateUrl: "./install-app.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstallAppDialogComponent implements AfterViewInit, OnDestroy {
  private viewInitialized = false;

  private isAlive$ = new Subject<boolean>();
  appName: string;
  appLink: string;
  constructor(private broadcaster: Broadcaster,
              private changeDetectionRef: ChangeDetectorRef,
              private dialog: MdlDialogReference,
              @Inject(APP_DATA) private appData: any) {
    this.broadcaster.on<any>("closeAllMdlDialogs")
      .pipe(takeUntil(this.isAlive$))
      .subscribe(res => {
        this.dialog.hide();
      });
    this.broadcaster.on<any>("closeDialog")
      .pipe(takeUntil(this.isAlive$))
      .subscribe(res => {
        this.dialog.hide();
      });

    if (!!this.appData) {
      this.appName = this.appData.appName;
    }
  }

  ngAfterViewInit() {
    if (!this.viewInitialized) {
      this.viewInitialized = true;

      this.onPushBugHack();
    }
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  installApp() {
    this.dialog.hide();
    window.open("itms-apps://itunes.apple.com/app/id1165103905", "_system");
  }

  private onPushBugHack() {
    setTimeout(() => {
      try {
        this.changeDetectionRef.detectChanges();
      } catch (e) {
        console.log("[vp-install-app onPushBugHack()", e);
      }
    }, 100);
  }

  @HostListener("document:keydown.esc")
  cancel(): void {
    this.dialog.hide();
  }

}
