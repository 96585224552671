
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    Output,
    EventEmitter,
    AfterViewInit
} from "@angular/core";
import { SearchRepository } from "../../../repository/search.repository";
import { DueDateTimeRangeType, getDueDateTimeRangeType } from "../../../models";
import { VNCStandardObj } from "../../../models";
import * as moment from "moment";
import { DatePipe } from "@angular/common";
import { Broadcaster } from "../../../../common/providers/broadcaster.service";
import { take, takeWhile } from "rxjs/operators";

@Component({
    selector: "vp-duedate-range",
    templateUrl: "./duedate-range-dialog.html",
    changeDetection: ChangeDetectionStrategy.OnPush

})
export class DueDateRangeComponent implements OnInit, AfterViewInit, OnDestroy {
    changed = false;
    mdlFixDone = false;
    @Output() closeDialog = new EventEmitter<boolean>();
    selectedTimeRange: DueDateTimeRangeType;
    datePipe = new DatePipe("en");
    startDate: string;
    endDate: string;
    isAlive = true;

    DueDateTimeRangeType = DueDateTimeRangeType;

    constructor(private searchRepo: SearchRepository,
        private broadcaster: Broadcaster,
        private changeDetectionRef: ChangeDetectorRef) {

        this.searchRepo.selectDueDateRange().pipe(take(1)).subscribe((data) => {
            this.selectedTimeRange = data.type;
            if (data.from && data.to) {
                this.startDate = data.from.date;
                this.endDate = data.to.date;
            } else {
                this.setupDates();
            }
            this.changeDetectionRef.markForCheck();
        });

        this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
          .subscribe(presence => {
            this.close();
          });

        this.broadcaster.on<any>("hideTaskDuedateDialog").pipe(takeWhile(() => this.isAlive))
          .subscribe(presence => {
            this.close();
          });
    }

    private setupDates() {
        let now = moment();
        this.startDate = now.format("YYYY-MM-DD");
        this.endDate = now.add("day", 1).format("YYYY-MM-DD");
    }

    ngOnInit() {
    }

    close() {
      this.closeDialog.emit(true);
    }

    ngAfterViewInit() {
      if (!this.mdlFixDone) {
        this.mdlFixDone = true;

        // PIYUSH_COMMENT serious issue with Mdl
        // https://github.com/mseemann/angular2-mdl/issues/145
        // https://github.com/angular/angular/issues/10816
        setTimeout(() => {
          this.changeDetectionRef.markForCheck();
        } , 100);
      }
    }

    ngOnDestroy() {
      this.isAlive = false;
      this.changeDetectionRef.markForCheck();
    }

    getDueDateTimeRangeObject(range: DueDateTimeRangeType): VNCStandardObj {
        return getDueDateTimeRangeType(range);
    }

    saveTime() {
        this.searchRepo.saveDueDateRange({
            type: this.selectedTimeRange || DueDateTimeRangeType.LAST_HOUR ,
            from: {
                date: this.datePipe.transform(this.startDate, "yyyy-MM-dd")
            },
            to: {
                date: this.datePipe.transform(this.endDate, "yyyy-MM-dd")
            }
        });
        this.close();
    }

    datesChanged() {
        this.changed = true;
        this.changeDetectionRef.markForCheck();
    }

    typeChanged() {
        this.changed = true;
        this.changeDetectionRef.markForCheck();
    }
}
