
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, HostListener, Component, Output, EventEmitter, OnDestroy } from "@angular/core";
import { TasksConstants } from "../../shared/task-constacts";
import { getSelectedFilterOption, TasksRootState, getTagList, getSelectedFilterType } from "../../store/index";
import { Store } from "@ngrx/store";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { Tag } from "../../models";
import { TaskRepository } from "../../repository/task.repository";
import { takeWhile } from "rxjs/operators";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "vp-tag-option-list",
  template: `
  <div class="task__dialog" id="tagsDialog">
  <div class="task__dialog-header">
    <div class="mobile-back-button">
      <button mat-button (click)="hide()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
    <div class="header_lbl disable-select">
      {{'TAGS' | translate}}
    </div>
    <div class="desktop-close-button">
      <button mat-button (click)="hide()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="task__dialog-body tag-option-list_dialog">
  <mdl-textfield class="filter-search-textfield" id="tagsSearchField" [(ngModel)]="searchText"  [label]="'FIND_THE_TAG' | translate"  floating-label></mdl-textfield>

    <mat-list role="list" id="tagsList">
      <mat-list-item role="listitem" *ngFor="let tag of tagList | vpTaskProjectSearch : searchText; let i = index;"  [class.selected]="i == selectedIndex"  (click)="navigateRoute(tag.name)">
        <div>
          <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>Icon/product/tag-new</title>
              <g id="Icon/product/tag-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <path d="M11,2 C11.5,2 11.9259259,2.14814815 12.2777778,2.44444444 L12.40625,2.5625 L21.40625,11.5625 C21.78125,11.9375 21.96875,12.40625 21.96875,12.96875 C21.96875,13.46875 21.8206019,13.8946759 21.5243056,14.2465278 L21.40625,14.375 L14.375,21.40625 C14,21.78125 13.53125,21.96875 12.96875,21.96875 C12.46875,21.96875 12.0428241,21.8206019 11.6909722,21.5243056 L11.5625,21.40625 L2.5625,12.40625 C2.22916667,12.0729167 2.04398148,11.6655093 2.00694444,11.1840278 L2,11 L2,3.96875 C2,3.40625 2.1875,2.9375 2.5625,2.5625 C2.89583333,2.22916667 3.30324074,2.04398148 3.78472222,2.00694444 L3.96875,2 L11,2 Z M11.013,3.997 L11,4 L4,4 L4,11 L3.997,11.013 L12.954,19.97 L12.96875,19.96875 L12.982,19.97 L19.97,12.982 L19.96875,12.96875 L19.97,12.954 L11.013,3.997 Z M6.4921875,5.015625 C6.9140625,5.015625 7.265625,5.15625 7.546875,5.4375 C7.828125,5.71875 7.96875,6.0703125 7.96875,6.4921875 C7.96875,6.9140625 7.828125,7.265625 7.546875,7.546875 C7.265625,7.828125 6.90625,7.96875 6.46875,7.96875 C6.0625,7.96875 5.71875,7.828125 5.4375,7.546875 C5.15625,7.265625 5.015625,6.9140625 5.015625,6.4921875 C5.015625,6.0703125 5.15625,5.71875 5.4375,5.4375 C5.71875,5.15625 6.0703125,5.015625 6.4921875,5.015625 Z" id="Combined-Shape" fill="#000000"></path>
              </g>
          </svg>
          <span class="list-item">{{ tag.name }}</span>
        </div>
        <div class="rounded-rectangle">
        <span>{{ tag.count }}</span>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
  </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TagOptionListComponent implements OnDestroy {
  selectedFilter: string;
  isAlive: boolean = true;
  taskConstants = TasksConstants;
  tagList: Tag[] = [];
  isOnline: boolean = false;
  searchText: string = "";

  constructor(
    private store: Store<TasksRootState>,
    private broadcaster: Broadcaster,
    private changerDetectorRef: ChangeDetectorRef,
    private taskRepo: TaskRepository,
    private dialogRef: MatDialogRef<TagOptionListComponent>
  ) {

    this.setupStore();

    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });
    this.broadcaster.on<any>("hideTagOptionListDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });
  }

  setupStore() {
    this.store
      .select(getSelectedFilterOption)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(value => {
        this.selectedFilter = value;
        this.changerDetectorRef.markForCheck();
      });

    this.store
      .select(getTagList)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(tags => {
        if (tags) {
          this.tagList = tags;
          this.changerDetectorRef.markForCheck();
        }
      });
  }

  navigateRoute(title: any) {
    this.hide(title);
  }

  isSelectedFilter(title: string): boolean {
    if (title === this.selectedFilter) {
      return true;
    }
    return false;
  }

  @HostListener("document:keydown.esc", ["$event"])
  hide(title?) {
    console.log("TagOptionListComponent, hide: ", title);
    this.dialogRef.close({ type: this.taskConstants.ROUTE_TYPE_TAG, title: title});
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.changerDetectorRef.markForCheck();
  }
}
