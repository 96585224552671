
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, Inject, ViewChild, ElementRef, AfterViewInit, HostListener } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder } from "@angular/forms";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { Subject } from "rxjs";
import { ErrorService } from "../../../common/providers//error-service";
import { SuccessService } from "../../../common/providers//success-service";
import { ErrorType, SuccessType } from "../../shared/task-enum";
import { TasksRootState } from "../../store";
import { Store } from "@ngrx/store";
import { getOnlineStatus } from "../../../reducers";
import { TaskRepository } from "../../repository/task.repository";
import { List } from "../../models";
import { getSelectedFilterOption } from "../../store/selectors/tasks.selectors";
import { Router } from "@angular/router";
import { MessageTranslatorService } from "../../services/message-translator-service";
import { TasksConstants } from "../../shared/task-constacts";
import { takeUntil, takeWhile } from "rxjs/operators";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { VncLibraryService } from "vnc-library";

@Component({
    selector: "vp-create-list-dialog",
    templateUrl: "create-list-dialog.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateListDialogComponent implements OnInit, AfterViewInit, OnDestroy {
    form: UntypedFormGroup;
    name = new UntypedFormControl(null, [Validators.required]);
    private isAlive$ = new Subject<boolean>();
    isAlive: boolean = true;
    isLoading: boolean = false;
    isOnline: boolean = false;
    isCreate: boolean = true;
    selectedList: List;
    oldListName: string;
    selectedFilterOption: string;
    @ViewChild("listname", { static: false }) listName: ElementRef;
    constructor(
      private dialogRef: MatDialogRef<CreateListDialogComponent>,
      private broadcaster: Broadcaster,
      private errorService: ErrorService,
      private successService: SuccessService,
      private vncLibaryService: VncLibraryService,
      private store: Store<TasksRootState>,
      private tasksRepo: TaskRepository,
      private changerDetectorRef: ChangeDetectorRef,
      private fb: UntypedFormBuilder,
      private router: Router,
      private messageTranslatorService: MessageTranslatorService,
      @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
      this.isCreate = this.data.isCreate;
      this.selectedList = this.data.selectedList;
      this.handleCreateListSuccessMessages();
      this.handleListErrorMessages();
      this.handleUpdateListSuccessMessages();
      this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeUntil(this.isAlive$))
        .subscribe(res => {
          this.dialogRef.close();
        });
      this.broadcaster.on<any>("hideCreateListDialogPage").pipe(takeUntil(this.isAlive$))
        .subscribe(res => {
          this.dialogRef.close();
        });

      this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
        this.isOnline = isOnline;
        this.changerDetectorRef.markForCheck();
      });

      this.store
      .select(getSelectedFilterOption)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(value => {
        this.selectedFilterOption = value;
      });
      this.oldListName = (this.selectedList) ? this.selectedList.name : "";

      if (!this.isCreate) {
        this.name.setValue(this.selectedList.name);
      }
    }

    ngOnInit() {
      this.form = this.fb.group({
        "name": this.name
      });
    }

    ngAfterViewInit(): void {
      if (this.listName) {
        setTimeout( () => {
          this.listName.nativeElement.focus();
        }, 200);
      }
    }

    handleListErrorMessages() {
      this.errorService.only(ErrorType.ListError).pipe(takeWhile(() => this.isAlive)).subscribe(error => {
        this.vncLibaryService.openSnackBar(error.messages, "","", "", 3000, "bottom", "left").subscribe(res => {
        });
        this.isLoading = false;
        this.changerDetectorRef.markForCheck();
      });
    }

    handleCreateListSuccessMessages() {
      this.successService.only(SuccessType.ListCreated).pipe(takeWhile(() => this.isAlive)).subscribe(success => {
        this.vncLibaryService.openSnackBar(success.messages, "","", "", 3000, "bottom", "left").subscribe(res => {
        });
        this.isLoading = false;
        this.changerDetectorRef.markForCheck();
        this.tasksRepo.getFolderListWithCounters();
        this.cancel(true);
      });
    }

    handleUpdateListSuccessMessages() {
      this.successService.only(SuccessType.ListUpdated).pipe(takeWhile(() => this.isAlive)).subscribe(success => {
        this.vncLibaryService.openSnackBar(success.messages, "","", "", 3000, "bottom", "left").subscribe(res => {
        });
        this.isLoading = false;
        this.changerDetectorRef.markForCheck();
        this.tasksRepo.getFolderListWithCounters();
        if (this.oldListName === this.selectedFilterOption) {
          this.router.navigate(["/task/list", this.selectedList.name]);
        }
        this.cancel(false);
      });
    }

    ngOnDestroy(): void {
      this.isAlive$.next(false);
      this.isAlive$.unsubscribe();
    }

    @HostListener("document:keydown.esc", ["$event"])
    hide() {
      this.dialogRef.close();
    }

    cancel(value?) {
      this.dialogRef.close(value);
    }

    save() {
      if (this.isOnline) {
        let listName = this.name.value;
        if (listName) {
          if (listName.length > 255 ) {
            this.vncLibaryService.openSnackBar(this.messageTranslatorService.getMessage(TasksConstants.LIST_IS_TOO_LONG), "","", "", 3000, "bottom", "left").subscribe(res => {
            });
          } else {
            this.isLoading = true;
            this.changerDetectorRef.markForCheck();
            this.tasksRepo.createList(listName);
          }
        }
      } else {
        this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
      }
    }

    update() {
      if (this.isOnline) {
        let listName = this.name.value;
        if (listName) {
          if (listName.length > 255 ) {
            this.vncLibaryService.openSnackBar(this.messageTranslatorService.getMessage(TasksConstants.LIST_IS_TOO_LONG), "","", "", 3000, "bottom", "left").subscribe(res => {
            });
          } else {
            this.isLoading = true;
            this.selectedList.name = listName;
            this.changerDetectorRef.markForCheck();
            this.tasksRepo.updateList(this.selectedList);
          }
        }
      } else {
        this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
      }
    }
}
