
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="taskSettingDialog" class="task__dialog vp-task-settings-dialog">
  <div class="task__dialog-header">
    <div class="mobile-back-button">
        <button mat-button (click)="close()">
          <mat-icon class="disable-select">close</mat-icon>
        </button>
      </div>
      <div class="header_lbl disable-select">
        {{'ABOUT' | translate}}
      </div>
  </div>


  <div class="task__dialog-body task__dialog-body--pad">
    <mat-list role="list" class="action-list">
      <mat-list-item role="listitem" id="aboutMenu" (click)="openAboutDialog()">
        <mat-icon>list</mat-icon>
        <span>{{'VERSION_AND_CHANGELOG_LBL' | translate}}</span>
      </mat-list-item>
      <mat-list-item role="listitem" id="helpFaqMenu" (click)="openHelpFaqDialog()">
        <mat-icon>help</mat-icon>
        <span>{{'TASK_HELP_FAQ_TITLE' | translate}}</span>
      </mat-list-item>
      <mat-list-item role="listitem" id="serviceDeskMenu" (click)="openServiceDeskDialog()">
        <mat-icon>headset_mic</mat-icon>
        <span>{{'TASK_SERVICE_DESK_TITLE' | translate}}</span>
      </mat-list-item>
      <mat-list-item role="listitem" id="legalnoticeMenu" (click)="openLegalNoticeDialog()">
        <mat-icon>assignment</mat-icon>
        <span>{{'LEGAL_NOTICE' | translate}}</span>
      </mat-list-item>
    </mat-list>
  </div>
</div>
