
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="task-header-menubar-div" class="task-header-menubar" [class.notask]="totalCount === 0" [class.task-header-menubar-device]="selectedTasksIds.length > 0">
    <div class="icons__holder">
        <div id="task-header-menubar-close" class="clear_icon_device" (click)="menuItemClick.emit({ event:$event, type: bulkUpdateIssueType.ClearAll })">
            <mdl-icon>close</mdl-icon>
        </div>
        <span class="filtersortmenu">
            <vnc-checkbox class="no-rounded" id="task-header-menubar-selectall" (change)="menuItemClick.emit({ event:!selectAll, type: bulkUpdateIssueType.MarkAll });updateSelectAll();" [checked]="selectAll"></vnc-checkbox>
        </span>
    </div>
    <div id="task-header-menubar-selectedcount" class="header-menubar-selected-count disable-select" *ngIf="selectedTasksIds.length > 0">
        {{selectedTasksIds.length}} {{ 'TASK_SELECTED' | translate}}
    </div>
    <div class="icons__holder" *ngIf="selectedTasksIds.length > 0">
        <div matTooltip="{{ 'MARK_AS_COMPLETED' | translate }}" matTooltipPosition="above" id="task-header-menubar-complete" class="icons__holder_icon" (click)="menuItemClick.emit({ event:$event, type: bulkUpdateIssueType.Complete })">
            <svg class="sidebar-item-div" width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>Icon/product/check-circle</title>
                <g id="Icon/product/check-circle" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Icon-24px" transform="translate(2.000000, 2.000000)" fill="#8B96A0">
                        <path d="M10,0 C4.48,0 0,4.48 0,10 C0,15.52 4.48,20 10,20 C15.52,20 20,15.52 20,10 C20,4.48 15.52,0 10,0 L10,0 Z M8,15 L3,10 L4.41,8.59 L8,12.17 L15.59,4.58 L17,6 L8,15 L8,15 Z" id="Shape"></path>
                    </g>
                </g>
            </svg>
        </div>
        <div matTooltip="{{ 'CLEAR_SELECTION' | translate }}" matTooltipPosition="above" id="task-header-menubar-clear" class="icons__holder_icon clear_icon_desktop" (click)="menuItemClick.emit({ event:$event, type: bulkUpdateIssueType.ClearAll })">
            <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>Icon/product/close-new</title>
                <g id="Icon/product/close-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <path d="M18.9497475,5.05025253 C19.3402718,5.44077682 19.3402718,6.0739418 18.9497475,6.46446609 L13.4142136,12 L18.9497475,17.5355339 C19.3402718,17.9260582 19.3402718,18.5592232 18.9497475,18.9497475 C18.5592232,19.3402718 17.9260582,19.3402718 17.5355339,18.9497475 L12,13.4142136 L6.46446609,18.9497475 C6.0739418,19.3402718 5.44077682,19.3402718 5.05025253,18.9497475 C4.65972824,18.5592232 4.65972824,17.9260582 5.05025253,17.5355339 L10.5857864,12 L5.05025253,6.46446609 C4.65972824,6.0739418 4.65972824,5.44077682 5.05025253,5.05025253 C5.44077682,4.65972824 6.0739418,4.65972824 6.46446609,5.05025253 L12,10.5857864 L17.5355339,5.05025253 C17.9260582,4.65972824 18.5592232,4.65972824 18.9497475,5.05025253 Z" id="Path" fill="#8B96A0"></path>
                </g>
            </svg>
        </div>
        <div matTooltip="{{ 'DUPLICATE_TASK' | translate }}" matTooltipPosition="above" *ngIf="screen === 'desktop'" id="task-header-menubar-duplicate" class="icons__holder_icon" (click)="menuItemClick.emit({ event:$event, type: bulkUpdateIssueType.Duplicate })">
            <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>Icon/product/content-copy</title>
                <g id="Icon/product/content-copy" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Icon-24px" transform="translate(3.000000, 2.000000)" fill="#8B96A0">
                        <path d="M16,3 C17.1,3 18,3.9 18,5 L18,5 L18,19 C18,20.1 17.1,21 16,21 L16,21 L5,21 C3.9,21 3,20.1 3,19 L3,19 L3,5 C3,3.9 3.9,3 5,3 L5,3 Z M16,5 L5,5 L5,19 L16,19 L16,5 Z M11.9359236,0 C12.4882083,0 12.9359236,0.44771525 12.9359236,1 C12.9359236,1.51283584 12.5498834,1.93550716 12.0525447,1.99327227 L11.9359236,2 L4.33333333,2 C3.09836327,2 2.08747935,2.95942684 2.00538306,4.17357901 L2,4.33333333 L2,16.0165979 C2,16.5688826 1.55228475,17.0165979 1,17.0165979 C0.487164161,17.0165979 0.0644928393,16.6305577 0.00672773133,16.133219 L0,16.0165979 L0,4.33333333 C0,2.01048865 1.82765421,0.11451548 4.12337942,0.00499702767 L4.33333333,0 L11.9359236,0 Z" id="Combined-Shape"></path>
                    </g>
                </g>
            </svg>
        </div>
        <div matTooltip="{{ 'DELETE_TASKS' | translate }}" matTooltipPosition="above" id="task-header-menubar-delete" class="icons__holder_icon" (click)="menuItemClick.emit({ event:$event, type: bulkUpdateIssueType.Delete })">
            <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>Icon/product/delete-new</title>
                <g id="Icon/product/delete-new" stroke="none" stroke-width="1" fill="#8B96A0" fill-rule="evenodd">
                    <path d="M18,7 L6,7 L6,18.2857143 C6,19.7746499 7.19131256,21 8.66666667,21 L15.3333333,21 C16.8086874,21 18,19.7746499 18,18.2857143 L18,7 Z M8,9 L16,9 L16,18.2857143 C16,18.6824929 15.6913126,19 15.3333333,19 L8.66666667,19 L8.57834889,18.9935231 C8.25938319,18.9465317 8,18.649428 8,18.2857143 L8,9 Z M13,3 C13.7404864,3 14.3869825,3.40242038 14.7327215,4.00049436 L18,4 C18.5522847,4 19,4.44771525 19,5 C19,5.55228475 18.5522847,6 18,6 L6,6 C5.44771525,6 5,5.55228475 5,5 C5,4.44771525 5.44771525,4 6,4 L9.26760632,3.99992752 C9.61342606,3.40216612 10.2597476,3 11,3 L13,3 Z" id="Combined-Shape" fill="#8B96A0"></path>
                </g>
            </svg>
        </div>
        <div id="task-header-menubar-more" class="icons__holder_icon" [matMenuTriggerFor]="taskContextPopover">
            <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>Icon/product/more-vert</title>
                <g id="Icon/product/more-vert" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Icon-24px" transform="translate(10.000000, 4.000000)" fill="#8B96A0">
                        <path d="M2,4 C3.1,4 4,3.1 4,2 C4,0.9 3.1,0 2,0 C0.9,0 0,0.9 0,2 C0,3.1 0.9,4 2,4 L2,4 Z M2,6 C0.9,6 0,6.9 0,8 C0,9.1 0.9,10 2,10 C3.1,10 4,9.1 4,8 C4,6.9 3.1,6 2,6 L2,6 Z M2,12 C0.9,12 0,12.9 0,14 C0,15.1 0.9,16 2,16 C3.1,16 4,15.1 4,14 C4,12.9 3.1,12 2,12 L2,12 Z" id="Shape"></path>
                    </g>
                </g>
            </svg>
        </div>
    </div>
    <mat-menu #taskContextPopover="matMenu" xPosition="before">
        <div class="task-context-menu-popover">


            <mat-list role="list">
                <mat-list-item role="listitem" *ngIf="screen !== 'desktop'" id="task-header-menubar-option-duplicate" (click)="menuItemClick.emit({ event:$event, type: bulkUpdateIssueType.Duplicate })">
                    <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <title>Icon/product/content-copy</title>
                        <g id="Icon/product/content-copy" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Icon-24px" transform="translate(3.000000, 2.000000)" fill="#000000">
                                <path d="M16,3 C17.1,3 18,3.9 18,5 L18,5 L18,19 C18,20.1 17.1,21 16,21 L16,21 L5,21 C3.9,21 3,20.1 3,19 L3,19 L3,5 C3,3.9 3.9,3 5,3 L5,3 Z M16,5 L5,5 L5,19 L16,19 L16,5 Z M11.9359236,0 C12.4882083,0 12.9359236,0.44771525 12.9359236,1 C12.9359236,1.51283584 12.5498834,1.93550716 12.0525447,1.99327227 L11.9359236,2 L4.33333333,2 C3.09836327,2 2.08747935,2.95942684 2.00538306,4.17357901 L2,4.33333333 L2,16.0165979 C2,16.5688826 1.55228475,17.0165979 1,17.0165979 C0.487164161,17.0165979 0.0644928393,16.6305577 0.00672773133,16.133219 L0,16.0165979 L0,4.33333333 C0,2.01048865 1.82765421,0.11451548 4.12337942,0.00499702767 L4.33333333,0 L11.9359236,0 Z" id="Combined-Shape"></path>
                            </g>
                        </g>
                    </svg> {{'DUPLICATE_TASK'| translate}}
                </mat-list-item>
                <mat-list-item role="listitem" id="task-header-menubar-priority" (click)="menuItemClick.emit({ event:$event, type: bulkUpdateIssueType.Priority })">
                    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <title>Icon/product/assignment-late</title>
                        <g id="Icon/product/assignment-late" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Icon-24px" transform="translate(3.000000, 1.000000)" fill="#000000">
                                <path d="M16,2 L11.82,2 C11.4,0.84 10.3,0 9,0 C7.7,0 6.6,0.84 6.18,2 L2,2 C0.9,2 0,2.9 0,4 L0,18 C0,19.1 0.9,20 2,20 L16,20 C17.1,20 18,19.1 18,18 L18,4 C18,2.9 17.1,2 16,2 L16,2 Z M10,17 L8,17 L8,15 L10,15 L10,17 L10,17 Z M10,13 L8,13 L8,7 L10,7 L10,13 L10,13 Z M9,4 C8.45,4 8,3.55 8,3 C8,2.45 8.45,2 9,2 C9.55,2 10,2.45 10,3 C10,3.55 9.55,4 9,4 L9,4 Z" id="Shape"></path>
                            </g>
                        </g>
                    </svg>{{'CHANGE_PRIORITY'| translate}}
                </mat-list-item>
                <mat-list-item role="listitem" id="task-header-menubar-repeat" (click)="menuItemClick.emit({ event:$event, type: bulkUpdateIssueType.Recurring })">
                    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <title>Icon/product/autorenew</title>
                        <g id="Icon/product/autorenew" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Icon-24px" transform="translate(4.000000, 1.480291)" fill="#000000">
                                <path d="M8,6.52075285 C8,7.07246132 8.31387329,7.20583612 8.70591205,6.81379735 L12,3.51970941 L8.70591205,0.22562146 C8.31604759,-0.164243002 8,-0.0376302616 8,0.518665968 L8,2.51970941 C3.58,2.51970941 0,6.09970941 0,10.5197094 C0,12.0897094 0.46,13.5497094 1.24,14.7797094 L2.7,13.3197094 C2.25,12.4897094 2,11.5297094 2,10.5197094 C2,7.20970941 4.69,4.51970941 8,4.51970941 L8,6.52075285 Z M13.3,7.71970941 C13.74,8.55970941 14,9.50970941 14,10.5197094 C14,13.8297094 11.31,16.5197094 8,16.5197094 L8,14.518666 C8,13.9669575 7.68612671,13.8335827 7.29408795,14.2256215 L4,17.5197094 L7.29408795,20.8137974 C7.68395241,21.2036618 8,21.0770491 8,20.5207528 L8,18.5197094 C12.42,18.5197094 16,14.9397094 16,10.5197094 C16,8.94970941 15.54,7.48970941 14.76,6.25970941 L13.3,7.71970941 Z" id="Shape"></path>
                            </g>
                        </g>
                    </svg> {{'CHANGE_REPEAT_PATTERN'| translate}}
                </mat-list-item>
                <mat-list-item role="listitem" id="task-header-menubar-startdate" (click)="menuItemClick.emit({ event:$event, type: bulkUpdateIssueType.StartDate })">
                  <mat-icon>today</mat-icon>{{'CHANGE_START_DATE'| translate}}
                </mat-list-item>
                <mat-list-item role="listitem" id="task-header-menubar-duedate" (click)="menuItemClick.emit({ event:$event, type: bulkUpdateIssueType.DueDate })">
                  <mat-icon>event</mat-icon>{{'CHANGE_DUE_DATE'| translate}}
                </mat-list-item>
                <mat-list-item role="listitem" id="task-header-menubar-assigned" *ngIf="isChangeUserOptionVisible || (authUser ? (authUser.team_user === 'false'): true)" (click)="menuItemClick.emit({ event:$event, type: bulkUpdateIssueType.AssignedTo })">
                  <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <title>Icon/product/user-new</title>
                      <g id="Icon/product/user-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <path d="M6,17.85 L6,19 C6,19.5522847 5.55228475,20 5,20 C4.44771525,20 4,19.5522847 4,19 L4,17.85 L4,17.85 C4,14.8766994 8.5673946,13 12.5,13 C16.3506761,13 20.8099674,14.7993182 20.9940999,17.6656036 L21,17.85 L21,19 C21,19.5522847 20.5522847,20 20,20 C19.4477153,20 19,19.5522847 19,19 L19,17.85 L19,17.85 C19,16.540378 15.79428,15.1542503 12.9670805,15.0119699 L12.7324336,15.0030208 L12.5,15 C9.64886306,15 6.18754404,16.3913943 6.00734383,17.740716 L6,17.85 Z M12.507014,3 C14.9932903,3 17,5.01382241 17,7.5 C17,9.98617759 14.9932903,12 12.507014,12 C10.0193052,12 8,9.98473341 8,7.5 C8,5.01526659 10.0193052,3 12.507014,3 Z M12.507014,5 C11.1229794,5 10,6.12073341 10,7.5 C10,8.87926659 11.1229794,10 12.507014,10 C13.8871336,10 15,8.88318908 15,7.5 C15,6.11681092 13.8871336,5 12.507014,5 Z" id="Shape" fill="#000000"></path>
                      </g>
                  </svg> {{'CHANGE_ASSIGNED_USER'| translate}}
                </mat-list-item>

                <mat-list-item role="listitem"id="task-header-menubar-project" *ngIf="projectList.length > 1" (click)="menuItemClick.emit({ event:$event, type: bulkUpdateIssueType.Project })">
                  <mat-icon>work</mat-icon> {{'CHANGE_ASSIGNED_PROJECT'| translate}}
                </mat-list-item>
                <mat-list-item role="listitem" id="task-header-menubar-list" (click)="menuItemClick.emit({ event:$event, type: bulkUpdateIssueType.List })">
                <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>Icon/product/assignment-24px</title>
                    <g id="Icon/product/assignment-24px" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="Icon-24px" transform="translate(2.000000, 1.000000)" fill="#000000">
                            <path d="M16,2 L11.82,2 C11.4,0.84 10.3,0 9,0 C7.7,0 6.6,0.84 6.18,2 L2,2 C0.9,2 0,2.9 0,4 L0,18 C0,19.1 0.9,20 2,20 L16,20 C17.1,20 18,19.1 18,18 L18,4 C18,2.9 17.1,2 16,2 L16,2 Z M9,2 C9.55,2 10,2.45 10,3 C10,3.55 9.55,4 9,4 C8.45,4 8,3.55 8,3 C8,2.45 8.45,2 9,2 L9,2 Z M11,16 L4,16 L4,14 L11,14 L11,16 L11,16 Z M14,12 L4,12 L4,10 L14,10 L14,12 L14,12 Z M14,8 L4,8 L4,6 L14,6 L14,8 L14,8 Z" id="Shape"></path>
                        </g>
                    </g>
                </svg>{{'CHANGE_LISTS'| translate}}
                </mat-list-item>
                <mat-list-item role="listitem" id="task-header-menubar-tag" (click)="menuItemClick.emit({ event:$event, type: bulkUpdateIssueType.Tags })">
                    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <title>Icon/product/tag-new</title>
                        <g id="Icon/product/tag-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <path d="M11,2 C11.5,2 11.9259259,2.14814815 12.2777778,2.44444444 L12.40625,2.5625 L21.40625,11.5625 C21.78125,11.9375 21.96875,12.40625 21.96875,12.96875 C21.96875,13.46875 21.8206019,13.8946759 21.5243056,14.2465278 L21.40625,14.375 L14.375,21.40625 C14,21.78125 13.53125,21.96875 12.96875,21.96875 C12.46875,21.96875 12.0428241,21.8206019 11.6909722,21.5243056 L11.5625,21.40625 L2.5625,12.40625 C2.22916667,12.0729167 2.04398148,11.6655093 2.00694444,11.1840278 L2,11 L2,3.96875 C2,3.40625 2.1875,2.9375 2.5625,2.5625 C2.89583333,2.22916667 3.30324074,2.04398148 3.78472222,2.00694444 L3.96875,2 L11,2 Z M11.013,3.997 L11,4 L4,4 L4,11 L3.997,11.013 L12.954,19.97 L12.96875,19.96875 L12.982,19.97 L19.97,12.982 L19.96875,12.96875 L19.97,12.954 L11.013,3.997 Z M6.4921875,5.015625 C6.9140625,5.015625 7.265625,5.15625 7.546875,5.4375 C7.828125,5.71875 7.96875,6.0703125 7.96875,6.4921875 C7.96875,6.9140625 7.828125,7.265625 7.546875,7.546875 C7.265625,7.828125 6.90625,7.96875 6.46875,7.96875 C6.0625,7.96875 5.71875,7.828125 5.4375,7.546875 C5.15625,7.265625 5.015625,6.9140625 5.015625,6.4921875 C5.015625,6.0703125 5.15625,5.71875 5.4375,5.4375 C5.71875,5.15625 6.0703125,5.015625 6.4921875,5.015625 Z" id="Combined-Shape" fill="#000000"></path>
                        </g>
                    </svg>{{'CHANGE_TAGS'| translate}}
                </mat-list-item>
                <mat-list-item role="listitem" id="task-header-menubar-location" (click)="menuItemClick.emit({ event:$event, type: bulkUpdateIssueType.Location })">
                 <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                     <title>Icon/product/location-new</title>
                     <g id="Icon/product/location-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                         <g id="Icon-24px" transform="translate(4.000000, 1.000000)" fill="#000000">
                             <path d="M8,0 C12.4228571,0 16,3.443 16,7.7 C16,9.65461538 15.0835503,11.9242604 13.8710168,14.0717708 L13.5334511,14.6540989 C13.476122,14.7505394 13.4182977,14.8466454 13.3600364,14.942376 L13.0056914,15.5119246 C12.9459153,15.6059889 12.8858188,15.6995955 12.8254602,15.7927035 L12.4606281,16.3450387 L12.0923004,16.8834349 L11.7232736,17.4059213 L11.3563441,17.9105274 L10.9943086,18.3952825 L10.6399636,18.8582157 L10.2961056,19.2973566 L8.76209811,21.1029683 C8.40451496,21.5238635 7.7734336,21.575188 7.35253844,21.2176049 C7.31128664,21.1825583 7.2729485,21.1442201 7.23790189,21.1029683 L5.7038944,19.2973566 L5.184,18.6296 L4.82546017,18.1555095 L4.46062813,17.6605826 L4.09230041,17.14679 C4.03077651,17.0596952 3.96922349,16.9718965 3.90769959,16.8834349 L3.53937187,16.3450387 L3.17453983,15.7927035 L2.816,15.2284 C2.69784615,15.0385231 2.5812071,14.8469799 2.46654893,14.6540989 L2.12898316,14.0717708 C0.916449704,11.9242604 0,9.65461538 0,7.7 C0,3.443 3.57714286,0 8,0 Z M8,2 C4.66497074,2 2,4.56503434 2,7.7 C2,8.69322797 2.3458444,9.96053154 3.01409327,11.4203421 C3.64939915,12.8081876 4.54667863,14.3111997 5.63770505,15.8676171 C6.30819597,16.8241143 7.01937804,17.7553435 7.73858591,18.6369503 L8,18.952 L8.26141409,18.6369503 C8.80081999,17.9757452 9.33571138,17.2866275 9.85230757,16.5798717 L10.362295,15.8676171 C11.4533214,14.3111997 12.3506009,12.8081876 12.9859067,11.4203421 C13.6541556,9.96053154 14,8.69322797 14,7.7 C14,4.56503434 11.3350293,2 8,2 Z M8,5.5 C9.38,5.5 10.5,6.62 10.5,8 C10.5,9.38 9.38,10.5 8,10.5 C6.62,10.5 5.5,9.38 5.5,8 C5.5,6.62 6.62,5.5 8,5.5 Z" id="Combined-Shape"></path>
                         </g>
                     </g>
                </svg>{{'CHANGE_LOCATION'| translate}}
                </mat-list-item>
                <mat-list-item role="listitem" id="task-header-menubar-location" *ngIf="isChangeUserOptionVisible" (click)="menuItemClick.emit({ event:$event, type: bulkUpdateIssueType.Watchers })">
                 <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                     <title>Icon/product/visibility</title>
                     <g id="Icon/product/visibility" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                         <g id="Icon-24px" transform="translate(1.000000, 4.500000)" fill="#000000">
                             <path d="M11,0 C16,0 20.27,3.11 22,7.5 C20.27,11.89 16,15 11,15 C6,15 1.73,11.89 0,7.5 C1.73,3.11 6,0 11,0 Z M11,2 C7.3276702,2 4.03570887,4.0392665 2.34847366,7.18104549 L2.185,7.499 L2.207812,7.54698098 C3.80585357,10.7580378 7.05081834,12.8886127 10.7102582,12.9957618 L11,13 C14.7772535,13 18.1520927,10.8425393 19.792188,7.54698098 L19.814,7.499 L19.792188,7.45301902 C18.1941464,4.24196222 14.9491817,2.11138727 11.2897418,2.00423816 L11,2 Z M11,3.5 C13.2085695,3.5 15,5.2914305 15,7.5 C15,9.7085695 13.2085695,11.5 11,11.5 C8.7914305,11.5 7,9.7085695 7,7.5 C7,5.2914305 8.7914305,3.5 11,3.5 Z M11,5.5 C9.896,5.5 9,6.396 9,7.5 C9,8.604 9.896,9.5 11,9.5 C12.104,9.5 13,8.604 13,7.5 C13,6.396 12.104,5.5 11,5.5 Z" id="Shape"></path>
                         </g>
                     </g>
                </svg>{{'ADD_WATCHERS'| translate}}
                </mat-list-item>
            </mat-list>
        </div>
    </mat-menu>
</div>
