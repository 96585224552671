/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, OnChanges, OnInit } from "@angular/core";
import { CommonUtil } from "src/app/common/utils/common.utils";
import { DatabaseService } from "src/app/task/services/database.service";

@Component({
  selector: "vp-avatar",
  templateUrl: "./user-avatar.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAvatarComponent implements OnChanges, OnDestroy, OnInit {
  @Input() type;
  @Input() user;
  @Input() avatarId;
  avatarURL: string;
  firstLastCharacters: string;
  photoLastUpdate: any;
  photoLastUpdateSubscription$: any;
  email: string;
  constructor(
    private changeDetectionRef: ChangeDetectorRef,
    private databaseService: DatabaseService
  ) {
  }

  ngOnInit(): void {
    if (this.user.avatarURL && this.user.avatarURL !== "") {
      if (this.avatarId) {
        if (this.avatarId in this.databaseService.avatar) {
          this.avatarURL = this.databaseService.avatar[this.avatarId];
        }
      }
      this.loadAvatarFromDB();
    }
    if (this.user.name && this.user.name !== "") {
      this.firstLastCharacters = CommonUtil.getAvatarText(this.user.name);
      this.changeDetectionRef.markForCheck();
    }
  }

  getBackgroundColor() {
    let color = "#888888";
    if (!this.avatarURL && this.user && this.user.bgcolor) {
      color = this.user.bgcolor;
    } else if (this.avatarURL) {
      color = "transparent";
    } else if (!this.avatarURL && this.firstLastCharacters) {
      color = CommonUtil.getBgColor(this.firstLastCharacters.substr(0, 1));
    }
    return color;
  }

  ngOnChanges(data) {
    if (data.user.currentValue.avatarURL && data.user.currentValue.avatarURL !== "") {
      this.loadAvatarFromDB();
   } else {
    this.imgLoadOnError("");
   }
   if (data.user.currentValue.name && data.user.currentValue.name !== "") {
    this.firstLastCharacters = CommonUtil.getAvatarText(data.user.currentValue.name);
    this.changeDetectionRef.markForCheck();
   } else {
    this.firstLastCharacters = null;
    this.changeDetectionRef.markForCheck();
   }
  }

  ngOnDestroy() {
    if (this.photoLastUpdateSubscription$) {
      this.photoLastUpdateSubscription$.unsubscribe();
    }
  }

  imgLoadOnError(event) {
    this.avatarURL = null;
    this.changeDetectionRef.markForCheck();
  }

  loadAvatarFromDB() {
    if (!this.avatarId) return
    this.databaseService.getAvatarById(this.avatarId).subscribe(res => {
      if (this.user.avatarURL && !res) {
        CommonUtil.getBase6ImageFromUrl(this.user.avatarURL).subscribe(res => {
          const avatar = {
            id: this.avatarId,
            data: res
          }
          this.databaseService.addAvatar(avatar).subscribe(ava => {
            this.databaseService.getAvatarById(this.avatarId).subscribe(a => {
              this.avatarURL = a.data;
              this.changeDetectionRef.markForCheck();
            })
          })
        })
      }
      if (res) {
        this.avatarURL = res.data;
        this.changeDetectionRef.markForCheck();
      }
    })
  }
}
