
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { EventEmitter, Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { NotificationEvent, TaskNotification, TaskNotificationType } from "./task-notifications.model";
import { MessageTranslatorService } from "../../services";
import { TasksConstants } from "../../shared/task-constacts";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { Store } from "@ngrx/store";
import { TasksRootState } from "../../store";
import { getIsWindowFocused } from "src/app/reducers";
import { ElectronService } from "../../services/electron.service";

@Injectable()
export class TaskNotificationsService {
    isWindowFocused = true;
    id: string;


    constructor(private messageTranslatorService: MessageTranslatorService,
        private broadcaster: Broadcaster,
        private store: Store<TasksRootState>,
        private electronService: ElectronService) {

        this.store.select(getIsWindowFocused).subscribe((focus) => {
            this.isWindowFocused = focus;
        });
    }

    emitter: Subject<NotificationEvent> = new Subject<NotificationEvent>();

    set(notification: TaskNotification, to: boolean): TaskNotification {
        notification.id = Math.random().toString(36).substring(3);
        if (notification.override && notification.override.id) {
            notification.id = notification.override.id;
        }
        notification.click = new EventEmitter<{}>();
        notification.bare = notification.bare || notification.override.from;
        this.emitter.next({ command: "set", notification: notification, add: to });
        return notification;
    }

    getChangeEmitter() {
        return this.emitter;
    }

    // With type method
    create(title: string, content: string, type: string, bare: string, override?: any): TaskNotification {
        return this.set({ title, content, type, bare, override }, true);
    }

    // HTML Notification method
    // example: this.notificationsService.html("Ticket #123456", "Closed", "notify", {bgColor: "#fe5019", timeOut: 500000});
    html(title: string, html: any, type: string, override?: any): TaskNotification {
        return this.set({ title, html, type, override }, true);
    }

    // Remove all notifications method
    remove(id?: string) {
        if (id) {
            this.emitter.next({ command: "clean", id: id });
        } else {
            this.emitter.next({ command: "cleanAll" });
        }
    }

    reminder(title: string, content: string, override?: any, isMultiple?: boolean): void {
        this.setBrowserNotify(
            title,
            content,
            TaskNotificationType.REMINDER,
            override,
            isMultiple
        );
    }

    assigned(title: string, content: string, override?: any, isMultiple?: boolean): void {
        this.setBrowserNotify(
            title,
            content,
            TaskNotificationType.ASSIGNMENT,
            override,
            isMultiple
        );
    }

    updated(title: string, content: string, override?: any, isMultiple?: boolean): void {
        this.setBrowserNotify(
            title,
            content,
            TaskNotificationType.TASK_UPDATE,
            override,
            isMultiple
        );
    }

    watched(title: string, content: string, override?: any, isMultiple?: boolean): void {
        this.setBrowserNotify(
            title,
            content,
            TaskNotificationType.TASK_WATCHER,
            override,
            isMultiple
        );
    }

    notify(title: string, content: string, type: string, override?: any, isMultiple?: boolean, open_in_browser?: string): void {
        override = {...override, isMultiple: isMultiple, open_in_browser: open_in_browser};
        this.set({
            title,
            content,
            type,
            override
        }, true);
    }

    private setBrowserNotify(title: string, content: string, type: string, override?: any, isMultiple?: boolean) {
        override = {...override, isMultiple: isMultiple};
        if (!this.isWindowFocused) {
            if ( override && override.isMultiple) {
                if ( override.tasks && override.tasks.length > 0) {
                    override.tasks.forEach(task => {
                        this.showChromeNotification(task.subject, type, task);
                    });
                }
            } else {
                this.showChromeNotification(title, type, override);
            }
        } else {
            this.set({
                title,
                content,
                type: type,
                override
            }, true);
        }
    }

    private showChromeNotification(subject: string, type: string, override: any) {
        if (document.hasFocus()) {
          return;
        }
    
        if (typeof ChromeNotification !== "undefined") {
          if (ChromeNotification.permission !== "granted") {
            ChromeNotification.requestPermission();
          } else {
            let title = "";
            let content = "";
            if (type === TaskNotificationType.ASSIGNMENT) {
                title = this.messageTranslatorService.getMessage(TasksConstants.NEW_TASK_ASSIGNMENT_TITLE);
                content = this.messageTranslatorService.getMessage(TasksConstants.NEW_TASK_ASSIGNMENT_SUB_TITLE);
            } else if (type === TaskNotificationType.TASK_UPDATE) {
                title = this.messageTranslatorService.getMessage(TasksConstants.TASK_UPDATE_TITLE);
                content = this.messageTranslatorService.getMessage(TasksConstants.TASK_UPDATE_SUB_TITLE);
            } else if (type === TaskNotificationType.TASK_WATCHER) {
                title = this.messageTranslatorService.getMessage(TasksConstants.TASK_WATCHER_TITLE);
                content = this.messageTranslatorService.getMessage(TasksConstants.TASK_WATCHER_SUB_TITLE);
            } else if (type === TaskNotificationType.REMINDER) {
                title = this.messageTranslatorService.getMessage(TasksConstants.REMINDER_TASK_DUE_TODAY);
                content = this.messageTranslatorService.getMessage(TasksConstants.TASK_DUE_TODAY);
            }
            const img = "assets/vnctask.png";
            const notification = new ChromeNotification(title, {
                body: content + "  " + "\"" + subject + "\"",
                icon: img
            });

            notification.onclick = () => {
                if (this.electronService.isElectron) {
                    this.electronService.showApp();
                } else {
                    window.focus();
                }
                this.broadcaster.broadcast("hideReminderDialog");
                this.broadcaster.broadcast("openTaskFromNotification", override);
                notification.close();
              };
            }
        }
    }
}
