
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */


import { Action } from "../actions";
import { AppActionTypes } from "../actions/app";
import { UserProfile } from "../shared/models/user-profile";

export interface AppState {
  loggedInUserLoading: boolean;
  isDeviceReady: boolean;
  isDatabaseReady: boolean;
  isOnline: boolean;
  isLoggedIn: boolean;
  federatedApps: any[];
  isWindowFocused: boolean;
  userProfile: UserProfile;
  activeProfile: string;
  isRightSidebarExpanded: boolean;
}

export const initialState: AppState = {
  loggedInUserLoading: false,
  isDeviceReady: false,
  isDatabaseReady: false,
  isOnline: false,
  isLoggedIn: false,
  federatedApps: [],
  isWindowFocused: true,
  userProfile: null,
  activeProfile: null,
  isRightSidebarExpanded: false,
};

export function appReducer(state: AppState = initialState, action: Action): AppState {
  switch (action.type) {

    case AppActionTypes.LOGIN_REQUEST: {
      return {
        ...state,
        loggedInUserLoading: true
      };
    }

    case AppActionTypes.DEVICE_READY: {
      return {
         ...state,
          isDeviceReady: true
      };
    }

    case AppActionTypes.DATABASE_READY: {
      return {
        ...state,
        isDatabaseReady: true
      };
    }

    case AppActionTypes.SET_ONLINE_STATUS: {
      return {
         ...state,
         isOnline: action.payload
      };
    }

    case AppActionTypes.SET_LOGIN_STATUS: {
      return {
         ...state,
         isLoggedIn: action.payload
      };
    }

    case AppActionTypes.SET_AVAILABLE_APPS: {
      return {
        ...state,
        federatedApps: action.payload
      };
    }

    case AppActionTypes.WINDOW_FOCUS_STATE: {

      if (state.isWindowFocused  ===  action.payload)
      {
        return state;
      }

      return {
        ...state,
        isWindowFocused: action.payload
      };
    }

    case AppActionTypes.SET_USER_PROFILE: {
      const response = action.payload as UserProfile;
      return {
        ...state,
        userProfile: response
      };
    }

    case AppActionTypes.SET_ACTIVE_PROFILE: {
      return {
        ...state,
        activeProfile: action.payload
      };
    }

    case AppActionTypes.SET_RIGHT_SIDEBAR_STATUS: {
      return {
        ...state,
        isRightSidebarExpanded: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

export const _getIsLoggedInUserLoading = (state: AppState) => state.loggedInUserLoading;
export const _IsDeviceReady = (state: AppState) => state.isDeviceReady;
export const _getIsDatabaseReady = (state: AppState) => state.isDatabaseReady;
export const _getOnlineStatus = (state: AppState) => state.isOnline;
export const _getLoginStatus = (state: AppState) => state.isLoggedIn;
export const _getFederatedApps = (state: AppState) => state.federatedApps;
export const _getIsWindowFocused = (state: AppState) => state.isWindowFocused;
export const _getUserProfile = (state: AppState) => state.userProfile;
export const _getActiveProfile = (state: AppState) => state.activeProfile;
export const _getIsRightSidebarExpanded = (state: AppState) => state.isRightSidebarExpanded;