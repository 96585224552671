
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Directive, ElementRef, EventEmitter, OnChanges, SimpleChanges, Output, Input } from "@angular/core";
import { environment } from "../../../environments/environment";

@Directive({
  selector: "[vpSuppressEvents]"
})
export class SuppressEventsDirective implements OnChanges {
  @Input() suppressEvents: string | string[] = null;
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  constructor(public element: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.suppressEvents) {
      if (changes.suppressEvents.firstChange) {
        let el = this.element.nativeElement;
        if (this.suppressEvents === "all" || this.suppressEvents === null) {
          if (environment.isCordova) {
              this.suppressEvents = ["click", "mousedown", "touchdown", "touchmove", "touchstart", "touchend"];
          } else {
              this.suppressEvents = [];
          }
        } else if (typeof this.suppressEvents === "string") {
          this.suppressEvents = [this.suppressEvents];
        } else if (typeof this.suppressEvents === "object" && !Array.isArray(this.suppressEvents)) {
          let r: string[] = [];
          for (let e of (this.suppressEvents as string[])) {
            r.push(e);
          }
          this.suppressEvents = r;
        }
        for (let evName of this.suppressEvents) {
          el.addEventListener(evName, (event) => {
            this.stopBubble(event);
          });
        }

        el.addEventListener("mouseup", (event) => {
          if (environment.isCordova) {
            this.stopBubble(event);
          }
          this.onClick.emit(event);
        });
      }
    }
  }

  stopBubble(event) {
    event.preventDefault();
    event.stopPropagation();
  }
}
