
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

  <div class="task__dialog">
    <div class="task__dialog-header">
        <div class="mobile-back-button">
            <button mat-button (click)="onEsc()">
                <mat-icon class="disable-select">keyboard_arrow_left</mat-icon>
            </button>
        </div>
        <div class="header_lbl disable-select">
          {{ 'AUTHENTICATOR_APP'| translate }}
        </div>
        <div class="desktop-close-button">
            <button mat-button (click)="onEsc()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
    </div>
  </div>
  <div class="task__dialog-body">
    <div class="content">
      <form [formGroup]="updateForm" (submit)="$event.preventDefault();updateForm.valid && submitForm()">
          <div class="tfa__body__div">
            <div>{{ 'AUTHENTICATOR_APP_TITLE'| translate }}</div>
            <div class="content-div">
                <div><span>1.</span><span class="content-span">{{ 'AUTHENTICATOR_APP_SUB_TITLE1'| translate }}</span></div>
                <div><span>2.</span><span class="content-span">{{ 'AUTHENTICATOR_APP_SUB_TITLE2'| translate }}</span></div>
                <div><img [src]=qrCode/></div>
                <div class="barcode_text margin-left-20 margin-bottom-15" (click)="isManualEnabled ? isManualEnabled=false : isManualEnabled=true">{{ 'CANT_BARCODE_TEXT' | translate }}</div>
                <div *ngIf="isManualEnabled" class="margin-left-20 margin-top-15">{{ 'QR_CODE_MANUAL_TEXT' | translate }}</div>
                <div *ngIf="isManualEnabled" class="margin-left-20 margin-top-15"><span>{{ 'ACCOUNT_NAME' | translate }}:</span><span class="detail_value">{{ email }}</span></div>
                <div *ngIf="isManualEnabled" class="margin-left-20"><span>{{ 'SECRET_KEY' | translate }}:</span><span class="detail_value">{{ secret }}</span></div>
                <div *ngIf="isManualEnabled" class="margin-left-20 margin-bottom-15"><span>{{ 'TYPE' | translate }}:</span><span class="detail_value">{{ 'OTP_TYPE_VALUE' | translate }}</span></div>
                <div><span>3.</span><span class="content-span">{{ 'AUTHENTICATOR_APP_SUB_TITLE3'| translate }}</span></div>
                <div><input type="text" maxlength="6" required
                  placeholder="{{ 'ENTER_OTP'|translate }} (*)" name="otpCode" [formControl]="otpCode"
                  id="otpCode"></div>
            </div>
          </div>
      </form>
    </div>
  </div>
  <div class="task__dialog-footer">
      <div class="desktop-footer">
          <button mat-button (click)="onEsc()">
              {{ 'CANCEL' | translate }}
          </button>
          <button mat-button [disabled]="!updateForm.valid" (click)="$event.preventDefault();updateForm.valid && submitForm()">
              {{ 'SAVE' | translate }}
          </button>
      </div>
      <div class="mobile-footer">
          <div class="title">
              {{ 'SAVE' | translate }}
          </div>
          <div class="save_footer_btn">
              <button mat-button (click)="$event.preventDefault();updateForm.valid && submitForm()">
                  <mat-icon>check</mat-icon>
              </button>
          </div>
      </div>
  </div>