
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, OnChanges, OnDestroy, Input, forwardRef, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { TaskOptionComponent } from "../task-option.component";
import { User, Project, AuthUser } from "../../../../models";
import { Store } from "@ngrx/store";
import { TasksRootState, getMemberList, getAuthUser } from "../../../../store/index";
import { MessageTranslatorService } from "../../../../services/message-translator-service";
import { TaskRepository } from "../../../../repository/task.repository";
import { getOnlineStatus } from "../../../../../reducers";
import { UntypedFormControl } from "@angular/forms";
import { CommonUtil } from "../../../../../common/utils/common.utils";
import { TasksConstants } from "../../../../shared/task-constacts";
import { takeWhile } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { VncLibraryService } from "vnc-library";

@Component({
  selector: "vp-vnctask-option-add-user",
  providers: [{
    provide: TaskOptionComponent,
    // eslint-disable-next-line
    useExisting: forwardRef(() => TaskAddUserOptionComponent)
  }],
  templateUrl: "option-add-user.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskAddUserOptionComponent extends TaskOptionComponent implements OnInit, OnChanges, OnDestroy {
  id: string = "add-user";
  triggerKey: string = "+";
  @Input() project: Project;
  isAlive = true;
  isOnline: boolean = false;
  authUser: AuthUser;
  email = new UntypedFormControl("");
  enableExternalUser: boolean = false;

  constructor(
    private store: Store<TasksRootState>,
    private taskRepo: TaskRepository,
    public changerDetectorRef: ChangeDetectorRef,
    private vncLibaryService: VncLibraryService,
    private messageTranslatorService: MessageTranslatorService,
    public matDialog: MatDialog
  ) {
    super(changerDetectorRef, matDialog);

    this.enableExternalUser = false;
    this.changerDetectorRef.markForCheck();
  }

  ngOnInit() {
    this.store.select(getMemberList).pipe(takeWhile(() => this.isAlive)).subscribe( members => {
      if (members) {
        members.forEach( member => (<any>member).name = member ? member.name : "");
        this.setItems(members);
        this.changerDetectorRef.markForCheck();
      }
    });

    this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
      this.isOnline = isOnline;
      this.changerDetectorRef.markForCheck();
    });

    this.store.select(getAuthUser).pipe(takeWhile(() => this.isAlive)).subscribe(user => {
      if (user) {
        this.authUser = user;
        this.changerDetectorRef.markForCheck();
      }
    });
  }

  ngOnChanges(changes) {
    console.log("TaskAddUserOptionComponent ngOnChanges", changes);

    if (changes && changes.project && changes.project.currentValue) {
      if (this.isOnline) {
          this.taskRepo.getMemberList(changes.project.currentValue.id);
      }
      this.changerDetectorRef.markForCheck();
    }
  }

  validate() {
    if (!this.isValid()) {
      this.taskRepo.errorProjectRequired();
    }
  }

  isValid() {
    return this.project && typeof this.project.id === "number";
  }

  getPayload() {
    let value: User = this.getValue();
    if (!value) return { };

    if (this.enableExternalUser) {
      return { invitee_email: value.name };
    } else {
      return { assigned_to_id: value.id, assigned_to_name: value.name };
    }
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  changeValue($event) {
    if ($event.target) {
      this.userSearchFilter($event.target.value);
      this.changerDetectorRef.markForCheck();
    }
  }

  setExternal() {
    if (this.authUser.can_invite_users === "true") {
      this.enableExternalUser = true;
      this.onExternalUserClick({ id: -1, name: this.email.value});
    } else {
      const message = this.messageTranslatorService.getMessage(TasksConstants.EXCEED_LIMIT_FOR_INVITE);
      this.vncLibaryService.openSnackBar(message, "","", "", 3000, "bottom", "left").subscribe(res => {
      });
    }
  }
}
