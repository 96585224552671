
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, ChangeDetectorRef, ChangeDetectionStrategy, HostListener } from "@angular/core";
import { Subject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { MessageTranslatorService } from "../../services/message-translator-service";
import { HeaderService } from "../../services/header.service";
import { AdvanceSearchRequest } from "../../models/advance-search-request";
import { SearchRepository } from "../../repository/search.repository";
import { getAppType, TimeRangeType, getTimeRangeType, getDueDateTimeRangeType, VNCStandardObj, DueDateTimeRangeType, TagType, UserType, ListType, Project, LocationType } from "../../models";
import { dialogType } from "../../models";
import { SelectAppsPopupComponent, TimeRangePopupComponent, DueDatePopupComponent, SelectUsersPopupComponent, SelectProjectsPopupComponent, SelectTagsPopupComponent, SearchThroughPopupComponent, SelectListsPopupComponent, SelectLocationsPopupComponent } from "../advance-search-popups";
import { TaskConfirmDialogComponent } from "../task-dialogs/confirm-dialog.component";
import { CommonUtil } from "../../../common/utils/common.utils";
import { TasksRootState, getAuthUser, getProjectsList } from "../../store/index";
import { Store } from "@ngrx/store";
import { TasksConstants } from "../../shared/task-constacts";
import { takeWhile, takeUntil, take } from "rxjs/operators";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "vp-advanced-task-search",
  templateUrl: "advanced-task-search.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdvancedTaskSearchComponent implements OnInit, AfterViewInit, OnDestroy {
  onDestroy$: Subject<null> = new Subject<null>();
  debouncer: Subject<any> = new Subject();
  private isAlive$ = new Subject<boolean>();
  advanceSearch: AdvanceSearchRequest;
  TimeRangeType = TimeRangeType;
  DueDateTimeRangeType = DueDateTimeRangeType;
  TagType = TagType;
  ListType = ListType;
  UserType = UserType;
  LocationType = LocationType;
  mdlFixDone = false;
  dialogType = dialogType;
  isAlive = true;
  authUser: any = {};
  projectList: Project[] = [];

  constructor(
    private searchRepo: SearchRepository,
    private translate: TranslateService,
    private broadcaster: Broadcaster,
    private headerService: HeaderService,
    private messageTranslatorService: MessageTranslatorService,
    private changerDetectorRef: ChangeDetectorRef,
    private store: Store<TasksRootState>,
    private matDialog: MatDialog,
    private dialogRef: MatDialogRef<AdvancedTaskSearchComponent>,
  ) {
    this.setupStore();
    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
      });
    this.broadcaster.on<any>("hideAdvanceTaskSearchDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
      });
  }

  setupStore() {
    this.searchRepo.selectAdvanceSearchRequest().pipe(takeUntil(this.isAlive$)).subscribe((request) => {
      this.advanceSearch = request;
      setTimeout(() => {
        this.changerDetectorRef.detectChanges();
      }, 0);
    });

    this.store.select(getAuthUser).pipe(takeWhile(() => this.isAlive)).subscribe(user => {
      if (user) {
        this.authUser = user;
        this.changerDetectorRef.markForCheck();
      }
    });

    this.store.select(getProjectsList).pipe(takeWhile(() => this.isAlive)).subscribe( projects => {
      this.projectList = projects;
      this.changerDetectorRef.markForCheck();
    });
  }

  ngOnInit() {
  }

  // [mdl-select] QUICKFIX: the selected value doesn't show
  ngAfterViewInit() {
    if (!this.mdlFixDone) {
      this.mdlBugHack();
    }
  }

  private mdlBugHack() {
    this.mdlFixDone = true;

    // https://github.com/mseemann/angular2-mdl/issues/145
    // https://github.com/angular/angular/issues/10816
    setTimeout(() => {
      this.changerDetectorRef.detectChanges();
    }, 100);
  }

  redirectTo(sub: string) {
    /*let dialogStyles: any = {
      "visibility" : "visible",
      "width": "99%" ,
      "max-width": "450px"
    };

    let config = {
      // Just Default, will be replaced anyways
      component: SelectAppsPopupComponent ,
      providers: [],
      isModal: true,
      styles: dialogStyles,
      animate: false,
      clickOutsideToClose: true
    };*/
    let component;
    if (sub === "apps") {
      component = SelectAppsPopupComponent;
    } else if (sub === "create-date-range") {
      component = TimeRangePopupComponent;
    } else if (sub === "due-date-range") {
      component = DueDatePopupComponent;
    } else if (sub === "users"){
      component = SelectUsersPopupComponent;
    } else if (sub === "projects"){
      component = SelectProjectsPopupComponent;
    } else if (sub === "tags"){
      component = SelectTagsPopupComponent;
    } else if (sub === "lists"){
      component = SelectListsPopupComponent;
    } else if (sub === "locations"){
      component = SelectLocationsPopupComponent;
    } else {
      component = SearchThroughPopupComponent;
    }

    const dlg = this.matDialog.open(component, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "advance-search-option-dialog"
    });
  }

  clear(): void {
    const dlg = this.matDialog.open(TaskConfirmDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-task-confirm-dialog",
      data: { type: this.dialogType.RESET, message: this.messageTranslatorService.getMessage(TasksConstants.RESET_ADVANCE_SEARCH_MESSAGE), header: null }
    });
    dlg.afterClosed().pipe(take(1)).subscribe(res => {
      this.onConfirmDialogClose(res);
    });
  }

  private onPushBugHack() {
    setTimeout(() => {
    this.changerDetectorRef.markForCheck();
    }, 100);
  }

  searchTags() {
    this.debouncer.next(true);
  }

  @HostListener("document:keydown.esc", ["$event"])
  hide() {
    this.close();
  }

  close() {
    this.dialogRef.close();
    // this.onPushBugHack();
  }

  getAppsTitle(): string {
    // We are hard coding 0 as we never allow user to save without selecting atleast 1 app
    let titleString = getAppType(this.advanceSearch.app_types[0]).title;

    for (let i = 1; i < this.advanceSearch.app_types.length; i++) {
      titleString = titleString + ", " + getAppType(this.advanceSearch.app_types[i]).title;
    }
    return titleString + ".";
  }

  getUsersTitle(): string {
    let titleString = this.advanceSearch.assignees.list[0].name;
    for (let i = 1; i < this.advanceSearch.assignees.list.length; i++) {
      if (i > 4) {
        break;
      }
      const name = this.advanceSearch.assignees.list[i].name;
      titleString = titleString + ", " + name;
    }
    if (this.advanceSearch.assignees.list.length > 5) {
      titleString = titleString + this.getAndMoreTitle(this.advanceSearch.assignees.list.length - 5);
    }
    return titleString + ".";
  }

  getProjectsTitle(): string {
    let titleString = this.advanceSearch.projects[0].name;
    for (let i = 1; i < this.advanceSearch.projects.length; i++) {
      if (i > 4) {
        break;
      }
      const name = this.advanceSearch.projects[i].name;
      titleString = titleString + ", " + name;
    }
    if (this.advanceSearch.projects.length > 5) {
      titleString = titleString + this.getAndMoreTitle(this.advanceSearch.projects.length - 5);
    }
    return titleString + ".";
  }

  getTagsTitle(): string {
    let titleString = this.advanceSearch.tags.list[0].name;
    for (let i = 1; i < this.advanceSearch.tags.list.length; i++) {
      if (i > 4) {
        break;
      }
      const name = this.advanceSearch.tags.list[i].name;
      titleString = titleString + ", " + name;
    }
    if (this.advanceSearch.tags.list.length > 5) {
      titleString = titleString + this.getAndMoreTitle(this.advanceSearch.tags.list.length - 5);
    }
    return titleString + ".";
  }

  getListsTitle(): string {
    let titleString = this.advanceSearch.lists.list[0].name;
    for (let i = 1; i < this.advanceSearch.lists.list.length; i++) {
      if (i > 4) {
        break;
      }
      const name = this.advanceSearch.lists.list[i].name;
      titleString = titleString + ", " + name;
    }
    if (this.advanceSearch.lists.list.length > 5) {
      titleString = titleString + this.getAndMoreTitle(this.advanceSearch.lists.list.length - 5);
    }
    return titleString + ".";
  }

  getLocationsTitle(): string {
    let titleString = this.advanceSearch.locations.list[0].name;
    for (let i = 1; i < this.advanceSearch.locations.list.length; i++) {
      if (i > 4) {
        break;
      }
      const name = this.advanceSearch.locations.list[i].name;
      titleString = titleString + ", " + name;
    }
    if (this.advanceSearch.locations.list.length > 5) {
      titleString = titleString + this.getAndMoreTitle(this.advanceSearch.locations.list.length - 5);
    }
    return titleString + ".";
  }

  getAndMoreTitle(length) {
    return " " + this.messageTranslatorService.getMessage(TasksConstants.AND) + length + " " + this.messageTranslatorService.getMessage(TasksConstants.MORE);
  }

  updateSearchOptions() {
    this.saveSearchOptions();
  }

  saveSearchOptions() {
    this.searchRepo.saveAttachment(this.advanceSearch.attachments);
    this.searchRepo.saveClosedTasks(this.advanceSearch.closed_tasks);
    this.searchRepo.saveSearchesUpdate(this.advanceSearch.save_searches);
    this.changerDetectorRef.markForCheck();
  }

  getTimeRangeObject(range: TimeRangeType): VNCStandardObj {
    return getTimeRangeType(range);
  }

  getDueDateTimeRangeObject(range: DueDateTimeRangeType): VNCStandardObj {
    return getDueDateTimeRangeType(range);
  }

  onConfirmDialogClose($event) {
    if ($event) {
      this.searchRepo.clearSearch();
      this.changerDetectorRef.markForCheck();
    }
  }

  ngOnDestroy(): void {
    this.isAlive$.next(false);
    this.isAlive$.complete();
    this.isAlive = false;
    this.changerDetectorRef.markForCheck();
  }
}
