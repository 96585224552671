
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vp-time-range-dialog task__dialog" id="taskTimeRangeDialog">
    <div class="task__dialog-header">
        <div class="mobile-back-button">
            <button mat-button (click)="close()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
        <div class="header_lbl disable-select">
            {{'CREATE_DATE_RANGE' | translate}}
        </div>
        <div class="desktop-close-button">
            <button mat-button (click)="close()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
    </div>
    <div class="task__dialog-body task__dialog-body--pad">

        <mat-list role="list">
            <mat-radio-group (change)="typeChanged()" class="time-range-radio-group" [(ngModel)]="selectedTimeRange">
                <mat-list-item role="listitem">
                    <mat-radio-button class="radio-button-style" id="input_none" [value]="TimeRangeType.NONE">{{ getTimeRangeObject(TimeRangeType.NONE).translation_key | translate }}</mat-radio-button>
                </mat-list-item>
                <mat-list-item role="listitem">
                    <mat-radio-button class="radio-button-style" id="input_last_week" [value]="TimeRangeType.LAST_WEEK">{{ getTimeRangeObject(TimeRangeType.LAST_WEEK).translation_key | translate }}</mat-radio-button>
                </mat-list-item>
                <mat-list-item role="listitem">
                    <mat-radio-button class="radio-button-style" id="input_last_month" [value]="TimeRangeType.LAST_MONTH">{{ getTimeRangeObject(TimeRangeType.LAST_MONTH).translation_key | translate }}</mat-radio-button>
                </mat-list-item>
                <mat-list-item role="listitem">
                    <mat-radio-button class="radio-button-style" id="input_custom" [value]="TimeRangeType.CUSTOM">{{ getTimeRangeObject(TimeRangeType.CUSTOM).translation_key | translate }}</mat-radio-button>
                </mat-list-item>
            </mat-radio-group>
        </mat-list>


        <mat-list role="list" *ngIf="selectedTimeRange === TimeRangeType.CUSTOM">
            <mat-list-item role="listitem">
                <div class="select-date">
                    <div class="date-option">
                        <span>{{ 'DATE_FROM' | translate }}:</span>
                        <input readonly="true" [(ngModel)]="startDate" [max]="endDate" class="" placeholder="Date" [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1" (dateTimeChange)="datesChanged()">
                        <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
                    </div>
                    <div class="date-option">
                        <span>{{ 'DATE_TO' | translate }}:</span>
                        <input readonly="true" [(ngModel)]="endDate" [min]="startDate" class="" placeholder="Date" [owlDateTimeTrigger]="dt2" [owlDateTime]="dt2" (dateTimeChange)="datesChanged()">
                        <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
                    </div>
                </div>
            </mat-list-item>
        </mat-list>
    </div>
    <div class="task__dialog-footer">
        <div class="desktop-footer">
            <button mat-button (click)="close()">
                {{ 'CANCEL' | translate }}
            </button>
            <button mat-button [disabled]="!changed" (click)="saveTime()">
                {{ 'SAVE' | translate }}
            </button>
        </div>
        <div class="mobile-footer" *ngIf="changed">
            <div class="title">
                {{ 'SETTINGS_UPDATED_APPLY_CHANGES' | translate }}
            </div>
            <div class="save_footer_btn">
                <button mat-button (click)="saveTime()">
                    <mat-icon>check</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>
