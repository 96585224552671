
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->


<ng-template #listIcon>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="Icon/product/format-list-bulleted" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Icon-24px" transform="translate(2.500000, 4.500000)" fill="#FFFFFF">
              <path d="M1.5,6 C0.67,6 0,6.67 0,7.5 C0,8.33 0.67,9 1.5,9 C2.33,9 3,8.33 3,7.5 C3,6.67 2.33,6 1.5,6 L1.5,6 Z M1.5,0 C0.67,0 0,0.67 0,1.5 C0,2.33 0.67,3 1.5,3 C2.33,3 3,2.33 3,1.5 C3,0.67 2.33,0 1.5,0 L1.5,0 Z M1.5,12.17 C0.76,12.17 0.17,12.77 0.17,13.5 C0.17,14.23 0.77,14.83 1.5,14.83 C2.23,14.83 2.83,14.23 2.83,13.5 C2.83,12.77 2.24,12.17 1.5,12.17 L1.5,12.17 Z M4.5,14.5 L18.5,14.5 L18.5,12.5 L4.5,12.5 L4.5,14.5 L4.5,14.5 Z M4.5,8.5 L18.5,8.5 L18.5,6.5 L4.5,6.5 L4.5,8.5 L4.5,8.5 Z M4.5,0.5 L4.5,2.5 L18.5,2.5 L18.5,0.5 L4.5,0.5 L4.5,0.5 Z" id="Shape"></path>
          </g>
      </g>
  </svg>
</ng-template>
<!-- <div id="assign_list_option" class="option-icon" *ngIf="onMainView" [class.selected]="value || isVisible" (click)="activateData()">
  <svg width="38px" height="38px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Icon/product/assignment-24px</title>
    <g id="Icon/product/assignment-24px" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Icon-24px" transform="translate(2.000000, 1.000000)" fill="#8B96A0">
            <path d="M16,2 L11.82,2 C11.4,0.84 10.3,0 9,0 C7.7,0 6.6,0.84 6.18,2 L2,2 C0.9,2 0,2.9 0,4 L0,18 C0,19.1 0.9,20 2,20 L16,20 C17.1,20 18,19.1 18,18 L18,4 C18,2.9 17.1,2 16,2 L16,2 Z M9,2 C9.55,2 10,2.45 10,3 C10,3.55 9.55,4 9,4 C8.45,4 8,3.55 8,3 C8,2.45 8.45,2 9,2 L9,2 Z M11,16 L4,16 L4,14 L11,14 L11,16 L11,16 Z M14,12 L4,12 L4,10 L14,10 L14,12 L14,12 Z M14,8 L4,8 L4,6 L14,6 L14,8 L14,8 Z" id="Shape"></path>
        </g>
    </g>
  </svg>
  <p>{{ 'LISTS' | translate }}</p>
</div> -->

<div class="icons-block" *ngIf="!activate">
  <div class="date-icon user-icon" (click)="selectActivateData()">
    <ng-container [ngTemplateOutlet]="listIcon"></ng-container>
  </div>
  <span>{{ 'LISTS' | translate }}</span>
</div>

<div id="assign_list_option_list" class="option-list add-assignee-list-dialog" *ngIf="activate">
  <div class="add-assignee-list-header">
    <span>{{ 'ASSIGN_LIST' | translate }}</span>
    <mdl-icon class="add-list-icon" (click)="openCreateListDialog()">playlist_add</mdl-icon>
  </div>
  <mat-list role="list" class="folder-list" *ngIf="filteredItems && filteredItems.length > 0">
    <mat-list-item role="listitem" *ngFor="let list of filteredItems; let i = index;"
    [class.selected]="i == selectedIndex"
    (click)="selectedData(list)">
      <mat-icon>assignment</mat-icon>
      <span>{{ list.name }}</span>
    </mat-list-item>
  </mat-list>
  <div *ngIf="filteredItems && filteredItems.length === 0" class="no-available-lists">
    <span>{{ 'NO_AVAILABLE_LISTS' | translate }}</span>
  </div>
</div>
