
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, HostListener, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { Broadcaster } from "../../../../common/providers/broadcaster.service";
import { AppConstants } from "../../../../common/utils/app-constants";
import { takeWhile } from "rxjs/operators";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "vp-task-legal-notice-dialog",
  templateUrl: "task-legal-notice-dialog.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TaskLegalNoticeDialogComponent implements OnDestroy{
  isAlive = true;
  localLang = "en";
  constructor(
    private broadcaster: Broadcaster,
    private dialogRef: MatDialogRef<TaskLegalNoticeDialogComponent>,
    private changerDetectorRef: ChangeDetectorRef ) {
    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
      });
    this.broadcaster.on<any>("hideTaskLegalNoticeDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
      });
    this.localLang = localStorage.getItem(AppConstants.TASK_LANGUAGE);
    this.changerDetectorRef.markForCheck();
  }

  @HostListener("document:keydown.esc", ["$event"])
  hide() {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
