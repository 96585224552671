
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, ViewChild, HostListener, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { CropperSettings, ImageCropperComponent } from "ngx-img-cropper";
import { TaskRepository } from "../../repository/task.repository";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { SetIsLoading } from "../../store";
import { Store } from "@ngrx/store";
import { TasksRootState } from "../../store/reducers/index";
import { getOnlineStatus } from "../../../reducers";
import { getAuthUser } from "../../store/selectors";
import { AuthUser } from "../../models";
import { TaskService } from "../../task.service";
import { takeWhile } from "rxjs/operators";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "vp-task-image-cropper-dialog",
    templateUrl: "./task-image-cropper-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class TaskImageCropperDialogComponent implements OnDestroy {
    imageData: any;
    cropperSettings: CropperSettings;
    @ViewChild("cropper", {static: false})
    cropper: ImageCropperComponent;
    isFileUploded = false;
    msgs: any;
    isAlive = true;
    user: AuthUser;
    isOnline = false;
    avatarData: any;

    constructor(
        private changeDetection: ChangeDetectorRef,
        private tasksRepo: TaskRepository,
        private broadcaster: Broadcaster,
        private dialogRef: MatDialogRef<TaskImageCropperDialogComponent>,
        private store: Store<TasksRootState>,
        private taskService: TaskService
    ) {
        this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
            .subscribe(presence => {
                this.hide();
            });
        this.broadcaster.on<any>("hideTaskImageCropperDialog").pipe(takeWhile(() => this.isAlive))
            .subscribe(presence => {
                this.hide();
            });
        this.imageData = {};
        this.cropperSettings = new CropperSettings();
        this.cropperSettings.width = 100;
        this.cropperSettings.height = 100;
        this.cropperSettings.keepAspect = true;
        this.cropperSettings.croppedWidth = 100;
        this.cropperSettings.croppedHeight = 100;
        this.cropperSettings.canvasWidth = 200;
        this.cropperSettings.canvasHeight = 200;
        this.cropperSettings.minWidth = 100;
        this.cropperSettings.minHeight = 100;
        this.cropperSettings.rounded = true;
        this.cropperSettings.minWithRelativeToResolution = true;
        this.cropperSettings.cropperDrawSettings.strokeColor = "rgba(255,255,255,1)";
        this.cropperSettings.cropperDrawSettings.strokeWidth = 2;
        this.cropperSettings.noFileInput = true;
        this.cropperSettings.preserveSize = true;
        this.cropperSettings.compressRatio = 0.3;

        this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
            this.isOnline = isOnline;
            this.changeDetection.markForCheck();
        });

        this.store.select(getAuthUser).pipe(takeWhile(() => this.isAlive)).subscribe(user => {
            if (user) {
              this.user = user;
              if ( this.isOnline) {
                this.avatarData = this.user.userAvatar;
                setTimeout( () => {
                    this.resetCropper();
                    this.loadExistingCropperAvtar(this.tasksRepo.buildAvatarUrl(this.user.jid, new Date().getTime()));
                }, 200);
              }
            }
        });
    }

    loadExistingCropperAvtar(data: any) {
        this.cropper.settings = this.cropperSettings;
        const image: HTMLImageElement = new Image();
        image.src = data;
        image.crossOrigin = "Anonymous";
        image.onload = () => {
          if (this.cropper) {
            this.cropper.setImage(image);
            this.isFileUploded = true;
            document.querySelector("canvas").classList.add("disable-select");
            this.changeDetection.markForCheck();
          }
        };
        this.changeDetection.markForCheck();
    }

    resetCropper() {
        this.cropper.reset();
        this.isFileUploded = false;
        this.imageData = {};
        this.changeDetection.markForCheck();
    }

    @HostListener("document:keydown.esc", ["$event"])
    hide(): void {
        this.isFileUploded = false;
        this.imageData = {};
        this.cropper.reset();
        this.dialogRef.close();
        this.changeDetection.markForCheck();
    }

    fileChangeListener($event) {
        const image: HTMLImageElement = new Image();
        const file: File = $event.target.files[0];
        const myReader: FileReader = new FileReader();
        this.cropper.settings = this.cropperSettings;
        myReader.onloadend = (loadEvent: any) => {
            image.src = loadEvent.target.result;
            this.isFileUploded = true;
            this.changeDetection.markForCheck();
        };
        image.onload = () => {
            if (this.cropper) {
                this.cropper.setImage(image);
                this.changeDetection.markForCheck();
            }
        };
        myReader.readAsDataURL(file);
    }

    saveCropperImge() {
        if (this.isOnline) {
            this.store.dispatch(new SetIsLoading(true));
            this.tasksRepo.updateUserAvatar(this.imageData.image);
            this.hide();
        } else {
            this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
        }
    }

    ngOnDestroy(): void {
      this.isAlive = false;
      this.changeDetection.markForCheck();
    }

    removeAvatar() {
        if (this.isOnline) {
            this.store.dispatch(new SetIsLoading(true));
            this.tasksRepo.removeUserAvatar();
            this.hide();
        } else {
            this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
        }
    }
}
