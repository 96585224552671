
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vp-task-datepicker-dialog">
    <div class="task_setting_dialog-header brand-color mdl-shadow--2dp" layout="row center-center">
      <h4>{{ (header !== "") ? header : 'PICK_DATE' | translate }}</h4>
    </div>
    <div class="task__dialog-body task__dialog-body--pad">
        <owl-date-time-inline [min]="rangeStart" [(ngModel)]="dateTime" [pickerType]="'calendar'"></owl-date-time-inline>
        <div class="date-picker-footer">
          <span (click)="hide()">{{ 'CANCEL' | translate }}</span>
          <span (click)="setDate()">{{ 'OK' | translate }}</span>
        </div>
    </div>
</div>
