
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vp-task-deactivate-confirmation-dialog task__dialog">
    <div class="task__dialog-header">
        <div class="mobile-back-button">
            <button mat-button (click)="close()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
        <div class="header_lbl disable-select">
            {{'CONFIRM_DEACTIVATION' | translate}}
        </div>
        <div class="desktop-close-button">
            <button mat-button (click)="close()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
    </div>
    <div class="task__dialog-body task__dialog-body--pad">
        <form class="confirm-password-form" [formGroup]="confirmDeactivateform">
            <mat-form-field hintLabel="{{ 'PASSWORD_HINT' | translate }}">
                <input type="password" autocomplete="password" formControlName="password" matInput placeholder="{{ 'CURRENT_PASSWORD' | translate }}"
                            required>
            </mat-form-field>
        </form>
    </div>
    <div class="task__dialog-footer">
        <div class="desktop-footer">
            <button mat-button (click)="close()">
                {{ 'CANCEL' | translate }}
            </button>
            <button mat-button [disabled]="!confirmDeactivateform.valid" (click)="onFormSubmit(confirmDeactivateform.value)">
                {{ 'SAVE' | translate }}
            </button>
        </div>
        <div class="mobile-footer" *ngIf="confirmDeactivateform.valid">
            <div class="title">
                {{ 'DEACTIVATE_YOUR_ACCOUNT' | translate }}
            </div>
            <div class="save_footer_btn">
                <button mat-button (click)="onFormSubmit(confirmDeactivateform.value)">
                    <mat-icon>check</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>
<div class="vnctask-loading" *ngIf="isLoading">
  <div class="indicator">
    <vnc-spinner [size]="'medium'" *ngIf="true"></vnc-spinner>
  </div>
</div>
