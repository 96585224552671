
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, ViewChild, ElementRef, AfterViewInit, ChangeDetectionStrategy } from "@angular/core";
import { ConfigService } from "../../../common/providers/config.service";
import { CommonUtil } from "../../../common/utils/common.utils";
import { TaskService } from "../../../task/task.service";

@Component({
  selector: "vp-authentication",
  templateUrl: "./authentication.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AuthenticationComponent {
  backgroundImage = "url(" + CommonUtil.getFullUrl("/assets/img/profile-background.jpg") + " )";
  serverURL = "https://";
  @ViewChild("urlInput", { static: false }) urlInput: ElementRef;
  constructor(private configService: ConfigService, private taskService: TaskService) {
    let serverUrl = localStorage.getItem("serverURL");
    if (serverUrl !== null && serverUrl !== undefined ){
      this.serverURL = serverUrl;
    }
  }

  loginNewServer() {
    this.configService.changeServerUrl(this.serverURL).subscribe( data => {
      if (data) {
        this.configService.API_URL = this.serverURL;
        this.configService.selectedServer = true;
        localStorage.setItem("serverURL", this.serverURL.trim());
        this.configService.clearCache();
        this.taskService.clearHeaders();
        this.redirectToMain();
      } else {
        alert("The Server URL entered by you is wrong. Please provide correct URL");
      }
    }, err => {
      alert("The Server URL entered by you is wrong. Please provide correct URL");
    });
  }

  redirectToMain() {
    let initialHref = window.location.href.split("/www/")[0];
    window.location.href = initialHref + "/www/index.html";
  }

  backToLogin() {
    this.configService.selectedServer = true;
    this.configService.loginIframe();
  }
}
