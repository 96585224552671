
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from ".";
import { VNCContact } from "../shared/models/contact.model";

export class ContactActionsTypes {

  static EMPTY_CONTACTS_SUCCESS = "[Contacts] Empty Contacts";
  static RESTORE_VNC_CONTACTS = "[Contacts] Restore Contacts";
  static BULK_LOAD_VNC_CONTACTS = "[Contacts] Bulk Load Contacts";
  static ADD_VNC_CONTACTS = "[Contacts] Add VNCd Contact";
  static UPDATE_VNC_CONTACTS = "[Contacts] Update VNCd Contact";
  static REMOVE_VNC_CONTACTS = "[Contacts] Remove VNCd Contact";

}

export class RestoreVNCContacts implements Action {
  readonly type = ContactActionsTypes.RESTORE_VNC_CONTACTS;
  constructor(public payload: VNCContact[]) { }
}

export class BulkLoadVNCContacts implements Action {
  readonly type = ContactActionsTypes.BULK_LOAD_VNC_CONTACTS;
  constructor(public payload: VNCContact[]) { }
}

export class RemoveVNCContacts implements Action {
  readonly type = ContactActionsTypes.REMOVE_VNC_CONTACTS;
  constructor(public payload: VNCContact) { }
}

export class AddVNCContacts implements Action {
  readonly type = ContactActionsTypes.ADD_VNC_CONTACTS;
  constructor(public payload: VNCContact) { }
}

export class UpdateVNCContacts implements Action {
  readonly type = ContactActionsTypes.UPDATE_VNC_CONTACTS;
  constructor(public payload: VNCContact) { }
}


export type ContactsAction = UpdateVNCContacts
  | AddVNCContacts
  | RemoveVNCContacts
  | BulkLoadVNCContacts
  | RestoreVNCContacts;
