
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

export enum BulkUpdateIssueType {
    Status,
    AssignedTo,
    InviteTo,
    Priority,
    Project,
    StartDate,
    DueDate,
    Recurring,
    ClearAll,
    MarkAll,
    Delete,
    Complete,
    Duplicate,
    Tags,
    List,
    Location,
    Watchers
}

export enum TaskOperation {
    Assign_to_user,
    Link_to_project,
    Add_comment
}

export enum ErrorType {
  GenericMessage,
  TaskCreateFail,
  TaskUpdateFail,
  UserRegisteredError,
  ResendActivationLinkError,
  ForgotPasswordError,
  ResetPasswordError,
  ChangePasswordError,
  DeleteAccountError,
  ListError,
  TeamUserListError,
  ArchiveUserError,
  UnArchiveUserError,
  InviteTeamUsersError,
  saveSearchesError,
  SaveSettingError,
  LocationError
}

export enum SuccessType {
  GenericMessage,
  TaskCreated,
  TasksRemoved,
  TaskDuplicated,
  TaskUpdated,
  TaskBulkUpdated,
  UserAvatarUpdated,
  SearchSaved,
  TaskBulkStatusUpdated,
  TaskCompleted,
  TaskRemoved,
  UserRegistered,
  ResendActivationLinkSuccess,
  ForgotPasswordSuccess,
  ResetPasswordSuccess,
  ChangePasswordSuccess,
  DeleteAccountSuccess,
  ListCreated,
  ListUpdated,
  ListDeleted,
  TeamUserListSuccess,
  ArchiveUserSuccess,
  UnArchiveUserSuccess,
  InviteTeamUsersSuccess,
  saveSearchesSuccess,
  SaveSettingSuccess,
  LocationCreated,
  LocationUpdated,
  LocationDeleted,
  TaskBulkWatcherUpdated
}

export enum MultiTaskRequestType {
  Duplicate
}

export enum DateFilterType {
  StartDate,
  DueDate
}
