
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { VNCStandardObj } from "./vnc-standard-obj";

export enum TimeRangeType {
  LAST_HOUR = 1,
  LAST_24_HOURS,
  LAST_WEEK,
  LAST_MONTH,
  LAST_YEAR,
  CUSTOM,
  NONE
}

export function getAllTimeRangeTypes(): TimeRangeType[] {
  return [
    TimeRangeType.LAST_HOUR,
    TimeRangeType.LAST_24_HOURS,
    TimeRangeType.LAST_WEEK,
    TimeRangeType.LAST_MONTH,
    TimeRangeType.LAST_YEAR,
    TimeRangeType.CUSTOM,
    TimeRangeType.NONE
  ];
}

export function getTimeRangeType(type: TimeRangeType): VNCStandardObj {
  let slug = "";
  let title = "";
  let key = "";

  switch (type) {
    case TimeRangeType.LAST_HOUR: {
      slug = "last_hour";
      key = "TIME_LAST_HOUR";
      break;
    }

    case TimeRangeType.LAST_24_HOURS: {
      slug = "last_24_hours";
      key = "TIME_LAST_24_HOURS";
      break;
    }

    case TimeRangeType.LAST_WEEK: {
      slug = "last_week";
      key = "TIME_LAST_WEEK";
      break;
    }

    case TimeRangeType.LAST_MONTH: {
      slug = "last_month";
      key = "TIME_LAST_MONTH";
      break;
    }

    case TimeRangeType.NONE: {
      slug = "none";
      key = "NONE";
      break;
    }

    default: {
      slug = "custom";
      key = "TIME_CUSTOM";
      break;
    }
  }

  return {
    slug: slug,
    title: title,
    translation_key: key,
  };
}

export function timeRangeTypeFromString(str: string): TimeRangeType {
  switch (str) {
    case "last_hour":
      return TimeRangeType.LAST_HOUR;
    case "last_24_hours":
      return TimeRangeType.LAST_24_HOURS;
    case "last_week":
      return TimeRangeType.LAST_WEEK;
    case "last_month":
      return TimeRangeType.LAST_MONTH;
    case "none":
      return TimeRangeType.NONE;
    default:
      return TimeRangeType.CUSTOM;
  }
}
