
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { Task, TaskComment, AuthUser } from "../../models";
import { Store } from "@ngrx/store";
import { getSelectedTasksIds } from "../../store";
import { TasksRootState } from "../../store/reducers";
import { getTasks } from "../../store/selectors";
import { takeWhile, take } from "rxjs/operators";

@Component({
  selector: "vp-vnctask-multiple-select",
  templateUrl: "task-mutiple-select.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskMultipleSelectionComponent implements OnDestroy {
  selectedTasks: Task[] = [];
  isAlive = true;
  viewAll: boolean = false;

  constructor(private store: Store<TasksRootState>,
    private changerDetectorRef: ChangeDetectorRef ) {
    this.store.select(getSelectedTasksIds).pipe(takeWhile(() => this.isAlive)).subscribe( value => {
      let selectedTasksIds = value;
      if ( selectedTasksIds.length > 0) {
        this.store.select(getTasks).pipe(take(1)).subscribe(tasks => {
          if (tasks.length > 0) {
            this.selectedTasks = tasks.filter( task => selectedTasksIds.includes(task.id));
            this.changerDetectorRef.markForCheck();
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.isAlive = false;
    this.changerDetectorRef.markForCheck();
  }
}
