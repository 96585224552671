
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, forwardRef, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ElementRef, EventEmitter, Output } from "@angular/core";
import { TaskOptionComponent } from "../task-option.component";
import { Project, Tag, ICompact } from "../../../../models";
import { Store } from "@ngrx/store";
import { TasksRootState, getFolderList } from "../../../../store/index";
import { Subject } from "rxjs";
import { TaskRepository } from "../../../../repository/task.repository";
import { MessageTranslatorService } from "../../../../services";
import { TasksConstants } from "../../../../shared/task-constacts";
import { debounceTime } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { VncLibraryService } from "vnc-library";
import { TaskService } from "src/app/task/task.service";

@Component({
  selector: "vp-new-vnctask-option-add-tags",
  providers: [{
    provide: TaskOptionComponent,
    // eslint-disable-next-line
    useExisting: forwardRef(() => NewTaskAddTagsOptionComponent)
  }],
  templateUrl: "new-option-add-tags.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewTaskAddTagsOptionComponent extends TaskOptionComponent implements OnInit, OnDestroy {
  id: string = "add-tags";
  triggerKey = "#";
  isAlive = true;
  searchTagsList: any[] = [];
  debouncer: Subject<any> = new Subject();
  tagText: string;
  tagsList: ICompact[] = [];
  @ViewChild("tagInput", { static: false }) tagInput: ElementRef;
  activate: boolean = false;
  constructor(private store: Store<TasksRootState>,
    public changerDetectorRef: ChangeDetectorRef,
    private taskRepository: TaskRepository,
    private vncLibaryService: VncLibraryService,
    private messageTranslatorService: MessageTranslatorService,
    public matDialog: MatDialog,
    private service: TaskService,
  ) {
    super(changerDetectorRef, matDialog);
    this.service.cancelActivationOfTags.subscribe(res => {
      this.cancelActivateData();
    });
  }

  ngOnInit() {
    this.debouncer.pipe(debounceTime(500)).subscribe(value => {
      if ( !this.tagText || ( this.tagText && this.tagText.trim() === "")) {
        this.searchTagsList = [];
        this.changerDetectorRef.markForCheck();
        return false;
      }
      let searchtext = this.tagText.substr(this.tagText.lastIndexOf(",") + 1);
      this.taskRepository.getSearchTags(searchtext).subscribe(tags => {
        this.searchTagsList = tags;
        this.changerDetectorRef.markForCheck();
      });
    });
  }

  getPayload() {
    let value = this.getTagValue();

    console.log("[TaskAddTagsOptionComponent][getPayload] value", value);
    console.log("[TaskAddTagsOptionComponent][getPayload] this.tagsList", this.tagsList);

    if (!value) return { };
    return { tags: value };
  }

  displayValue(selectedTagName) {
    console.log("[TaskAddTagsOptionComponent][displayValue] selectedTagName", selectedTagName);

    // add tag to added list
    if (selectedTagName && (this.searchTagsList.length >= 1)) {
      let isDuplicate = false;
      this.tagsList.forEach(t => {
        if (t.name.toLowerCase() === selectedTagName.toLowerCase()) {
          const message = this.messageTranslatorService.getMessage(TasksConstants.DUPLICATE_TAG_NAME);
          this.vncLibaryService.openSnackBar(message, "","", "", 3000, "bottom", "left").subscribe(res => {
          });
          isDuplicate = true;
          return false;
        }
      });

      if (!isDuplicate) {
        for (let sTag of this.searchTagsList) {
          if (sTag.name.toLowerCase()  === selectedTagName.trim().toLowerCase()) {
            // reuse existing tag id
            this.tagsList.push({ "id": sTag.id, "name": selectedTagName.trim() });
            break;
          }
        }
      }
      return this.tagText.substr(0, this.tagText.lastIndexOf(",") + 1);
    }
    return selectedTagName;
  }

  addTag(event) {
    console.log("[TaskAddTagsOptionComponent][addTag] event", event);

    this.tagText = this.tagInput.nativeElement.value;
    this.searchTagsList = [];
    if (this.tagText.trim().length >= 2) {
      let separatedTagsValues = this.tagText.split(",");
      let isDuplicate = false;
      let failInlength = false;
      let failInSpace = false;
      let failedTags = [];

      separatedTagsValues.forEach(tag => {
        if (tag.length < 2 || tag.length > 30) {
          failedTags.push(tag);
          failInlength = true;
        } else if (tag.trim().indexOf(" ") >= 0 ) {
          failedTags.push(tag);
          failInSpace = true;
        } else {
          let isTagAlreadyExist = false;
          this.tagsList.forEach(t => {
            if (t.name.toLowerCase() === tag.trim().toLowerCase()) {
              failedTags.push(tag.trim());
              isDuplicate = true;
              isTagAlreadyExist = true;
            }
          });
          if (!isTagAlreadyExist) {
            this.tagsList.push({ "id": Tag.tempId(), "name": tag.trim() });
          }
        }
      });

      // validate
      if (failInlength) {
        const message = this.messageTranslatorService.getMessage(TasksConstants.TAG_MIN_LENGTH);
        this.vncLibaryService.openSnackBar(message, "","", "", 3000, "bottom", "left").subscribe(res => {
        });
        this.tagInput.nativeElement.value = failedTags.toString();
        this.tagText = this.tagInput.nativeElement.value;
        this.changerDetectorRef.markForCheck();
      } else if (failInSpace) {
        const message = this.messageTranslatorService.getMessage(TasksConstants.TAG_SPACE_NOT_ALLOWED);
        this.vncLibaryService.openSnackBar(message, "","", "", 3000, "bottom", "left").subscribe(res => {
        });
        this.tagInput.nativeElement.value = failedTags.toString();
        this.changerDetectorRef.markForCheck();
      } else if (isDuplicate) {
        const message = this.messageTranslatorService.getMessage(TasksConstants.DUPLICATE_TAG_NAME);
        this.vncLibaryService.openSnackBar(message, "","", "", 3000, "bottom", "left").subscribe(res => {
        });
        this.tagInput.nativeElement.value = failedTags.toString();
        this.changerDetectorRef.markForCheck();
      } else {
        console.log("[TaskAddTagsOptionComponent][addTag] success");

        this.tagText = "";
        this.tagInput.nativeElement.value = "";
        this.changerDetectorRef.markForCheck();
      }
    }
  }

  removeTag(tagNameOrObj) {
    console.log("[TaskAddTagsOptionComponent][removeTag] tagNameOrObj", tagNameOrObj);
    console.log("[TaskAddTagsOptionComponent][removeTag] tagsList before", this.tagsList);

    let tagName;
    if (typeof tagNameOrObj === "string") {
      tagName = tagNameOrObj;
    } else {
      tagName = tagNameOrObj.name;
    }

    this.tagsList = this.tagsList.filter(item => item.name !== tagName);

    console.log("[TaskAddTagsOptionComponent][removeTag] tagsList after", this.tagsList);

    this.onRemoveTag(tagName);
  }

  searchTags(event) {
    this.debouncer.next(true);
  }

  setTags() {
    console.log("[TaskAddTagsOptionComponent][setTags] setTags", this.tagsList);

    this.onTagClick(this.tagsList);
    this.resetTagList();
  }

  resetTagList() {
    console.log("[TaskAddTagsOptionComponent][resetTagList]");

    this.tagsList = [];
    this.tagText = "";
    this.tagInput.nativeElement.value = "";
    this.changerDetectorRef.markForCheck();
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  backToMain() {
    this.resetTagList();
    this.goBack();
  }

  @Output() selectActivate = new EventEmitter();
  @Output() selectedTags = new EventEmitter();

  selectActivateData() {
    this.activate = true;
    this.selectActivate.emit(this.activate);
    this.changerDetectorRef.markForCheck();
  }

  cancelActivateData() {
    this.activate = false;
    this.selectActivate.emit(this.activate);
  }


  selectedData(){
    this.selectedTags.emit(this.tagsList);
    this.tagsList = [];
    this.activate = false;
    this.selectActivate.emit(this.activate);
    this.changerDetectorRef.markForCheck();
  }
}
