
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="assign_watcher_option" class="option-icon" *ngIf="onMainView" [class.selected]="value || isVisible" (click)="onOpen.emit(id)">
  <svg width="38px" height="38px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Icon/product/visibility</title>
    <g id="Icon/product/visibility" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Icon-24px" transform="translate(1.000000, 4.500000)" fill="#8B96A0">
            <path d="M11,0 C16,0 20.27,3.11 22,7.5 C20.27,11.89 16,15 11,15 C6,15 1.73,11.89 0,7.5 C1.73,3.11 6,0 11,0 Z M11,2 C7.3276702,2 4.03570887,4.0392665 2.34847366,7.18104549 L2.185,7.499 L2.207812,7.54698098 C3.80585357,10.7580378 7.05081834,12.8886127 10.7102582,12.9957618 L11,13 C14.7772535,13 18.1520927,10.8425393 19.792188,7.54698098 L19.814,7.499 L19.792188,7.45301902 C18.1941464,4.24196222 14.9491817,2.11138727 11.2897418,2.00423816 L11,2 Z M11,3.5 C13.2085695,3.5 15,5.2914305 15,7.5 C15,9.7085695 13.2085695,11.5 11,11.5 C8.7914305,11.5 7,9.7085695 7,7.5 C7,5.2914305 8.7914305,3.5 11,3.5 Z M11,5.5 C9.896,5.5 9,6.396 9,7.5 C9,8.604 9.896,9.5 11,9.5 C12.104,9.5 13,8.604 13,7.5 C13,6.396 12.104,5.5 11,5.5 Z" id="Shape"></path>
        </g>
    </g>
  </svg>
  <p>{{ 'WATCHERS' | translate }}</p>
</div>

<div id="assign-watchers-option-list" class="option-list add-watchers-list-dialog" *ngIf="active">
  <div class="add-watchers-list-header">
    <span>{{ 'WATCHERS' | translate }}:</span>
    <div class="icons-div">
      <mdl-icon *ngIf="isUpdated" class="icon-close" (click)="backToMain()">clear</mdl-icon>
      <mdl-icon *ngIf="isUpdated" class="icon-check" (click)="setWatchers()">check_circle</mdl-icon>
    </div>
  </div>
  <div class="selected-list-wrapper" *ngIf="selectedMemberList.length > 0">
    <mat-list role="list" class="selectedMemberList">
      <mat-list-item role="listitem" *ngFor="let member of selectedMemberList">
        <div class="selected-watcher-list">
          <span class="member-avatar">
            <vp-avatar [avatarId]="member.id" [user]="{ name: member && member.name ? member.name : '', avatarURL: member.avatar }"> </vp-avatar>
            <span class="selected-member-close-icon" (click)="removeSelectedMember(member)">
              <mdl-icon>close</mdl-icon>
            </span>
          </span>
          <div class="selected-member-name" [innerHTML]="member.name"></div>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
  <mat-list role="list">
    <mat-list-item class="user-list-item" *ngFor="let member of filteredItems; let i = index;"
    [class.selected]="i == selectedIndex"
    (click)="addMemberToList(member)">
      <vp-avatar [avatarId]="member.id" [user]="{ name: member && member.name ? member.name : '', avatarURL: member.avatar }"> </vp-avatar>
      <span class="username-place">{{(member.name !== "") ? member.name : ('NO_NAME' | translate)  }}</span>
    </mat-list-item>
  </mat-list>
</div>
