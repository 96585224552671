
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { EventEmitter } from "@angular/core";

export interface TaskNotification {
    id?: string;
    type: string;
    bare?: string;
    title?: string;
    content?: string;
    override?: any;
    html?: any;
    state?: string;
    createdOn?: Date;
    destroyedOn?: Date;
    animate?: string;
    timeOut?: number;
    maxLength?: number;
    pauseOnHover?: boolean;
    clickToClose?: boolean;
    theClass?: string;
    click?: EventEmitter<{}>;
}

export interface NotificationType {
    name: string;
    color: string;
}

export interface NotificationEvent {
    add?: boolean;
    command: string;
    id?: string;
    notification?: TaskNotification;
}

export interface Options {
    timeOut?: number;
    showProgressBar?: boolean;
    pauseOnHover?: boolean;
    lastOnBottom?: boolean;
    clickToClose?: boolean;
    maxLength?: number;
    maxStack?: number;
    preventDuplicates?: number;
    preventLastDuplicates?: boolean | string;
    theClass?: string;
    rtl?: boolean;
    icons?: string;
    position?: ["top" | "bottom", "right" | "left"];
}

export enum TaskNotificationType {
    REMINDER = "reminder",
    ASSIGNMENT = "assignment",
    TASK_UPDATE = "task_update",
    MENTIONING = "mentioning",
    OVERDUE_TASKS = "overdue_tasks",
    ASSIGNMENT_REMOVED = "assignment_removed",
    DELETION = "deletion",
    TODAY_DUE_TASKS = "due_tasks",
    TASK_WATCHER = "watcher_added"
}