
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, HostListener, OnDestroy, ChangeDetectionStrategy, EventEmitter, Output, ChangeDetectorRef } from "@angular/core";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { AudioService } from "../../services/audio.service";
import { Subject } from "rxjs";
import { FileUpload } from "../../models";
import { environment } from "../../../../environments/environment";
import { takeWhile, takeUntil } from "rxjs/operators";
import { MatDialogRef } from "@angular/material/dialog";


@Component({
  selector: "vp-task-record-voice-msg-dialog",
  templateUrl: "task-record-voice-msg-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TaskRecordVoiceMsgDialogComponent implements OnDestroy {
  isRecording = false;
  private isAlive$ = new Subject<boolean>();
  recordedTime;
  isPauseRecording = false;
  isAlive = true;

  constructor(private broadcaster: Broadcaster,
    private audioService: AudioService,
    private changeDetectionRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<TaskRecordVoiceMsgDialogComponent>,
  ) {
    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });
    this.broadcaster.on<any>("hideTaskRecordVoiceMsgDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });
    this.setupStore();
  }

  @HostListener("document:keydown.esc", ["$event"])
  hide() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.abortRecording();
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
    this.isAlive = false;
    this.changeDetectionRef.markForCheck();
  }

  startRecording() {
    if (!this.isRecording) {
      this.isRecording = true;
      this.checkAudioPermission();
      this.audioService.startRecording();
      this.changeDetectionRef.markForCheck();
    }
  }


  abortRecording() {
    if (this.isRecording) {
      this.isRecording = false;
      this.isPauseRecording = false;
      this.audioService.abortRecording();
      this.changeDetectionRef.markForCheck();
    }

  }

  sendRecording() {
    if (this.isRecording) {
      this.audioService.stopRecording();
      this.isRecording = false;
      this.changeDetectionRef.markForCheck();
    }
  }

  pauseRecording() {
    if (this.isRecording) {
      this.isPauseRecording =  true;
      this.audioService.pauseRecording();
      this.changeDetectionRef.markForCheck();
    }
  }

  resumeRecording() {
    if (this.isRecording) {
      this.isPauseRecording =  false;
      this.audioService.resumeRecording();
      this.changeDetectionRef.markForCheck();
    }
  }

  setupStore() {
    this.audioService.getRecordedAudio().pipe(takeUntil(this.isAlive$)).subscribe(res => {
      console.log("[Audio] Recorded Audio", res);
      let upload: FileUpload = {
        content: res.content,
        filename: res.title,
        content_type: res.type
      };
      this.broadcaster.broadcast("OnAddAttachItem", { data: upload });
      this.hide();
      this.changeDetectionRef.markForCheck();
    });

    this.audioService.getRecordedTime().pipe(takeUntil(this.isAlive$)).subscribe(res => {
      console.log("[Audio] Recorded Time" , res);
      this.recordedTime = res;
      this.changeDetectionRef.markForCheck();
    });

    this.audioService.isRecordingFailed().pipe(takeUntil(this.isAlive$)).subscribe(res => {
      console.log("[Audio] Audio Recording Failed" , res);
      this.isRecording = false;
      this.changeDetectionRef.markForCheck();
    });
  }

  checkAudioPermission() {
    if (environment.isCordova) {
      if (device.platform === "Android") {
        let permissions = cordova.plugins.permissions;
        let checkAudioPermissionCallback = function (status) {
          if (!status.hasPermission) {
            let errorCallback = function () {
              console.log("[task-record-voice.component] Audio permission is not turned on");
            };
            permissions.requestPermission(
              permissions.RECORD_AUDIO,
              function (s) {
                if (!s.hasPermission) {
                  errorCallback();
                }
              },
              errorCallback);
          }
        };
        permissions.checkPermission(permissions.RECORD_AUDIO, checkAudioPermissionCallback, null);
      }
    }
  }
}
