
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  AfterViewInit
} from "@angular/core";
import { SearchRepository } from "../../../repository/search.repository";
import { ICompact, TagType, VNCStandardObj, getTagType } from "../../../models";
import { TaskRepository } from "../../../repository/task.repository";
import { isNullOrUndefined } from "util";
import { Broadcaster } from "../../../../common/providers/broadcaster.service";
import { takeWhile, take } from "rxjs/operators";
import { TasksRootState, getTagList } from "src/app/task/store";
import { Store } from "@ngrx/store";

@Component({
  selector: "vp-select-tags",
  templateUrl: "./select-tags-dialog.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectTagsComponent implements OnInit, AfterViewInit, OnDestroy {
  changed = false;
  isAlive = true;
  allSelected: boolean;
  tagList: ICompact[] = [];
  selectedTagType: TagType;
  selectedTagList: ICompact[];
  TagType = TagType;
  mdlFixDone = false;
  @Output() closeDialog = new EventEmitter<boolean>();

  constructor(
    private broadcaster: Broadcaster,
    private searchRepo: SearchRepository,
    private tasksRepo: TaskRepository,
    private store: Store<TasksRootState>,
    private changeDetectionRef: ChangeDetectorRef) {

    this.store
      .select(getTagList)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(tags => {
        if (tags) {
          this.tagList = tags;
          this.changeDetectionRef.markForCheck();
        }
    });

    this.searchRepo.selectSavedTags().pipe(take(1)).subscribe((selectedTags) => {
      this.selectedTagType = selectedTags.type;
      this.selectedTagList = selectedTags.list;
      this.changeDetectionRef.markForCheck();
    });

    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
     });

    this.broadcaster.on<any>("hideTaskSelectTagsDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
     });
  }

  ngOnInit() {
  }

  close() {
    this.closeDialog.emit(true);
  }

  ngAfterViewInit() {
    if (!this.mdlFixDone) {
      this.mdlFixDone = true;

      // PIYUSH_COMMENT serious issue with Mdl
      // https://github.com/mseemann/angular2-mdl/issues/145
      // https://github.com/angular/angular/issues/10816
      setTimeout(() => {
        this.changeDetectionRef.markForCheck();
      } , 100);
    }
  }

  checkForUpdate() {
    this.changed = true;
    this.changeDetectionRef.markForCheck();
  }

  enableSubmit() {}

  tagSelected(event , tag: ICompact) {
    if (event === true) {
      // Removing if already selected. This is sometimes caused by double click.
      this.selectedTagList = this.selectedTagList.filter(c => c.id !== tag.id);
      this.selectedTagList.push(tag);
    } else {
      this.selectedTagList = this.selectedTagList.filter(c => c.id !== tag.id);
    }

    this.changed = true;
    this.allSelected = false;
    if (this.selectedTagList.length === 0) {
      this.changed = false;
    }
    this.changeDetectionRef.markForCheck();
  }

  typeChanged() {
    this.changed = true;
    this.changeDetectionRef.markForCheck();
  }

  typeSelected() {
    this.changed = false;
    this.changeDetectionRef.markForCheck();
  }

  getTagTypeObject(range: TagType): VNCStandardObj {
    return getTagType(range);
  }

  isTagSelected(tag: ICompact) {
    return !isNullOrUndefined(this.selectedTagList.find((c) => c.id === tag.id));
  }

  saveTags() {
    this.searchRepo.saveTagList(
      {
        type: this.selectedTagType,
        list: this.selectedTagList
      }
    );
    this.close();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.changeDetectionRef.markForCheck();
  }
}
