
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  AfterViewInit ,
  ChangeDetectionStrategy ,
  ChangeDetectorRef ,
  Component ,
  EventEmitter ,
  OnDestroy ,
  OnInit ,
  Output
} from "@angular/core";
import { SearchRepository } from "../../../repository/search.repository";
import { AdvanceSearchThroughType } from "../../../models";
import { Broadcaster } from "../../../../common/providers/broadcaster.service";
import { take, takeWhile } from "rxjs/operators";

@Component({
  selector: "vp-search-through" ,
  templateUrl: "./search-through-dialog.html" ,
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class SearchThroughTypeComponent implements OnInit , AfterViewInit, OnDestroy {
  changed = false;
  mdlFixDone = false;
  @Output() closeDialog = new EventEmitter<boolean>();
  allSelected: boolean;
  searchThroughType: AdvanceSearchThroughType;
  isAlive = true;

  constructor(
    private searchRepo: SearchRepository ,
    private broadcaster: Broadcaster,
    private changeDetectionRef: ChangeDetectorRef) {

    this.searchRepo.selectSearchThroughType().pipe(take(1)).subscribe((type) => {
      this.searchThroughType = type;
      this.allSelected = this.isAllFilesSelected(this.searchThroughType);
      this.changeDetectionRef.markForCheck();
    });

    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
      });

    this.broadcaster.on<any>("hideTaskSearchThroughDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
      });
  }

  ngOnInit() {
  }

  close() {
    this.closeDialog.emit(true);
  }

  ngAfterViewInit() {
    if (!this.mdlFixDone) {
      this.mdlFixDone = true;

      // PIYUSH_COMMENT serious issue with Mdl
      // https://github.com/mseemann/angular2-mdl/issues/145
      // https://github.com/angular/angular/issues/10816
      setTimeout(() => {
        this.changeDetectionRef.markForCheck();
      } , 100);
    }
  }

  ngOnDestroy() {
    this.isAlive = false;
    this.changeDetectionRef.markForCheck();
  }

  selectAll() {
    if (this.allSelected) {
      this.allSelected = true;
      this.searchThroughType = {
        taskname: true ,
        description: true ,
        comment: true ,
      };
    }
    else {
      this.allSelected = false;
      this.searchThroughType = {
        taskname: false ,
        description: false ,
        comment: false ,
      };
    }
    this.checkForUpdate();
  }

  selectNone() {
    if (!this.allSelected) {
      return;
    }

    this.allSelected = false;
    this.searchThroughType = {
      taskname: false ,
      description: false ,
      comment: false ,
    };

  }

  saveType() {
    this.searchRepo.saveSearchThroughType(this.searchThroughType);
    this.close();
  }

  checkForUpdate() {
    this.changed = true;
  }

  updateValue(model?) {
    if (model === "taskname") this.searchThroughType.taskname = !this.searchThroughType.taskname;
    if (model === "description") this.searchThroughType.description = !this.searchThroughType.description;
    if (model === "comment") this.searchThroughType.comment = !this.searchThroughType.comment;
  }

  enableSubmit() {
    return this.searchThroughType.taskname || this.searchThroughType.description || this.searchThroughType.comment;
  }

  isAllFilesSelected(request: AdvanceSearchThroughType) {
    return request.taskname && request.description && request.comment;
  }

}
