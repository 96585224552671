
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="task__dialog vp-task-aboutus-dialog" id="taskVersionChangelogDialog">
  <div class="task__dialog-header">
    <div class="mobile-back-button">
      <button mat-button (click)="hide()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
    <div class="header_lbl disable-select">
      {{'VERSION_AND_CHANGELOG_LBL' | translate}}
    </div>
    <div class="desktop-close-button">
      <button mat-button (click)="hide()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="task__dialog-body help-dialog">
    <div class="section_holder">
      <div class="section changelog">
          <a class="open-new-window"  id="changelogBtn" href="https://redmine.vnc.biz/projects/changelog/wiki/VNCtask" target="_blank">{{'CHANGELOG' | translate}}</a>
      </div>
      <div class="section --img">
        <img src="assets/images/VNCtask.svg" />
      </div>
      <div class="section">
        <p class="t-center task_about_title">
          <span>VNC</span>task</p>
        <p class="t-center --version">{{'VERSION' | translate}} {{ app_version }} </p>
      </div>
    </div>
  </div>
</div>
