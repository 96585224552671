
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<!-- <div id="priority_option" class="option-icon" *ngIf="onMainView" [class.selected]="value || isVisible" (click)="onOpen.emit(id); activate = !activate">
  <svg width="38px" height="38px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Icon/product/assignment-late</title>
    <g id="Icon/product/assignment-late" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Icon-24px" transform="translate(3.000000, 1.000000)" fill="#8B96A0">
            <path d="M16,2 L11.82,2 C11.4,0.84 10.3,0 9,0 C7.7,0 6.6,0.84 6.18,2 L2,2 C0.9,2 0,2.9 0,4 L0,18 C0,19.1 0.9,20 2,20 L16,20 C17.1,20 18,19.1 18,18 L18,4 C18,2.9 17.1,2 16,2 L16,2 Z M10,17 L8,17 L8,15 L10,15 L10,17 L10,17 Z M10,13 L8,13 L8,7 L10,7 L10,13 L10,13 Z M9,4 C8.45,4 8,3.55 8,3 C8,2.45 8.45,2 9,2 C9.55,2 10,2.45 10,3 C10,3.55 9.55,4 9,4 L9,4 Z" id="Shape"></path>
        </g>
    </g>
  </svg>
  <p>{{ 'PRIORITY' | translate }}</p>
</div> -->
<ng-template #priorityIcon>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="Icon/product/flag-outline-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Icon-24px" transform="translate(5.000000, 4.000000)" fill="#FFFFFF">
              <path d="M2,16 C2,16.5522847 1.55228475,17 1,17 C0.44771525,17 0,16.5522847 0,16 L0,0.995397568 C0,0.494216766 0.385092325,0.0655238361 0.881209867,0.0068390339 L0.997544646,0 L9,0 L9.4,2 L13.9988322,2 C14.5204527,2 14.9366012,2.38426347 14.9933897,2.87931318 L15,2.99539757 L15,11.0046024 C15,11.5057832 14.612829,11.9344762 14.1140334,11.993161 L13.9970707,12 L8,12 L7.6,10 L2,10 L2,16 Z M7.36,2 L2,2 L2.0007381,7.99891275 L9.23960781,8 L9.64,10 L13,10 L13,4 L7.76039219,4 L7.36,2 Z" id="Combined-Shape"></path>
          </g>
      </g>
  </svg>
</ng-template>

<div class="icons-block" *ngIf="!activate">
  <div class="date-icon priority-icon"  (click)="selectActivateData()">
    <ng-container [ngTemplateOutlet]="priorityIcon"></ng-container>
  </div>
  <span>{{ 'PRIORITY' | translate }}</span>
</div>

<div id="priority_option_list" class="option-list" *ngIf="activate">
  <mat-list role="list">
    <mat-list-item role="listitem" *ngFor="let item of filteredItems; let i = index;"
      [class.selected]="i == selectedIndex"
      (click)="selectedData(item)">
      <mat-icon [style.color]="getPriorityColor(item)">assignment_late</mat-icon>
      <span>{{ item.displayName }}</span>
    </mat-list-item>
  </mat-list>
</div>
