
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="estimate_option" class="option-icon" *ngIf="onMainView" [class.selected]="value || isVisible" (click)="onOpen.emit(id)">
    <mat-icon>access_time</mat-icon>
    <p>{{ 'ESTIMATED_TIME' | translate }}</p>
  </div>

  <div id="repeat_option_list" class="option-list add-task-estimate-time" *ngIf="active">
    <div class="estimate-save-close">
      <span>{{ 'ESTIMATED_TIME' | translate }}:</span>
      <div class="icons-div">
        <mdl-icon class="icon-close" (click)="close()" id="estimate_close">clear</mdl-icon>
        <mdl-icon class="icon-check" (click)="setEstimatedTime()" id="estimate_save">check_circle</mdl-icon>
      </div>
    </div>
    <div class="estimate-time-label">
      <div>{{ 'HOURS' | translate }}</div>
      <div>{{ 'MINUTES' | translate }}</div>
    </div>
    <div>
    <input class="add-task-estimate-input" [(ngModel)]="estimatedHours" (keydown)="validateNumber($event, estimatedHours)" type="text" value="0" id="estimate_hour" maxlength="2" inputmode="numeric" pattern="[0-9]*">
    <input class="add-task-estimate-input" [(ngModel)]="estimatedMinutes" (keydown)="validateNumber($event, estimatedMinutes)" type="text" value="0" id="estimate_minute" maxlength="2" inputmode="numeric" pattern="[0-9]*">
    </div>
  </div>
