
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ViewChild, Component, Input, Output, EventEmitter, OnChanges, AfterViewInit, ElementRef, HostListener, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from "@angular/core";
import { TaskUtils } from "../../shared/task-utils";
import { TaskDatePickerComponent } from "../task-date-picker/task-date-picker.component";
import { take } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "vp-vnctask-autocomplete",
  templateUrl: "task-autocomplete.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskAutocompleteComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() items: any[];
  @Input() filterProp: string;
  filteredItems: any[] = [];
  query: string = "";
  selectedIndex: number = 0;
  focused: boolean;
  isTaskDate: boolean = false;
  @Output() onSelect = new EventEmitter();
  rangeStart = new Date();

  constructor(
    private elementRef: ElementRef,
    private changerDetectorRef: ChangeDetectorRef,
    private matDialog: MatDialog ) {
    this.rangeStart.setDate(this.rangeStart.getDate() - 1);
  }

  ngAfterViewInit() {
  }

  ngOnChanges(changes) {
    if (changes.items) {
      this.filteredItems = (this.items || []).slice();
      this.changerDetectorRef.markForCheck();
    }
  }

  filter() {
    this.filteredItems = (this.items || []).filter(item => {
      let value = TaskUtils.getPropertyFromObject(item, this.filterProp);
      return value.toLowerCase().includes(this.query.toLowerCase());
    });
    this.changerDetectorRef.markForCheck();
  }

  getDisplayText(item) {
    item.date === undefined ? this.isTaskDate = false : this.isTaskDate = true;
    return TaskUtils.getPropertyFromObject(item, this.filterProp);
  }

  onKeyDown(event) {
    if (event.keyCode === TaskUtils.KEYCODES.ENTER) {
      event.preventDefault();
      this.onSelect.emit(this.filteredItems[this.selectedIndex]);
      return;
    }
    if (event.keyCode === TaskUtils.KEYCODES.ARROW_DOWN) {
      event.preventDefault();
      this.selectedIndex -= 1;
    }
    if (event.keyCode === TaskUtils.KEYCODES.ARROW_UP) {
      event.preventDefault();
      this.selectedIndex += 1;
    }
    if (this.selectedIndex < 0) {
      this.selectedIndex = this.filteredItems.length - 1;
    }
    if (this.selectedIndex > this.filteredItems.length - 1) {
      this.selectedIndex = 0;
    }
  }

  onClick(index) {
    this.onSelect.emit(this.filteredItems[index]);
  }

  editDateClick() {
    const dlg = this.matDialog.open(TaskDatePickerComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-task-confirm-dialog",
      data: { header: "", rangeStart:  this.rangeStart}
    });
    dlg.afterClosed().pipe(take(1)).subscribe(res => {
      if (res) {
        this.editDateSelect(res);
      }
    });
  }

  editDateSelect(event) {
    let item = { date: event, name: "dateSelect" };
    this.onSelect.emit(item);
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick($event) {
    let elem = this.elementRef.nativeElement;
    if (!elem.contains($event.target) || $event.target.nodeName !== "INPUT") {
      this.focused = false;
    }
  }

  private onPushBugHack() {
    setTimeout(() => {
    this.changerDetectorRef.markForCheck();
    }, 100);
  }

  ngOnDestroy(): void {
    this.onSelect.unsubscribe();
  }
}
