
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="taskHelpManualDialog" class="task__dialog vp-task-help-faq-dialog">
  <div class="task__dialog-header">
    <div class="mobile-back-button">
      <button mat-button (click)="close()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
    <div class="header_lbl disable-select">
      {{'TASK_HELP_FAQ_TITLE' | translate}}
    </div>
    <div class="desktop-close-button">
      <button mat-button (click)="close()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
  </div>

  <div class="task__dialog-body task__dialog-body--pad">
    <div class="content">
      <div>
        <p class="heading">{{'FAQ' | translate}}</p>
        <p class="link">
          <a class="open-new-window" href="{{ 'FAQ_LINK' | translate }}" target="_blank">{{ 'FAQ_LINK' | translate }}</a>
        </p>
      </div>
      <div>
        <p class="heading">{{'HELP' | translate}}</p>
        <p class="link">
          <a class="open-new-window" href="mailto:vnctask-support@vnc.biz" target="_top">vnctask-support@vnc.biz</a>
        </p>
      </div>
      <div>
        <p class="heading">{{'MANUAL' | translate}}</p>
        <p class="link user-manual">
            {{'COMING_SOON' | translate}}
        </p>
      </div>
    </div>
  </div>
</div>
