
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="compose-div">
  <div class="task-subject-div">
    <textarea (keyup)="onNewTaskInputChange()" (paste)="pasteValue()" (input)="inputHandler($event)" (change)="changeValue($event)" class="new_task_input_div subject-line-1" id="new_task_input" placeholder="{{ 'NEW_TASK_PLACEHOLDER' | translate }}" #composeNewTask></textarea>
  </div>
  <div class="wrapper">
    <div class="container">
      <div contenteditable="true"></div>
    </div>
  </div>
</div>
