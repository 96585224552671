
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="due_date_option" class="option-icon" *ngIf="onMainView" [class.selected]="value || isVisible" (click)="onOpen.emit(id)">
  <mat-icon>event</mat-icon>
  <p>{{ 'TASK_DUE_DATE' | translate }}</p>
</div>

<div id="due_date_option_list" class="option-list" *ngIf="active">
  <mat-list role="list">
    <mat-list-item role="listitem" *ngFor="let item of filteredItems; let i = index;" [class.selected]="i == selectedIndex" (click)="onItemClick(item)">
      <mat-icon *ngIf="item.date">event</mat-icon>
      <mat-icon *ngIf="!item.date">event_busy</mat-icon>
      <div class="date-items" style="display: flex;width:100%;justify-content:space-between;">
        <span class="dateDesc">{{ item.name }}</span>
        <span class="align-right" *ngIf="item.date"> {{ item.date | vpLocaleDate: 'MMM d'}} </span>
        <span class="align-right" *ngIf="!item.date">{{ 'NONE' | translate }}</span>
      </div>
    </mat-list-item>
    <mat-list-item role="listitem" class="date-list-last-item">
      <button class="date-picker-button mat-button" (click)='openDatePicker(dateType)'>
        <mat-icon>event</mat-icon>
        <span>{{ 'PICK_DUE_DATE' | translate }}</span>
      </button>
    </mat-list-item>
  </mat-list>
</div>
