
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { VNCStandardObj } from "./vnc-standard-obj";

export enum ListType {
  NONE = 1,
  ALL_LISTS,
  SELECTED
}

export function getAllListTypes(): ListType[] {
  return [
    ListType.NONE,
    ListType.ALL_LISTS,
    ListType.SELECTED
  ];
}

export function getListType(type: ListType): VNCStandardObj {
  let slug = "";
  let title = "";
  let key = "";

  switch (type) {
    case ListType.ALL_LISTS: {
      slug = "all_lists";
      key = "ALL_LISTS";
      break;
    }

    case ListType.SELECTED: {
      slug = "selected";
      key = "CONTENT_SELECTED";
      break;
    }

    default: {
      slug = "none";
      key = "NONE";
      break;
    }
  }

  return {
    slug: slug,
    title: title,
    translation_key: key,
  };
}

export function ListTypeFromString(str: string): ListType {
  switch (str) {
    case "all_lists":
      return ListType.ALL_LISTS;
    case "selected":
      return ListType.SELECTED;
    default:
      return ListType.NONE;
  }
}
