
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="assign-user-option" class="option-icon" *ngIf="onMainView" [class.selected]="value || isVisible" (click)="onOpen.emit(id)">
  <svg width="38px" height="38px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Icon/product/user-new</title>
    <g id="Icon/product/user-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M6,17.85 L6,19 C6,19.5522847 5.55228475,20 5,20 C4.44771525,20 4,19.5522847 4,19 L4,17.85 L4,17.85 C4,14.8766994 8.5673946,13 12.5,13 C16.3506761,13 20.8099674,14.7993182 20.9940999,17.6656036 L21,17.85 L21,19 C21,19.5522847 20.5522847,20 20,20 C19.4477153,20 19,19.5522847 19,19 L19,17.85 L19,17.85 C19,16.540378 15.79428,15.1542503 12.9670805,15.0119699 L12.7324336,15.0030208 L12.5,15 C9.64886306,15 6.18754404,16.3913943 6.00734383,17.740716 L6,17.85 Z M12.507014,3 C14.9932903,3 17,5.01382241 17,7.5 C17,9.98617759 14.9932903,12 12.507014,12 C10.0193052,12 8,9.98473341 8,7.5 C8,5.01526659 10.0193052,3 12.507014,3 Z M12.507014,5 C11.1229794,5 10,6.12073341 10,7.5 C10,8.87926659 11.1229794,10 12.507014,10 C13.8871336,10 15,8.88318908 15,7.5 C15,6.11681092 13.8871336,5 12.507014,5 Z" id="Shape" fill="#29b6f6"></path>
    </g>
  </svg>
  <p>{{ 'ASSIGN_USER' | translate }}</p>
</div>

<div id="assign-user-option-list" class="option-list add-assignee-user-dialog" *ngIf="active">
  <div *ngIf="authUser.team_user === 'false' && authUser.can_invite_users === 'true' && project.can_invite_users === 'true'">
      <div class="assignee-user-save-close">
        <span>{{ 'ASSIGN_USER' | translate }}:</span>
        <div class="icons-div">
          <mdl-icon class="icon-close" (click)="goBack()">clear</mdl-icon>
          <mdl-icon *ngIf="email.valid && email.value !== ''" class="icon-check" (click)="setExternal()">check_circle</mdl-icon>
        </div>
      </div>
      <div class="external-user-div">
        <mdl-icon class="user-icon">person</mdl-icon>
        <div class="external-textfield">
            <mat-form-field hintLabel="{{ 'HINTLABEL_FOR_INVITE_EXTERNAL' | translate }}">
              <input type="email" [formControl]="email" autocomplete="email" (input)="changeValue($event)" (change)="changeValue($event)" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" matInput>
              <mat-error *ngIf="email.errors?.pattern">
                  {{ 'EMAIL_INVALID_ERROR' | translate }}
              </mat-error>
            </mat-form-field>
        </div>
      </div>
  </div>
  <mat-list role="list" [class.external-user-list]="authUser.team_user === 'false' && authUser.can_invite_users === 'true' && project.can_invite_users === 'true'">
    <mat-list-item class="user-list-item" *ngFor="let member of filteredItems; let i = index;"
      [class.selected]="i == selectedIndex"
      (click)="onItemClick(member)">
      <vp-avatar [avatarId]="member.id" [user]="{ name: member && member.name ? member.name : '', avatarURL: member.avatar }"> </vp-avatar>
      <span class="username-place">{{(member.name !== "") ? member.name : ('NO_NAME' | translate)  }}</span>
    </mat-list-item>
  </mat-list>
</div>
