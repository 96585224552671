
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, HostListener, ChangeDetectionStrategy, OnDestroy, Inject, ChangeDetectorRef } from "@angular/core";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { MessageTranslatorService } from "../../services/message-translator-service";
import { AddFieldsType, Task } from "../../models";
import { TasksConstants } from "../../shared/task-constacts";
import { TaskAddReminderDialogComponent } from "../task-add-reminder-dialog/task-add-reminder-dialog.component";
import { TaskAddAttachmentDialogComponent } from "../task-add-attachment-dialog/task-add-attachment-dialog.component";
import { CommonUtil } from "../../../common/utils/common.utils";
import { TaskWatchersComponent } from "../task-watchers-dialog/task-watchers-dialog.component";
import { Store } from "@ngrx/store";
import { TasksRootState } from "../../store";
import { getOnlineStatus } from "../../../reducers";
import { takeWhile, take } from "rxjs/operators";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { VncLibraryService } from "vnc-library";

@Component({
  selector: "vp-task-add-field-dialog",
  templateUrl: "task-add-field-dialog.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TaskAddFieldDialogComponent implements OnDestroy {
  addFieldsType = AddFieldsType;
  isAlive = true;
  task: Task;
  isOnline: boolean = false;
  reminderDateTime: {
    isDueDate: boolean;
    dateTime: Date;
  };

  constructor(
    private messageTranslatorService: MessageTranslatorService,
    private broadcaster: Broadcaster,
    private store: Store<TasksRootState>,
    private changerDetectorRef: ChangeDetectorRef,
    private matDialog: MatDialog,
    private vncLibaryService: VncLibraryService,
    private dialogRef: MatDialogRef<TaskAddFieldDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.task = this.data.task;
    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
      });
    this.broadcaster.on<any>("hideTaskAddFieldDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.close();
      });

    this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
      this.isOnline = isOnline;
      this.changerDetectorRef.markForCheck();
    });
  }

  @HostListener("document:keydown.esc", ["$event"])
  hide() {
    this.close();
  }

  close(value?) {
    this.dialogRef.close(value);
  }

  redirectTo(type) {
    if (AddFieldsType.subtask === type) {
      const message = this.messageTranslatorService.getMessage(TasksConstants.COMING_SOON);
      this.vncLibaryService.openSnackBar(message, "","", "", 3000, "bottom", "left").subscribe(res => {
      });
    } else {
      switch (type) {
        case AddFieldsType.reminder:
          this.openReminderDialog();
          break;
        case AddFieldsType.attachment:
          this.openAttachDialog();
          break;
        case AddFieldsType.url:
          this.addExternalURL();
          break;
        case AddFieldsType.watchers:
          this.openWatcherDialog();
          break;
      }
    }
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  addExternalURL() {
    this.close({ type : AddFieldsType.url});
  }

  openReminderDialog() {
    if (!this.isOnline) {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
      return;
    }

    if (this.task.remind_on === null) {
      this.reminderDateTime = {
        isDueDate: true,
        dateTime: this.task.due_date ? this.task.due_date : null
      };
    } else {
      let currDate = new Date();
      currDate.setHours(0, 0, 0, 0);
      let dueDate = new Date(
        this.task.remind_on.getFullYear(),
        this.task.remind_on.getMonth(),
        this.task.remind_on.getDate(),
      );
      dueDate.setHours(0, 0, 0, 0);
      let isDueDate = false;
      if (currDate.getTime() === dueDate.getTime()) {
        isDueDate = true;
      } else {
        isDueDate = false;
      }
      this.reminderDateTime = {
        isDueDate: isDueDate,
        dateTime: this.task.remind_on
      };
    }

    const dlg = this.matDialog.open(TaskAddReminderDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vnctaskaddreminderdialog",
      data: { reminderDateTime: this.reminderDateTime }
    });
    dlg.afterClosed().pipe(take(1)).subscribe(reminderDateTime => {
      if (!!reminderDateTime) {
        this.close({ type : AddFieldsType.reminder, data: reminderDateTime });
      }
    });
  }

  openAttachDialog() {
    if (!this.isOnline) {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
      return;
    }

    const dlg = this.matDialog.open(TaskAddAttachmentDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vnctaskaddattachmentdialog"
    });
    dlg.afterClosed().pipe(take(1)).subscribe(uploads => {
      if (!!uploads) {
        this.close({ type : AddFieldsType.attachment, data: uploads });
      }
    });
  }

  openWatcherDialog() {
    const dlg = this.matDialog.open(TaskWatchersComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-task-watchers-dialog"
    });
    dlg.afterClosed().pipe(take(1)).subscribe(res => {
      if (!!res) {
        this.close({ type : AddFieldsType.watchers, data: res });
      }
    });
  }
}
