
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vp-create-list-dialog" id="listAddPopup">
    <form [formGroup]="form">
        <div *ngIf="isCreate" class="mat-dialog__title">{{ 'CREATE_NEW_LIST' | translate }}</div>
        <div *ngIf="!isCreate" class="mat-dialog__title">{{ 'RENAME_LIST' | translate }}</div>
        <div class="mat-dialog__content-block">
            <mat-form-field>
              <input matInput #listname type="text" formControlName="name" id="listAddField">
            </mat-form-field>
        </div>

        <div class="mat-dialog__actions-block">
          <vnc-button id="listCreateBtn" *ngIf="isCreate" (click)="save()" [disabled]="!form.valid" [height]="'medium'" [shape]="'rectangle'" [type]="!form.valid ? 'disabled' : 'link'">
            <span>{{ 'CREATE' | translate }}</span>
          </vnc-button>
          <vnc-button id="listRenameBtn" *ngIf="!isCreate" (click)="update()" [disabled]="!form.valid" [height]="'medium'" [shape]="'rectangle'" [type]="!form.valid ? 'disabled' : 'link'">
            <span>{{ 'UPDATE' | translate }}</span>
          </vnc-button>
          <vnc-button id="listCancelBtn" (click)="cancel()" [height]="'medium'" [shape]="'rectangle'" [type]="'link'">
            <span>{{ 'CANCEL' | translate }}</span>
          </vnc-button>
        </div>
    </form>
</div>
<div class="vnctask-loading" *ngIf="isLoading">
  <div class="indicator">
    <vnc-spinner [size]="'medium'" *ngIf="true"></vnc-spinner>
  </div>
</div>
