
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { PagedList, Task, TaskComment, User, AuthUser, Project, Priority, MyUser, Tag, List, TeamUser, SearchQuery, Location, DeletedTask, Settings } from "./models";
import { Observable, throwError, of, forkJoin, Subject } from "rxjs";
import { ConfigService } from "../common/providers/config.service";
import { BulkUpdateIssueType } from "./shared/task-enum";
import { TaskUtils } from "./shared/task-utils";
import { TasksConstants } from "./shared/task-constacts";
import { TaskAttachment } from "./models/task-attachment";
import { FileUpload } from "./models/file-upload";
import { TimeEntry } from "./models/time-entry";
import { map, catchError } from "rxjs/operators";
import { CommonUtil } from "../common/utils/common.utils";
import { environment } from "src/environments/environment";
import { HttpParams, HttpHeaders, HttpClient } from "@angular/common/http";
import { VNCtaskConfig } from "./models/vnctask-config";
import { ContactInfo } from "../shared/models/contact-info.model";
import { VNCContact } from "src/app/shared/models/contact.model";

@Injectable()
export class TaskService {
  private headers: HttpHeaders;
  msgs: any;
  isCordovaOrElectron = environment.isCordova || environment.isElectron;

  removeWatcherSubject: Subject<any> = new Subject<any>();
  cancelActivationOfTags: Subject<any> = new Subject<any>();
  constructor(private http: HttpClient, private config: ConfigService) {

  }

  getAuthUser(): Observable<AuthUser> {
    return this.http.get(this.getUrl() + "/users/current.json", { headers: this.getHeaders(), withCredentials: true })
      .pipe(map((json: any) => new AuthUser(json.user)), map(res => {
        return res;
      }), catchError(this.handleErrorObservable.bind(this)));
  }

  getTasks(offset: number, limit: number, params: string, afterDate?: string): Observable<Task[]> {
    let search = afterDate ?
                  this.getSearchParamsSinceDate(afterDate, params, limit, offset) :
                    this.getSearchParams(params, limit, offset);

    console.log("[task.service] getTasks:", search);

    return this.http.get(this.getUrl() + "/issues.json?include=journals,attachments,tags,list,invitation_details,location,spent_hours,watchers", {
      params: search,
      headers: this.getHeaders()
    })
    .pipe(map(res => new PagedList<Task>(res)), map(res => {
      // console.log('[task.service] getTasks, res: ', res);
      return res.list;
    }), catchError(this.handleErrorObservable.bind(this)));
  }

  getReminderTasks(time: number = 5): Observable<Task[]> {
    // console.log('[task.service] getReminderTasks: ', time);

    if (!!localStorage.getItem("lastTimeInBackground")) {
      console.log("[lastTimeInBackground]", localStorage.getItem("lastTimeInBackground")); // We will handle this in future if we want to get the tasks at the time we keep the app in background
      // const backgroundTime: any = new Date(+localStorage.getItem("lastTimeInBackground"));
      // const now: any = new Date();
      // const diff: number = Math.floor((now - backgroundTime) / 1000 / 60);
      // time = time - diff;
      localStorage.removeItem("lastTimeInBackground");
    }
    return this.http.get(this.getUrl() + `/issues.json?utf8=✓&set_filter=1&f[]=status_id&op[status_id]=o&f[]=remind_on&op[remind_on]==&v[remind_on][]=${time}`, {
      headers: this.getHeaders()
    })
    .pipe(map((res: any) => {
      return res.issues;
    }), catchError(this.handleErrorObservable.bind(this)) );
  }

  getAssignmentTasks(afterDate: Date): Observable<Task[]> {
    return this.http.get(this.getUrl() + "/vnctasks/assigned_to_me.json?after=" + afterDate.toUTCString(), {
      headers: this.getHeaders()
    })
    .pipe(map((res: any) => {
      return res.issues;
    }), catchError(this.handleErrorObservable.bind(this)) );
  }

  getUpdatedTasks(afterDate: Date): Observable<any> {
    let searchParams = new HttpParams();
    searchParams = searchParams.append("f[]", ["tracker_id"].toString());
    searchParams = searchParams.append("op[tracker_id]", "=");
    searchParams = searchParams.append("v[tracker_id][]", localStorage.getItem("tracker_id"));
    searchParams = searchParams.append("f[]", ["updated_on"].toString());
    searchParams = searchParams.append("op[updated_on]", ">=");
    searchParams = searchParams.append("v[updated_on][]", afterDate.toISOString().split(".")[0] + "Z");
    searchParams = searchParams.append("f[]", ["author_assigned_or_watcher"].toString());
    searchParams = searchParams.append("op[author_assigned_or_watcher]", "=");
    searchParams = searchParams.append("v[author_assigned_or_watcher][]", "me");
    searchParams = searchParams.append("f[]", ["project_id"].toString());
    searchParams = searchParams.append("op[project_id]", "=");
    searchParams = searchParams.append("v[project_id][]", "mine");

    return this.http.get(this.getUrl() + "/issues.json?include=journals,attachments,tags,list,invitation_details,location,spent_hours,watchers", {
      params: searchParams,
      headers: this.getHeaders()
    })
    .pipe(map((res: any) => {
      return res.issues;
    }), catchError(this.handleErrorObservable.bind(this)) );
  }

  getOverduedTasks(): Observable<Task[]> {
    let today = new Date();
    let searchParams: HttpParams = this.getSearchParams({}, 1000, 0);
    searchParams = searchParams.append("f[]", ["due_date"].toString());
    searchParams = searchParams.append("f[]", ["status_id"].toString());
    searchParams = searchParams.append("f[]", ["author_or_assigned"].toString());
    searchParams = searchParams.append("op[due_date]", "<=");
    searchParams = searchParams.append("op[status_id]", "o");
    searchParams = searchParams.append("op[author_or_assigned]", "=");
    searchParams = searchParams.append("v[due_date][]", TaskUtils.datePipe.transform(today.setDate(today.getDate() - 1), "yyyy-MM-dd"));
    searchParams = searchParams.append("v[author_or_assigned][]", "me");
    searchParams = searchParams.append("f[]", ["project_id"].toString());
    searchParams = searchParams.append("op[project_id]", "=");
    searchParams = searchParams.append("v[project_id][]", "mine");
    searchParams = searchParams.append("sort", "due_date:asc");
    return this.http.get(this.getUrl() + "/issues.json", {
      params: searchParams,
      headers: this.getHeaders()
    })
    .pipe(map(res => new PagedList<Task>(res)), map(res => {
      return res.list;
    }), catchError(this.handleErrorObservable.bind(this)) );
  }

  getSearchStatisticCount(statisticsParams: any, filterParams: any): Observable<any> {
    let searchParams = this.getSearchParams(filterParams);
    if (!statisticsParams) {
      return;
    }
    Object.keys(statisticsParams)
      .forEach(key => {
        let values = statisticsParams[key];
        if (!Array.isArray(values)) values = [statisticsParams[key]];
        values.forEach(value => searchParams = searchParams.append(key, value));
      });

    return this.http.get(this.getUrl() + "/issues.json", {
      params: searchParams,
      headers: this.getHeaders()
    })
    .pipe(map(res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  getTaskStatisticsCount(): Observable<any> {
    return this.http.get(this.getUrl() + "/vnctasks/count.json", {
      headers: this.getHeaders()
    })
    .pipe(map(res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  getProjects(): Observable<Project[]> {
    return this.http.get(this.getUrl() +
      "/projects.json?member=1&only_core_fields=1&tracker_id=" + localStorage.getItem("tracker_id") + "&limit=1000&include=invitation_details",
      { headers: this.getHeaders() })
      .pipe(map(res => new PagedList<Project>(res)), map((res: PagedList<Project>) => {
        return res.list;
      }), catchError(this.handleErrorObservable.bind(this)));
  }

  getProjectId(projectName: String): Observable<any> {
    return this.http.get(this.getUrl() + "/projects/" + projectName + ".json", { headers: this.getHeaders() })
    .pipe(map(res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  getMembers(project_id: number): Observable<User[]> {
    return this.http.get(this.getUrl() + "/projects/" + project_id + "/memberships.json?only_user_details=1&limit=1000", { headers: this.getHeaders() })
      .pipe(map((res: any) => res.users.map(json => new User(json))), map( users => { return users; }), catchError(this.handleErrorObservable.bind(this)));
  }

  getPriorities(): Observable<Priority[]> {
    return this.http.get(this.getUrl() + "/enumerations/issue_priorities.json", { headers: this.getHeaders() })
      .pipe(map((res: any) => res.issue_priorities.map(json => new Priority(json))), map(priorities => {
        return priorities;
      }), catchError(this.handleErrorObservable.bind(this)) );
  }

  getTaskDetails(id: number): Observable<any> {
    return this.http.get(
      this.getUrl() + "/issues/" + id + ".json?include=journals,attachments,tags,list,location,invitation_details,watchers", {
        headers: this.getHeaders()
      })
      .pipe(map((res: any) => new Task(res.issue)), map( res => {
        return res;
      }), catchError(this.handleErrorObservable.bind(this)) );
  }

  getAttachmentContent(attachment): Observable<any> {
    let url = attachment.content_url.replace(localStorage.getItem("REDMINE_URL"), this.getUrl());
    return this.http.get(
      url, {
        headers: this.getHeaders(), responseType: "blob"
      })
      .pipe(map(res => { return res; }), catchError(this.handleErrorObservable.bind(this)) );
  }

  downloadFileAsBlob(attachment): Observable<any> {
    let url = attachment.content_url.replace(localStorage.getItem("REDMINE_URL"), this.getUrl());
    let head = new HttpHeaders({
      "Content-Type": "text/html",
    });
    if (this.isCordovaOrElectron) {
      head = head.set("Authorization", localStorage.getItem("token"));
    }
    return this.http.get(url + "?disposition=attachment", { headers: head, responseType: "blob" })
    .pipe(map(res => { return res; }), catchError(this.handleErrorObservable.bind(this)) );
  }

  private getSearchParams(params, limit?, offset?) {
    if (!params) params = {};
    let searchParams = new HttpParams();
    Object.keys(params)
      .forEach(key => {
        let values = params[key];
        if (!Array.isArray(values)) values = [params[key]];
        values.forEach(value => searchParams = searchParams.append(key, value));
      });
    searchParams = searchParams.append("f[]", ["tracker_id"].toString());
    searchParams = searchParams.append("op[tracker_id]", "=");
    searchParams = searchParams.append("v[tracker_id][]", localStorage.getItem("tracker_id"));
    if (!searchParams.get("v[project_id][]")) {
      searchParams = searchParams.append("f[]", ["project_id"].toString());
      searchParams = searchParams.append("op[project_id]", "=");
      searchParams = searchParams.append("v[project_id][]", "mine");
    }
    if (limit) {
      searchParams = searchParams.append("limit", limit.toString());
    }
    if (offset) {
      searchParams = searchParams.append("offset", offset.toString());
    }
    return searchParams;
  }

  private getSearchParamsSinceDate(sinceDate, params, limit?, offset?) {
    if (!params) params = {};
    let searchParams = new HttpParams();
    Object.keys(params)
      .forEach(key => {
        let values = params[key];
        if (!Array.isArray(values)) values = [params[key]];
        values.forEach(value => searchParams = searchParams.append(key, value));
    });
    searchParams = searchParams.append("f[]", ["tracker_id"].toString());
    searchParams = searchParams.append("op[tracker_id]", "=");
    searchParams = searchParams.append("v[tracker_id][]", localStorage.getItem("tracker_id"));
    searchParams = searchParams.append("f[]", ["updated_on"].toString());
    searchParams = searchParams.append("op[updated_on]", ">=");
    searchParams = searchParams.append("v[updated_on][]", sinceDate);
    searchParams = searchParams.append("f[]", ["project_id"].toString());
    searchParams = searchParams.append("op[project_id]", "=");
    if (!searchParams.get("v[project_id][]")) {
      searchParams = searchParams.append("f[]", ["project_id"].toString());
      searchParams = searchParams.append("op[project_id]", "=");
      searchParams = searchParams.append("v[project_id][]", "mine");
    }
    if (limit) {
      searchParams = searchParams.append("limit", limit.toString());
    }
    if (offset) {
      searchParams = searchParams.append("offset", offset.toString());
    }
    return searchParams;
  }

  getAvatarURL(userId) {
    let url = this.getUrl() + "/account/get_avatar/" + userId + ".json";
    if (this.isCordovaOrElectron) {
      url = url + "?token=" + localStorage.getItem("token");
    }
    return url;
  }

  addCommentFor(id: number, notes: string): Observable<any> {
    return this.http.put(
      this.getUrl() + "/issues/" + id + ".json?include=journals",
      { issue: { notes: notes } },
      { headers: this.getHeaders() }
    )
    .pipe(map((res: any) => res.issue.journals.filter( journal => journal.id === res.issue.journals[res.issue.journals.length - 1].id )[0]), catchError(this.handleErrorObservable.bind(this)));
  }

  editCommentFor(comment: TaskComment): Observable<any> {
    return this.http.put(
      this.getUrl() + "/journals/" + comment.id + ".json",
      { "journal": { "notes": comment.notes }},
      { headers: this.getHeaders() }
    )
    .pipe(map(res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  removeTasks(selectedIds: number[]): Observable<any> {
    return this.http.delete(
      this.getUrl() + "/vnctasks/delete.json?ids[]=" + selectedIds.join("&ids[]="),
      { headers: this.getHeaders() }
    )
    .pipe(map(res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  bulkCreateTasks(body: any): Observable<Task[]> {
    return this.http.post(this.getUrl() + "/issues/bulk_create.json", {
      issues: body
    }, { headers: this.getHeaders() })
    .pipe(map(res => new PagedList<Task>(res)), map(res => {
        return res.list;
      }), catchError(this.handleErrorObservable.bind(this)) );
  }

  addNewTask(body: any): Observable<Task> {
        body.tracker_id = localStorage.getItem("tracker_id");
    if (body?.tags) {
      body.tags = body?.tags.map(t => t.name).join(",");
    }

    if ( body.watchers ) {
      let memberIds = [];
      body.watchers.forEach( member => {
        memberIds.push(member.id);
      });
      body.watcher_user_ids = memberIds;
      delete body.watchers;
    } else {
      body.watcher_user_ids = [];
    }

    if (body.repeats) {
      body.recurring_tasks_attributes = {
        "0": {
          "interval_number": "1",
          "interval_unit": body.repeats,
          "interval_modifier": "mdff",
          "fixed_schedule": "1"
        }
      };
      delete body.repeats;
    }

    // console.log('[task.service] addNewTask: ', body);

    return this.http.post(this.getUrl() + "/issues.json?include=tags,list,location,invitation_details,watchers", {
      issue: body
    }, { headers: this.getHeaders() })
    .pipe(map((res: any) => new Task(res.issue)), map(res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  updateUserAvatar(body: any): Observable<any> {
    return this.http.post(this.getUrl() + "/my/save_avatar.json", {
      "avatar_base64": body
    }, { headers: this.getHeaders() })
    .pipe(map(res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  removeUserAvatar(): Observable<any> {
    return this.http.post(this.getUrl() + "/my/save_avatar.json", {
      "delete" : "true"
    }, { headers: this.getHeaders() })
    .pipe(map(res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  getSearchTags(searchTag: String): Observable<any> {
    return this.http.get(this.getServerAPIUrl() + "/api/tags?name=" + searchTag, { headers: this.getHeaders() })
    .pipe(map(res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  getAllTags(): Observable<any> {
    return this.http.get(this.getUrl() + "/tags.json?limit=1000", { headers: this.getHeaders() })
    .pipe(map((res: any) => res.tags), catchError(this.handleErrorObservable.bind(this)));
  }

  getAllUsers(): Observable<any> {
    return this.http.get(this.getUrl() + "/my/users.json", { headers: this.getHeaders() })
    .pipe(map((res: any) => res.users.map(json => new MyUser(json))), catchError(this.handleErrorObservable.bind(this)));
  }

  getAllTagsWithCount(): Observable<any> {
    return this.http.get(this.getUrl() + "/vnctasks/tags_with_count.json", { headers: this.getHeaders() })
    .pipe(map((res: any) => res.tags.map(json => new Tag(json))), map(tagList => {
      return tagList;
    }), catchError(this.handleErrorObservable.bind(this)));
  }

  updateBulkTasks(selectedIds: number[], type: BulkUpdateIssueType, values: any): Observable<any> {
    let body: any = {};
    let storedLanguage = localStorage.getItem(TasksConstants.TASK_LANGUAGE);
    body.ids = selectedIds;
    switch (type) {
      case BulkUpdateIssueType.Status:
        body.issue = {
          "status_id": values,
          "done_ratio": 100
        };
        if (storedLanguage === "de") {
          body.notes = "Status abgeschlossen";
        } else {
          body.notes = "Status updated to Completed.";
        }
        break;
      case BulkUpdateIssueType.AssignedTo:
        body.issue = {
          "assigned_to_id": values,
          "invitee_email": ""
        };
        if (storedLanguage === "de") {
          body.notes = "Benutzer aktualisiert";
        } else {
          body.notes = "User updated";
        }
        break;
      case BulkUpdateIssueType.InviteTo:
        body.issue = {
          "invitee_email": values,
          "assigned_to_id": "none",
        };
        if (storedLanguage === "de") {
          body.notes = "Eingeladene Person aktualisiert";
        } else {
          body.notes = "Invitee updated";
        }
        break;
      case BulkUpdateIssueType.Priority:
        body.issue = {
          "priority_id": values
        };
        if (storedLanguage === "de") {
          body.notes = "Priorität aktualisiert";
        } else {
          body.notes = "Priority updated";
        }
        break;
      case BulkUpdateIssueType.Project:
        body.issue = {
          "project_id": values
        };
        if (storedLanguage === "de") {
          body.notes = "Projekt aktualisiert";
        } else {
          body.notes = "Project updated";
        }
        break;
      case BulkUpdateIssueType.StartDate:
        body.issue = {
          "start_date": values
        };
        if (storedLanguage === "de") {
          body.notes = "Startdatum aktualisiert";
        } else {
          body.notes = "Start date updated";
        }
        break;
      case BulkUpdateIssueType.DueDate:
        body.issue = {
          "due_date": values
        };
        if (storedLanguage === "de") {
          body.notes = "Fälligkeitsdatum aktualisiert";
        } else {
          body.notes = "Due date updated";
        }
        break;
      case BulkUpdateIssueType.Tags:
        body.issue = {
          "tags": values
        };
        if (storedLanguage === "de") {
          body.notes = "Tag aktualisiert";
        } else {
          body.notes = "Tag updated";
        }
        break;
      case BulkUpdateIssueType.List:
        body.issue = {
          "list_id": values
        };
        if (storedLanguage === "de") {
          body.notes = "Liste aktualisiert";
        } else {
          body.notes = "List updated";
        }
        break;
      case BulkUpdateIssueType.Location:
        body.issue = {
          "location_id": values
        };
        if (storedLanguage === "de") {
          body.notes = "Standort aktualisiert";
        } else {
          body.notes = "Location updated";
        }
        break;
      case BulkUpdateIssueType.Watchers:
        body.issue = {
          "watcher_user_ids": values
        };
        if (storedLanguage === "de") {
          body.notes = "Watcher added";
        } else {
          body.notes = "Watcher added";
        }
        break;
    }
    return this.http.post(this.getUrl() + "/issues/bulk_update.json", body
      , { headers: this.getHeaders() })
      .pipe(map(res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  updateBulkTasksRecurring(selectedIds: number[], type: BulkUpdateIssueType, values: any): Observable<any> {
    let body: any = {};
    let storedLanguage = localStorage.getItem(TasksConstants.TASK_LANGUAGE);
    body.ids = selectedIds;
    if (values === "m") {
      body.issue = {
        "recurring_tasks_attributes": {
          "0": {
            "interval_number": "1",
            "interval_unit": values,
            "interval_modifier": "mdff",
            "fixed_schedule": "1"
          }
        }
      };
    } else if (values === "n") {
      return this.http.delete(this.getUrl() + "/recurring_tasks/remove_recurrences.json?ids[]=" + selectedIds.join("&ids[]="),
        { headers: this.headers });
    } else {
      body.issue = {
        "recurring_tasks_attributes": {
          "0": {
            "interval_number": "1",
            "interval_unit": values,
            "fixed_schedule": "1"
          }
        }
      };
    }
    if (storedLanguage === "de") {
      body.notes = "Wiederholung aktualisiert";
    } else {
      body.notes = "Recurrence updated";
    }
    return this.http.post(this.getUrl() + "/issues/bulk_update.json", body
      , { headers: this.getHeaders() })
      .pipe(map(res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  updateTask(task: Task, newTask: Task, fileUploads: FileUpload[], watcherMembers: User[]): Observable<any> {
    let payload: any = { issue: {} };
    ["project", "status", "priority"].forEach(key => {
      // let oldValue = task[key] || {};
      let newValue = newTask[key] || {};
      payload.issue[`${key}_id`] = newValue.id || "";
    });

    // assigned to & external email
    const inviteeEmailVal = newTask["invitee_email"];
    const assignedTo = newTask["assigned_to"];
    if (inviteeEmailVal) {
      payload.issue["invitee_email"] = inviteeEmailVal;
      payload.issue["assigned_to_id"] = "";
    } else {
      payload.issue["assigned_to_id"] = assignedTo ? assignedTo.id : "";
      payload.issue["invitee_email"] = "";
    }

    ["start_date", "due_date", "subject", "description"].forEach((key, i) => {
      if (!task || task[key] !== newTask[key]) {
        payload.issue[key] = newTask[key] && i < 2 ? TaskUtils.getFormattedDate(newTask[key]) : newTask[key];
      }
    });

    // tags
    let tags = [];
    newTask["tags"].forEach(tag => {
      tags.push(tag.name);
    });

    payload.issue["tags"] = tags.toString();
    payload.issue["remind_on"] = newTask["remind_on"] ? newTask["remind_on"].toString() : "";
    payload.issue["list_id"] = newTask["list"] ? newTask["list"].id : "clear";
    payload.issue["location_id"] = newTask["location"] ? newTask["location"].id : "clear";
    if (newTask["estimated_hours"]) {
       payload.issue["estimated_hours"] = newTask["estimated_hours"];
    }
    if (newTask["spent_hours"] && newTask["spent_hours"] !== "00:00") {
       payload.issue["spent_hours"] = newTask["spent_hours"];
    }
    if (newTask["done_ratio"]) {
       payload.issue["done_ratio"] = newTask["done_ratio"];
    }
    if (newTask["external_url"]) {
       payload.issue["external_url"] = newTask["external_url"];
    } else {
       payload.issue["external_url"] = "";
    }
    if (fileUploads && fileUploads.length > 0) {
      let uploads = [];
      fileUploads.forEach( fileUpload => {
        uploads.push({ token: fileUpload.token, filename: fileUpload.filename, content_type: fileUpload.content_type});
      });
      payload.issue["uploads"] = uploads;
    }

    if ( watcherMembers ) {
      let memberIds = [];
      watcherMembers.forEach( member => {
        memberIds.push(member.id);
      });
      payload.issue["watcher_user_ids"] = memberIds;
    } else {
      payload.issue["watcher_user_ids"] = [];
    }

    if (newTask.repeat) {
      payload.issue["recurring_tasks_attributes"] = {
          "0": {
            "id": newTask.recurrence_id,
            "interval_number": "1",
            "interval_unit": newTask.repeat,
            "interval_modifier": "mdff",
            "fixed_schedule": "1"
          }
      };
      if (newTask.repeat === "n") {
        payload.issue["recurring_tasks_attributes"][0]._destroy = "1";
      }
    }
    payload.issue["lock_version"] = newTask.lock_version;

    return this.http.put(
      this.getUrl() + "/issues/" + newTask.id + ".json?include=journals,attachments,tags,list,watchers", payload,
      { headers: this.getHeaders() })
      .pipe(map((res: any) => new Task(res.issue)), map(res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  getUserProfile(): Observable<any> {
    return this.http.get(`api/profile`, { headers: this.headers})
    .pipe(map(res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  uploadFile(body: any): Observable<any> {
    let uploadFileHeader = new HttpHeaders({
      "Content-Type": "application/octet-stream",
    });
    if (this.isCordovaOrElectron) {
      uploadFileHeader = uploadFileHeader.set("Authorization", localStorage.getItem("token"));
    }
    return this.http.post(this.getUrl() + "/uploads.json", body,
    { headers: uploadFileHeader})
    .pipe(map(res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  deleteAttachment(attachment: TaskAttachment) {
    return this.http.delete(this.getUrl() + "/attachments/" + attachment.id + ".json",
    { headers: this.headers})
    .pipe(map ( res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  deleteBulkAttachments(attachmentsIds: number[]) {
    return this.http.delete(this.getUrl() + "/bulk_delete_attachments.json?ids[]=" + attachmentsIds.join("&ids[]="),
    { headers: this.headers})
    .pipe(map ( res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  userRegisration(body: any): Observable<any> {
    return this.http.post(this.getUrl() + "/vnctask_accounts/register.json?isAuthenticated=false", body, { headers: this.getHeaders(), responseType: "text" })
    .pipe(map ( res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  resendActivationLink(mail: string): Observable<any> {
    return this.http.get(this.getUrl() + "/vnctask_accounts/activation_email.json?isAuthenticated=false&mail=" + mail, { headers: this.getHeaders(), responseType: "text" })
    .pipe(map ( res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  activateAccount(token: string): Observable<any> {
    return this.http.get(this.getUrl() + "/vnctask_accounts/activate?isAuthenticated=false&token=" + token, { headers: this.getHeaders(), responseType: "text" })
    .pipe(map ( res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  forgotPassword(mail: string): Observable<any> {
    return this.http.post(this.getUrl() + "/vnctask_accounts/lost_password.json?isAuthenticated=false", {
      mail: mail
    }, { headers: this.getHeaders(), responseType: "text" })
    .pipe(map ( res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  resetPassword(token: string, password: string, confirmPassword: string): Observable<any> {
    return this.http.post(this.getUrl() + "/vnctask_accounts/lost_password.json?isAuthenticated=false", {
        token: token,
        new_password: password,
        new_password_confirmation: confirmPassword
    }, { headers: this.getHeaders(), responseType: "text" })
    .pipe(map ( res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  changePassword(password: string, new_password: string, new_password_confirmation: string): Observable<any> {
    return this.http.post(this.getUrl() + "/vnctask_accounts/change_password.json", {
      password: password,
      new_password: new_password,
      new_password_confirmation: new_password_confirmation
    }, { headers: this.getHeaders() })
    .pipe(map ( res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  deleteAccount(feedback: string, password: string): Observable<any> {
    return this.http.delete(this.getUrl() + "/vnctask_accounts/delete_account.json?feedback=" + feedback + "&password=" + password, { headers: this.getHeaders() })
    .pipe(map ( res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  getLists(): Observable<any> {
    return this.http.get(this.getUrl() + "/vnctask_lists.json", { headers: this.getHeaders() })
    .pipe(map((res: any) => res.vnctask_lists.map(json => new List(json))), map(lists => {
      return lists;
    }), catchError(this.handleErrorObservable.bind(this)));
  }

  createList(listName: string): Observable<any> {
    return this.http.post(this.getUrl() + "/vnctask_lists.json",
    {
      "vnctask_list":
      {
        "name": listName
      }
    }
    , { headers: this.getHeaders() })
    .pipe(map ( res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  UpdateList(list: List): Observable<any> {
    return this.http.put(this.getUrl() + "/vnctask_lists/" + list.id + ".json",
    {
      "vnctask_list":
      {
        "name": list.name
      }
    }
    , { headers: this.getHeaders() })
    .pipe(map ( res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  deleteList(list: List): Observable<any> {
    return this.http.delete(this.getUrl() + "/vnctask_lists/" + list.id + ".json", { headers: this.getHeaders() })
    .pipe(map ( res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  acceptInvitation(token: string): Observable<any> {
    return this.http.post(this.getUrl() + "/vnctask_invitations/accept.json",
    {
      "token": token
    }
    , { headers: this.getHeaders() })
    .pipe(map ( res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  getMyTeamUsers(): Observable<any> {
    return this.http.get(this.getUrl() + "/vnctask_teams/my_team_users.json", { headers: this.getHeaders() })
    .pipe(map((res: any) => res.my_team_users.map(json => new TeamUser(json))), map(users => {
      return users;
    }), catchError(this.handleErrorObservable.bind(this)));
  }

  getArchiveUsers(): Observable<any> {
    return this.http.get(this.getUrl() + "/vnctask_teams/my_team_users.json?archived=1", { headers: this.getHeaders() })
    .pipe(map((res: any) => res.my_team_users.map(json => new TeamUser(json))), map(users => {
      return users;
    }), catchError(this.handleErrorObservable.bind(this)));
  }

  archiveUsers(teamUserIds: number[]): Observable<any> {
    return this.http.post(this.getUrl() + "/vnctask_teams/archive_team_user.json",
    {
      "user_ids": teamUserIds
    }
    , { headers: this.getHeaders() })
    .pipe(map ( res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  unArchiveUsers(teamUserIds: number[]): Observable<any> {
    return this.http.post(this.getUrl() + "/vnctask_teams/unarchive_team_user.json",
    {
      "user_ids": teamUserIds
    }
    , { headers: this.getHeaders() })
    .pipe(map ( res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  inviteTeamUsers(emailAdresses: string[]): Observable<any> {
    return this.http.post(this.getUrl() + "/vnctask_teams/invite_user.json",
    {
      "invitee_emails": emailAdresses
    }
    , { headers: this.getHeaders() })
    .pipe(map ( res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  getHeaders(): HttpHeaders {
    if (!this.headers) {
      this.headers = new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": "application/json"
      });
      if (this.isCordovaOrElectron) {
        let token = localStorage.getItem("token");
        if (token) {
          this.headers = this.headers.set("Authorization", localStorage.getItem("token"));
        }
      }
    } else {
      if (this.isCordovaOrElectron) {
        let token = localStorage.getItem("token");
        if (token) {
          this.headers = this.headers.set("Authorization", localStorage.getItem("token"));
        }
      }
    }
    return this.headers;
  }

  clearHeaders() {
    this.headers = null;
  }

  cordovaLogout(): Observable<any> {
    return this.http.get(this.config.API_URL + "/api/cordova-logout", { headers: this.getHeaders() })
    .pipe(map ( res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  logout() {
    window.location.href = "/api/call-logout";
  }

  private handleErrorObservable(error: Response | any) {
    console.log("task.service handleError: ", error);
    let msg;
    let json = { errors: null };
    let storedLanguage = localStorage.getItem(TasksConstants.TASK_LANGUAGE);
    if (error.status === 403 && error.statusText === "Forbidden") {
      if (storedLanguage === "de") {
        msg = "Erlaubnis abgelehnt";
      } else {
        msg = "Permission declined";
      }
    } else if (error.status === 404 && error.statusText === "Not Found") {
      if (storedLanguage === "de") {
        msg = "Ressource nicht gefunden";
      } else {
        msg = "Resource Not Found";
      }
    }

    try { json = error.json(); }
    catch (e) { }
    if (Array.isArray(json.errors)) {
      msg = json.errors[0];
    }

    if (!msg) {
      if (error.error) {
        try { json = JSON.parse(error.error); }
        catch (e) {
          json = error.error;
        }
        if (Array.isArray(json.errors)) {
          msg = json.errors[0];
        }
      }
    }

    if (!msg) {
      if (storedLanguage === "de") {
        msg = "Ein unerwarteter Fehler ist aufgetreten";
      } else {
        msg = "Unexpected error occurred";
      }
    }
    if (error.status === 403) {
      if (!this.isCordovaOrElectron && error.url && !error.url.includes("bulk_update") && !error.url.includes("api/task/issues/")) {

          this.logout();
      }
    }
    return throwError(msg);
  }

  getUrl() {
    if (localStorage.getItem("serverURL")) {
      return localStorage.getItem("serverURL") + TasksConstants.TASK_API_URL;
    } else {
      return this.config.API_URL + TasksConstants.TASK_API_URL;
    }
  }

  getServerAPIUrl() {
    if (localStorage.getItem("serverURL")) {
      return localStorage.getItem("serverURL");
    } else {
      return this.config.API_URL;
    }
  }

  saveSearches(query) {
    return this.http.post(this.getUrl() + "/queries.json",
    query
    , { headers: this.getHeaders() })
    .pipe(map(res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  addFirebaseToken(token: string): Observable<any> {
    const osSlug = CommonUtil.isOnIOS() ? "ios" : "android";
    return this.http.post(this.getUrl() + "/device_tokens.json", {
      "device_token": {"value": token, "os": osSlug}
    }, { headers: this.getHeaders() })
    .pipe(map ( res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  getSaveSearches() {
    return this.http.get(this.getUrl() + "/queries.json?for_task=1&include=issue_count"
    , { headers: this.getHeaders() })
    .pipe(map((res: any) => res.queries.map(json => new SearchQuery(json))), map(saveSearches => {
      return saveSearches;
    }), catchError(this.handleErrorObservable.bind(this)));
  }

  getSettings() {
    return this.http.get(this.getUrl() + "/vnctask/settings.json"
    , { headers: this.getHeaders() })
    .pipe(map((res: any) => new Settings(res)), map(Settings => {
      return Settings;
    }), catchError(this.handleErrorObservable.bind(this)));
  }

  saveSettings(authUser: AuthUser) {
    let user = {
      "notification" : authUser.notification,
      "sound" : authUser.sound,
      "language" : authUser.language,
      "mail_notification": authUser.mail_notification,
      "global_mute": authUser.global_mute
    };
    let pref = {
      "no_self_notified": ( authUser.no_self_notified === "true" ) ? "1" : "0"
    };
    return this.http.post(this.getUrl() + "/my/account.json",
    {
      "user": user,
      "pref": pref
    }
    , { headers: this.getHeaders() })
    .pipe(map((json: any) => new AuthUser(json.user)), map(res => {
        return res;
    }), catchError(this.handleErrorObservable.bind(this)));
  }

  getTimeLogHistory(issue_id: number): Observable<any> {
    return this.http.get(this.getUrl() + "/time_entries.json?issue_id=" + issue_id, { headers: this.getHeaders() })
    .pipe(map((res: any) => res.time_entries.map(json => new TimeEntry(json))), map(timeEntries => {
      return timeEntries;
    }), catchError(this.handleErrorObservable.bind(this)));
  }

  getLocations(): Observable<any> {
    return this.http.get(this.getUrl() + "/vnctask_locations.json", { headers: this.getHeaders() })
    .pipe(map((res: any) => res.vnctask_locations.map(json => new Location(json))), map(locations => {
      return locations;
    }), catchError(this.handleErrorObservable.bind(this)));
  }

  createLocation(locationName: string): Observable<any> {
    return this.http.post(this.getUrl() + "/vnctask_locations.json",
    {
      "vnctask_location":
      {
        "name": locationName
      }
    }
    , { headers: this.getHeaders() })
    .pipe(map ( res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  removeFirebaseToken(token: string): Observable<any> {
    return this.http.delete(`${this.getUrl()}/device_tokens/${token}.json`, { headers: this.getHeaders() })
    .pipe(map ( res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  UpdateLocation(location: Location): Observable<any> {
    return this.http.put(this.getUrl() + "/vnctask_locations/" + location.id + ".json",
    {
      "vnctask_location":
      {
        "name": location.name
      }
    }
    , { headers: this.getHeaders() })
    .pipe(map ( res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  deleteLocation(location: Location): Observable<any> {
    return this.http.delete(this.getUrl() + "/vnctask_locations/" + location.id + ".json", { headers: this.getHeaders() })
    .pipe(map ( res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  createLogTime(body: any) {
    return this.http.post(this.getUrl() + "/time_entries.json",
    body
    , { headers: this.getHeaders() })
    .pipe(map((res: any) => new TimeEntry(res.time_entry)), map(timeEntry => {
      return timeEntry;
    }), catchError(this.handleErrorObservable.bind(this)));
  }

  addWatchers(userIds: number[], taskId: number) {
    return this.http.post(this.getUrl() + "/issues/" + taskId + "/watchers.json",
    {
      "watcher" : {
        "user_ids" : userIds
      },
      "object_type": "issue"
    }
    , { headers: this.getHeaders() })
    .pipe(map(res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  deleteWatcher(userId: number, taskId: number) {
    return this.http.delete(this.getUrl() + "/issues/" + taskId + "/watchers/" + userId + ".json", { headers: this.getHeaders() })
    .pipe(map ( res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }

  getDeletedTasks(afterDate?: string): Observable<DeletedTask[]> {
    return this.http.get(this.getUrl() + "/vnctasks/deleted_tasks.json?deleted_after=" + afterDate, {
      headers: this.getHeaders()
    })
    .pipe(map((res: any) => res.deleted_tasks.map(json => new DeletedTask(json))), map(deletedTasks => {
        return deletedTasks;
      }), catchError(this.handleErrorObservable.bind(this)));
  }

  getDescriptionDiff(journal_id: any, detail_id: any): Observable<any> {
    return this.http.get(this.getUrl() + "/journals/diff/" + journal_id + ".json?detail_id=" + detail_id, { headers: this.getHeaders() })
    .pipe(map((res: any) => {
      return res.issue;
    }), catchError(this.handleErrorObservable.bind(this)));
  }

  getVnctaskConfig(): Observable<any> {
    return this.http.get(this.getUrl() + "/vnctask/configs.json", { headers: this.getHeaders() })
    .pipe(map((res: any) => new VNCtaskConfig(res.vnctask_configs)), map (vnctaskConfig => {
      return vnctaskConfig;
    }), catchError(this.handleErrorObservable.bind(this)));
  }

  public getLoggedInUserContactInfo(email: string): Observable<any>  {
    const response = new Subject<any>();
    let headers = new HttpHeaders();
    if (this.isCordovaOrElectron) {
        const token = localStorage.getItem("token");
        headers = new HttpHeaders({ "Authorization": token });
    }

    // forkJoin(
    //   [this.http.get(this.config.API_URL + "/api/profile", { headers: headers }),
    //   this.http.get(this.config.API_URL + "/api/my-products", { headers: headers })],
    // ).subscribe(([userProfile, res]) => {
    //   const profile = userProfile as any;
    //   if (!!profile && profile !== null && profile.user) {
    //     const products = res as any;
    //     profile.user.products = products.products;
    //     const result = this.getContacts(profile.user);
    //     response.next(result);
    //   }
    // });

    // return response.asObservable();


    // return this.http.get(this.config.API_URL + "/api/profile", { headers: headers }).pipe(map((profile: any) => {
    //   if (!!profile && profile !== null && profile.user) {
    //     return this.http.get(this.config.API_URL + "/api/my-products", { headers: headers }).pipe(map((res: any) => {
    //       profile.products = res.products;
    //       return this.getContacts(profile);
    //     }));
    //   } else {
    //     const contact = new ContactInfo();
    //     contact.jid = email;
    //     contact.fullName = email.substring(0, email.indexOf("@"));
    //     contact.phones = [];
    //     contact.emails = [];
    //     contact.urls = [];
    //     return contact;
    //   }
    // }));

    return this.http.get(this.config.API_URL + "/api/getLoggedInUserInfo", { headers: headers }).pipe(map((res: any) => {
      if (!!res && res !== null && res.contact) {
        return this.getContacts(res.contact);
      } else {
        const contact = new ContactInfo();
        contact.jid = email;
        contact.fullName = email.substring(0, email.indexOf("@"));
        contact.phones = [];
        contact.emails = [];
        contact.urls = [];
        return contact;
      }
    }));
  }

  private getContacts(contactItem: any): VNCContact {
    const contact = contactItem as VNCContact;
    contact.id = contactItem.id;
    if (contactItem.addresses && contactItem.addresses !== null && contactItem.addresses.length > 0) {
        contact.address = contactItem.addresses;
    }
    if (contactItem.avatar) {
        contact.avatar = contactItem.avatar;
    }
    if (contactItem.company) {
        contact.company = contactItem.company;
    }
    if (contactItem.created_at && contactItem.created_at !== null) {
        contact.created_at = new Date(contactItem.created_at);
    }
    if (contactItem.deleted_at && contactItem.deleted_at !== null) {
        contact.deleted_at = new Date(contactItem.deleted_at);
    }
    if (contactItem.emails) {
        contact.emails = contactItem.emails;
    }
    if (contactItem.first_name) {
        contact.firstName = contactItem.first_name;
    }
    if (contactItem.groups) {
        contact.groups = contactItem.groups;
    }
    if (contactItem.is_company) {
        contact.is_company = contactItem.is_company === "true" ? true : false;
    }
    if (contactItem.is_global) {
        contact.is_global = contactItem.is_global === "true" ? true : false;
    }
    if (contactItem.jid) {
        contact.jid = contactItem.jid;
    }
    if (contactItem.job_title) {
        contact.jobTitle = contactItem.job_title;
    }
    if (contactItem.last_name) {
        contact.lastName = contactItem.last_name;
    }
    if (contactItem.middle_name) {
        contact.middleName = contactItem.middle_name;
    }
    if (contactItem.phones) {
        contact.phones = contactItem.phones;
    }
    if (contactItem.updated_at && contactItem.updated_at !== null) {
        contact.updated_at = new Date(contactItem.updated_at);
    }
    if (contactItem.is_global) {
        contact.is_global = contactItem.is_global === "true" ? true : false;
    }
    if (contactItem.notes) {
        contact.notes = contactItem.notes;
    }
    if (contactItem.im_accounts && contactItem.im_accounts !== null && contactItem.im_accounts.length > 0) {
        contact.im_accounts = contactItem.im_accounts;
    }
    if (contactItem.urls) {
        contact.urls = contactItem.urls;
    }
    if (contactItem.custom_fields) {
        contact.custom_fields = contactItem.custom_fields;
    }
    if (contactItem.events && contactItem.events !== null && contactItem.events.length > 0) {
        contact.events = contactItem.events;
    }
    if (contactItem.contact_lists) {
        contact.contact_list = contactItem.contact_lists;
    }
    if (contactItem.tags) {
        contact.tags = contactItem.tags;
    }
    if (contactItem.time_zone && contactItem.time_zone !== null) {
        contact.timezone = contactItem.time_zone;
    }
    if (contactItem.language && contactItem.language !== null) {
        contact.language = contactItem.language;
    }
    if (contactItem.skills && contactItem.skills !== null && contactItem.skills.length > 0) {
        contact.skills = contactItem.skills;
    }
    if (contactItem.interests && contactItem.interests !== null && contactItem.interests.length > 0) {
        contact.interests = contactItem.interests;
    }
    if (contactItem.birthday && contactItem.birthday !== null) {
        contact.birthday = new Date(contactItem.birthday);
    }
    if (contactItem.gender && contactItem.gender !== null) {
        contact.gender = contactItem.gender;
    }
    if (contactItem.marital_status && contactItem.marital_status !== null) {
        contact.marital_status = contactItem.marital_status;
    }
    if (contactItem.private_email && contactItem.private_email !== null) {
        contact.private_email = contactItem.private_email;
    }
    if (contactItem.start_date && contactItem.start_date !== null) {
        contact.start_date = new Date(contactItem.start_date);
    }
    if (contactItem.end_date && contactItem.end_date !== null) {
        contact.end_date = new Date(contactItem.end_date);
    }
    if (contactItem.per_week_availability && contactItem.per_week_availability !== null) {
        contact.per_week_availability = contactItem.per_week_availability;
    }
    if (contactItem.hourly_rate && contactItem.hourly_rate !== null) {
        contact.hourly_rate = contactItem.hourly_rate;
    }
    if (!!contactItem.vnc_employee && contactItem.vnc_employee !== null) {
        contact.vnc_employee = contactItem.vnc_employee;
    }
    if (contactItem.payment_mode && contactItem.payment_mode !== null) {
        contact.payment_mode = contactItem.payment_mode;
    }
    if (contactItem.passport_expiry && contactItem.passport_expiry !== null) {
        contact.passport_expiry = new Date(contactItem.passport_expiry);
    }
    if (contactItem.rfc_limit && contactItem.rfc_limit !== null) {
        contact.rfc_limit = contactItem.rfc_limit;
    }
    if (contactItem.username && contactItem.username !== null) {
        contact.username = contactItem.username;
    }
    if (contactItem.admin && contactItem.admin !== null) {
        contact.admin = contactItem.admin;
    }
    if (contactItem.agb_accepted && contactItem.agb_accepted !== null) {
        contact.agb_accepted = contactItem.agb_accepted;
    }
    if (contactItem.video_bridge && contactItem.video_bridge !== null) {
        contact.video_bridge = contactItem.video_bridge;
    }
    if (contactItem.omemo && contactItem.omemo !== null) {
        contact.omemo = contactItem.omemo;
    }
    if (contactItem.id_number && contactItem.id_number !== null) {
        contact.national_id_number = contactItem.id_number;
    }
    if (contactItem.id_expiry && contactItem.id_expiry !== null) {
        contact.national_id_expiry = new Date(contactItem.id_expiry);
    }
    if (contactItem.products && contactItem.products !== null && contactItem.products.length > 0) {
        contact.products = contactItem.products;
    }
    if (contactItem.favorite && contactItem !== null) {
        contact.favorite = contactItem.favorite === "true" ? true : false;
    }
    contact.fullName = this.getFullName(contact.firstName, contact.lastName);
    contact.bgAvatarColor = CommonUtil.getRandomAvatarColor();
    return contact;
  }

  private getFullName(firstName: string, lastName: string): string {
    if (firstName && lastName) {
        return firstName + " " + lastName;
    } else {
        if (firstName && firstName !== undefined) {
            return firstName;
        } else if (lastName && lastName !== undefined) {
            return lastName;
        }
    }
  }

  public getContactInfo(email: string): Observable<any> {
    const query: string[] = [];
    query.push("email=" + email);
    let headers = new HttpHeaders();
    if (this.isCordovaOrElectron) {
        const token = localStorage.getItem("token");
        headers = new HttpHeaders({ "Authorization": token });
    }
    return this.http.get(this.config.API_URL + "/api/getContactInfo?" + query.join("&").toString(), { headers: headers }).pipe(map((res: any) => {
      if (!!res && res !== null && res.contacts && res.contacts.length > 0) {
        return this.getContacts(res.contacts[0]);
      } else {
        const contact = new ContactInfo();
        contact.jid = email;
        contact.first_name = email?.substring(0, email?.indexOf("@"));
        contact.phones = [];
        contact.emails = [];
        contact.urls = [];
        return contact;
      }
    }));
  }

  public getCableAuth(): Observable<any> {
    return this.http.get(this.config.API_URL + "/api/cableAuth", {
      headers: this.getHeaders()
    }).pipe(map(res => { return res; }), catchError(this.handleErrorObservable.bind(this)));
  }
}
