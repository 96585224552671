
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { AppType, AdvanceSearchTime, AdvanceSearchDueDateTime, AdvanceSearchThroughType, Project, ICompact, AdvanceSearchTag, AdvanceSearchUser, AdvanceSearchList, AdvanceSearchLocation } from "../../models";
import { Action } from "../../../actions";
import { MyUser } from "../../models/user";

export class SearchActionTypes {
  static SEARCH_KEYWORD_UPDATE = "[SEARCH] Update Keyword";
  static SEARCH_CLEAR_ALL = "[SEARCH] Clear All";
  static SEARCH_APP_UPDATE = "[SEARCH] Update Apps";
  static SEARCH_TIME_RANGE_UPDATE = "[SEARCH] Update Time Range";
  static SEARCH_DUEDATE_RANGE_UPDATE = "[SEARCH] Update DueDate Range";
  static SEARCH_THROUGH_TYPE_UPDATE = "[SEARCH] Update Search Through Type";
  static SEARCH_ASSIGNEES_UPDATE = "[SEARCH] Update Assignees";
  static SEARCH_PROJECTS_UPDATE = "[SEARCH] Update Projects";
  static SEARCH_TAGS_UPDATE = "[SEARCH] Update Tags";
  static SEARCH_LISTS_UPDATE = "[SEARCH] Update Lists";
  static SEARCH_LOCATIONS_UPDATE = "[SEARCH] Update Locations";
  static SEARCH_ATTACHMENTS_UPDATE = "[SEARCH] Update Attachments";
  static SEARCH_CLOSED_TASKS_UPDATE = "[SEARCH] Update Closed Tasks";
  static SAVE_SEARCH_UPDATE = "[SEARCH] Update Save Searches";
}

export class SearchKeywordUpdate implements Action {
  readonly type = SearchActionTypes.SEARCH_KEYWORD_UPDATE;

  constructor(public payload: string) {
  }
}

export class SearchClearAll implements Action {
  readonly type = SearchActionTypes.SEARCH_CLEAR_ALL;
}

export class SearchAppUpdate implements Action {
  readonly type = SearchActionTypes.SEARCH_APP_UPDATE;

  constructor(public payload: AppType[]) {
  }
}

export class SearchTimeRangeUpdate implements Action {
  readonly type = SearchActionTypes.SEARCH_TIME_RANGE_UPDATE;

  constructor(public payload: AdvanceSearchTime) {
  }
}

export class SearchDueDateRangeUpdate implements Action {
  readonly type = SearchActionTypes.SEARCH_DUEDATE_RANGE_UPDATE;

  constructor(public payload: AdvanceSearchDueDateTime) {
  }
}

export class SearchThroughTypeUpdate implements Action {
  readonly type = SearchActionTypes.SEARCH_THROUGH_TYPE_UPDATE;

  constructor(public payload: AdvanceSearchThroughType) {
  }
}

export class SearchAssigneesUpdate implements Action {
  readonly type = SearchActionTypes.SEARCH_ASSIGNEES_UPDATE;

  constructor(public payload: AdvanceSearchUser) {
  }
}

export class SearchProjectsUpdate implements Action {
  readonly type = SearchActionTypes.SEARCH_PROJECTS_UPDATE;

  constructor(public payload: Project[]) {
  }
}

export class SearchTagsUpdate implements Action {
  readonly type = SearchActionTypes.SEARCH_TAGS_UPDATE;

  constructor(public payload: AdvanceSearchTag) {
  }
}

export class SearchListsUpdate implements Action {
  readonly type = SearchActionTypes.SEARCH_LISTS_UPDATE;

  constructor(public payload: AdvanceSearchList) {
  }
}

export class SearchLocationsUpdate implements Action {
  readonly type = SearchActionTypes.SEARCH_LOCATIONS_UPDATE;

  constructor(public payload: AdvanceSearchLocation) {
  }
}

export class SearchAttachmentUpdate implements Action {
  readonly type = SearchActionTypes.SEARCH_ATTACHMENTS_UPDATE;

  constructor(public payload: boolean) {
  }
}


export class SearchClosedTasksUpdate implements Action {
  readonly type = SearchActionTypes.SEARCH_CLOSED_TASKS_UPDATE;

  constructor(public payload: boolean) {
  }
}


export class SaveSearchesUpdate implements Action {
  readonly type = SearchActionTypes.SAVE_SEARCH_UPDATE;

  constructor(public payload: boolean) {
  }
}

// action types
export type SearchAction =
 | SearchKeywordUpdate
 | SearchClearAll
 | SearchAppUpdate
 | SearchTimeRangeUpdate
 | SearchDueDateRangeUpdate
 | SearchThroughTypeUpdate
 | SearchAssigneesUpdate
 | SearchProjectsUpdate
 | SearchTagsUpdate
 | SearchAttachmentUpdate
 | SearchClosedTasksUpdate
 | SaveSearchesUpdate
 | SearchListsUpdate
 | SearchLocationsUpdate;
