
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="taskReminderDialog" class="task__dialog">
      <div class="vp-task-reminder-dialog task__dialog-header">
        <div class="mobile-back-button">
          <button mat-button (click)="close()">
            <mat-icon class="disable-select">close</mat-icon>
          </button>
        </div>
        <div class="header_lbl disable-select">
          {{'REMINDER_TIME' | translate}}
        </div>
        <div class="desktop-close-button">
          <button mat-button (click)="close()">
            <mat-icon class="disable-select">close</mat-icon>
          </button>
        </div>
      </div>
      <div class="task__dialog-body task__dialog-body--pad">
        <mat-list role="list">
          <mat-radio-group (change)="customTypeSelect()" class="add-reminder-radio-group" [(ngModel)]="selectedReminder">
            <mat-radio-button class="radio-button-style" [value]="reminderOption.ON_DUE_DATE">{{'ON_THE_DUE_DATE' | translate}}</mat-radio-button>
            <mat-list-item role="listitem" *ngIf="selectedReminder === reminderOption.ON_DUE_DATE">
              <div class="custom-date-div">
                <mdl-icon class="reminder-date-icon">today</mdl-icon>
                <div class="reminder-div">
                  <input readonly="true" [(ngModel)]="dueDateTime" class="reminder-time" placeholder="{{ 'TIME' | translate }}" [owlDateTimeTrigger]="dt2" [owlDateTime]="dt2">
                  <owl-date-time [pickerType]="'timer'" #dt2></owl-date-time>
                </div>
              </div>
            </mat-list-item>
            <mat-radio-button class="radio-button-style" [value]="reminderOption.ON_CUSTOM_DATE">{{ 'SET_THE_CUSTOM_DATE' | translate }}</mat-radio-button>
            <mat-list-item role="listitem" *ngIf="selectedReminder === reminderOption.ON_CUSTOM_DATE">
              <div class="custom-date-div">
                  <mdl-icon class="reminder-date-icon">today</mdl-icon>
                  <div class="reminder-div">
                    <!-- calendar only -->
                    <input readonly="true" [min]="minDate" [(ngModel)]="customDate" class="reminder-date" placeholder="{{ 'DATE' | translate }}" [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1">
                    <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>

                    <!-- timer only -->
                    <input readonly="true" [(ngModel)]="customTime" class="reminder-time" placeholder="{{ 'TIME' | translate }}" [owlDateTimeTrigger]="dt2" [owlDateTime]="dt2">
                    <owl-date-time [pickerType]="'timer'" #dt2></owl-date-time>
                  </div>
                </div>
            </mat-list-item>
          </mat-radio-group>
        </mat-list>
      </div>
      <div class="task__dialog-footer">
        <div class="desktop-footer">
            <button mat-button (click)="close()">
                {{ 'CANCEL' | translate }}
            </button>
            <button mat-button (click)="addReminder($event)">
                {{ 'SAVE' | translate }}
            </button>
        </div>
        <div class="mobile-footer">
            <div class="title">
                {{ 'SET_THE_REMINDER_TIME' | translate }}
            </div>
            <div class="save_footer_btn">
                <button mat-button (click)="addReminder($event)">
                    <mat-icon>check</mat-icon>
                </button>
            </div>
        </div>
      </div>
</div>

