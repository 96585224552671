
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Task } from "./task";
import { Project } from "./project";

export class PagedList<T> {
  list: T[];
  total_count: number;
  offset: number;
  limit: number;

  constructor(data: any) {
    this.total_count = data.total_count;
    this.offset = data.offset;
    this.limit = data.limit;
    if (data.issues) {
      this.list = data.issues.map(issue => new Task(issue));
    }
    if (data.projects) {
      this.list = data.projects.map(project => new Project(project));
    }
  }
}

export class List {
  id: number;
  name: string;
  tasks_count: number;

  constructor(json) {
    this.id = json.id;
    this.name = json.name;
    this.tasks_count = json.tasks_count;
  }
}