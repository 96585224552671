
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vp-task-deactivate-feedback-dialog task__dialog">
    <div class="task__dialog-header">
        <div class="mobile-back-button">
            <button mat-button (click)="close()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
        <div class="header_lbl disable-select">
        {{'DEACTIVATE_ACCOUNT' | translate}}
        </div>
        <div class="desktop-close-button">
            <button mat-button (click)="close()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
    </div>
    <div class="task__dialog-body task__dialog-body--pad">
        <div class="row message-header">
            <span>{{ 'SORRY_LEAVING_MESSAGE' | translate }}</span><br>
            <span>{{ 'WHAT_IS_REASON_MESSAGE' | translate }}</span>
        </div>
        <mat-list role="list">
            <mat-radio-group class="task-account-radio-group" [(ngModel)]="selectedFeedbackType">
                <mat-list-item role="listitem"><mat-radio-button class="radio-button-style" id="input_no_more" [value]="FeedbackType.NO_MORE">{{ getFeedbackType(FeedbackType.NO_MORE).translation_key | translate }}</mat-radio-button></mat-list-item>
                <mat-list-item role="listitem"><mat-radio-button class="radio-button-style" id="input_not_expected" [value]="FeedbackType.NOT_EXPECTED">{{ getFeedbackType(FeedbackType.NOT_EXPECTED).translation_key | translate }}</mat-radio-button></mat-list-item>
                <mat-list-item role="listitem"><mat-radio-button class="radio-button-style" id="input_dont_like" [value]="FeedbackType.DONT_LIKE">{{ getFeedbackType(FeedbackType.DONT_LIKE).translation_key | translate }}</mat-radio-button></mat-list-item>
                <mat-list-item role="listitem"><mat-radio-button class="radio-button-style" id="input_lots_of_bugs" [value]="FeedbackType.LOT_OF_BUGS">{{ getFeedbackType(FeedbackType.LOT_OF_BUGS).translation_key | translate }}</mat-radio-button></mat-list-item>
                <mat-list-item role="listitem"><mat-radio-button class="radio-button-style" id="input_other" [value]="FeedbackType.OTHER">{{ getFeedbackType(FeedbackType.OTHER).translation_key | translate }}</mat-radio-button></mat-list-item>
            </mat-radio-group>
            <mat-list-item role="listitem" *ngIf="selectedFeedbackType === FeedbackType.OTHER">
                <textarea [(ngModel)]="otherReason" placeholder="{{ 'ENTER_A_REASON' | translate }}" rows="4"></textarea>
            </mat-list-item>
        </mat-list>
    </div>

    <div class="task__dialog-footer">
        <div class="desktop-footer">
            <button mat-button (click)="close()">
                {{ 'CANCEL' | translate }}
            </button>
            <button mat-button [disabled]="selectedFeedbackType === FeedbackType.OTHER && !otherReason" (click)="onFeedbackSubmit()">
                {{ 'SAVE' | translate }}
            </button>
        </div>
        <div class="mobile-footer">
            <div class="title">
                {{ 'DEACTIVATE_YOUR_ACCOUNT' | translate }}
            </div>
            <div class="save_footer_btn">
                <button mat-button [disabled]="selectedFeedbackType === FeedbackType.OTHER && !otherReason" (click)="onFeedbackSubmit()">
                    <mat-icon>check</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>