
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { takeWhile } from "rxjs/operators";

@Component({
  selector: "vp-redirect-page",
  templateUrl: "./redirect.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RedirectComponent implements OnDestroy {
  isAlive: boolean = true;
  constructor(
    private activated: ActivatedRoute,
    private router: Router,
    private changerDetectorRef: ChangeDetectorRef
  ) {
    this.activated.queryParams.pipe(takeWhile(() => this.isAlive)).subscribe(params => {
      if (params.action && params.token || params.id ) {

        switch (params.action) {
          case "confirm-email":
            if (params.token) {
              localStorage.setItem("activation_token", params.token);
              window.postMessage({
                type: "GO_TO_CONFIRM_EMAIL"
              }, "*");
            }
            break;
          case "password-reset":
            if (params.token) {
              localStorage.setItem("password_reset_token", params.token);
              window.postMessage({
                type: "GO_TO_RESET_PASSWORD_PAGE"
              }, "*");
            }
            break;
          case "open-task":
            if (params.id) {
              window.postMessage({
                type: "OPEN_TASK_DETAIL_PAGE",
                id: params.id
              }, "*");
            }
            break;
          case "task-invitation":
            if (params.token) {
              localStorage.setItem("invitation_token", params.token);
              window.postMessage({
                type: "GO_TO_TASK_INVITATION"
              }, "*");
            }
            break;
          case "show-diff":
            if (params.journal_id && params.detail_id && params.id) {
              window.postMessage({
                type: "SHOW_DESCRIPTION_DIFFERENCE",
                id: params.id,
                journal_id: params.journal_id,
                detail_id: params.detail_id
              }, "*");
            }
            break;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.changerDetectorRef.markForCheck();
  }
}