
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";


@Injectable()
export class LocalStorageService {

  constructor() {

  }

  storePriorties(priorties) {
    localStorage.setItem("priorties", JSON.stringify(priorties));
  }

  getPriorties() {
    const priorties = localStorage.getItem("priorties");
    if (priorties) {
      const priortiesList = JSON.parse(priorties);
      return priortiesList;
    }
    return null;
  }

  storeUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
  }

  getUser() {
    const user = localStorage.getItem("user");
    if (user) {
      const userObject = JSON.parse(user);
      return userObject;
    }
    return null;
  }

  storeProjects(projects) {
    localStorage.setItem("projects", JSON.stringify(projects));
  }

  getProjects() {
    const projects = localStorage.getItem("projects");
    if (projects) {
      return JSON.parse(projects);
    }
    return null;
  }

  getMemberList(projectId) {
    const members = localStorage.getItem("members-" + projectId);
    if (members) {
      return JSON.parse(members);
    }
    return null;
  }

  storeMemberList(projectId, membersList) {
    localStorage.setItem("members-" + projectId, JSON.stringify(membersList));
  }

  storeMyTeamUsers(users) {
    localStorage.setItem("myteamusers", JSON.stringify(users));
  }

  getMyTeamUsers() {
    const users = localStorage.getItem("myteamusers");
    if (users) {
      return JSON.parse(users);
    }
    return null;
  }

  cleanLocalStorage() {
    localStorage.removeItem("user");
    localStorage.removeItem("projects");
    localStorage.removeItem("myteamusers");
    localStorage.removeItem("priorties");
  }

  storeSaveSearches(projects) {
    localStorage.setItem("savesearches", JSON.stringify(projects));
  }

  getSaveSearches() {
    const projects = localStorage.getItem("savesearches");
    if (projects) {
      return JSON.parse(projects);
    }
    return null;
  }
}
