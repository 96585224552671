
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<!-- <div id="description_option" class="option-icon" *ngIf="onMainView" [class.selected]="value || isVisible" (click)="activateData()">
  <svg width="38px" height="38px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Icon/product/edit</title>
    <g id="Icon/product/edit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Icon-24px" transform="translate(3.000000, 2.997500)" fill="#8B96A0">
            <path d="M0,14.2525 L0,18.0025 L3.75,18.0025 L14.81,6.9425 L11.06,3.1925 L0,14.2525 L0,14.2525 Z M17.71,4.0425 C18.1,3.6525 18.1,3.0225 17.71,2.6325 L15.37,0.2925 C14.98,-0.0975 14.35,-0.0975 13.96,0.2925 L12.13,2.1225 L15.88,5.8725 L17.71,4.0425 L17.71,4.0425 Z" id="Shape"></path>
        </g>
    </g>
  </svg>
    <p>{{ 'DESCRIPTION' | translate }} data</p>
  </div> -->

  <ng-template #descriptionIcon>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="Icon/product/edit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Icon-24px" transform="translate(3.000000, 2.997500)" fill="#FFFFFF">
          <path
            d="M0,14.2525 L0,18.0025 L3.75,18.0025 L14.81,6.9425 L11.06,3.1925 L0,14.2525 L0,14.2525 Z M17.71,4.0425 C18.1,3.6525 18.1,3.0225 17.71,2.6325 L15.37,0.2925 C14.98,-0.0975 14.35,-0.0975 13.96,0.2925 L12.13,2.1225 L15.88,5.8725 L17.71,4.0425 L17.71,4.0425 Z"
            id="Shape"></path>
        </g>
      </g>
    </svg>
  </ng-template>
  <div class="icons-block" *ngIf="!activate">
    <div class="date-icon description-icon" (click)="selectActivateData()">
      <ng-container [ngTemplateOutlet]="descriptionIcon"></ng-container>
    </div>
    <span>{{ 'DESCRIPTION' | translate }}</span>
  </div>

  <div id="description_option_list" class="option-list add-description-dialog" *ngIf="active || activate">
    <div class="description-save-close">
      <span>{{ 'DESCRIPTION' | translate }}:</span>
      <div class="icons-div">
        <mdl-icon id="description_cancel" class="icon-close" (click)="cancelActivateData()">clear</mdl-icon>
        <mdl-icon id="description_save" class="icon-check" (click)="setDescription()">check_circle</mdl-icon>
      </div>
    </div>
    <div class="detail__toolbar">
        <vp-vnctask-toolbar #taskToolbar [content]="content"></vp-vnctask-toolbar>
        <!-- test -->
    </div>
  </div>
