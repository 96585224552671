
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, HostListener, Component, OnDestroy, ElementRef, ViewChild, Input, Inject } from "@angular/core";
import { TasksConstants } from "../../shared/task-constacts";
import { TasksRootState, getMyTeamUsers, getAuthUser, getMemberList } from "../../store/index";
import { Store } from "@ngrx/store";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { TeamUser, AuthUser, User } from "../../models";
import { TaskRepository } from "../../repository/task.repository";
import { getOnlineStatus } from "../../../reducers";
import { Subject } from "rxjs";
import { ErrorService } from "../../../common/providers/error-service";
import { SuccessService } from "../../../common/providers/success-service";
import { ErrorType, SuccessType } from "../../shared/task-enum";
import { CommonUtil } from "../../../common/utils/common.utils";
import { MessageTranslatorService } from "../../services/message-translator-service";
import sort from "fast-sort";
import { takeWhile } from "rxjs/operators";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "vp-task-watchers",
  templateUrl: "task-watchers.html",
  // template: `

  // `,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TaskWatchersComponent implements OnDestroy {
  isAlive: boolean = true;
  taskConstants = TasksConstants;
  isOnline: boolean = false;
  searchText: string = "";
  query: string = "";
  isLoading: boolean = false;
  project_id: any;
  memberList: User[] = [];
  selectedMemberList: User[] = [];
  isUpdated: boolean = false;
  copyMembers: User[] = [];
  constructor(
    private store: Store<TasksRootState>,
    private broadcaster: Broadcaster,
    private changerDetectorRef: ChangeDetectorRef,
    private taskRepo: TaskRepository,
    private successService: SuccessService,
    private errorService: ErrorService,
    private messageTranslatorService: MessageTranslatorService,
    public dialogRef: MatDialogRef<TaskWatchersComponent>
  ) {
    this.setupStore();
    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });
    this.broadcaster.on<any>("hideWatcherDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });
  }

  setupStore() {
    this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
      this.isOnline = isOnline;
      this.changerDetectorRef.markForCheck();
    });

    this.store.select(getMemberList).pipe(takeWhile(() => this.isAlive)).subscribe((memberList) => {
      this.memberList = memberList;
      this.copyMembers = memberList;
      this.changerDetectorRef.markForCheck();
    });

    this.broadcaster.on<any>("sortMemberList").pipe(takeWhile(() => this.isAlive))
    .subscribe(presence => {
      if (this.memberList) {
        if (this.memberList.length > 0) {
          sort(this.memberList).asc( member => member.name);
        }
      }
    });
  }

  @HostListener("document:keydown.esc", ["$event"])
  hide(selectedMembers?) {
    this.dialogRef.close(selectedMembers);
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.changerDetectorRef.markForCheck();
  }

  clearText() {
    this.query = "";
    this.changerDetectorRef.markForCheck();
  }

  addMemberToList(member) {
    this.selectedMemberList.push(member);
    this.memberList = this.memberList.filter(item => item.id !== member.id);
    this.isUpdated = true;
    this.changerDetectorRef.markForCheck();
  }

  removeSelectedMember(member) {
    this.selectedMemberList = this.selectedMemberList.filter(item => item.id !== member.id);
    this.memberList.push(member);
    this.broadcaster.broadcast("sortMemberList");
    this.isUpdated = true;
    if (this.selectedMemberList && this.selectedMemberList.length === 0) {
      this.isUpdated = false;
    }
    this.changerDetectorRef.markForCheck();
  }

  addWatchers() {
    if (this.selectedMemberList.length > 0) {
      this.hide(this.selectedMemberList);
    }
  }

  searchTextChange() {
    let filteredList = this.copyMembers.filter(mem => {
      const isAlreadySelected = this.selectedMemberList.some(selectedMem => selectedMem.id === mem.id);
      return !isAlreadySelected &&
        (mem.name.toLowerCase().includes(this.searchText.toLowerCase()) || mem.username.toLowerCase().includes(this.searchText.toLowerCase()));
    });
    this.memberList = filteredList;
  }
}
