
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
    Component,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import { Router } from "@angular/router";
import { TaskNotification, TaskNotificationType } from "./task-notifications.model";
import { TaskNotificationsService } from "./task-notifications.service";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { trigger, state, style, transition, animate } from "@angular/animations";

@Component({
    selector: "vp-task-notification",
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger("enterLeave", [

            // Enter from right
            state("fromRight", style({ opacity: 1, transform: "translateX(0)" })),
            transition("* => fromRight", [
                style({ opacity: 0, transform: "translateX(5%)" }),
                animate("400ms ease-in-out")
            ]),
            state("fromRightOut", style({ opacity: 0, transform: "translateX(-5%)" })),
            transition("fromRight => fromRightOut", [
                style({ opacity: 1, transform: "translateX(0)" }),
                animate("300ms ease-in-out")
            ]),

            // Enter from left
            state("fromLeft", style({ opacity: 1, transform: "translateX(0)" })),
            transition("* => fromLeft", [
                style({ opacity: 0, transform: "translateX(-5%)" }),
                animate("400ms ease-in-out")
            ]),
            state("fromLeftOut", style({ opacity: 0, transform: "translateX(5%)" })),
            transition("fromLeft => fromLeftOut", [
                style({ opacity: 1, transform: "translateX(0)" }),
                animate("300ms ease-in-out")
            ]),

            // Rotate
            state("scale", style({ opacity: 1, transform: "scale(1)" })),
            transition("* => scale", [
                style({ opacity: 0, transform: "scale(0)" }),
                animate("400ms ease-in-out")
            ]),
            state("scaleOut", style({ opacity: 0, transform: "scale(0)" })),
            transition("scale => scaleOut", [
                style({ opacity: 1, transform: "scale(1)" }),
                animate("400ms ease-in-out")
            ]),

            // Scale
            state("rotate", style({ opacity: 1, transform: "rotate(0deg)" })),
            transition("* => rotate", [
                style({ opacity: 0, transform: "rotate(5deg)" }),
                animate("400ms ease-in-out")
            ]),
            state("rotateOut", style({ opacity: 0, transform: "rotate(-5deg)" })),
            transition("rotate => rotateOut", [
                style({ opacity: 1, transform: "rotate(0deg)" }),
                animate("400ms ease-in-out")
            ])
        ])
    ],
    templateUrl: "./task-notifications.html"
})

export class TaskNotificationComponent implements OnInit, OnDestroy {

    @Input() timeOut: number;
    @Input() showProgressBar: boolean;
    @Input() pauseOnHover: boolean;
    @Input() clickToClose: boolean;
    @Input() maxLength: number;
    @Input() theClass: string;
    @Input() rtl: boolean;
    @Input() animate: string;
    @Input() position: number;
    @Input() item: TaskNotification;

    // Progress bar variables
    progressWidth = 0;
    stopTime = false;
    timer: any;
    steps: number;
    speed: number;
    count = 0;
    start: any;
    diff: any;
    defaultBackground = "rgba(25,125,194, 0.8)";
    activeCallInterval: any;
    isAlive = true;
    enableAudio = false;
    enableVideo = false;
    hasWebcam = false;
    participants = [];
    backCameraId = "";
    frontCameraId = "";
    private _roomName;
    taskNotificationType = TaskNotificationType;

    constructor(private router: Router,
        private zone: NgZone,
        private broadcaster: Broadcaster,
        private notificationService: TaskNotificationsService) {

    }

    ngOnInit() {
        if (this.animate) {
            this.item.state = this.animate;
        }
        if (this.item.override) {
            this.attachOverrides();
        }
        if (this.timeOut !== 0) {
            this.startTimeOut();
        }
    }

    padZeros(num) {
        if (num < 10) {
            return "0" + num;
        }
        return num;
    }

    getSessions() {
        if (this.item.override && this.item.override.viewers) {
            return Object.keys(this.item.override.viewers);
        }
        return [];
    }

    startTimeOut() {
        if (this.item.type !== "audio" && this.item.type !== "video" && this.item.type !== "active") {
            this.steps = this.timeOut / 1000;
        }

        this.speed = this.timeOut / this.steps;
        this.start = new Date().getTime();
        this.zone.runOutsideAngular(() => this.timer = setTimeout(this.instance, this.speed));
    }

    onEnter() {
        if (this.pauseOnHover) {
            this.stopTime = true;
        }
    }

    onLeave() {
        if (this.pauseOnHover) {
            this.stopTime = false;
            setTimeout(this.instance, (this.speed - this.diff));
        }
    }

    setPosition(): number {
        return this.position !== 0 ? this.position * 90 : 0;
    }

    onClick($e) {
        if (this.item.click) {
            this.item.click.emit($e);
        }

        if (this.clickToClose) {
            this.remove();
        }
    }

    cancel(): void {
        this.remove();
    }

    openTask(task?: any): void {
        this.remove();
        this.broadcaster.broadcast("hideReminderDialog");
        this.broadcaster.broadcast("openTaskFromNotification", task || this.item.override);
    }

    showOverdueTasks() {
        this.remove();
        this.broadcaster.broadcast("hideReminderDialog");
        this.broadcaster.broadcast("showOverdueTasksFromNotification");
    }

    showDueTodayTasks() {
        this.remove();
        this.broadcaster.broadcast("hideReminderDialog");
        this.broadcaster.broadcast("showDueTodayTasksFromNotification");
    }

    // Attach all the overrides
    attachOverrides() {
        Object.keys(this.item.override).forEach(a => {
            if (this.hasOwnProperty(a)) {
                (<any>this)[a] = this.item.override[a];
            }
        });
    }

    ngOnDestroy() {
        clearTimeout(this.timer);
        this.isAlive = false;
    }

    private instance = () => {
        this.zone.runOutsideAngular(() => {
            this.zone.run(() => this.diff = (new Date().getTime() - this.start) - (this.count * this.speed));
            if (this.count++ === this.steps) {
                this.zone.run(() => this.remove());
            } else if (!this.stopTime) {
                if (this.showProgressBar) {
                    this.zone.run(() => this.progressWidth += 100 / this.steps);
                }
                this.timer = setTimeout(this.instance, (this.speed - this.diff));
            }
        });
    }

    remove() {
        if (this.animate) {
            this.item.state = this.animate + "Out";
            this.zone.runOutsideAngular(() => {
                setTimeout(() => {
                    this.zone.run(() => this.notificationService.set(this.item, false));
                }, 310);
            });
        } else {
            this.notificationService.set(this.item, false);
        }
    }
}
