
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

export class PendingOperation {

  public static PENDING_OPERATION_TYPE_TASK_DELETE = "task_delete";
  public static PENDING_OPERATION_TYPE_TASK_UPDATE = "task_update";
  public static PENDING_OPERATION_TYPE_TASK_DUPLOCATE = "tasK_duplicate";
  public static PENDING_OPERATION_TYPE_TASK_CREATE = "task_create";

  public static PENDING_OPERATION_TYPE_COMMENT_ADD = "comment_add";
  public static PENDING_OPERATION_TYPE_COMMENT_EDIT = "comment_edit";

  public static PENDING_OPERATION_TYPE_ATTACHMENTS_BULK_DELETE = "attachments_delete";

  task_id: number;
  type: string;
  additionalData: any;

  constructor(json) {
    this.task_id = json.task_id;
    this.type = json.type;
    if (json.additional_data) {
      this.additionalData = JSON.parse(json.additional_data);
    }
  }
}
