
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Output, EventEmitter, ChangeDetectionStrategy, Inject, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { takeWhile } from "rxjs/operators";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "vp-apps-dialog",
  templateUrl: "./apps-dialog.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppsDialogComponent implements OnDestroy {
  url: string;
  @Output() closeSwitch = new EventEmitter();
  isAlive = true;

  // This is an un-used depedency. Injected it here so
  // angular would instantiate it once for the shared module.
  constructor(
    private broadcaster: Broadcaster,
    private changerDetectorRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<AppsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.url = this.data.url;
    this.changerDetectorRef.markForCheck();
    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });
    this.broadcaster.on<any>("hideAppsDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });
  }

  hide(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.changerDetectorRef.markForCheck();
  }
}
