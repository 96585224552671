
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ICompact } from "./task";

export class TaskComment {
  id: number;
  user: ICompact;
  notes: string;
  created_on: Date;
  details: any[];
  userAvatar: any;
  isEditMode: boolean = false;
  firstLastNameCharacter: string;
  isEdited: boolean = false;

  constructor(json) {
    this.id = json.id;
    this.user = json.user;
    this.notes = json.notes;
    this.created_on = new Date(json.created_on);
    this.details = json.details;
    this.getCharacters(this.user.name);
    if (json.user && json.user.avatar_url) {
      this.userAvatar = json.user.avatar_url;
    }
  }

  getCharacters(name) {
    let firstLatstChar: string;
    let words: string[] = name.split(" ");
    if (words[0]) firstLatstChar = words[0].charAt(0);
    if (words.length > 1) firstLatstChar = firstLatstChar + words[1].charAt(0);
    this.firstLastNameCharacter = firstLatstChar;
  }

  static commentCreateoffline(notes: string, user: ICompact): TaskComment {
    let taskComment = new TaskComment({
      id: this.tempId(),
      notes: notes,
      user: user,
      created_on: new Date()
    });
    return taskComment;
  }

  static tempId(): number {
    return 1000000000 + Math.floor(Math.random() * 9999999); // temp id
  }
}
