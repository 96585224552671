
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { MessageTranslatorService } from "../../services/message-translator-service";
import { ChangeDetectionStrategy, ChangeDetectorRef, HostListener, Component, OnDestroy } from "@angular/core";
import { TasksConstants } from "../../shared/task-constacts";
import { TasksRootState, getMyTeamUsers, getAuthUser } from "../../store/index";
import { Store } from "@ngrx/store";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { MdlDialogReference, MdlDialogService } from "@angular-mdl/core";
import { TeamUser, AuthUser } from "../../models";
import { TaskRepository } from "../../repository/task.repository";
import { getOnlineStatus } from "../../../reducers";
import { InviteUsersComponent } from "./invite-users.component";
import { ArchiveUsersComponent } from "./archive-users.components";
import { CommonUtil } from "../../../common/utils/common.utils";
import { ErrorService } from "../../../common/providers/error-service";
import { SuccessService } from "../../../common/providers/success-service";
import { ErrorType, SuccessType } from "../../shared/task-enum";
import { takeWhile } from "rxjs/operators";
import { VncLibraryService } from "vnc-library";

@Component({
  selector: "vp-users-management",
  template: `
  <div class="task__dialog vp-users-management-dialog">
  <div class="task__dialog-header brand-color mdl-shadow--2dp">
    <div class="task__dialog-mobile-back-button">
      <button *ngIf="selectedTeamUsersList.length === 0" (click)="hide()" mat-button class="brand-color mat-button">
        <mdl-icon>chevron_left</mdl-icon>
      </button>
      <button *ngIf="selectedTeamUsersList.length > 0" (click)="clearSelectedUser()" mat-butto class="brand-color mat-button">
        <mdl-icon>clear</mdl-icon>
      </button>
    </div>
    <div class="task__dialog-desktop-close-button" *ngIf="selectedTeamUsersList.length === 0">
      <button (click)="hide()" class="brand-color mat-button">
        <mdl-icon class="material-icons">clear</mdl-icon>
      </button>
    </div>
    <div class="desktop-clear-btn" *ngIf="selectedTeamUsersList.length > 0">
      <button (click)="clearSelectedUser()" class="brand-color mat-button">
        <mdl-icon>chevron_left</mdl-icon>
      </button>
    </div>
    <div *ngIf="selectedTeamUsersList.length === 0" class="task__dialog-mobile-save-button button-icon" #bottomRightButton [matMenuTriggerFor]="archiveUserPopover">
      <button class="brand-color mat-button">
        <mdl-icon>more_vert</mdl-icon>
      </button>
    </div>
    <div *ngIf="selectedTeamUsersList.length > 0" class="task__dialog-mobile-save-button button-icon">
      <button *ngIf="selectedTeamUsersList.length > 0" class="brand-color mat-button" (click)="archiveUsers()">
        <mdl-icon>archive</mdl-icon>
      </button>
    </div>
    <h4 *ngIf="selectedTeamUsersList.length === 0">{{ 'USERS_MANAGEMENT' | translate}}</h4>
    <h4 *ngIf="selectedTeamUsersList.length > 0" class="selected-list-header">{{ selectedTeamUsersList.length }}</h4>
    <div class="desktop-header-btn" *ngIf="selectedTeamUsersList.length > 0">
      <button (click)="selectAllUsers()" class="brand-color mat-button select-all-btn">
        <mdl-icon class="material-icons">done_all</mdl-icon>
      </button>
      <button (click)="archiveUsers()" class="brand-color mat-button archive-btn">
        <mdl-icon class="material-icons">archive</mdl-icon>
      </button>
    </div>
  </div>
  <div class="task__dialog-body">
    <div class="option-list">
      <mdl-textfield class="filter-search-textfield" [(ngModel)]="searchText"  [label]="'SEARCH_USERS' | translate"  floating-label></mdl-textfield>
      <mdl-list>
        <mdl-list-item
          *ngFor="let teamUser of teamUserList  | vpTaskUserSearch : searchText;" vpLongPress [timeout]="700" (onLongPress)="$event.stopPropagation();selectTeamUsers(teamUser)" (click)="onClickTeamUser(teamUser)" [class.collected-users]="teamUser.isSelected">
          <div  class="task-icon-avtar disable-select" *ngIf ="!teamUser.avatar">{{ (teamUser) ? teamUser.firstLastNameCharacter : ('NO_NAME' | translate).charAt(0) }}</div>
          <div class="task-comment-avtar disable-select" *ngIf="teamUser.avatar" [class.hide-avatar]="teamUser.isSelected">
              <img img-cache [src] = "teamUser.avatar"/>
          </div>
          <div class="task-icon-avtar task-done-avtar disable-select" *ngIf="teamUser.isSelected">
            <mdl-icon>done</mdl-icon>
          </div>
          <span class="username-place disable-select">{{ (teamUser) ? teamUser.name : ('NO_NAME' | translate) }}</span>
          <mdl-icon *ngIf="!isMobileView" class="more-options" (click)="$event.stopPropagation();" [matMenuTriggerData]="{name: teamUser}" [matMenuTriggerFor]="moreMenu">more_vert</mdl-icon>
        </mdl-list-item>
      </mdl-list>
    </div>
  </div>
  <button *ngIf="selectedTeamUsersList.length === 0" id="newUserButton" (click)="openInviteDialog()" mat-button class="add_list-button add-button-with-icon">
    <i class="material-icons disable-select">add</i>
  </button>
  <mat-menu #archiveUserPopover="matMenu" class="team-user-item-menu" xPosition="after">
    <div class="archive-user-menu-popover">
        <mdl-list>
            <mdl-list-item mdl-ripple class="menu-item" (click)="openArchiveUsersDialog()">
              <mdl-icon>archive</mdl-icon>
              {{'ARCHIVED_USERS' | translate}}
            </mdl-list-item>
        </mdl-list>
    </div>
  </mat-menu>
  <mat-menu #moreMenu="matMenu" class="team-user-item-menu" xPosition="after">
    <ng-template matMenuContent let-name="name">
      <div  mat-menu-item (click)="selectTeamUsers(name)">
        <mdl-icon>check_circle</mdl-icon>
        <span>{{ 'SELECT_USER' | translate }}</span>
      </div>
      <div  mat-menu-item (click)="selectAllUsers()">
        <mdl-icon>done_all</mdl-icon>
        <span>{{ 'SELECT_ALL_USERS' | translate }}</span>
      </div>
      <div  mat-menu-item (click)="archiveUser(name)">
        <mdl-icon>archive</mdl-icon>
        <span>{{ 'ARCHIVE_USER' | translate }}</span>
      </div>
    </ng-template>
  </mat-menu>
  </div>
  <div class="vnctask-loading" *ngIf="isLoading">
    <div class="indicator">
    <vnc-spinner [size]="'medium'" *ngIf="true"></vnc-spinner>
    </div>
  </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class UsersManagementComponent implements OnDestroy {
  selectedFilter: string;
  isAlive: boolean = true;
  taskConstants = TasksConstants;
  teamUserList: TeamUser[] = [];
  isOnline: boolean = false;
  searchText: string = "";
  isLongPressed: boolean =  false;
  selectedTeamUsersList: TeamUser[] = [];
  isLoading: boolean = false;
  selectedTeamUserCount: number;
  authUser: AuthUser;
  isMobileView: boolean = CommonUtil.isOnMobileDevice();
  constructor(
    private store: Store<TasksRootState>,
    private broadcaster: Broadcaster,
    private changerDetectorRef: ChangeDetectorRef,
    private taskRepo: TaskRepository,
    private dialog: MdlDialogReference,
    private dialogService: MdlDialogService,
    private vncLibaryService: VncLibraryService,
    private successService: SuccessService,
    private errorService: ErrorService,
    private messageTranslatorService: MessageTranslatorService
  ) {
    this.setupStore();
    this.handleUserManagementErrorMessages();
    this.handleUserManagementSuccessMessages();
    this.handleArchiveUserErrorMessages();
    this.handleArchiveUserSuccessMessages();
    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });
    this.broadcaster.on<any>("hideUsersManagementDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });
    if (this.isOnline) {
      this.isLoading = true;
    }
    this.taskRepo.syncMyTeamUsers();
    this.taskRepo.syncAuthUser();
  }

  setupStore() {
    this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
      this.isOnline = isOnline;
      this.changerDetectorRef.markForCheck();
    });
    this.store.select(getMyTeamUsers).pipe(takeWhile(() => this.isAlive)).subscribe((teamUsers) => {
      teamUsers = teamUsers.filter( teamUsers => teamUsers.archived === false);
      this.teamUserList = teamUsers;
      this.changerDetectorRef.markForCheck();
    });
    this.store.select(getAuthUser).pipe(takeWhile(() => this.isAlive)).subscribe(user => {
      if (user) {
        this.authUser = user;
        this.changerDetectorRef.markForCheck();
      }
    });
  }

  handleUserManagementErrorMessages() {
    this.errorService.only(ErrorType.TeamUserListError).pipe(takeWhile(() => this.isAlive)).subscribe(error => {
      this.vncLibaryService.openSnackBar(error.messages, "","", "", 3000, "bottom", "left").subscribe(res => {
      });
      this.isLoading = false;
      this.changerDetectorRef.markForCheck();
    });
  }

  handleUserManagementSuccessMessages() {
    this.successService.only(SuccessType.TeamUserListSuccess).pipe(takeWhile(() => this.isAlive)).subscribe(success => {
      this.isLoading = false;
      this.changerDetectorRef.markForCheck();
    });
  }

  handleArchiveUserErrorMessages() {
    this.errorService.only(ErrorType.ArchiveUserError).pipe(takeWhile(() => this.isAlive)).subscribe(error => {
      this.vncLibaryService.openSnackBar(error.messages, "","", "", 3000, "bottom", "left").subscribe(res => {
      });
      this.isLoading = false;
      this.changerDetectorRef.markForCheck();
    });
  }

  handleArchiveUserSuccessMessages() {
    this.successService.only(SuccessType.ArchiveUserSuccess).pipe(takeWhile(() => this.isAlive)).subscribe(success => {
      this.vncLibaryService.openSnackBar(this.selectedTeamUserCount + " " + success.messages, "","", "", 3000, "bottom", "left").subscribe(res => {
      });
      this.isLoading = false;
      this.selectedTeamUsersList = [];
      this.taskRepo.syncMyTeamUsers();
      this.taskRepo.syncAuthUser();
      this.changerDetectorRef.markForCheck();
    });
  }

  openInviteDialog() {
    let dialogStyles: any = {};

    dialogStyles = {
      "visibility" : "visible",
      "min-width": "250px",
      "border-radius": "5px",
      "height": "auto",
      "max-width": "calc(100% - 40px)"
    };

    let config = {
      component: InviteUsersComponent,
      providers: [],
      isModal: true,
      styles: dialogStyles,
      animate: false,
      clickOutsideToClose: true
    };
    this.dialogService.showCustomDialog(config);
  }

  openArchiveUsersDialog() {
    let uDialog = this.dialogService.showCustomDialog({
      component: ArchiveUsersComponent,
      providers: [],
      isModal: true,
      styles: { visibility: "visible" },
      animate: false,
      clickOutsideToClose: true,
      classes: ""
    });
  }

  @HostListener("document:keydown.esc", ["$event"])
  hide() {
    this.dialog.hide();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.changerDetectorRef.markForCheck();
  }

  selectTeamUsers(teamUser) {
    if (teamUser) {
      teamUser.isSelected = !teamUser.isSelected;
      this.isLongPressed = true;
      this.onSelectTeamUser(teamUser);
    }
  }

  onSelectTeamUser(teamUser) {
    if (teamUser.isSelected) {
      this.selectedTeamUsersList.push(teamUser);
    } else {
      for (let i = 0; i < this.selectedTeamUsersList.length; i++) {
        if (this.selectedTeamUsersList[i].id === teamUser.id) {
          this.selectedTeamUsersList.splice(i, 1);
        }
      }
    }
    this.changerDetectorRef.markForCheck();
  }

  selectAllUsers() {
    this.selectedTeamUsersList = [];
    for (let i = 0; i < this.teamUserList.length; i++) {
      this.teamUserList[i].isSelected = true;
      this.selectedTeamUsersList.push(this.teamUserList[i]);
    }
    this.changerDetectorRef.markForCheck();
  }

  onClickTeamUser(teamUser) {
    if (CommonUtil.isOnIOS() && this.isLongPressed) {
      this.isLongPressed = false;
      return;
    }
    if (this.selectedTeamUsersList.length > 0 ){
      teamUser.isSelected = !teamUser.isSelected;
      this.onSelectTeamUser(teamUser);
    }
  }

  archiveUser(teamUser) {
    if (this.isOnline) {
      if (teamUser) {
        let userIds: number[] = [];
        this.selectedTeamUserCount = 1;
        userIds.push(teamUser.id);
        this.isLoading = true;
        this.taskRepo.archiveUsers(userIds);
      }
    } else {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
    }
  }

  archiveUsers() {
    if (this.isOnline) {
      if (this.selectedTeamUsersList.length > 0 ) {
        let userIds: number[] = [];
        this.selectedTeamUserCount = this.selectedTeamUsersList.length;
        this.selectedTeamUsersList.forEach(teamUser => {
          userIds.push(teamUser.id);
        });
        this.isLoading = true;
        this.taskRepo.archiveUsers(userIds);
      }
    } else {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
    }
  }

  clearSelectedUser() {
    for (let i = 0; i < this.teamUserList.length; i++) {
      this.teamUserList[i].isSelected = false;
    }
    this.selectedTeamUsersList = [];
    this.changerDetectorRef.markForCheck();
  }
}
