
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="taskLegalNoticeDialog" class="task__dialog vp-task-legal-notice-dialog">
    <div class="task__dialog-header">
        <div class="mobile-back-button">
            <button mat-button (click)="close()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
        <div class="header_lbl disable-select">
            {{'LEGAL_NOTICE' | translate}}
        </div>
        <div class="desktop-close-button">
            <button mat-button (click)="close()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
    </div>
    <div class="task__dialog-body task__dialog-body--pad">
        <div class="legalnotice-action-list" *ngIf="localLang === 'de'">
            <div class="terms-action-div" id="termsOfUseMenu">
                <a target="_blank" class="open-new-window none-decoration" href="https://vnclagoon.com/de/Nutzungsbedingungen/">
                    <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <title>Icon/product/assignment-24px</title>
                        <g id="Icon/product/assignment-24px" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Icon-24px" transform="translate(2.000000, 1.000000)" fill="#8B96A0">
                                <path d="M16,2 L11.82,2 C11.4,0.84 10.3,0 9,0 C7.7,0 6.6,0.84 6.18,2 L2,2 C0.9,2 0,2.9 0,4 L0,18 C0,19.1 0.9,20 2,20 L16,20 C17.1,20 18,19.1 18,18 L18,4 C18,2.9 17.1,2 16,2 L16,2 Z M9,2 C9.55,2 10,2.45 10,3 C10,3.55 9.55,4 9,4 C8.45,4 8,3.55 8,3 C8,2.45 8.45,2 9,2 L9,2 Z M11,16 L4,16 L4,14 L11,14 L11,16 L11,16 Z M14,12 L4,12 L4,10 L14,10 L14,12 L14,12 Z M14,8 L4,8 L4,6 L14,6 L14,8 L14,8 Z" id="Shape"></path>
                            </g>
                        </g>
                    </svg>
                    <span>{{'TERMS_OF_USE' | translate}}</span>
                </a>
            </div>
            <div class="terms-action-div" id="dataPrivacyMenu">
                <a target="_blank" class="open-new-window none-decoration" href="https://vnclagoon.com/de/datenschutzerklaerung/">
                    <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <title>Icon/product/lock</title>
                        <g id="Icon/product/lock" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Icon-24px" transform="translate(4.000000, 1.000000)" fill="#8B96A0">
                                <path d="M14,7 L13,7 L13,5 C13,2.24 10.76,0 8,0 C5.24,0 3,2.24 3,5 L3,7 L2,7 C0.9,7 0,7.9 0,9 L0,19 C0,20.1 0.9,21 2,21 L14,21 C15.1,21 16,20.1 16,19 L16,9 C16,7.9 15.1,7 14,7 L14,7 Z M8,16 C6.9,16 6,15.1 6,14 C6,12.9 6.9,12 8,12 C9.1,12 10,12.9 10,14 C10,15.1 9.1,16 8,16 L8,16 Z M11.1,7 L4.9,7 L4.9,5 C4.9,3.29 6.29,1.9 8,1.9 C9.71,1.9 11.1,3.29 11.1,5 L11.1,7 L11.1,7 Z" id="Shape"></path>
                            </g>
                        </g>
                    </svg>
                    <span>{{'DATA_PRIVACY' | translate}}</span>
                </a>
            </div>
        </div>
        <div class="legalnotice-action-list" *ngIf="localLang === 'en'">
            <div class="terms-action-div" id="termsOfUseMenu">
                <a target="_blank" class="open-new-window none-decoration" href="https://vnclagoon.com/terms">
                    <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <title>Icon/product/assignment-24px</title>
                        <g id="Icon/product/assignment-24px" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Icon-24px" transform="translate(2.000000, 1.000000)" fill="#8B96A0">
                                <path d="M16,2 L11.82,2 C11.4,0.84 10.3,0 9,0 C7.7,0 6.6,0.84 6.18,2 L2,2 C0.9,2 0,2.9 0,4 L0,18 C0,19.1 0.9,20 2,20 L16,20 C17.1,20 18,19.1 18,18 L18,4 C18,2.9 17.1,2 16,2 L16,2 Z M9,2 C9.55,2 10,2.45 10,3 C10,3.55 9.55,4 9,4 C8.45,4 8,3.55 8,3 C8,2.45 8.45,2 9,2 L9,2 Z M11,16 L4,16 L4,14 L11,14 L11,16 L11,16 Z M14,12 L4,12 L4,10 L14,10 L14,12 L14,12 Z M14,8 L4,8 L4,6 L14,6 L14,8 L14,8 Z" id="Shape"></path>
                            </g>
                        </g>
                    </svg>
                    {{'TERMS_OF_USE' | translate}}
                </a>
            </div>
            <div class="terms-action-div" id="dataPrivacyMenu">
                <a target="_blank" class="open-new-window none-decoration" href="https://vnclagoon.com/data-privacy-policy/">
                    <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <title>Icon/product/lock</title>
                        <g id="Icon/product/lock" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Icon-24px" transform="translate(4.000000, 1.000000)" fill="#8B96A0">
                                <path d="M14,7 L13,7 L13,5 C13,2.24 10.76,0 8,0 C5.24,0 3,2.24 3,5 L3,7 L2,7 C0.9,7 0,7.9 0,9 L0,19 C0,20.1 0.9,21 2,21 L14,21 C15.1,21 16,20.1 16,19 L16,9 C16,7.9 15.1,7 14,7 L14,7 Z M8,16 C6.9,16 6,15.1 6,14 C6,12.9 6.9,12 8,12 C9.1,12 10,12.9 10,14 C10,15.1 9.1,16 8,16 L8,16 Z M11.1,7 L4.9,7 L4.9,5 C4.9,3.29 6.29,1.9 8,1.9 C9.71,1.9 11.1,3.29 11.1,5 L11.1,7 L11.1,7 Z" id="Shape"></path>
                            </g>
                        </g>
                    </svg>
                    {{'DATA_PRIVACY' | translate}}
                </a>
            </div>
        </div>
    </div>
</div>
