
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { SearchActionTypes } from "../actions/search";
import { Action } from "../../../actions";
import { AppType, TimeRangeType, DueDateTimeRangeType, AdvanceSearchRequest, AdvanceSearchTime, AdvanceSearchThroughType, Project, ICompact, AdvanceSearchDueDateTime, TagType, AdvanceSearchTag, UserType, AdvanceSearchUser, ListType, AdvanceSearchList, LocationType, AdvanceSearchLocation } from "../../models";
import * as _ from "lodash";


export interface SearchState {
  request: AdvanceSearchRequest;
  keyword: string;
}

export const initialState: SearchState = {
  request: {
    app_types: [
      AppType.VNCTASK
    ],

    time: {
      type: TimeRangeType.NONE,
      from: null,
      to: null
    },

    dueDateTime: {
      type: DueDateTimeRangeType.NONE,
      from: null,
      to: null
    },

    search_through_type: {
      taskname: true,
      description: true,
      comment: true
    },

    assignees: {
      type: UserType.NONE,
      list: []
    },
    projects: [],
    tags: {
      type: TagType.NONE,
      list: []
    },
    lists: {
      type: ListType.NONE,
      list: []
    },
    locations: {
      type: LocationType.NONE,
      list: []
    },

    attachments: false,
    closed_tasks: false,
    save_searches: false,
  },
  keyword: ""
};

export function searchReducer(state: SearchState = initialState, action: Action): SearchState {
  switch (action.type) {

    case SearchActionTypes.SEARCH_KEYWORD_UPDATE: {
      return {
        ...state,
        keyword: action.payload as string
      };
    }

    case SearchActionTypes.SEARCH_CLEAR_ALL: {
      return {
        ...state,
        request: {
          app_types: [
            AppType.VNCTASK
          ],

          time: {
            type: TimeRangeType.NONE,
            from: null,
            to: null
          },

          dueDateTime: {
            type: DueDateTimeRangeType.NONE,
            from: null,
            to: null
          },

          search_through_type: {
            taskname: true,
            description: true,
            comment: true
          },

          assignees: {
            type: UserType.NONE,
            list: []
          },
          projects: [],
          tags: {
            type: TagType.NONE,
            list: []
          },
          lists: {
            type: ListType.NONE,
            list: []
          },
          locations: {
            type: LocationType.NONE,
            list: []
          },
          attachments: false,
          closed_tasks: false,
          save_searches: false,
        },
        keyword: ""
      };
    }

    case SearchActionTypes.SEARCH_APP_UPDATE: {
      return {
        ...state,
        request: {
          ...state.request,
          app_types: action.payload as AppType[]
        }
      };
    }

    case SearchActionTypes.SEARCH_TIME_RANGE_UPDATE: {
      return {
        ...state,
        request: {
          ...state.request,
          time: action.payload as AdvanceSearchTime
        }
      };
    }

    case SearchActionTypes.SEARCH_DUEDATE_RANGE_UPDATE: {
      return {
        ...state,
        request: {
          ...state.request,
          dueDateTime: action.payload as AdvanceSearchDueDateTime
        }
      };
    }

    case SearchActionTypes.SEARCH_THROUGH_TYPE_UPDATE: {
      return {
        ...state,
        request: {
          ...state.request,
          search_through_type: action.payload as AdvanceSearchThroughType
        }
      };
    }

    case SearchActionTypes.SEARCH_ASSIGNEES_UPDATE: {
      return {
        ...state,
        request: {
          ...state.request,
          assignees: action.payload as AdvanceSearchUser
        }
      };
    }

    case SearchActionTypes.SEARCH_TAGS_UPDATE: {
      return {
        ...state,
        request: {
          ...state.request,
          tags: action.payload as AdvanceSearchTag
        }
      };
    }

    case SearchActionTypes.SEARCH_LISTS_UPDATE: {
      return {
        ...state,
        request: {
          ...state.request,
          lists: action.payload as AdvanceSearchList
        }
      };
    }

    case SearchActionTypes.SEARCH_LOCATIONS_UPDATE: {
      return {
        ...state,
        request: {
          ...state.request,
          locations: action.payload as AdvanceSearchLocation
        }
      };
    }

    case SearchActionTypes.SEARCH_PROJECTS_UPDATE: {
      return {
        ...state,
        request: {
          ...state.request,
          projects: action.payload as Project[]
        }
      };
    }

    case SearchActionTypes.SEARCH_ATTACHMENTS_UPDATE: {
      return {
        ...state,
        request: {
          ...state.request,
          attachments: action.payload as boolean
        }
      };
    }

    case SearchActionTypes.SEARCH_CLOSED_TASKS_UPDATE: {
      return {
        ...state,
        request: {
          ...state.request,
          closed_tasks: action.payload as boolean
        }
      };
    }

    case SearchActionTypes.SAVE_SEARCH_UPDATE: {
      return {
        ...state,
        request: {
          ...state.request,
          save_searches: action.payload as boolean
        }
      };
    }

    default: {
      return state;
    }
  }
}

export const _getAdvanceSearchRequest = (state: SearchState) => state.request;
export const _getSearchKeyword = (state: SearchState) => state.keyword;
