
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, forwardRef, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from "@angular/core";
import { TaskOptionComponent } from "../task-option.component";
import { TaskRepository } from "../../../../repository/task.repository";
import { TaskToolbarComponent } from "../../../task-toolbar/task-toolbar.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "vp-vnctask-option-add-description",
  providers: [{
    provide: TaskOptionComponent,
    // eslint-disable-next-line
    useExisting: forwardRef(() => TaskAddDescriptionComponent)
  }],
  templateUrl: "options-add-description.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskAddDescriptionComponent extends TaskOptionComponent implements OnInit {
  id: string = "add-description";
  triggerKey: string = "%";
  description: any = {
    name: "Description",
    value: ""
  };
  @ViewChild("taskToolbar", { static: false }) taskToolbar: TaskToolbarComponent;

  constructor(
    private taskRepo: TaskRepository,
    public changerDetectorRef: ChangeDetectorRef,
    public matDialog: MatDialog
  ) {
    super(changerDetectorRef, matDialog);
  }

  ngOnInit() { }

  getPayload() {
    let value = this.getValue();
    if (!value || (value && !value.value)) return {};
    return { description: value.value };
  }

  setDescription() {
    this.description.value = this.taskToolbar.getContentInTextile();
    this.onDescriptionClick(this.description);
  }
}
