
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="taskAttachmentOptionDialog" class="vp-task-attachment-option-dialog">
  <div>
    <mat-list role="list" class="action-list">
      <mat-list-item role="listitem" id="attachment-option-uploadfile" (click)="uploadFileChooser.click()">
        <mdl-icon>file_upload</mdl-icon>
        <span>{{'UPLOAD_FILE' | translate}}</span>
        <input id="custom-file-input" #uploadFileChooser type="file" class="hide" (click)="uploadFileChooser.value = null;" (change)="fileUploadListener($event)">
      </mat-list-item>
      <mat-list-item role="listitem" *ngIf="isMobileDevice" id="attachment-option-photo" (click)="capturePhoto()" [class.disable-event]="writeExternalPermission === 'false'">
        <mdl-icon>camera_alt</mdl-icon>
        <span>{{'TAKE_PHOTO' | translate}}</span>
        <input id="custom-photo-input" class="hide" type="file" (change)="fileUploadListener($event)" accept="image/*" capture="camera" />
      </mat-list-item>
      <mat-list-item role="listitem" *ngIf="isMobileDevice" id="attachment-option-video" (click)="makeVideo()" [class.disable-event]="writeExternalPermission === 'false'">
        <mdl-icon>videocam</mdl-icon>
        <span>{{'MAKE_VIDEO' | translate}}</span>
        <input id="custom-video-input" class="hide" type="file" (change)="fileUploadListener($event)" accept="video/*" capture="camorder" />
      </mat-list-item>
      <mat-list-item role="listitem" id="attachment-option-voice" (click)="openRecordVoiceDialog()">
        <mdl-icon>mic</mdl-icon>
        <span>{{'ADD_VOICE_MESSAGE' | translate}}</span>
      </mat-list-item>
    </mat-list>
  </div>
</div>