
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnDestroy, ChangeDetectorRef, HostListener } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { TFARepository } from "src/app/task/repository/tfa.repository";
import { RootState, getOnlineStatus, getUserProfile } from "src/app/reducers";
import { takeUntil, take } from "rxjs/operators";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { MessageTranslatorService } from "src/app/task/services";
import { TasksConstants } from "src/app/task/shared/task-constacts";
import { MatDialogRef } from "@angular/material/dialog";
import { VncLibraryService } from "vnc-library";

@Component({
  selector: "vp-tfa-settings",
  templateUrl: "./tfa-settings.html"
})
export class TFASettingsComponent implements OnDestroy {
  app = "vnclagoon";
  updateForm: UntypedFormGroup;
  otpCode: UntypedFormControl;
  qrCode: any;
  secret: any;
  email: any;
  private isAlive$ = new Subject<boolean>();
  isAppOnline: boolean;
  isManualEnabled: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<TFASettingsComponent>,
    private translate: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private tfaRepo: TFARepository,
    private store: Store<RootState>,
    private broadcaster: Broadcaster,
    private vncLibaryService: VncLibraryService,
    private messageTranslatorService: MessageTranslatorService
  ) {
    this.setupStore();

    this.broadcaster.on<any>("hideTfaSettings")
      .pipe(takeUntil(this.isAlive$))
      .subscribe(data => {
        this.dialogRef.close();
      });

    this.otpCode = new UntypedFormControl("", [Validators.required]);
    const defaultGroup = {
      "otpCode": this.otpCode
    };
    this.updateForm = new UntypedFormGroup(defaultGroup);
    let user = localStorage.getItem("profileUser");
    const contactUser = typeof user === "string"
            ? JSON.parse(user)
            : user;
    this.secret = this.createSecret();
    this.email = contactUser.email;
    this.qrCode = this.getQRCodeGoogleUrl(this.app, this.email, this.secret);
    this.changeDetectorRef.markForCheck();
  }

  setupStore() {
    this.store.select(getOnlineStatus).pipe(takeUntil(this.isAlive$)).subscribe(v => this.isAppOnline = v);
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();

    if (document.querySelector(".dialog-backdrop") !== null) {
      (<HTMLElement>document.querySelector(".dialog-backdrop")).style.display = "none";
    }
  }

  getBase32LookupTable() {
    return [
        "A", "B", "C", "D", "E", "F", "G", "H", //  7
        "I", "J", "K", "L", "M", "N", "O", "P", // 15
        "Q", "R", "S", "T", "U", "V", "W", "X", // 23
        "Y", "Z", "2", "3", "4", "5", "6", "7", // 31
        "="  // padding char
    ];
  }

  createSecret(secretLength = 16) {
    let validChars = this.getBase32LookupTable();
    let secret = "";
    for (let i = 0; i < secretLength; i++) {
        secret += validChars[Math.floor(Math.random() * (validChars.length - 1 ))];
    }
    return secret;
  }

  getQRCodeGoogleUrl(app, name, secret) {
    let urlencoded = encodeURI("otpauth://totp/" + app + "(" + name + ")?secret=" + secret );
    return "https://chart.googleapis.com/chart?chs=200x200&chld=M|0&cht=qr&chl=" + urlencoded;
  }

  @HostListener("document:keydown.esc")
  onEsc(): void {
    this.dialogRef.close();
  }

  submitForm() {
    if (!this.isAppOnline) {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
      return;
    }
    if (this.otpCode && this.otpCode.value) {
      this.tfaRepo.enableTFA(this.secret).subscribe( res => {
        this.tfaRepo.verify2faOTP(this.otpCode.value).subscribe( res => {
            const message = this.messageTranslatorService.getMessage(TasksConstants.TFA_CONFIGURED_SUCCESS);
            this.vncLibaryService.openSnackBar(message, "","", "", 3000, "bottom", "left").subscribe(res => {
            });
            this.dialogRef.close(true);
        }, err => {
          console.log("[TFASettingsComponet][submitForm] Enter wrong otp:", err);
          const message = this.messageTranslatorService.getMessage(TasksConstants.ENTER_WRONG_OTP);
            this.vncLibaryService.openSnackBar(message, "","", "", 3000, "bottom", "left").subscribe(res => {
          });
        });
      }, err => {
        console.log("[TFASettingsComponet][submitForm] error saving 2fa settings:", err);
          const message = this.messageTranslatorService.getMessage(TasksConstants.ERROR_SAVE_2FA_SETTING);
          this.vncLibaryService.openSnackBar(message, "","", "", 3000, "bottom", "left").subscribe(res => {
        });
      });
    }
  }
}
