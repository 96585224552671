
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

 <div id="mainLayout" class= "task-container">
    <div class="task-list-view" [class.task-detail]="isDetailView" [class.task-list]="tasks.length > 0 && !isDetailView"  [class.is-right-sidebar-expanded]="rightSidebarExpanded">
      <div *ngIf="isSearchMode">
        <div class="task-search-result-panel">
          <div class="search-result">
            <span>{{ searchStatistic.total_count }} {{ 'RESULT_FOUND_IN_TASK' | translate}}</span>
          </div>
          <div class="search-header-options">
            <div class="search-header-options-task">
              <img class="vnc-icon" src="assets/images/VNCtask.svg"><span>{{ 'TASKS' | translate}}</span>
            </div>
            <div *ngIf="isSaveSearchEnable" class="search-header-options-save" (click)="saveSearchQuery()">
              <mdl-icon>save</mdl-icon><span>{{ 'SAVE_SEARCH' | translate}}</span>
            </div>
            <button id="main-layout-search-clear" mat-button (click)="onClearSearch()">
              <svg style="margin: 2px;" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>Icon/product/close-new</title>
                <g id="Icon/product/close-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <path d="M18.9497475,5.05025253 C19.3402718,5.44077682 19.3402718,6.0739418 18.9497475,6.46446609 L13.4142136,12 L18.9497475,17.5355339 C19.3402718,17.9260582 19.3402718,18.5592232 18.9497475,18.9497475 C18.5592232,19.3402718 17.9260582,19.3402718 17.5355339,18.9497475 L12,13.4142136 L6.46446609,18.9497475 C6.0739418,19.3402718 5.44077682,19.3402718 5.05025253,18.9497475 C4.65972824,18.5592232 4.65972824,17.9260582 5.05025253,17.5355339 L10.5857864,12 L5.05025253,6.46446609 C4.65972824,6.0739418 4.65972824,5.44077682 5.05025253,5.05025253 C5.44077682,4.65972824 6.0739418,4.65972824 6.46446609,5.05025253 L12,10.5857864 L17.5355339,5.05025253 C17.9260582,4.65972824 18.5592232,4.65972824 18.9497475,5.05025253 Z" id="Path" fill="#8B96A0"></path>
                </g>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <vp-vnctask-filter *ngIf="( screen === 'desktop' && !isSearchMode ) || !isSearchDialogActive" (onClick)="onClickReloadTasks()" (onCloseCheckClick)="onCheckShowOnlyCloseTasks($event)" (onOpenCheckClick)="onCheckShowOnlyOpenTasks($event)" (onShowMyTaskClick)="onCheckShowOnlyMyTasks($event)" (onSelect)="onFilterSelect($event)"></vp-vnctask-filter>
      <vp-vnctask-header-menubar #filter [isChangeUserOptionVisible]="isChangeUserOptionVisible" [selectAll]= "selectAll" [isSearchMode]= "isSearchMode" [selectedTasksIds]="selectedTasksIds" [totalCount]="tasks.length" (menuItemClick)="clickEventManage($event)"></vp-vnctask-header-menubar>
      <vp-vnctask-list (scrollMore)="loadMoreTasks($event)" id="tasksList" (openAddTaskDialog)="openAddTaskDialog()" (onSelectTask)="onSelectTask($event)" [tasks]="tasks" (onTaskClick)="onTaskClick($event);"></vp-vnctask-list>
      <!-- <button id="newTaskButton" *ngIf="isMobileDevice && !isSearchMode" (click)="openAddTaskDialog()" class="task__add-button brand-button-color mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect mdl-button--colored" [class.detailview] = "isDetailView">
        <i class="material-icons">add</i>
      </button> -->
      <vnc-button id="newTaskButton" *ngIf="isMobileDevice && !isSearchMode" (click)="openAddTaskDialog()" class="task__add-button add-task-mobile-button" [class.detailview] = "isDetailView" [diameter]="'large'" [shape]="'circle'" [roundButtonType]="'blue'">
        <mat-icon>add</mat-icon>
     </vnc-button>
    </div>
    <div class="task-detail-view" [class.hide-detail-view]="(!isDetailView && isMobileDevice) || tasks.length === 0" [class.is-right-sidebar-expanded]="rightSidebarExpanded">
      <router-outlet></router-outlet>
    </div>
    <vp-right-sidebar *ngIf="rightSidebarExpanded"></vp-right-sidebar>
</div>
<div class="vnctask-loading" *ngIf="isLoading" [class.pulltorefresh]="isRefreshing" id="loadingSpinner">
  <div class="indicator">
    <vnc-spinner [size]="'medium'" *ngIf="true"></vnc-spinner>
  </div>
</div>