
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vp-select-tags-dialog" id="taskSelectTagsDialog">
  <div class="task__dialog-header">
    <div class="mobile-back-button">
        <button mat-button (click)="close()">
          <mat-icon class="disable-select">close</mat-icon>
        </button>
    </div>
    <div class="header_lbl disable-select">
        {{'SELECT_TAGS' | translate}}
    </div>
    <div class="desktop-close-button">
        <button mat-button (click)="close()">
          <mat-icon class="disable-select">close</mat-icon>
        </button>
    </div>
  </div>
  <div class="task__dialog-body task__dialog-body--pad">

    <mat-list role="list">
      <mat-radio-group (change)="typeChanged()" class="select-tags-radio-group" [(ngModel)]="selectedTagType">
        <mat-list-item role="listitem">
          <mat-radio-button class="radio-button-style" id="input_none" [value]="TagType.NONE">{{ getTagTypeObject(TagType.NONE).translation_key | translate }}</mat-radio-button>
        </mat-list-item>
        <mat-list-item role="listitem">
          <mat-radio-button class="radio-button-style" id="input_all_tags" [value]="TagType.ALL_TAGS">{{ getTagTypeObject(TagType.ALL_TAGS).translation_key | translate }}</mat-radio-button>
        </mat-list-item>
        <mat-list-item role="listitem">
          <mat-radio-button class="radio-button-style" id="input_selected" [value]="TagType.SELECTED">{{ getTagTypeObject(TagType.SELECTED).translation_key | translate }}</mat-radio-button>
        </mat-list-item>
      </mat-radio-group>
    </mat-list>



    <div class="selected-tags" *ngIf="selectedTagType === TagType.SELECTED">
      <div id="select-tags-list-dialog" class="option-list">
        <mdl-textfield class="filter-search-textfield" [(ngModel)]="searchText" [label]="'FIND_THE_TAG' | translate" floating-label></mdl-textfield>
        <mat-list role="list" [class.changed]="changed">
          <mat-list-item role="listitem" class="tag-list-item" *ngFor="let tag of tagList  | vpTaskProjectSearch : searchText; let i = index;"
            [class.selected]="i == selectedIndex">
            <div class="list-lable">
              <span>{{tag.name}}</span>
            </div>
            <div class="list-action">
              <vnc-checkbox [isRoundCheckBox]="true" (change)="tagSelected($event , tag)" [checked]="isTagSelected(tag)"></vnc-checkbox>
            </div>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
  </div>
  <div class="task__dialog-footer">
      <div class="desktop-footer">
          <button mat-button (click)="close()">
              {{ 'CANCEL' | translate }}
          </button>
          <button mat-button [disabled]="!changed" (click)="saveTags()">
              {{ 'SAVE' | translate }}
          </button>
      </div>
      <div class="mobile-footer" *ngIf="changed">
          <div class="title">
              {{ 'SETTINGS_UPDATED_APPLY_CHANGES' | translate }}
          </div>
          <div class="save_footer_btn">
              <button mat-button (click)="saveTags()">
                  <mat-icon>check</mat-icon>
              </button>
          </div>
      </div>
  </div>
</div>
